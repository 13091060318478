import React from "react";
import PropTypes from "prop-types";
import Button from '@mui/material/Button';

import Utils from "../../utils";
import {API, doPostPdf} from '../../api';
import Custom3 from "./components/Custom3";
import {clientType} from "../../constants";

export default class NoResultReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            messages: Utils.isNotNullOrUndefined(sessionStorage.getItem("messages")) ? JSON.parse(sessionStorage.getItem("messages")) : [],
            isLoading : false,
        };
    }

    render() {
        let searchData = JSON.parse(sessionStorage.getItem('searchData'));
        const relationNodes = JSON.parse(sessionStorage.getItem('relationPathNodes'));
        const emptyFrom = sessionStorage.getItem('resultFrom');
        const notRelevant = sessionStorage.getItem('notRelevant');

        let relatedItems = '';
        if (relationNodes?.nodes.length > 0) {
            relationNodes.nodes.forEach(item => {
                relatedItems += item + ','
            })
            relatedItems = relatedItems.slice(0, -1)
        }

        let searchOpt = null;
        if (Utils.isNotNullOrUndefined(searchData)) {
            let options = searchData.options;
            searchOpt = Object.keys(options).map((key) => {
                let val = options[key]
                if (key === 'lng') {
                    key = 'Language '
                }
                if (key === 'match') {
                    key = 'Match type '
                    val = `<span style="color: #ef4254" >${val.toUpperCase()} </span>with morphological similarity and char difference tolerance of:
                            <span style="color: #ef4254" >${options.ld}</span>`
                }
                if (key === 'date') {
                    key = 'Date '
                }
                if (key === 'related') {
                    key = 'Related'
                }
                if (key === 'country') {
                    key = 'Country'
                }
                if (key === 'relatedRadius') {
                    key = 'Related radius '
                }

                if (key !== 'ld') {
                    return (
                        <div className='row' key={`search_${key}`}>
                            <div className='col-xs-12 ' style={{fontSize: '17px', padding: '10px 0 0 60px'}}><b>{key}:</b> <span dangerouslySetInnerHTML={{__html: val}}></span></div>
                        </div>
                    )
                }
                return null;

            })
        }

        return (
            <div className="container-fluid">
                <div className='row banner'>
                    <img alt='noResultBannerImg' src="/images/no_result_banner.png" style={{width: "100%"}}/>
                </div>
                <div className='col-xs-12 removePrint text-right' style={{marginTop: "10px"}}>
                    <Button
                        onClick={() => !this.state.isLoading && this.genPdf()}
                        secondary="true"
                        className="fab-explore"
                        style={{
                            textTransform: "capitalize",
                            borderRadius: 25,
                            backgroundColor: "#ef4254",
                            color: "#fff",
                            minWidth:'100px',
                            fontSize: '13px',
                            padding: '6px 10px'
                        }}>{this.state.isLoading ? 'Downloading...' : 'Print as PDF'}</Button>
                </div>
                {
                    emptyFrom === 'search' ? (
                        <div className='noResult'>
                            <div style={{padding: "0 20px"}}>
                                <div className='row'>
                                    <div className='col-xs-12' style={{
                                        padding: '30px 0 10px 60px',
                                        fontSize: '16px',
                                        fontFamily: "roboto",
                                        color: "#000"
                                    }}>The following criteria were used to run the search query with no results:
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-xs-12' style={{padding: '10px 0 0 60px', fontSize: '17px'}}><b>Search
                                        Query:</b> <span style={{color: "#ef4254"}}>{searchData.q}</span></div>
                                </div>
                                <div className='row'>
                                    <div className='col-xs-12' style={{padding: '10px 0 0 60px', fontSize: '17px'}}><b>Entity
                                        type: </b><span style={{color: "#ef4254"}}>
                                            {searchData.type.toUpperCase()}
                                        </span></div>
                                </div>
                                <div>{searchOpt}</div>
                                {notRelevant && <div>
                                    <div className='row'>
                                        <div className='col-xs-12' style={{padding: '10px 0 0 60px', fontSize: '17px'}}>
                                            <b>Date
                                                and Time of the Research: </b> {searchData.date}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-xs-12' style={{padding: '10px 0 0 60px', fontSize: '17px'}}>
                                            <b> Results Found: </b><span
                                            style={{color: "#ef4254"}}>{sessionStorage.getItem('notRelevant')}</span>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                            {!notRelevant
                                ?
                                <div>
                                    <div className='row'>
                                        <div className='col-xs-12 text-center' style={{
                                            padding: '20px 0',
                                            fontSize: '16px',
                                            fontWeight: "bolder",
                                            textTransform: "uppercase",
                                            color: "#ef4254"
                                        }}>
                                            The research did not generate any results to display.
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-xs-12 text-center'
                                             style={{paddingBottom: '20px', fontSize: '16px'}}>
                                            <b>Date and Time of the Research: </b> {searchData.date}</div>
                                    </div>
                                </div>
                                :
                                <div className='row '>
                                    <div className='col-xs-12 text-center' style={{padding: '0 40px'}}>
                                        <span style={{color: '#ef4254', fontSize: '20px', fontWeight: 600}}>NO RELEVANT MATCH REPORT</span>
                                        <div style={{margin: '5px 0', textAlign: 'center'}}>
                                            <div className={'analystContent'}
                                                 style={{display: '-webkit-box', alignItems: 'center'}}>
                                                <span className={'editIconNoRelevant'}>&#9998;</span>
                                                <span className={'analystText'}>USER NOTES</span></div>
                                            <div className={'analystLine'}/>
                                        </div>

                                        <div style={{
                                            display: '-webkit-box',
                                            alignItems: 'flex-start',
                                            padding: '5px 0 20px 0'
                                        }}>
                                            <div className='customContent' style={{marginTop: '0'}}>
                                                <Custom3 ref="componentContent" print={this.props.location.search}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    ) : (
                        <div style={{padding: "0 20px"}} className='noResult'>
                            <div className='row'>
                                <div className='col-xs-12' style={{padding: '10px 0 0 60px', fontSize: '17px'}}><b>Search
                                    Query:</b> <span style={{color: "#ef4254"}}>{relatedItems}</span></div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 text-center'
                                     style={{paddingBottom: '20px', fontSize: '15px', marginTop: 35, color: "#ef4254"}}>
                                    <p>There is no relation between the nodes. </p>
                                    <p>Please note that relation path search has limitations. </p>
                                    <p>We have searched the distance of 6 relations. The path among the searched nodes
                                        may still exist in further radiuses.</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 text-center'
                                     style={{paddingBottom: '20px', fontSize: '16px'}}><b>Date
                                    and Time of the Research: </b> {relationNodes.date}</div>
                            </div>
                        </div>
                    )
                }

                <div className="row">

                    {this.state.messages.length > 0 && emptyFrom !== 'path' &&
                        <div style={{textAlign: "center", color: "#ef4254", marginBottom: "20px"}}
                             dangerouslySetInnerHTML={{__html: this.state.messages.join("</br>")}}/>
                    }
                </div>
                <div className='row'>
                    <div className='col-xs-12 ' style={{height: "8px", backgroundColor: "#ef4254"}}></div>
                </div>


                <div className="row" style={{padding: "0 20px"}}>
                    <div className='col-xs-12 ' style={{padding: "0 50x"}}>
                        <p style={{fontSize: "18px", marginTop: "20px"}}><b>IMPORTANT DISCLAIMERS</b></p>

                        <b>1.No External Distribution</b>

                        <div style={{fontSize: "15px", marginBottom: "18px", textAlign: "justify"}}>
                            The results of ARDIS analysis, including the PDF or any other format saved in part of fully
                            (hereinafter “Analysis”),
                            shall not be communicated under any circumstances to third parties, including to the Subject
                            of the analysis. The only
                            exception to this rule being the applicable Swiss or user’s jurisdiction specific
                            legislation of compulsory disclosure to
                            regulatory authorities or GDPR related matters in a manner provided by the law of the
                            applicable jurisdiction.
                        </div>
                        <b>2. Analysis Content</b>
                        <div style={{fontSize: "15px", marginBottom: "18px", textAlign: "justify"}}>

                            The content in this Analysis is overwhelmingly Machine Generated and is provided based on
                            sources which Polixis
                            has reasonable grounds to believe to be serious and reliable. We aim to provide content
                            which to the best of our
                            knowledge and within the limits of reasonable diligence, is accurate.
                            This being said, we cannot be liable or responsible for any inaccuracy, incompleteness,
                            error, delay or timely nature of
                            information, content and materials in this product.
                        </div>
                        <b>3. "As Is Basis"</b>
                        <div style={{fontSize: "15px", marginBottom: "18px", textAlign: "justify"}}>

                            The information in the product is provided on “as is” basis without warranties or
                            representations of any kind. Within
                            the limits permitted by the applicable law Polixis disclaims all warranties, whether express
                            or implied, including, but
                            not limited to, any and all warranties of merchantability or fitness for a particular
                            purpose, of non-infringement and
                            title.
                            In no case, will Polixis, its employees or agents be liable for any direct, indirect,
                            consequential, special, incidental,
                            punitive, or exemplary damages, including, without limitation, lost profits, lost savings,
                            lost revenues, reputation or
                            goodwill.
                            The Customer shall the case being, confirm the contents of any documents and information
                            provided by ARDIS
                            analysis by means of appropriate secondary sources before making any cost-relevant or
                            regulatory-sensitive
                            decisions in connection with them.
                        </div>
                        <b>4. Links to third parties</b>
                        <div style={{fontSize: "15px", marginBottom: "18px", textAlign: "justify"}}>

                            The Analysis may contain links to third party websites. These links are provided for our
                            visitors convenience and
                            Polixis does not necessarily approve their content and cannot be held responsible for these
                            websites’ operations.
                        </div>
                        <b>5. Other provisions</b>
                        <div style={{fontSize: "15px", marginBottom: "18px", textAlign: "justify"}}>

                            Content and information provided in the Analysis is Polixis’s property and is protected by
                            applicable Swiss and
                            international intellectual property and copyright law. Any use of this information for
                            commercial purposes other than
                            provided in the buy/sale agreement, is forbidden unless there is a prior written consent
                            given by Polixis’s authorised
                            representative. Information provided in the product is not intended to provide any
                            investment, financial, legal or tax
                            advice. <br/> <br/>
                            Any dispute arising in connection to this disclaimer shall be subject to Swiss jurisdiction.
                            To report any inaccuracy in the report or provide your comments and feedback, please contact
                            us at coordinates indicated below.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-3" style={{backgroundColor: "#d5dbe3", height: "40px"}}></div>
                        <div className="col-xs-9" style={{
                            fontSize: 12,
                            backgroundColor: "#ef4254",
                            padding: "10px 20px",
                            color: "#fafafa",
                            height: "40px"
                        }}>Our client support team coordinates are: info@polixis.com or + 41 (0) 22 340 22 00
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    getContent() {
        const ckData = sessionStorage.getItem('ckdata') ? JSON.parse(sessionStorage.getItem('ckdata')) : ''
        return `<div>${ckData}</div>`;
    }

    genPdf() {
        setTimeout(() => {
            const theResult1 = this.strRemove('removePrint');
            const replacedResult = this.strReplace('customContent', theResult1);
            const pdfContent = '<!DOCTYPE html><html lang="en"><base href=' + process.env.REACT_APP_BASE + '>' + replacedResult + '</html>';
            this.generatePdf(pdfContent);
        }, 100);
    }

    generatePdf(html) {
        this.setState({isLoading: true});
        doPostPdf(API.GENERATE_PDF, {
            html,
            entityIds: "",
            requestedDate: Date.now(),
            requestedFrom: clientType
        }, true).then(response => {
            const {data, headers} = response;
            this.setState({isLoading: false});
            this.downloadFile(data, headers['content-type'])
        }).catch(() => {
            this.setState({isLoading: false});
        });
    }

    downloadFile(data, contentType) {
        const notRelevant = sessionStorage.getItem('notRelevant');
        const fileName = notRelevant ? `NoRelevantReport.pdf` : `NoResultReport.pdf`;
        Utils.downloadFileAndClose(fileName, data, contentType)
        this.setState({downloading: false})
    }

    strRemove(theTarget) {
        const html = document.getElementsByTagName('html')[0];
        const cloned = html.cloneNode(true);
        const banner = cloned.getElementsByClassName('banner')[0];
        banner.style.width = "1000px";
        const remove = cloned.getElementsByClassName(theTarget)[0];
        if (remove.parentNode) {
            remove.parentNode.removeChild(remove);
        }
        return cloned;

    };

    strReplace(theTarget, theString) {
        const replaced = this.getContent();
        const custom = theString.getElementsByClassName(theTarget)[0];
        if (custom && custom.parentNode) {
            custom.innerHTML = replaced
        }
        return theString.innerHTML
    };
}

NoResultReport.propTypes = {
    searchData: PropTypes.object,
};


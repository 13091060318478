import PolixisRoutes from "./routes";
import {API} from "./api";
import {getNextAvatar, nodeTypes} from "./constants";
import {fuzzinessValues} from "./application/search/constants/search";

const Utils = {
    getSelectedNodes: nodes => {
        let selectedNodes = [];
        nodes.forEach(node => {
            if (node.checked) {
                selectedNodes.push(node);
            } else {
                if(node.metadata) {
                    for (let i = 0; i < node.metadata.length; i++) {
                        const subnode = node.metadata[i];
                        if (subnode.checked) {
                            selectedNodes.push(node);
                            break;
                        }
                    }
                }
            }
        });

        return selectedNodes;
    },
    getMainNodes: nodes => {
        let count = 0;
        const mainNodes = nodes
            .filter(node => node.pie && !node.checked)
            .filter(node => {
                if (count < 6 && (node.metadata.length === 0 || node.metadata.filter(subnode => subnode.checked).length === 0)) {
                    count++;
                    return true;
                } else {
                    return false;
                }
            })
            .filter((node, index) => index < 5);
        return {
            mainNodes,
            showDots: count === 6
        };
    },
    getNodesByType: (nodes, type) => {
        const typedNodes = [];
        nodes.forEach(node => {
            if (node.pie) {
                node.pie.forEach(item => {
                    if (item.toLowerCase() === type) typedNodes.push(node)
                })
            }
        })

        return typedNodes;
    },
    getSanctionNodes: nodes => {
        return Utils.getNodesByType(nodes, 'sanction');
    },
    getPepNodes: nodes => {
        return Utils.getNodesByType(nodes, 'pep');
    },
    getInfoNodes: nodes => {
        return Utils.getNodesByType(nodes, 'info');
    },
    getClosedNodes: nodes => {
        return Utils.getNodesByType(nodes, 'non active');
    },
    getNodesByCountry: (nodeCountries, selectCountries) => {
        const set2 = new Set(selectCountries);
        return nodeCountries.filter(item => set2.has(item))

    },

    filterNodesByType: (node, type) => {
        let isValid = true;
        if (node.pie && node.pie.length > 0)  {
            isValid = isValid &&  node.pie.includes(type)
        } else isValid = false;

        return isValid;
    },

    checkNodesByDate : (dates, date) => {
        let isValid = true;
        let dateValues = [];

        Object.values(dates).forEach(dateArray => {
            dateArray.forEach(date => {
                dateValues.push(date);
            });
        });

        if (dateValues.length > 0) {
            const containDates = dateValues.filter(item => item.includes(date.toUpperCase())).length >0;
            isValid = isValid && containDates;
        } else isValid = false;
        return isValid;
    },

    cloneNodes: nodes => {
        return nodes.map(node => {
            const clonedNode = Object.assign({}, node);
            clonedNode.subnodes = Utils.cloneSubnodes(node.metadata);

            return clonedNode;
        });
    },

    cloneSubnodes: subnodes => {
        return subnodes.map(subnode => {
            return Object.assign({}, subnode);
        });
    },
    inputPlaceholder: type => {
        if (type === nodeTypes.PERSON) {
            return "First name, last name, middle name";
        } else {
            return "Company name";
        }
    },
    createSearchQuery: (keyword, type, options = null, node = null) => {
        let qry = {
            q: keyword,
            type: type.toLowerCase(),
            options: JSON.stringify(options),
            node
        };
        let searchRequest = Object.assign({}, {"keyword": keyword}, {"type": type}, options);
        sessionStorage.setItem("searchRequest", JSON.stringify(searchRequest));

        const search = "?q=" + new Date().getTime();
        return {pathname: PolixisRoutes.App.Search, search: search, state: qry};
    },
    getNodeImage: (node) => {
        if (Utils.isNullOrUndefined(node.image)) {
            if (node.nodeType === "PERSON") {
                if (Utils.isNullOrUndefined(node.gender)) {
                    return "/images/nodeAvatars300/node_avatar_300.png";
                } else {
                    return getNextAvatar(node.gender);
                }
            } else if (node.nodeType === "ORGANIZATION") {
                return "/images/nodeAvatars300/node_company_300.png";
            } else {
                return "/images/nodeAvatars300/undetermined_300.png";
            }
        } else {
            if (node.image.startsWith("../")) {
                return node.image.replace("..", API.BASE_IMG_URL)
            } else {
                return node.image;
            }
        }
    },
    makeCanvasImage: (pies, img) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 160;
        canvas.height = 160;

        if (Utils.isNotNullOrUndefined(pies) && pies.length > 0) {
            let total = pies.reduce((sum, {count}) => sum + count, 0);
            let currentAngle = Math.PI / 2; // where to start make pie
            let centerX = canvas.width / 2;
            let centerY = canvas.height / 2;

            for (let result of pies) {
                let sliceAngle = (result.count / total) * 2 * Math.PI; // create pie
                let piesChartRadiusSize = canvas.width / 2;
                ctx.beginPath();
                ctx.arc(centerX, centerY, piesChartRadiusSize, currentAngle, currentAngle + sliceAngle);
                ctx.lineTo(centerX, centerY);
                ctx.fillStyle = result.color;
                pies.length > 1 && Utils.creatPieBorder(ctx, 4);
                let middleAngle = currentAngle + (-0.5 * sliceAngle);
                let textX = Math.cos(middleAngle) + centerX;
                let textY = Math.sin(middleAngle) + centerY;
                currentAngle += sliceAngle;
                ctx.save();
                ctx.fill();
                Utils.textStyle(ctx, textX, textY);
                Utils.textRotate(ctx, currentAngle, pies.length, middleAngle);
                Utils.drawTextAlongArc(ctx, result.text, currentAngle);
                Utils.createCenterImageCanvas(ctx, canvas, img);
                ctx.restore();
            }
        } else {
            canvas.width = 120;
            canvas.height = 120;
            ctx.save();
            ctx.beginPath();
            ctx.arc(0, 0, 120, 0, 120);
            ctx.drawImage(img, 0, 0, 120, 120);
        }
        return canvas.toDataURL();
    },
    drawTextAlongArc: (ctx, text, currentAngle) => {
        if (text.startsWith('NON')) text = 'INACTIVE';
        currentAngle = text.length <= 4 ? 0.6 : 1.2; // pie text length start/end
        ctx.rotate(-1 * currentAngle / 2);
        ctx.rotate(-1 * (currentAngle / text.length) / 2);
        for (let i = 0; i < text.length; i++) {
            ctx.rotate(currentAngle / text.length);
            ctx.save();
            ctx.translate(0, -1 * 64);  // text radius 53, default canvas.width/2 - x%;
            ctx.fillText(text[i], 0, 0);
            ctx.restore();
        }
        ctx.restore();
    },
    createCenterImageCanvas: (ctx, canvas, image) => {
        let arcRadiusSize = 32 * canvas.width / 100;
        let startAngle = 0;
        let endAngle = Math.PI * 2;
        ctx.beginPath();
        ctx.arc(canvas.width / 2, canvas.width / 2, arcRadiusSize, startAngle, endAngle);
        ctx.fill();
        ctx.save();
        ctx.clip();
        let imageSize = image.width < 300 || image.height < 300 ? 10 : 150;
        let imagePosition = image.height < 300 ? 15 : 26;
        ctx.drawImage(image, 0, 0, image.width + imageSize, image.height + imageSize, imagePosition, imagePosition, canvas.width, canvas.height);
        ctx.closePath();
    },
    creatPieBorder: (ctx, borderLineWeight) => {
        ctx.strokeStyle = "#ffffff";
        ctx.lineWidth = borderLineWeight;
        ctx.closePath();
        ctx.stroke();
    },
    textStyle: (ctx, textX, textY) => {
        ctx.font = `500 12px Arial`;
        ctx.fillStyle = "#ffffff";
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.translate(textX, textY);
    },
    textRotate: (ctx, currentAngle, len) => {
        let pieFontRotatePercent = len * 19 / 100;
        len === 1 ? ctx.rotate(currentAngle + (3 * Math.PI / 2))
            : len === 2 ? ctx.rotate(currentAngle)
                : ctx.rotate(currentAngle + pieFontRotatePercent);
    },
    isNullOrUndefined: (obj) => {
        return obj === null || obj === undefined;
    },
    isNotNullOrUndefined: (obj) => {
        return !Utils.isNullOrUndefined(obj);
    },
    uniq: (arr) => {
        // replace with lodash.uniqBy
        const cb =  (o) => o['id'];
      return [...arr.reduce((map, item) => {
                const key = (item === null || item === undefined) ?
                    item : cb(item);

                map.has(key) || map.set(key, item);

                return map;
        }, new Map()).values()];

    },
    isEqual: (value,other) => {
        if (typeof value !== typeof other) {
            return false;
        }
        if (value === null || other === null || value === undefined || other === undefined) {
            return value === other;
        }
        if (typeof value !== 'object' && typeof other !== 'object') {
            return value === other;
        }
        if (Array.isArray(value) && Array.isArray(other)) {
            if (value.length !== other.length) {
                return false;
            }
            for (let i = 0; i < value.length; i++) {
                if (!value[i] !== other[i]) {
                    return false;
                }
            }
            return true;
        }
        if (typeof value === 'object' && typeof other === 'object') {
            const valueKeys = Object.keys(value);
            const otherKeys = Object.keys(other);
            if (valueKeys.length !== otherKeys.length) {
                return false;
            }
            for (let i = 0; i < valueKeys.length; i++) {
                const key = valueKeys[i];
                if (value[key] !== other[key]) {
                    return false;
                }
            }
            return true;
        }
        return false;
    },
    isMobile: () => {
        return window.innerWidth < 480;
    },
    isMiddleDevice: () => {
        return window.innerWidth > 481 && window.innerWidth < 900;
    },
    getJSONFromBuffer:(data) => {
        const decoder = new TextDecoder('utf-8');
        const jsonStr = decoder.decode(data);
        try {
            return JSON.parse(jsonStr);
        } catch (e) {
            return data;
        }
    },
    getJSONFromBlob:async(data) => {
        const dataToString = await data.text();
       try {
           return JSON.parse(dataToString);
       } catch {
           return data;
       }
    },
    getReportFormattedDay: () => {
        const now = new Date();
        const year = now.toLocaleDateString("en-US", {year: 'numeric'});
        const month = now.toLocaleDateString("en-US", {month: 'long'});
        const day = now.toLocaleDateString("en-US", {day: '2-digit'});
        const time = now.toLocaleTimeString('en-US', { hour: '2-digit',minute: '2-digit', hour12: true });
        return `${day} ${month} ${year} ${time}`
    },
    isLogin: () => {
        return localStorage.getItem('bnli');
    },
    downloadFileAndClose: (name, data, header) => {
        const blob = new Blob([data], {type: header});
        const dom = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        dom.href = url;
        dom.download = decodeURI(name);
        dom.style.display = 'none';
        document.body.appendChild(dom, url);
        dom.click();
        dom.parentNode.removeChild(dom);
        window.URL.revokeObjectURL(url);
    },
    searchById: (data) => {
        const searchValue = {'value':data.value, 'type': data.type};
        sessionStorage.setItem('searchValue', JSON.stringify(searchValue));
        window.open("/search", "_blank");
        setTimeout(() => {
            sessionStorage.removeItem('searchValue');
        }, 1000)
    },

    newsSearchPdfContent : (classNameForSearch) => {
        const html = document.getElementsByClassName(classNameForSearch);
        const div = document.createElement('div');
        const articles = []
        for (let item of html) {
            articles.push(item.cloneNode(true))
        }

        articles.forEach(item => {
            item.style.width = "1000px";
            const blocks = item.getElementsByTagName('blockquote');
            for (let block of blocks) {
                block.style.fontSize = "12px";
            }
            const remove = item.getElementsByClassName("remove-inPdf");
            const articleContent = item.getElementsByClassName("article-pdf-content");
            articleContent[0].classList.remove("article-content-hide")

            const length = remove.length;
            let i = 0;
            while (i < length) {
                remove[i].innerHTML = ''
                i++
            }
            i=0;
            const viewContents = item.getElementsByClassName("pdf-view-content");
            while(i < viewContents.length) {
                viewContents[i].classList.add("pdf-hide-content");
                i++;
            }
            div.appendChild(item)
        })
        return div.innerHTML
    },
    getFuzzinessDistance : (fuzziness) => {
        const selectedFuzziness = Object.keys(fuzzinessValues).filter(f => fuzzinessValues[f].value === fuzziness);
        return fuzzinessValues[selectedFuzziness];
    }

};

export default Utils;

import {BoundingBox} from "../../../../constants";
import tippy from "tippy.js";

export const graphUtils = {
    layoutSubNodes : (node, cytoscape) => {
        const cyElement = cytoscape.getElementById(node.id);
        const collection = cytoscape.nodes( 'node[parentNodeId = "' + node.id + '"], #' + node.id);

        const nodeX = cyElement.position('x');
        const nodeY = cyElement.position('y');
        const x = nodeX - (collection.length === 2 ? BoundingBox.width : BoundingBox.width / 2);
        const y = nodeY - (collection.length === 2 ? BoundingBox.height : BoundingBox.height / 2);
        const newLayout = collection.layout({
            name: 'concentric',
            fit: false,
            padding: 30,
            avoidOverlap: true,
            avoidOverlapPadding: 5,
            boundingBox: {
                x1: x,
                y1: y,
                w: BoundingBox.width,
                h: BoundingBox.height
            }
        });
        newLayout.run();
    },
    makePopup: (element, content) => {
        try {
            const ref = element.popperRef();
            const dummyDomEle = document.createElement('div');
            const tip = tippy( dummyDomEle, {
                getReferenceClientRect: ref.getBoundingClientRect,
                trigger: 'manual',
                content: () => {
                    const div = document.createElement('div');
                    div.innerHTML = content;
                    return div;
                },
                arrow: true,
                placement: 'right-start',
                animation: 'scale',
                interactive: true,
                appendTo: document.body,
                theme: 'nodeProp'
            });
            element.on('mouseover', () => tip.show());
            element.on('mouseout', () => tip.hide());
        } catch {
        }
    }
}


import React from "react";
import PropTypes from "prop-types";

import {Dialog, Divider, DialogTitle, DialogContent, DialogActions, DialogContentText} from '@mui/material';

import Utils from "../../../utils";

export default class ShowHitContentDialog extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            open: true,
        };
    }

    render() {
        const isNewsDialog = Utils.isNotNullOrUndefined( this.props.subTitleData );

        const actions = [
            <button key="Cancel"
                    className="btn btn-floating btn-large waves-effect waves-light "
                    style={ {
                        borderRadius: 25,
                        marginRight: "10px",
                        color: "#fff",
                        textTransform: 'capitalize',
                        backgroundColor: "#ef4254"
                    } }
                    onClick={ () => this.handleClose() }
            >
                Cancel
            </button>
        ];

        let content;
        let title;
        if ( isNewsDialog ) {
            const subTitleData = this.props.subTitleData;
            title = 'Content';
            content = <div key="content">
                <div style={ { padding: '10px 20px' } }>
                    <div><b style={ { color: '#ef4254', textTransform: 'capitalize' } }>{ subTitleData.name }</b></div>
                    <div><i style={ { fontWeight: "bold" } }>{ subTitleData.provider }</i></div>
                    <div><i style={ { fontWeight: "bold" } }>{ subTitleData.datePublished }</i></div>
                </div>
                <div style={ {
                    fontFamily: "Arial, Helvetica, sans-serif",
                    maxWidth: '100%',
                    textAlign: 'justify',
                    padding: '20px'
                } } dangerouslySetInnerHTML={ { __html: this.props.content.content } }>
                </div>
            </div>;
        } else {
            title = this.props.content.title;
            content = <div key="content">
                <div style={ { padding: '10px 20px' } }>{ this.props.content.contentText }</div>
                <div style={ {
                    fontFamily: "Arial, Helvetica, sans-serif",
                    maxWidth: '100%',
                    textAlign: 'justify',
                    padding: '20px'
                } }>
                </div>
            </div>;
        }
        return <Dialog
            maxWidth="md"
            open={ this.state.open }
            style={ { zIndex: 1500, width: "75%", maxWidth: "832px", margin: "0 auto" } }
            className={ isNewsDialog ? 'newsContent-dialog' : '' }
            scroll="paper">
            <DialogTitle style={ { fontSize: "22px", color: 'white' } } className="explore-header">
                { title }
            </DialogTitle>
            <DialogContent style={ { maxHeight: "712px" } }>
                <DialogContentText component="div" className="explore-dialog-body">
                    <div className="row" style={ { fontSize: "16px", whiteSpace: "pre-line" } }>
                        { content }
                    </div>
                </DialogContentText>
            </DialogContent>
            <Divider/>
            <DialogActions>
                { actions }
            </DialogActions>
        </Dialog>;
    }

    handleClose() {
        if ( Utils.isNotNullOrUndefined( this.props.handleClose ) ) {
            this.props.handleClose();
        }
        this.setState( { open: false }, () => {
            setTimeout( () => {
                this.setState( { open: false } );
            }, 10 );
        } );
    }

}

ShowHitContentDialog.propTypes = {
    content: PropTypes.object,
    subTitleData: PropTypes.object,
    handleClose: PropTypes.func
};

import React from "react";

export default class CoverPage extends React.Component {
    render() {
        let date = new Date();
        let d = date.getDate();
        var month = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";
        let m = month[date.getMonth()];
        let y = date.getFullYear();

        return (
            <div className="cover-page page-break-after" style={ { pageBreakAfter: 'always', minHeight: '1200px' } }>
                <img alt='coverTopPart' src="/images/cover_top_part.png" width="90%"/>
                <div className="row">
                    <div className="col-xs-3 red-part">
                        <div className="col-xs-11"></div>
                        <div className="col-xs-1"></div>
                    </div>
                    <div className="col-xs-9 grey-part text-right">
                        <img alt='coverLogo' src="/images/cover_logo.png" height="90%" width="50%"/>
                    </div>
                </div>

                <div className="text-left" width="50%">
                    <h1>Due Diligence report by <b style={ { color: "#EF4254!important" } }>Ardis </b></h1>
                    <h3> private and confidential</h3>
                    <hr/>
                    <h3> Date of the Report: { d } { m } { y } </h3>
                </div>

                <div className="report-bottom-part page-break-after" style={{ height: '200px',marginTop: '-10px' }}>
                    <img alt='coverBottomPart' src="/images/cover_bottom_part.png" width="38%"/>
                    <div>
                        <div>
                            ARDIS IS A SOFTWARE OWNED AND OPERATED BY ITS PARENT COMPANY POLIXIS, A GENEVA-BASED SWISS REGTECH AND RISK ADVISORY FIRM. KINDLY REFER TO THE DISCLAIMER ON THE TERMS AND CONDITIONS IN THE APPENDIX OF THIS REPORT.
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

import React from "react";
import PropTypes from "prop-types";

import Draggable from "react-draggable";

import Icon from '@mui/material/Icon';

import Utils from "../../../../utils";
import {API, doPost} from '../../../../api';
import {nodeTypes, postActions, ProgressState} from "../../../../constants";

export default class NodeDetails extends React.Component {
    constructor(props) {
        super(props);
        this.title = '';
        this.state = {nodeData: null, nodeId: null, state: ProgressState.Loading, nodeType: '', avatar: null};
    }

    render() {
        return this.renderContent();
    }

    componentDidMount() {
        this.loadContent();
    }

    loadContent() {
        const prId = sessionStorage.getItem('prId');

        let node;
        const requestData = {
            action: postActions.DETAILS,
            time: new Date().getTime(),
            nid: this.props.id,
            projectId: this.props.projectId || prId,
            bnli: this.props.bnli,

        };
        this.setState({state: ProgressState.Loading});
        doPost(API.GET_NODE_DATA, {qry: JSON.stringify(requestData)}, true).then(response => {
            if (Utils.isNotNullOrUndefined(response.data)) {
                let avatar = Utils.isNotNullOrUndefined(response.data.avatar) ? response.data.avatar.startsWith("../") ? response.data.avatar.replace("..", API.BASE_IMG_URL) : response.data.avatar : null;
                node = response.data;
                this.setState({
                    nodeData: node,
                    state: ProgressState.Success,
                    nodeType: response.data.nodeType,
                    avatar
                });
            }
        }).catch(error => {
            console.error(error);
            this.props.sessionExpired()
        });
    }

    renderContent() {
        let content = [];
        let isPerson = Utils.isNotNullOrUndefined(this.state.avatar);

        switch (this.state.state) {
            case ProgressState.Loading:
                content.push(<div key="loading">Loading ...</div>);
                break;
            case ProgressState.Success:
                if (this.state.nodeData ) {
                    if (this.state.nodeData.ardisId) {
                        content.push(
                            <div className="row pointer" key={`${this.state.nodeData.ardisId}_detail`} onClick={() => this.searchWithValue(this.state.nodeData.ardisId)}>
                                <div className="col-sm-12  main-color">ARDIS ID:</div>
                                <div className="col-sm-10 word-break"
                                     style={{wordBreak: "break-all"}} >{this.state.nodeData.ardisId}</div>
                                <div className="col-sm-2">
                                    <Icon className="material-icons pointer" style={{
                                        fontSize: '16px',
                                        margin: '4px',
                                        float: 'right',
                                        color: "#b0b0b0"
                                    }}>search</Icon>
                                </div>
                            </div>
                        );

                        if (this.state.nodeData.uni) {
                            content.push(
                                <div key={`${this.state.nodeData.ardisId}_unis`}>
                                    {
                                        (Object.keys(this.state.nodeData.uni).length >=2 ? Object.keys(this.state.nodeData.uni).slice(0,2) :Object.keys(this.state.nodeData.uni) ).map((u) => {
                                            const value = this.state.nodeData.uni[u].length > 1 ? this.state.nodeData.uni[u].join (",") : this.state.nodeData.uni[u][0];
                                            return (
                                                <div className="row pointer" key={`${this.state.nodeData.ardisId}_${u}_detail`}
                                                     onClick={() => this.searchWithValue(value)}>
                                                    <div className="col-sm-12  main-color">{u}</div>
                                                    <div className="col-sm-10 word-break"
                                                         style={{wordBreak: "break-all"}}>{value}</div>
                                                    <div className="col-sm-2 ">
                                                        <Icon className="material-icons pointer" style={{
                                                            fontSize: '16px',
                                                            margin: '4px',
                                                            float: 'right',
                                                            color: "#b0b0b0"
                                                        }}>search</Icon>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            );
                        }

                        if (this.state.nodeData.names) {
                            content.push(
                                <div key={`${this.state.nodeData.ardisId}_names`}>
                                    <div className="col-sm-12 main-color aka-margin">AKA:</div>
                                    {
                                        (this.state.nodeData.names.length >= 2 ? this.state.nodeData.names.slice(0,2) : this.state.nodeData.names).map((n) => {
                                            return (
                                                <div className="row pointer" key={`${this.state.nodeData.ardisId}_${n}_detail`}
                                                     onClick={() => this.searchWithValue(n)}>
                                                    <div className="col-sm-10 word-break"
                                                         style={{wordBreak: "break-all"}}>{n}</div>
                                                    <div className="col-sm-2 ">
                                                        <Icon className="material-icons pointer" style={{
                                                            fontSize: '16px',
                                                            margin: '4px',
                                                            float: 'right',
                                                            color: "#b0b0b0"
                                                        }}>search</Icon>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            );
                        }

                        if(this.state.nodeData.countries){
                            let countries = Object.values(this.state.nodeData.countries);
                            const countrySet = new Set();
                            for(let i =0; i< countries.length; i++) {
                                for (let j = 0; j< countries[i].length; j++) {
                                    countrySet.add(countries[i][j])
                                }
                            }
                            countries = Array.from(countrySet)

                            content.push(
                                <div key={`${this.state.nodeData.ardisId}_jurisdiction`}>
                                    <div className="col-sm-12 main-color aka-margin">Jurisdiction:</div>
                                    {
                                        (countries.map((c) => {
                                            return (
                                                <div className="row pointer" key={`${this.state.nodeData.ardisId}_${c}_detail`}>
                                                    <div className="col-sm-10 word-break" style={{wordBreak: "break-all"}}>{c.replaceAll("_", " ")}</div>
                                                </div>
                                            )
                                        }))
                                    }
                                </div>
                            );
                        }

                        if (this.state.nodeData.nodeType.toLowerCase() === nodeTypes.PERSON && this.state.nodeData.dates["Date of Birth"]) {
                            content.push(
                                <div key={`${this.state.nodeData.ardisId}_birth`}>
                                    <div className="col-sm-12 main-color aka-margin">Date of Birth:</div>
                                    <div className="row pointer">
                                        <div className="col-sm-10 dateOfBirth font-bolder" style={{wordBreak: "break-all"}}>
                                            { this.state.nodeData.dates["Date of Birth"].join(", ")}
                                        </div>
                                    </div>

                                </div>
                            );
                        }
                    }
                }
                break;
            default:
                break;
        }
        return (
            <Draggable
                handle=".handle"
                defaultPosition={{x: this.props.x + 80, y: this.props.y}}
                scale={1}
            >
                <div style={{position: 'absolute', zIndex: 2, cursor: 'move'}}>
                    <div className="container sticker handle"
                         style={isPerson ? {
                                 zIndex: 2009,
                                 borderTopLeftRadius: '7px',
                                 borderBottomLeftRadius: '7px',
                                 width: '420px'
                             } :
                             {zIndex: 2009, borderTopLeftRadius: '7px', borderBottomLeftRadius: '7px', width: '370px'}}
                         id={"drag" + this.props.id}
                         key={"drag" + this.props.id}>
                        <div className="row node-header">
                            <table style={{width: "100%"}}>
                                <tbody>
                                <tr>
                                    <td className="node-title">{this.title} </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className={isPerson ? "col-xs-4" : "hidden"}>
                                <div className={isPerson ? 'avatar-gray' : 'avatar-color'}>
                                    <img alt='avatarGra' src={this.state.avatar}
                                         style={isPerson ? {
                                             width: '100%',
                                             marginTop: '13px',
                                             borderRadius: '50%',
                                             filter: 'grayscale(100%)'
                                         } : {
                                             width: '100%',
                                             marginTop: '13px',
                                             borderRadius: '50%'
                                         }}/>
                                </div>
                            </div>
                            <div className={isPerson ? "col-xs-8" : "col-xs-12"}>
                                <div className="node-content" style={isPerson ? {width: '264px'} : {width: '353px'}}>
                                    {content}
                                </div>
                            </div>
                        </div>

                        <div className="text-center sticker-toolbar" style={{
                            position: "absolute",
                            float: "right",
                            right: "-30px",
                            top: "-1px",
                            height: "101%",
                            paddingLeft: "2px",
                            borderTopRightRadius: '7px',
                            borderBottomRightRadius: '7px',
                            width: "30px",
                            background: "rgba(150, 150, 150, .8)",

                        }}>
                            <table style={{textAlign: "center"}}>
                                <tbody>
                                <tr>
                                    <td onClick={() => this.props.onClose(this.props.id)}>
                                        <Icon className="material-icons hover" title="Close"
                                              style={{color: '#ffffff', cursor: "pointer", fontSize: "24px"}}>close</Icon>
                                    </td>
                                </tr>
                                {this.props.isPossibleToAddCard &&
                                    <tr>
                                        <td onClick={() => this.props.addToCard(this.props.id)}>
                                            <Icon className="material-icons hover" title="Add Node To Relation Cart" style={{
                                                color: '#ffffff',
                                                cursor: "pointer",
                                                fontSize: "24px"
                                            }}>add_shopping_cart</Icon>

                                        </td>

                                    </tr>
                                }
                                {this.props.haveSubnodes(this.props.id) &&
                                    <tr>
                                        <td onClick={() => this.props.hideSubnodes(this.props.id)}>
                                            <Icon className="material-icons hover" title="Hide Subnodes"
                                                  style={{color: '#ffffff', cursor: "pointer"}}>unfold_less </Icon>

                                        </td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </Draggable>
        )
    }

    searchWithValue(value) {
        Utils.searchById({'value': value, 'type': this.state.nodeType});
    }

}

NodeDetails.propTypes = {
    id: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    projectId: PropTypes.string,
    onClose: PropTypes.func,
    hideSubnodes: PropTypes.func,
    bnli: PropTypes.string,
    haveSubnodes: PropTypes.func,
    addToCard: PropTypes.func,
    sessionExpired: PropTypes.func,
    isPossibleToAddCard: PropTypes.bool
};

import React from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import {createTheme, ThemeProvider} from "@mui/material/styles";

let OrgInstanceIndex = 0;

export default class Org extends React.Component {
    constructor(props) {
        super(props);
        this.id = OrgInstanceIndex++;
    }

    getMuiTheme = (print) => createTheme({
    components: {
        // MuiTable: {
        //     styleOverrides: {
        //         root: {
        //             border : '1px solid #bfbfbf66'
        //         }
        //     }
        // },
        MUIDataTableBodyRow: {
            styleOverrides: {
                root: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: '#FAFAFAFF',
                    },
                },
            }
        },
        // MuiTableCell: {
        //     styleOverrides: {
        //         root: {
        //             borderColor: !print && '#95989a'
        //         }
        //     }
        //
        // },
        // MuiTableBody: {
        //     styleOverrides : {
        //         root: {
        //             border: "1px solid #ccc"
        //         }
        //     }
        // },
        MUIDataTableHeadCell: {
            styleOverrides: {
                toolButton: {
                    fontSize: '14px',
                    fontWeight: 600,
                },
                fixedHeader: {
                    padding: 0,
                    paddingLeft: '25px'
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "12px",
                },
            }
        },
        MUIDataTableBodyCell: {
            styleOverrides: {
                root: {
                    fontSize: '13px',
                    padding: '5px',
                    paddingLeft: '14px'
                }
            }
        },
        MuiSvgIcon: {
           styleOverrides: {
               root: {
                   fontSize: '20px'
               },
           }
        },
        MuiTablePagination: {
            styleOverrides : {
                caption: {
                    fontSize: '14px'
                },
                input: {
                    fontSize: '14px'
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '14px'
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    fontSize: '14px'
                },
                underline: {
                    '&:after': {
                        borderBottom: "2px solid #ef4254"
                    }
                }
            }
        },
        MUIDataTableToolbar: {
            styleOverrides: {
                icon: {
                    '&:hover': {
                        color: "#ef4254"
                    }
                },
                iconActive: {
                    color: "#ef4254"
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: !print && "unset !important",
                },
                elevation4: {
                    boxShadow: '0px 0px 5px 0px #bfbfbf'
                }
            }
        }
    }
})

    render() {
        const options = {
            filterType: "textField",
            selectableRows: 'none',
            pagination: this.props.data.data.length > 50 && this.props.print === '',
            rowsPerPageOptions: [50, 100, 200],
            rowsPerPage: this.props.print === '' ? 50 : 300,
            viewColumns: false,
            filter: false,
            print: this.props.print !== '?print=true',
            search: this.props.print !== '?print=true',
            download: this.props.print !== '?print=true',
            responsive: "standard",
        };
        const data = [];
        this.props.data.data.forEach((item) => {
            data.push(item);
        });
        if (this.props.print !== '') {
            data.splice(0, 0, this.props.data.header);
        }
        return (
            <ThemeProvider theme={this.getMuiTheme(this.props.print === '')}>
                <div style={{textAlign: 'center', margin: '30px 0 20px 0'}}>
                    <p>{this.props.data.title}</p>
                </div>
                <div className={this.props.print === '?print=true' ? 'org-table' : ''}>
                    <MUIDataTable data={data}
                                  columns={this.props.data.header}
                                  options={options}
                    />
                </div>

            </ThemeProvider>
        );
    }
}

Org.defaultProps = {
    isMaximize: false,
};

Org.propTypes = {
    data: PropTypes.object,
    isMaximize: PropTypes.bool,
    print: PropTypes.string
};

export const ApplicationActions = {
    UpdateSearchBar: "Application.Actions.UpdateSearchBar",
    LoadCountries: "Application.Actions.LoadCountries",
    LoadCountriesSuccess: "Application.Actions.LoadCountriesSuccess",
    DoSearch: "Application.Actions.DoSearch",
    DoSearchSuccess: "Application.Actions.DoSearchSuccess",
    RunPathSearch: "Application.Actions.RunPathSearch",
    DoPathSearch: "Application.Actions.DoPathSearch",
    DoPathSearchSuccess: "Application.Actions.DoPathSearchSuccess",
    SearchResult: "Application.Actions.SearchResult",
    SearchResultSuccess: "Application.Actions.SearchResultSuccess",
    DoGeneration: "Application.Actions.DoGeneration",
    GenerateDirectPdf:"Application.Actions.GenerateDirectPdf",
    GenerateSuccess: "Application.Actions.GenerateSuccess",
    GenerateResult: "Application.Actions.GenerateResult",
    GenerateResultSuccess: "Application.Actions.GenerateResultSuccess",
    GenerateReport: "Application.Actions.GenerateReport",
    GenerateReportSuccess: "Application.Actions.GenerateReportSuccess",
    CancelGenerateReport: "Application.Actions.CancelGenerateReport",
    ShowExploreDialog: "Application.Actions.ShowExploreDialog",
    GetExploreSuggestions: "Application.Actions.GetExploreSuggestions",
    ShowMergeNodesDialog: "Application.Actions.ShowMergeNodesDialog",
    CloseExploreDialog: "Application.Actions.CloseExploreDialog",
    CloseMergeNodesDialog: "Application.Actions.CloseMergeNodesDialog",
    AddComponentsScreenshotToCustom: "Application.Action.AddComponentsScreenshotToCustom",
    CanvasToImage: "Application.Action.CanvasToImage",
    NewAppendix: "Application.Action.NewAppendix",
    AppendixTree: "Application.Action.AppendixTree",
    RefreshJudicialDataFromAppendix: "Application.Action.RefreshJudicialDataFromAppendix",
    AddAppendixComponent: "Application.Action.AddAppendixComponent",
    CancelSearch: "Application.Action.CancelSearch",
    CancelRelationsPathSearch: "Application.Action.CancelRelationsPathSearch",
    StopPathSearch: "Application.Action.StopPathSearch",
    StopSearch: "Application.Action.StopSearch",
    StopReport: "Application.Action.StopReport",
    StopSearchSuccess: "Application.Action.StopSearchSuccess",
    SessionExpired:"Application.Actions.SessionExpired",
    AddToCard: "Application.Action.AddToCard",
    EmptyCard: "Application.Action. EmptyCard",
    RefreshCard: "Application.Actions.RefreshCard",
    EmptyResult: "Application.Actions.EmptyResult",
    ClearStore: "Application.Actions.ClearStore",
    HandleFail: "Application.Actions.HandleFail",
    NoServer: "Application.Actions.NoServer"
};


export const loadCountries = checkServer => {
    return { type: ApplicationActions.LoadCountries, payload:{checkServer} };
};

export const loadCountriesSuccess = countries => {
    return { type: ApplicationActions.LoadCountriesSuccess, payload: {countries} };
};

export const updateSearchBar = () => {
    return { type: ApplicationActions.UpdateSearchBar };
};

export const searchResult = () => {
    return {
        type: ApplicationActions.SearchResult
    };
};

export const searchResultSuccess = (results) => {
    return {
        type: ApplicationActions.SearchResultSuccess,
        payload: {results}
    };
};

export const doSearch = query => {
    return {
        type: ApplicationActions.DoSearch,
        payload: query
    };
};

export const doSearchSuccess = (response) => {
    return {
        type: ApplicationActions.DoSearchSuccess,
        payload: {response}
    };
};

export const runPathSearch = () => {
    return {
        type: ApplicationActions.RunPathSearch
    };
};

export const doPathSearch = query => {
    return {
        type: ApplicationActions.DoPathSearch,
        payload: query
    };
};

export const doPathSearchSuccess = (response, queryData ) => {
    return {
        type: ApplicationActions.DoPathSearchSuccess,
        payload:  { response, queryData }
    };
};

export const stopPathSearch = () => {
    return {
        type: ApplicationActions.StopPathSearch,
    };
};

export const cancelRelationsPathSearch = (queryData) => {
    return {
        type: ApplicationActions.CancelRelationsPathSearch,
        payload: queryData
    }
}

export const getExploreSuggestions  = data => {
    return {
        type: ApplicationActions.GetExploreSuggestions,
        payload: data
    }
}

export const showExploreDialog = (nodes) => {
    return {
        type: ApplicationActions.ShowExploreDialog,
        payload: { nodes }
    };
};

export const closeExploreDialog = () => {
    return {
        type: ApplicationActions.CloseExploreDialog
    };
};

export const showMergeNodesDialog = selectedNodes => {
    return {
        type: ApplicationActions.ShowMergeNodesDialog,
        payload: { selectedNodes }
    };
};

export const closeMergeNodesDialog = () => {
    return {
        type: ApplicationActions.CloseMergeNodesDialog
    };
};

export const generateReport = (subnodeIds, empty = false, isInReport = false, isOnlyNews = false, newTab = false) => {
    return {
        type: ApplicationActions.GenerateReport,
        payload: {ids: subnodeIds, isEmptyReport: empty, isInReport, isOnlyNews, newTab}
    };
};

export const generateDirectPdf = (data) => {
    return {
        type: ApplicationActions.GenerateDirectPdf,
        payload: data
    };
}
export const cancelGenerateReport = () => {
    return {
        type: ApplicationActions.CancelGenerateReport
    }
}

export const doGeneration = (ids, isEmptyReport,isInReport, action = "explore") => {
    return {
        type: ApplicationActions.DoGeneration,
        payload: { ids, isEmptyReport, isInReport, action}
    };
};

export const generateSuccess = () => {
    return {
        type: ApplicationActions.GenerateSuccess
    };
};

export const generateResult = () => {
    return {
        type: ApplicationActions.GenerateResult
    };
};

export const generateResultSuccess = response => {
    return {
        type: ApplicationActions.GenerateResultSuccess,
        payload: response
    };
};

export const addComponentsScreenshotToCustom = () => {
    return {
        type: ApplicationActions.AddComponentsScreenshotToCustom
    };
};
export const canvasToImage = () => {
    return {
        type: ApplicationActions.CanvasToImage
    };
};

export const addAppendixComponent = type => {
    return {
        type: ApplicationActions.AddAppendixComponent,
        payload: type
    };
};

export const newAppendix = (data) => {
    return {
        type: ApplicationActions.NewAppendix,
        payload: data
    };
};

export const appendixTree = () => {
    return {
        type: ApplicationActions.AppendixTree,
    };
};


export const refreshJudicialDataFromAppendix = id =>{
    return {
        type: ApplicationActions.RefreshJudicialDataFromAppendix,
        payload: {id}
    };
};

export const cancelSearch = () => {
    return {
        type: ApplicationActions.CancelSearch,
    };
};

export const sessionExpired = () => {
    return {
        type: ApplicationActions.SessionExpired,
    };
};

export const addToCard = (nodes) => {
    return {
        type: ApplicationActions.AddToCard,
        payload: {nodes}
    };
};

export const emptyCard = () => {
    return {
        type: ApplicationActions.EmptyCard,
    };
};

export const emptyResult = (searchData, notRelevant) => {
    return {
        type: ApplicationActions.EmptyResult,
        payload: {searchData, notRelevant}
    };
};

export const clearStore = (storeData) => {
    return {
        type: ApplicationActions.ClearStore,
        payload: {storeData}
    };
};

export const handleFail = () => {
    return  {type : ApplicationActions.HandleFail}
}

export const handleNoServer = () => {
    return  {type : ApplicationActions.NoServer}
}


export const stopSearch = (searchData) => {
    return {
        type: ApplicationActions.StopSearch,
        payload: searchData
    }
}

export const stopReport = (reportData) => {
    return {
        type: ApplicationActions.StopReport,
        payload: reportData
    }
}

export const stopSearchSuccess = () => {
    return {
        type: ApplicationActions.StopSearchSuccess
    };
};

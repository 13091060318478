import React from "react";
import PropTypes from "prop-types";

import Utils from "../../utils";
import {API, doPost} from '../../api';
import {getNextBracket} from "../../constants";
import {showConfirmDialog} from "../../shared/dialog/actions";
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css';

export default class Subnode extends React.Component {
    constructor(props) {
        super(props);
        this.bracket = null;
        this.state = {subnode: this.props.subnode, status: props.status, statusCancel: props.statusCancel, mainNodeMetadata : props.mainNodeMetadata};
    }

    render() {
        const {subnode} = this.state;

        if (Utils.isNullOrUndefined(this.bracket)) {
            this.bracket = getNextBracket();
        }
        const isSuperAdmin = (localStorage.getItem('type') === '1' || localStorage.getItem('type') === '4');

        const checked = Utils.isNullOrUndefined(subnode.checked) ? false : subnode.checked;
        const checkboxImage = checked ? "/images/brackets/" + this.bracket + "/gray_ellipse.png" : "/images/brackets/" + this.bracket + "/item_line_checkbox.png";
        const borderColor = checked ? "rgb(149, 152, 154)" : "rgb(176, 178, 180)";
        const names = []

        if (this.state.subnode.names) {
            for (let i = 0; i < this.state.subnode.names.length; i++) {
                names.push(<span
                    key={`${this.state.subnode.id}_${this.state.subnode.names[i]}`}>{this.state.subnode.names[i]} {i !== this.state.subnode.names.length - 1 ? " ," : ""}</span>)
            }
        }

        return (
            <div className="subnode" id={"sub" + subnode.id}>
                <table>
                    <tbody>
                    <tr>
                        <td width={27} className="text-center"
                            style={{borderRight: '1px solid', borderRightColor: borderColor}}>
                            <img alt='checkboxImage' src={checkboxImage}
                                 className={(this.state.status === "DONE" || this.state.statusCancel === "DONE") ? "pointer sub-check" : "sub-check cursor-progress"}
                                 onClick={() => this.toggleCheckbox(subnode)}/>
                        </td>
                        <td className="sub-content">
                            <div className="name" style={{color: '#777'}}>
                                {names}
                            </div>
                            <div className="content">
                                {isSuperAdmin && <div style={{whiteSpace: 'nowrap', width: '290px'}}>
                                    <span
                                        style={{color: '#ef4254'}}>{this.state.subnode.isPhantom ? "Phantom: " : "Id: "}</span>
                                    <span className="text-lighter" style={{fontSize: '13px'}}>
                                        {this.state.subnode.id}
                                    </span>
                                </div>
                                }
                                <div style={{display: "flex", alignItems: "flex-start"}}>
                                    <span>Source: </span>
                                    <span className="text-lighter" style={{fontSize: '13px', marginLeft: 8}}>
                                       {
                                           this.state.subnode.source.map((s) => {
                                               return (
                                                   <span key={`${this.state.subnode.id}_${s}`}>{s}<br/></span>
                                               )
                                           })
                                       }
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td style={{textAlign: 'right', verticalAlign: 'top', zIndex: '200'}}
                            title='Unmap node from list'>
                            {(this.props.subnodeLength > 1) ? [
                                <img alt='baseline' src='/images/baseline-link_off-24px.svg'
                                     key={"font" + subnode.id}
                                     className="link_off unmapPopover"
                                     style={{marginTop: '35px', cursor: 'pointer', marginLeft: '5px'}}
                                     onClick={() => this.dialog_unmapNodes()}/>
                            ] : []}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({subnode: this.props.subnode, status: nextProps.status, statusCancel: nextProps.statusCancel})
    }

    toggleCheckbox(subnode) {
        if (this.state.status === "DONE" || this.state.statusCancel === "DONE") {

            const checked = Utils.isNullOrUndefined(subnode.checked) ? false : subnode.checked;
            subnode.checked = !checked;
            this.setState({
                subnode: subnode
            });
            this.props.onChange();
        }
    }


    dialog_unmapNodes() {
        showConfirmDialog({
            title: "Unmap node",
            message: "Are you sure you want to unmap this node?",
            continueButtonLabel: "Unmap node",
            onContinue: () => this.unmapNodes()
        });
    }

    unmapNodes() {
        const requestData = {
            unmappingNodeId: this.state.subnode.id,
            mappedNodeIds: this.state.mainNodeMetadata.map(n => n.id),
            bnli: localStorage.getItem('bnli')
        }

        doPost(API.UNMAP_NODE, {qry: JSON.stringify(requestData)}, true).then(response => {
            if (Utils.isNotNullOrUndefined(response.data)) {
                this.props.doSearch();
            }
        }).catch(error => {
            console.error(error);
        });
    }

}

Subnode.propTypes = {
    subnode: PropTypes.object,
    onChange: PropTypes.func,
    doSearch: PropTypes.func,
    status: PropTypes.string,
    statusCancel: PropTypes.string,
    subnodeLength: PropTypes.number,
    isManuallyMapped: PropTypes.bool,
};

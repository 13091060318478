import React from "react";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {logout} from "../authentication/actions";
import IdleTimer from 'react-idle-timer';
import {showAlertDialog} from "../shared/dialog/actions";
import {INITIAL_STATE} from "../constants";

const mapStateToProps = (store = INITIAL_STATE.authentication) => {
    return {
        isLoggedIn: store.authentication.isAuthenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
    };
};

class ApplicationLayout extends React.Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.handleOnAction = this.handleOnAction.bind(this);
        this.handleOnActive = this.handleOnActive.bind(this);
        this.handleOnIdle = this.handleOnIdle.bind(this);
    }

    render() {
        localStorage.setItem('plx', '14400000');
        sessionStorage.setItem('timeout', 'false');
        return (
            <div className="application container-full">
                <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref
                    }}
                    timeout={14400000}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={500}
                />
                {this.props.children}
            </div>
        );
    }

    handleOnAction() {
        if (!localStorage.getItem('plx') || !sessionStorage.getItem('timeout')) {
            showAlertDialog({title: "Your session expired", expired:true});
            this.props.logout();
        } else if (localStorage.getItem('plx')) {
            let timer = this.idleTimer ? this.idleTimer.getRemainingTime() : 14400000
            localStorage.setItem('plx', timer);
            sessionStorage.setItem('timeout', 'false');
        }
    }

    handleOnActive() {
        if (localStorage.getItem('plx') === '0' && sessionStorage.getItem('timeout') === 'true') {
            showAlertDialog({title: "Your session expired", expired: true});
            this.props.logout();
        }
    }

    handleOnIdle() {
        sessionStorage.setItem('timeout', 'true');
        localStorage.setItem('plx', '0');
    }
}

ApplicationLayout.propTypes = {
    children: PropTypes.element,
    logout: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationLayout);

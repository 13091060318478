import React from "react";
import PropTypes from "prop-types";
import {isNullOrUndefined} from "util";

import {Icon,Dialog,Checkbox,Backdrop ,TextField ,Typography, DialogTitle,
    DialogActions,DialogContent,FormControlLabel,CircularProgress,DialogContentText} from "@mui/material";
import {withStyles} from "@mui/styles";

import Utils from '../../../../utils';
import {API, doPost} from '../../../../api';
import {avatarSize, postActions} from "../../../../constants";
import Avatar from "../../../components/Avatar";

const customContentStyle = {
    width: '95%',
    maxWidth: 'none',
};

const stylesSubnodesFilter = {
    search: {
        "& div::before": {
            borderBottom: "1px solid #ef4254!important"
        },
    }
};

const cancelBtn = {
    borderRadius: 25,
    color: "#fff",
    textTransform: 'capitalize',
    margin: "5px"
}

const continueBtn = {
    borderRadius: 25,
    color: "#fff",
    textTransform: 'capitalize',
    margin: "5px",
    backgroundColor: "#ef4a4a"
}

class SubnodesFilter extends React.Component {
    constructor(props) {
        super(props);
        let filter;
        this.pie = true;
        filter = {
            sanction: false,
            pep: false,
            non_active: false,
            info: false,
            without_entity: false
        };
        this.firstTimeChecked = props.nodes.filter(item => item.checked).map(item => item.id);
        this.firstTimeUnchecked = props.nodes.filter(item => !item.checked).map(item => item.id);
        this.showLoadMore = Utils.isNotNullOrUndefined(props.nodes[0]) && Utils.isNotNullOrUndefined(props.nodes[0].moreRelations) ? props.nodes[0].moreRelations : true;
        this.nodes = props.nodes;
        let uniqueRelations = {};
        props.nodes.forEach(item => {
            item.category.forEach(cat => {
                let category = cat.toLowerCase().substr(1);
                const include = category !== "other" && category !== "generic_cross_entity" && category !== "interpersonal";
                if (include) {
                    if (category.startsWith('shareholding')) {
                        filter['shareholding'] = false;
                    } else {
                        filter[category] = false;
                    }
                }
            })
        })

        this.state = {
            subnodes: props.nodes,
            subEdges: props.edges,
            clickedNodeId: props.clickedNodeId,
            subnodesSelected: [],
            filter,
            relationFilter: uniqueRelations,
            checkedAll: Utils.isNullOrUndefined(props.checkedAll) ? false : props.checkedAll,
            open: true,
            showWarning: false,
            showLoadMore: true,
            subnodesEdges: [],
            openBackDrop: false,
            hideFormers: false,
            existFilters: false,
            moreRelationWarning: '',
        };
    }

    renderFilterItem (item, index) {
        const labelText = item === 'without_entity' ? 'other' : item;
        const title =  labelText.charAt(0).toUpperCase() +  labelText.substr(1).replace(/_/g, ' ');
        return (
            <div key={`${labelText}_${index}`} style={{marginTop:5}} >
                <FormControlLabel
                    style={{width: "100%", height: '27px'}}
                    label={<span style={{fontSize: "16px", color: "rgba(0, 0, 0, 0.87)"}}>{title}</span>}
                    control={
                        <Checkbox
                            checkedIcon={<Icon
                                className="material-icons"
                                style={{
                                    color: "#ef4254",
                                    fontSize: "24px"
                                }}>check_box</Icon>
                            }
                            icon={<Icon
                                className="material-icons"
                                style={{color: "#6e7072", fontSize: "24px"}}
                            >check_box_outline_blank</Icon>
                            }
                            checked={this.state.filter[item]}
                            onChange={() => this.filterResult(item)}
                        />
                    }/>
            </div>
        )
    }

    render() {
        const notMainOrClickedNodes = (elementData) => elementData.id !== this.props.mainNodeId && elementData.id !== this.state.clickedNodeId;
        const nodesToAdd = this.state.subnodes.filter(item => this.firstTimeChecked.indexOf(item.id) === -1 && item.checked && notMainOrClickedNodes(item));
        const nodesToRemove = this.state.subnodes.filter(item => this.firstTimeUnchecked.indexOf(item.id) === -1 && !item.checked && notMainOrClickedNodes(item));
        const disabled = nodesToAdd.length === 0 && nodesToRemove.length === 0;

        const actions = [
            <div key="text">{this.state.showWarning &&
                <div style={{color: '#ef4254', width: '50%', float: 'left', textAlign: 'left', marginLeft: '20px'}}>*To
                    continue you should choose at least one node or press cancel</div>}</div>,
            !this.props.extraNodes && <button key="cancel" style={cancelBtn} onClick={() => this.props.handleClose()}
                    className="btn btn-floating btn-large waves-effect waves-light">{this.props.extraNodes ? 'Ok' : 'Cancel'}</button>,
            !this.props.extraNodes &&  <button key="continue" disabled={disabled} style={continueBtn} onClick={() => this.addToGraph()}
                                                   className="btn btn-floating btn-large waves-effect waves-light">Continue</button> ,
            this.props.extraNodes && <button key="continue" style={continueBtn} onClick={() => this.props.handleClose()}
                    className="btn btn-floating btn-large waves-effect waves-light">Ok</button>
        ];

        const legendItems = [];
        const nodesRender = [...this.state.subnodes]
        let checkedAll = this.state.checkedAll;
        const checkAllNodesImage = checkedAll ? "/images/center_checkbox_checked.png" : "/images/center_checkbox.png";
        nodesRender.forEach((elementData, index) => {
            if (elementData.id === this.props.mainNodeId) {
                elementData.checked = true;
                elementData.disable = true
            }
            if (elementData.isVisible && elementData.checked) {
                elementData.checked = true;
            }
            if (elementData.id === this.props.clickedNodeId) return;
            const checked = Utils.isNullOrUndefined(elementData.checked) ? false : elementData.checked;
            const checkboxImage = checked ? "/images/center_checkbox_checked.png" : "/images/center_checkbox.png";
            const checkboxImageUnable = "/images/brackets/3/gray_ellipse.png";
            const relationLabel = this.getRelationWithClicked(elementData.id);
            if (isNullOrUndefined(elementData.pie)) {
                this.pie = false;
            } else {
                this.pie = true;
                elementData.avatarPie = Object.keys({...elementData.pie})
                let pies = [];

                if (!isNullOrUndefined(elementData.pie)) {
                    Object.keys(elementData.pie).forEach(pieItem => {
                        pies.push({
                            name: pieItem,
                            color: elementData.pie[pieItem].color,
                            text: elementData.pie[pieItem].value
                        })
                    })
                }
                elementData.pieData = pies;
                if ( elementData.image) {
                    elementData.avatar = elementData.image;

                }
            }
            legendItems.push(
                <div key={"item-" + index} style={{height: '55px'}} className={this.pie ? 'subnode-pie' : '' }>
                    <table>
                        <tr><th/></tr>
                        <tbody>
                        <tr title={relationLabel}>
                            <td>
                                <img alt='checkboxImage' src={elementData.unable ? checkboxImageUnable : checkboxImage}
                                     style={{margin: '0 10px', cursor: elementData.disable ? 'not-allowed' : "pointer", opacity: elementData.disable ? '0.5' : "1"}}
                                     onClick={() => this.checkboxClick(elementData)}
                                     className={elementData.unable ? '' : "pointer"}/>
                            </td>
                            <td style={{width: '550px'}}>
                                <span className={elementData.unable ? '' : "pointer"} style={{
                                    cursor: elementData.disable ? 'not-allowed' : "pointer",
                                    textTransform: 'capitalize',
                                    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                    fontSize: '12px',
                                    opacity: elementData.disable ? '0.5' : "1",
                                }} onClick={() => this.checkboxClick(elementData)}>{elementData.title}</span>
                            </td>
                            <td style={!isNullOrUndefined(elementData.pie) ? {
                                transform: "scale(0.4)",
                                position: 'relative',
                                top: '-3px',
                                left: '0',
                                right: '-6px'
                            } : {transform: "scale(0.4)", position: 'relative', top: '-3px', left: '-6px'}}>
                                <Avatar node={elementData} size={avatarSize.NORMAL}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            );

        });
        const title = this.props.extraNodes ? '' : 'The node has too many relations. Showing all related nodes might harm the graph readability. You may select fewer relations to show from the list below.';

        const paperProps = this.props.extraNodes ? {
            width: "50%",
            height: "240px",
            margin: "0 auto",
            transform: "translate(0px, 11px)"
        } : {
            width: "95%",
            height: "830px",
            margin: "0 auto",
            transform: "translate(0px, 11px)"
        };

        const leftFilter = Object.keys(this.state.filter).slice(0,5);
        const rightFilter = Object.keys(this.state.filter).slice(5);

        return (
            <Dialog
                style={{zIndex: 5000}}
                open={true}
                onClose={this.props.handleClose}
                scroll="paper"
                PaperProps={{
                        style: paperProps
                }}
                maxWidth="xl"
            >
                <DialogTitle style={{color: 'fff'}} className="explore-header">
                    {title}
                </DialogTitle>
                <DialogContent style={{paddingTop: "0px"}}>
                    <DialogContentText component="div" style={customContentStyle} className="explore-dialog-body">
                        {
                            this.props.extraNodes ?
                                <div className='extra-subnodes'>
                                    <p>Relations are more than 1000, if you don't find the entity you are searching in this list, then try the main search or relationship path search</p>
                                </div> :
                                <div className="row">
                                    <Typography variant={'h5'}
                                                style={{fontSize: '16px', color: '#2679af', padding: '1.5rem .5rem 0 1.5rem'}}>
                                        {Utils.isNotNullOrUndefined(this.props.clickedNode) && this.props.clickedNode.title}
                                    </Typography>
                                    <div className="col-sm-8">
                                        <TextField
                                            id="search-entity"
                                            placeholder="Search"
                                            variant={"standard"}
                                            multiline
                                            className={this.props.classes.search}
                                            inputProps={{style: {fontSize: '13px'}}}
                                            style={{width: '95%', marginTop: "8px", marginBottom: "8px"}}
                                            onChange={(event) => this.searchItem(event)}
                                        /><br/>

                                        <Typography color="error">{this.state.moreRelationWarning}</Typography>
                                        <div className="entity">
                                            <div style={{display: 'flex', alignItems: 'center', paddingBottom: '.5rem'}}>
                                                <img src={checkAllNodesImage} className="pointer" alt='pointer'
                                                     style={{margin: '10px 0px'}}
                                                     onClick={() => this.toggleCheckboxes()}/>
                                                <span style={{fontSize: "14px", marginLeft: '1rem'}}>All</span>
                                            </div>
                                            {legendItems}
                                        </div>

                                    </div>
                                    <div className="col-sm-2">
                                        <div className='text-lighter' style={{padding: '23px 0', color: '#ef4254'}}>
                                        </div>
                                        <div>
                                            <div className='filters-body'>
                                                {
                                                    this.state.existFilters &&  <div className='filters-revert' onClick={() => this.revertFilters()}>
                                                        <Icon className="material-icons pointer" style={ {
                                                            fontSize: '26px',
                                                            margin: '4px',
                                                            float: 'right',
                                                            color: "#ef4254"
                                                        } }>refresh</Icon>
                                                    </div>
                                                }
                                                <FormControlLabel
                                                    title={this.state.hideFormers ?'Click to hide the former relations': ' Click to show the former relations'}
                                                    style={{width: "100%", height: '27px'}}
                                                    label={<span style={{fontSize: "16px", color: "rgba(0, 0, 0, 0.87)"}}>{this.state.hideFormers ?'Hide All Formers': 'Select all Formers' }</span>}
                                                    control={
                                                        <Checkbox
                                                            checkedIcon={<Icon
                                                                className="material-icons"
                                                                style={{
                                                                    color: "#ef4254",
                                                                    fontSize: "24px",
                                                                    padding: 0
                                                                }}>check_box</Icon>
                                                            }
                                                            icon={<Icon
                                                                className="material-icons"
                                                                style={{color: "#6e7072", fontSize: "24px"}}
                                                            >check_box_outline_blank</Icon>
                                                            }
                                                            checked={this.state.hideFormers}
                                                            onChange={() => this.filterFormers()}
                                                        />
                                                    }/>
                                            </div>
                                            {
                                                leftFilter.map((item, index) => {
                                                    return this.renderFilterItem(item, index)
                                                })
                                            }
                                        </div>

                                    </div>

                                    <div className="col-sm-2">
                                        <div className='text-lighter' style={{padding: '23px 0', color: '#ef4254'}}>
                                        </div>
                                        {
                                            rightFilter.map((item, index) => {
                                                return this.renderFilterItem(item,index)
                                            })
                                        }
                                    </div>

                                    {this.showLoadMore &&
                                        <div className="text-center read-more" style={{clear: 'both', paddingTop: '20px'}}
                                             onClick={() => this.loadMore()}>Load More<br/>
                                            <i className="material-icons"> expand_more</i>
                                        </div>}
                                </div>

                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{fontFamily: "Roboto, sans-serif"}}>
                    {this.state.actionsText !== "" &&
                        <Typography style={{fontSize: "16px"}} color="error">{this.state.actionsText}</Typography>}
                    {actions}
                </DialogActions>

                <Backdrop style={{zIndex: 2020}} open={this.state.openBackDrop}>
                    <CircularProgress style={{color: "#fff"}}/>
                </Backdrop>
            </Dialog>
        );
    }

    getRelationWithClicked(subNodeId) {
        let labels = '';
        this.state.subEdges.forEach(item => {
            if ((item.sourceId === this.state.clickedNodeId && item.targetId  === subNodeId) ||  (item.sourceId === subNodeId && item.targetId === this.state.clickedNodeId)) {
                labels += `${item.label} ,`;
            }
        })
        return labels.length > 0 ? labels.slice(0, -1) : labels;
    }

    searchItem(event) {
        let searchKey = event.target.value;
        let matchedNodes = [];
        if (searchKey.length > 0) {
            this.nodes.forEach((elementData) => {
                if (elementData.title.toLowerCase().indexOf(searchKey.toLowerCase()) !== -1) {
                    matchedNodes.push(elementData);
                }
            });
            this.setState({subnodes: matchedNodes})

        } else {
            this.setState({subnodes: this.nodes})
        }
    }

    revertFilters() {
        let allChecked = 0;
        this.state.subnodes.forEach(item => {
           const include = this.firstTimeChecked.includes(item.id);
           item.checked = include;
           allChecked = include ? allChecked + 1 : allChecked
       })

        const filters = this.state.filter;
        Object.keys(filters).forEach(item => filters[item] = false)

        this.setState({checkedAll: allChecked === this.state.subnodes.length, filter: filters, existFilters: false, moreRelationWarning: '', hideFormers: false})
    }

     filterFormers() {
        let allChecked = 0;
        this.state.subnodes.forEach(item => {
            if (item.isHistorical) {
                item.checked = !this.state.hideFormers;
                allChecked = item.checked ? allChecked +1 : allChecked;
            } else {
                item.checked = false;
            }
        })
        this.setState({
            hideFormers: !this.state.hideFormers,
            checkedAll: allChecked === this.state.subnodes.length,
            existFilters: true
        })
    }

    filterResult(type) {
        let filter = this.state.filter;
        filter[type] = !this.state.filter[type];

        const checkedFilter = Object.keys(filter).filter(item => filter[item] === true);
        let allChecked = 0;
        this.setState({filter}, () => {
            this.state.subnodes.forEach((elementData) => {
                const pieWithCategory = this.getNodesCategory(elementData);
                const prevChecks = [];

                pieWithCategory.forEach(item => {
                    if (checkedFilter.includes(item) ) prevChecks.push(item);
                })
                elementData.checked = prevChecks.length > 0 ;
                allChecked = elementData.checked ? allChecked+1 : allChecked;
            });
            this.setState({checkedAll: allChecked === this.state.subnodes.length, existFilters: true})
        });
    }

    getNodesCategory(element) {
        const pieData =  element.pie ? Object.keys(element.pie) : ['without_entity'];
        const categories = element.category.map(item =>{
            let category = item.toLowerCase().substr(1);
            if (category.startsWith('shareholding')) {
                category = 'shareholding'
            }
            return category;
        });
        return pieData.concat(categories);
    }

    checkboxClick(subnode) {
        const checked = Utils.isNullOrUndefined(subnode.checked) ? false : subnode.checked;
        subnode.checked = !checked;
        let subnodes = this.state.subnodes;
        this.setState({showWarning: false});

        const categories = this.getNodesCategory(subnode);
        const unchecked = subnodes.filter(item => !item.checked);
        const filters = this.state.filter;

        if (!subnode.checked) {
            categories.forEach(item => filters[item] = false);
        }
        this.setState({checkedAll: unchecked.length === 0, filter: filters, subnodes});
    }

    toggleCheckboxes() {
        const nodes = [];
        let moreRelationWarning = '';
        let i = 0;
        let j = 0;
        while (i < this.state.subnodes.length) {
            const node = this.state.subnodes[i];
            node.checked = !this.state.checkedAll;
            nodes.push(node);
            if (!this.firstTimeChecked.includes(node.id)) j++;
            if  (j > 99 && !this.state.checkedAll) {
                moreRelationWarning = !this.state.checkedAll ? 'Only 100 nodes are selected, because the relations are too many.' : ''
                break;
            }
            i++
        }

        this.setState({
            checkedAll: !this.state.checkedAll,
            nodes,
            showWarning: false,
            existFilters: true,
            moreRelationWarning
        }, () => {
            if (!this.state.checkedAll) {
                const filters = this.state.filter;
                Object.keys(filters).forEach(item => filters[item] = false)
                this.setState({filter: filters, hideFormers: false});
            }
        });
    }

     notMainOrClickedNodes (elementData) {
        return  elementData.id !== this.props.mainNodeId && elementData.id !== this.state.clickedNodeId;
     }


    addToGraph() {
        const nodesToAdd = this.state.subnodes.filter(item => this.firstTimeChecked.indexOf(item.id) === -1 && item.checked && this.notMainOrClickedNodes(item));
        const nodesToRemove = this.state.subnodes.filter(item => this.firstTimeUnchecked.indexOf(item.id) === -1 && !item.checked && this.notMainOrClickedNodes(item));
        const clickedNode = this.state.subnodes.filter(item => item.id === this.state.clickedNodeId)[0];

        if (nodesToAdd.length > 0 || nodesToRemove.length > 0) {
            if (nodesToAdd.length < 101) {
                this.props.addSubnodesToGraph(nodesToAdd, nodesToRemove, this.state.subnodesEdges, clickedNode);
            } else if (this.state.actionsText === "" || Utils.isNullOrUndefined(this.state.actionsText)) {
                this.setState(
                    {actionsText: "Only 100 nodes can be shown"},
                    () => setTimeout(() => this.setState({actionsText: ""}), 3000)
                );
            }
        }
    }

    loadMore() {
        this.setState({openBackDrop: true});
        const prId = sessionStorage.getItem('prId');
        const bnli = localStorage.getItem('bnli');
        const requestData = {
            action: postActions.GRAPH_MORE,
            time: new Date().getTime(),
            nid: this.state.clickedNodeId,
            bnli: bnli,
            projectId: prId
        };
        doPost(API.GET_RELATED_NODES, {qry: JSON.stringify(requestData)}, true).then(response => {
            if (Utils.isNotNullOrUndefined(response.data)) {
                let subnodes = this.state.subnodes.slice(0);
                this.showLoadMore = (Utils.isNotNullOrUndefined(response.data.data.nodes[0]) && Utils.isNotNullOrUndefined(response.data.data.nodes[0].moreRelations)) ? response.data.data.nodes[0].moreRelations : false;
                let subnodesId = subnodes.map(function (x) {
                    return x.id;
                })
                let subnodesEdges = response.data.data.edges;
                response.data.data.nodes.forEach(node => {
                    if (subnodesId.indexOf(node.id) === -1) {
                        node.checked = false;
                        node.isVisible = false;
                        subnodes.push(node)
                    }
                })
                this.firstTimeUnchecked = this.firstTimeUnchecked.concat(subnodes.map(_node => _node.id))
                this.setState({subnodes, subnodesEdges, openBackDrop: false}, () => {
                    this.nodes = this.state.subnodes;
                });
            }
        }).catch(error => {
            console.error(error);
            this.setState({openBackDrop: false});
            // this.props.sessionExpired()
        });
    }
}

SubnodesFilter.propTypes = {
    nodes: PropTypes.array,
    clickedNodeId: PropTypes.string,
    addSubnodesToGraph: PropTypes.func,
    handleClose: PropTypes.func,
    checkedAll: PropTypes.bool,
    mainNodeId: PropTypes.string,
    sessionExpired: PropTypes.func,
    clickedNode: PropTypes.object,
};

export default withStyles(stylesSubnodesFilter)(SubnodesFilter);

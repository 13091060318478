import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

let ITEMS_PER_PAGE = 200;
let HtmlTextInstanceIndex = 0;

export default class HtmlText extends React.Component {
    constructor( props ) {
        super( props );
        this.id = HtmlTextInstanceIndex++;
        this.state = { currentPage: 0 };
    }

    render() {
        let content = null;
        if ( this.props.data ) {
            let items = this.props.data;
            const totalCount = items.length;
            if ( this.props.print !== "" ) {
                ITEMS_PER_PAGE = totalCount;
            }
            if ( totalCount > ITEMS_PER_PAGE ) {
                items = items.slice( this.state.currentPage * ITEMS_PER_PAGE, this.state.currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE );
            }
            content = items.map( ( line, index ) => {
                return <div key={ "text_" + index } dangerouslySetInnerHTML={ { __html: line.join( "" ) } }/>;
            } );
        }
        return (
            <div key={ "html-text-" + this.id }>
                { content }
                { this.renderPagination() }
            </div>
        );
    }

    renderPagination() {
        let items = this.props.data;
        const totalCount = items.length;
        if ( totalCount > ITEMS_PER_PAGE ) {
            return <div className="pagination-div">
                <ReactPaginate
                    containerClassName="pagination pull-right"
                    pageCount={ totalCount / ITEMS_PER_PAGE }
                    breakLabel={ <a href='/#' onClick={(e)=>e.preventDefault()}>...</a> }
                    breakClassName={ "break-me" }
                    subContainerClassName={ "pages pagination" }
                    activeClassName={ "active" }
                    pageRangeDisplayed={ ITEMS_PER_PAGE }
                    marginPagesDisplayed={ ITEMS_PER_PAGE }
                    onPageChange={ ( data ) => this.changePage( data ) }/>
            </div>;
        } else {
            return null;
        }
    }

    changePage( data ) {
        this.setState( { currentPage: data.selected } );
    }
}

HtmlText.defaultProps = {
    isMaximize: false,
};

HtmlText.propTypes = {
    data: PropTypes.array,
    isMaximize: PropTypes.bool,
    print: PropTypes.string
};
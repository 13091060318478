export const searchFilterTypes = {
    sanction: {
        title: 'Filter your search results by leaving persons or entities under direct sanctions.',
        name: 'sanction',
        label: 'Sanction'
    },
    pep: {
        title: 'Filter your search results by leaving persons or entities under direct political exposure.',
        name: 'pep',
        label: 'Pep'
    },
    closed: {
        title: 'Filter your search results by leaving only closed entities.',
        name: 'non_active',
        label: 'Closed'
    },
    info: {
        title: 'Filter your search results by entities that have specific alerts, such as Forbes-listed entities, entities that appear on certain warning lists, wanted lists, etc..',
        name: 'info',
        label: 'Info'
    }

}

export const nodePieTypes = {
    sanction: "SANCTION",
    pep: "PEP",
    info: "INFO",
    non_active: "NON_ACTIVE"
}

export const initialStartDate = -2209085880;
export const initialStartYear = 1900;

export const nodesExploreMaxCount = 30;

export const defaultRelatedRadius = 2;
export const fuzzinessValues = {
    EXACT: {
        label: "",
        match:"exact",
        value:-1,
        title: ""
    },
    FUZZY_0 : {
        label: "Low",
        match:"fuzzy",
        value:0,
        title: "Fuzzy"
    },
    FUZZY_1 : {
        label: "Medium",
        match:"fuzzy",
        value:1,
        title: "Fuzzy + 1 char difference"
    },
    FUZZY_2 : {
        label: "High",
        match:"fuzzy",
        value:2,
        title: "Fuzzy + 2 char difference"
    },
    FUZZY_3 : {
        label: "Maximum",
        match:"fuzzy",
        value:3,
        title: "Fuzzy + 3 char difference"
    }
}

export const nonSelectedNodesDialog = {
    title: "Error",
    message: "You must select at least one person/company."
}

export const maxSelectedNodesDialog = {
    title: "Warning",
    message: "The count of selected nodes is greater than 20. Are you sure you want to continue merge nodes?"
}

export const maxSelectedNodesExceedDialog = {
    title: "Explore declined",
    message:  "The count of selected nodes is greater than 30"
}

export const popUpWarnDialog = {
    title: "Attention!",
    message: "Popups blocked in your browser. Please allow popups in browser settings and try again.",
}

export const searchTypeChangeDialog = {
    title: "Warning",
    message: "The search string contains numbers. Are you sure you want to use a fuzzy matching?",
    continueExact:"Continue as exact",
    continueFuzzy: "Continue as fuzzy"
}

export const searchQueryContainsAlphaNumericItemAlert = ' Search value should contain at least three alphanumeric characters.';

export const searchQueryMixedCharsAlert = 'The search value contains mixed alphabet characters.';
export const searchQueryMinLengthAlert = 'Search value length should be at least three symbols.';

export const searchQueryExceedMaxNames ='You cannot search for more than three names simultaneously.';

export const searchDateLabelText = "Type in date of birth, date of death for a person; or registration or closing date for a company.";

export const searchRelatedLabel = "Type a related person's or related company's name.";
export const days = [ '---', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31' ];
export const months = [ '---', 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC' ];

export const RUSSIAN_REG_EXP = /[А-Яа-яЁё]/g;
export const SYMBOL_REG_EXP = /[\/\\|&$.@!#%^*(_) +]/g;

export const ENGLISH_REG_EXP = /[A-Za-z]/g;
export const  ARDIS_ID_REX = new RegExp("[PLU]\\d{15}\\d*");

export const relatedRadiusTitles = {
    minRelatedRadius: "You will not see any related persons or companies in your search result.",
    directRelatedRadius: "You will only see persons and companies with direct links to the person or entity you searched.",
    secondaryRelatedRadius: "You will be able to see secondary relations (i.e. relations of relations) of the person or entity you searched."
}

export const advancedLanguageOptionTitle = 'Manually choose the language in which you want to make your search, or choose the option of Auto language detection.';
export const advancedMatchOptionTitle = "'Exact' match type makes exact search on the name you type. When you’re not sure about the exact spelling of the name, choose 'Fuzzy' match type.";
export const advancedCountryOptionTitle = "Type in either the full name of a related country or the ISO alpha-2 code. (e.g. 'RU' or 'Russia')";

export const noResultAlert = "No results have been found in both PERSON  and ORGANIZATION categories";

import React from "react";
import PropTypes from "prop-types";

export default class Lighter extends React.Component {
    render() {
        return (<div className="lighter">
            { this.renderContent() }
        </div>);
    }

    renderContent() {
        let content = [];
        this.props.data.colors.forEach( ( obj ) => {
            content.push(
                <div className="row lighter-rows" key={ "key_" + obj.name }>
                    { obj.isHighlighted ?
                        <div className="col-xs-4 text-right lighter-name lighter-name-big">
                            <span className="name-span">{ obj.name } &nbsp; &nbsp;</span>
                            <span><img alt='lighterRow' src="/images/lighter_row.png" style={ { backgroundColor: obj.color } }/></span>
                        </div> :
                        <div className="col-xs-4 text-right lighter-name text-lighter">
                            <span>{ obj.name }  &nbsp; &nbsp;</span>
                            <span><img alt='lighterRowImg' src="/images/lighter_row.png" style={ { backgroundColor: obj.color } }/></span>
                        </div>
                    }

                    <div className="col-xs-2 lighter-square">
                        { obj.isHighlighted ?
                            <div className="report-cube-big" style={ { backgroundColor: obj.color } }/> :
                            <div className="report-cube" style={ { backgroundColor: obj.color } } /> }
                    </div>
                    <div className="col-xs-5 lighter-text">
                        <div className="lighter-title">{ obj.title }</div>
                        <ul className="lighter-comment">
                                {
                                    obj.comment.map((item, i) => (
                                        <li key={`comment_${i}`}>{item}</li>
                                    ))
                                }
                        </ul>
                    </div>
                </div>
            )
        } );
        return (
            <div>
                { content }
            </div>
        )
    }
}

Lighter.propTypes = {
    data: PropTypes.object
};
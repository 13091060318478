import React, {useState} from "react";

import {Grid, Icon, Dialog, DialogTitle, DialogActions, DialogContent, TextField, Button} from '@mui/material';
import c from "./dialog.module.css";
import moment from "moment";
import {newsSearchItems} from "../../../constants";
import {monthsShort} from "moment/moment";
import {initialStartDate, initialStartYear} from "../../../application/search/constants/search";
import {ThemeProvider} from "@mui/material/styles";
import {datePickerStyles} from "../../../application/search/constants/searchStyles";
import {getFormattedDate} from "../../../application/search/constants/getFormattedDate";

const DialogDatePicker = ({onSave, isOpen, onCancel}) => {

    const [open, setOpen] = useState(isOpen);
    const [localDate, setLocalDate] = useState({
        year: "",
        month: "",
        day: ""
    });

    const [disabledDate, setDisabledDate] = useState({
        year: true,
        month: true
    })
    const [error, setError] = useState(null);
    const handleClose = () => {
        onCancel();
    }

    const getConcatenateDate = () => {
        let formattedDate = ""

        if (!disabledDate.month && localDate.day) {
            formattedDate += localDate.day + "/"
        }

        if (!disabledDate.year && localDate.month) {
            formattedDate += localDate.month + "/"
        }

        formattedDate += localDate.year;
        return formattedDate;
    }
    const handleSave = () => {
        setOpen(!open)
        const date = getConcatenateDate();

        onSave(date);
    }

    const validateYear = (year) => {
        const currentYear = moment().year();
        return /^\d{4}$/.test(year) && parseInt(year) > initialStartYear && parseInt(year) <= currentYear;
    }

    const handleYearKeyDown = (e) => {
        if (e.code === newsSearchItems.codes.ENTER || e.code === newsSearchItems.codes.NUM_ENTER) {
            if (validateYear(e.target.value)) handleSave(e.target.value);
            else setError(`Enter date from 01/JAN/${initialStartYear} to ${moment().format('DD/MMM/YYYY')}`)
        }
    }

    const onYearChange = (e) => {
        const {value} = e.target;
        setLocalDate({...localDate, year: value})

        if (/^\d+$/.test(value)) {
            const isValid = validateYear(value);
                setDisabledDate({
                    ...disabledDate,
                    year: !isValid,
                });

            if (value.length >=4 && !isValid) {
                setError(`Enter date from 01/JAN/${initialStartYear} to ${moment().format('DD/MMM/YYYY')}`)

            } else setError(null)
        }
    }

    const handleSelect = (name, e) => {
        setLocalDate({
            ...localDate,
            [name]: e.target.value
        });
        setDisabledDate({
            ...disabledDate,
            month: false
        });
    }
    const daysArray = localDate.month ? Array.from({length: moment().month(localDate.month).daysInMonth()}, (_, i) => i + 1) : [];
    const concatDate = getConcatenateDate()

    const isDisabledSave = disabledDate.year || !moment(getFormattedDate(concatDate)).isBetween(moment.unix(initialStartDate), moment.now());

    return (
        <Dialog
            open={open}
            maxWidth='md'
            fullWidth
            onClose={handleClose}>
            <DialogTitle className={c.dialogTitle}>
                <div className={c.dialogTitleInner}>
                    <div>Enter related date</div>
                    <Icon className={`${c.pointer} ${c.dialogIcons}`}
                          onClick={handleClose}>close</Icon>
                </div>
            </DialogTitle>
            <DialogContent className={c.dialogBody}>
                <ThemeProvider theme={datePickerStyles}>
                   <div className={c.dialogBodyDate}>{concatDate && `Selected Date: ${concatDate}`}</div>


                    <Grid container spacing={3} alignItems='flex-end'>
                        <Grid item xs={4}>
                            <div>Year</div>
                            <TextField
                                placeholder={`Enter Year (1900 - ${moment().year()})`}
                                fullWidth
                                value={localDate.year}
                                autoFocus
                                type={"year"}
                                onKeyDown={(e) => handleYearKeyDown(e)}
                                onChange={onYearChange}
                                className={c.formItem}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className={c.dialogSubTitle}>Month</div>
                                <select disabled={disabledDate.year} value={localDate.month}
                                        className={`${c.selects} ${c.formItem}`}
                                        onChange={(e) => handleSelect('month', e)}>
                                    <option key={`month_none`} value={"--"}>---</option>
                                    {
                                        monthsShort().map((month, index) => <option key={`month_${month}-${index}`} value={month}> {month} </option>)
                                    }
                                </select>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className={c.dialogSubTitle}>Day</div>
                                <select disabled={disabledDate.month} value={localDate.day}
                                        className={`${c.selects} ${c.formItem} `}

                                        onChange={(e) => handleSelect('day', e)}>
                                    <option key={`day_none`} value={"--"}>---</option>
                                    {
                                        daysArray.map((day, index) => <option key={`day_${day}-${index}`} value={day < 10 ? `0${day}` : day}>{day < 10 ? `0${day}` : day}</option>)
                                    }
                                </select>
                            </div>
                        </Grid>
                        {
                            error && <Grid  className={c.dialogError}><p>{error}</p></Grid>
                        }
                    </Grid>
                </ThemeProvider>

            </DialogContent>
            <DialogActions>
                <div className={c.actions}>
                    <Button onClick={handleClose} className={c.cancel}>Cancel</Button>
                    <Button onClick={handleSave} className={c.save} disabled={isDisabledSave} sx={{
                        "&.Mui-disabled": {
                            backgroundColor: "#ccc",
                            color: "#fff"
                        }
                    }}>Save</Button>
                </div>
            </DialogActions>

        </Dialog>
    )
}

export default DialogDatePicker;

import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    ARDIS_ID_REX,
    defaultRelatedRadius, ENGLISH_REG_EXP,
    fuzzinessValues,
    maxSelectedNodesDialog,
    maxSelectedNodesExceedDialog, nodesExploreMaxCount,
    nonSelectedNodesDialog, noResultAlert,
    popUpWarnDialog, RUSSIAN_REG_EXP,
    searchQueryContainsAlphaNumericItemAlert,
    searchQueryExceedMaxNames,
    searchQueryMinLengthAlert,
    searchQueryMixedCharsAlert, searchTypeChangeDialog, SYMBOL_REG_EXP,
} from "./constants/search";
import {
    appEnv,
    maxSearchPipes,
    newsSearchItems,
    nodeTypes, pipeEnv,
    postActions,
    ProgressStatus,
    searchLanguageTypes, userTypes
} from "../../constants";
import Utils from "../../utils";
import c from "./search.module.css";
import {
    Business,
    DateRange,
    KeyboardArrowDown,
    KeyboardArrowUp,
    LocationOn,
    Person,
    Timeline,
    Translate
} from "@mui/icons-material";
import {Button, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import SearchFilter from "../components/SearchFilter";
import AdvancedSearchOptions from "../components/AdvancedSearchOptions";
import MediaScreening from "../pressa/components/MediaScreening";
import Loader from "./Loader";
import {useDispatch, useSelector} from "react-redux";
import {
    addToCard,
    clearStore,
    emptyResult,
    generateReport,
    showExploreDialog,
    showMergeNodesDialog,
    stopSearch
} from "../actions";
import {emptyNewsState} from "../pressa/pressaStore/newsAction";
import ExploreDialog from "./ExploreDialog";
import MergeNodesDialog from "./MergeNodesDialog";
import PolixisRoutes from "../../routes";
import {showAlertDialog, showConfirmDialog} from "../../shared/dialog/actions";

const SearchBar = ({
                        showExplore,
                        onSearch,
                        isRunSearch,
                        filter,
                        query,
                        onResultFilter,
                        isChangedFilter,
                        onGenerateReport,
                        onRoutChange
                    }) => {
    const results = useSelector(state => state?.application.search);
    const dialog = useSelector(state => state?.dialog);
    const explore = useSelector(state => state?.application.explore);
    const mergeNodes = useSelector(state => state?.application.mergeNodes);

    // eslint-disable-next-line
    const updateSearchBar = useSelector(state => state?.application.updateSearchBar);

    const dispatch = useDispatch();
    const [advancedSearch, setAdvancedSearch] = useState({
        countries: query?.countries || [],
        related: query?.related || [],
        date: query?.date || [],
        relatedRadius: query?.relatedRadius || defaultRelatedRadius,
        fuzziness: query?.ld || query?.options?.match ? fuzzinessValues.EXACT.value : fuzzinessValues.FUZZY_2.value,
        language: {
            type: searchLanguageTypes.AUTO,
            languageCode: null,
            label: null
        }
    });

    const searchValue = sessionStorage.getItem("searchValue") ? JSON.parse(sessionStorage.getItem("searchValue")) : null;
    const searchRequest = sessionStorage.getItem("searchRequest") ? JSON.parse(sessionStorage.getItem("searchRequest")) : null;
    const [searchQuery, setSearchQuery] = useState(searchValue?.value || searchRequest?.keyword || "");
    const [searchType, setSearchType] = useState(searchValue?.type.toLowerCase() || searchRequest?.type || query?.type || nodeTypes.PERSON);

    const [messages, setMessages] = useState([]);
    const [cartSnack, setCartSnack] = useState({
        showSnack: false,
        snackNodes: []
    });

    const [searchResults, setSearchResults] = useState({
        resultCount: results.resultCount,
        status: results.status,
        result: results.results
    })

    const [isTypeChanged, setIsTypeChanged] = useState(false);
    const initialType = useRef(null);
    const [matchType, setMatchType] = useState(query?.options?.match);
    const [showQueryLengthAlert, setShowQueryLengthAlert] = useState(false);
    const [showPipeErrorText, setShowPipeErrorText] = useState(null);
    const [showMaxPipesCount, setShowMaxPipesCount] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState(null);
    const [showResultFilterOption, setShowResultFilterOption] = useState(false);
    const [showAdvancedOption, setShowAdvancedOption] = useState(false);
    const [isCancelSearch, setIsCancelSearch] = useState(false);
    const isPlxUser = localStorage.getItem('type') === userTypes.superAdmin || localStorage.getItem('type') === userTypes.dataManager;
    const isOnlyNewsSearch = JSON.parse(sessionStorage.getItem('isOnlyNewsSearch'));

    const autoChange = useRef(false);
    const handleQueryChange = (e) => {
        setSearchQuery(e.target.value)
    }
    const doSearchKeyPress = async (e) => {
        if (e.code === newsSearchItems.codes.ENTER) {
            await handleSearch();
        }
    }

    const toggleClickIcons = () => {
        setShowAdvancedOption(!showAdvancedOption);
        setShowResultFilterOption(false);
    }

    const getSelectedAdvancedOptions = useCallback(() => {
        let options = {};

        if (advancedSearch.countries?.length > 0) {
            options.country = advancedSearch.countries;
        }

        if (advancedSearch.related?.length > 0) {
            options.related = advancedSearch.related;
        }

        if (advancedSearch.date?.length > 0) {
            options.date = advancedSearch.date;
        }
        if (advancedSearch.language.type !== searchLanguageTypes.AUTO) {
            options.lng = advancedSearch.language.languageCode;
        }

        options.relatedRadius = advancedSearch.relatedRadius;
        const selectedFuzziness = Utils.getFuzzinessDistance(advancedSearch.fuzziness);
        options.match = selectedFuzziness.match;
        if (selectedFuzziness.match !== fuzzinessValues.EXACT.match) {
            options.ld = selectedFuzziness.value;
        }
        return options;
    }, [advancedSearch])

    const validSearchValue = (item) => {
        const containsNumber = item.match(/\d+/g);
        const itemTrim = item.replace(/\s/g, '');
        let isArdisId = ARDIS_ID_REX.test(item);
        let notAlphaNum = Utils.isNotNullOrUndefined(itemTrim.match(SYMBOL_REG_EXP)) ? itemTrim.match(SYMBOL_REG_EXP) : null;
        let russianLetters = Utils.isNotNullOrUndefined(itemTrim.match(RUSSIAN_REG_EXP)) ? itemTrim.match(RUSSIAN_REG_EXP).length : null;
        let englishLetters = Utils.isNotNullOrUndefined(itemTrim.match(ENGLISH_REG_EXP)) ? itemTrim.match(ENGLISH_REG_EXP).length : null;
        let volAlphaNum = Utils.isNotNullOrUndefined(notAlphaNum) ? itemTrim.length - notAlphaNum.length : 3;

        return {
            containsNumber,
            isArdisId,
            notAlphaNum,
            russianLetters,
            englishLetters,
            volAlphaNum
        }
    }

    const validateSearchPipeKeywords = (value) => {
        const pipeItems = value.split('|')
        const errors = [];
        setShowPipeErrorText("");
        setShowMaxPipesCount(false);
        pipeItems.forEach(item => {
            if (item.length > 2) {
                const validItem = validSearchValue(item);
                if (validItem.volAlphaNum < 2) {
                    errors.push(searchQueryContainsAlphaNumericItemAlert)
                }
            } else {
                errors.push(searchQueryMinLengthAlert)
            }
        })
        const uniqueErrors = [...new Set(errors)];
        if (uniqueErrors.length > 0) {
            setShowMaxPipesCount(true);
            setShowPipeErrorText(uniqueErrors.join(''));
        } else {
            const options = getSelectedAdvancedOptions();

            continueSearch(options.match);
        }
    }

    const validateSingleSearch = (val) => {
        const options = getSelectedAdvancedOptions();
        const valValidation = validSearchValue(val)
        if (valValidation.volAlphaNum > 2) {
            setAlert(false);
            const isNumeric = !isNaN(parseFloat(val)) && isFinite(val);
            if (!isNumeric && valValidation.containsNumber !== null && options.match === 'fuzzy' && !val.startsWith('entity_') && !valValidation.isArdisId) {
                return showConfirmDialog({
                    title: searchTypeChangeDialog.title,
                    message: searchTypeChangeDialog.message,
                    continueButtonLabel: searchTypeChangeDialog.continueFuzzy,
                    continueSecondButtonLabel: searchTypeChangeDialog.continueExact,
                    onContinue: () => continueSearch(),
                    onSecondContinue: () => changeMatchTypeAndSearch(),
                });
            } else if (valValidation.russianLetters > 0 && valValidation.englishLetters > 0) {
                setAlert(true);
                setAlertText(searchQueryMixedCharsAlert)
                continueSearch(options.match);
            } else {
                continueSearch(options.match);
            }
        } else {
            setShowQueryLengthAlert(true);
        }
    }

    const changeMatchTypeAndSearch = async () => {
        setAdvancedSearch({
            ...advancedSearch,
            fuzziness: fuzzinessValues.EXACT.value
        })

        setMatchType(fuzzinessValues.EXACT.match);
        continueExactSearch();
    }

    const validateSearchQuery = async () => {
        let inputValue = "";

        if (inputValue.indexOf("&&") !== -1) {
            inputValue.split("&&").forEach((item) => {
                if (item.indexOf(":") === -1) {
                    inputValue = inputValue + item;
                }

            })
        } else {
            inputValue = searchQuery;
        }

        let val = inputValue;
        if (val.length > 2) {
            const count = Utils.isNotNullOrUndefined(val.match(/(\|)/g)) ? val.match(/(\|)/g).length : 0;
            const isLastPipe = val.trimEnd().length - 1 === val.trimEnd().lastIndexOf('|');
            if (isLastPipe && (count <= maxSearchPipes || count - 1 === maxSearchPipes)) {
                await setSearchQuery(searchQuery.trimEnd().slice(0, -1));

                const afterRemove = Utils.isNotNullOrUndefined(searchQuery.match(/(\|)/g)) ? searchQuery.match(/(\|)/g).length : 0
                if (afterRemove > 0) {
                    validateSearchPipeKeywords(searchQuery)
                } else {
                    validateSingleSearch(searchQuery)
                }
            } else if (count > maxSearchPipes && appEnv.toLowerCase() === pipeEnv) {
                setShowMaxPipesCount(true);
                setShowPipeErrorText(searchQueryExceedMaxNames)

            } else {
                if (count > 0) {
                    if (isLastPipe) {
                        await setSearchQuery(searchQuery.trimEnd().slice(0, -1));
                        validateSearchPipeKeywords(searchQuery);
                    } else {
                        validateSearchPipeKeywords(val);
                    }
                } else {
                    validateSingleSearch(val);
                }
            }
        } else {
            setShowQueryLengthAlert(true);
        }
    }

    const doSearch = useCallback((type = null, match = null) => {
        const options = getSelectedAdvancedOptions();
        if (match) {
            options.match = match;
        }

        const query = Utils.createSearchQuery(searchQuery, type || searchType, options);
        query.fromAction = true;
        onSearch(query);
    }, [getSelectedAdvancedOptions, searchQuery, searchType, onSearch])

    const resetResultFilter = () => {
        onResultFilter(null, true);
    }

    const resetSearchStates = () => {
        setIsCancelSearch(false);
        setMessages([]);
        setIsTypeChanged(false);
        setShowResultFilterOption(false);
        setShowAdvancedOption(false);
        setShowQueryLengthAlert(false);
        setAlert(false);
        setAlertText(null);
        initialType.current = null;
    }
    const handleSearch = async () => {
        autoChange.current = false;
        clearStorage();
        dispatch(emptyNewsState());
        resetResultFilter();
        resetSearchStates();
        await validateSearchQuery();
    }

    const handleSearchTypeChange = (e) => {
        setSearchType(e.target.value);
        initialType.current = null;
        autoChange.current = true;
    }

    const onAdvancedOptionsChange = (data) => {
        const advancedData = {
            countries: data.countries,
            related: data.related,
            date: data.dateOfBirth,
            relatedRadius: data.relatedRadius,
            fuzziness: data.charactersCount,
            language: {
                type: data?.language || searchLanguageTypes.AUTO,
                languageCode: data?.languageCode,
                label: data.languageLabel
            },
        }
        setAdvancedSearch(advancedData);
    }

    const removeAdvancedSearchData = () => {
        setAdvancedSearch({
            countries: [],
            related: [],
            date: [],
            relatedRadius: defaultRelatedRadius,
            fuzziness: fuzzinessValues.FUZZY_2.value,
            language: {
                type: searchLanguageTypes.AUTO,
                languageCode: null,
                label: null
            }
        })
    }

    const handleFetchFail = (path) => {
        onRoutChange(path);
    }

    const handleAdvancedOptionsToggle = () => {
        setShowAdvancedOption(false);
        setShowResultFilterOption(!showResultFilterOption);
    }

    const handleReportInNewTab = () => {
        const newWin = window.open(PolixisRoutes.App.EmptyResult);
        if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
            showAlertDialog(popUpWarnDialog);
        }
    }

    const generateEmptyReport = () => {
        const noResultInBothTypes = autoChange.current !== true && searchResults.resultCount === 0;
        const searchData = {
            type: noResultInBothTypes ? nodeTypes.PERSON + " and " + nodeTypes.ORGANIZATION : searchType,
            date: Utils.getReportFormattedDay(),
            q: searchQuery,
            messages: messages,
            options: getSelectedAdvancedOptions()
        }
        dispatch(emptyResult(searchData, searchResults.resultCount));
        handleReportInNewTab();
    }

    const goToNewsSearch = () => {
        sessionStorage.setItem("isOnlyNews", 'true');
        sessionStorage.setItem("queryQ", searchQuery);
        dispatch(generateReport(null, true));
        onRoutChange(PolixisRoutes.App.Report)
    }

    const cancelSearching = () => {
        const requestData = {
            action: postActions.STOP,
            time: new Date().getTime(),
            bnli: localStorage.getItem('bnli'),
            projectId: sessionStorage.getItem('prId')
        };
        dispatch(stopSearch(requestData));

        setIsCancelSearch(true);
    }

    const handleGenerateReport = () => {
        onGenerateReport(PolixisRoutes.App.Report)
    }

    const changeTypeAndSearch = useCallback(() => {
        let changeType = searchType === nodeTypes.PERSON ? nodeTypes.ORGANIZATION : nodeTypes.PERSON;
        initialType.current = searchType;
        setSearchType(changeType);
        setIsTypeChanged(true);
        doSearch(changeType);
    }, [doSearch, searchType])

    const mergeSubNodes = () => {
        const selectedNodes = Utils.getSelectedNodes(searchResults.result);
        dispatch(showMergeNodesDialog(Utils.cloneNodes(selectedNodes)));
    }

    const clearStorage = () => {
        sessionStorage.removeItem('Graph');
        sessionStorage.removeItem('PNG');
        sessionStorage.removeItem('HITSPIN');
        sessionStorage.removeItem('HITSCHECK');
        sessionStorage.removeItem('includedJudicial');
        sessionStorage.removeItem('pinnedJudicial');
        sessionStorage.removeItem('judicialAppendix');
        sessionStorage.removeItem('ckdata');
        sessionStorage.removeItem('PinnedItemsNews');
        sessionStorage.removeItem('messages');
        sessionStorage.removeItem('searchValue');
    }

    const checkAndGetSelectedNodes = (nodes) => {
        let selectedIds = [];
        let subNodesIds = [];

        if (nodes.length === 0) {
            selectedIds = null;
            subNodesIds = null;
            showAlertDialog(nonSelectedNodesDialog);
        } else {
            nodes.forEach(selectedNode => {
                selectedIds.push(selectedNode.ardisId);
                selectedNode.metadata.forEach(subNode => {
                    if (subNode.checked) {
                        subNodesIds.push(subNode.id);
                    }
                });
            });
        }

        return {
            subNodesIds,
            selectedIds
        }
    }
    const generateReportNews = () => {
        clearStorage();
        const selectedNodes = Utils.getSelectedNodes(searchResults.result);
        const data = checkAndGetSelectedNodes(selectedNodes);
        if (data.selectedIds && data.subNodesIds) {
            sessionStorage.setItem('newsFromReport', "true");
            sessionStorage.setItem('selectedIds', data.selectedIds.join(', '));
            dispatch(generateReport(data.subNodesIds, false, true, true));
            onRoutChange(PolixisRoutes.App.Report);
        }
    }

    const handleCardAddition = async () => {
        const selectedNodes = Utils.getSelectedNodes(searchResults.result);
        const nodeToCard = selectedNodes.map((i) => i.nameEn);
        dispatch(addToCard(selectedNodes));
        await setCartSnack({showSnack: true, snackNodes: nodeToCard})
        setTimeout(() => setCartSnack({showSnack: false, snackNodes: []}), 2000)
    }

    const getIdsFromNodes = (nodes) => {
        const ids = [];
        nodes.forEach((n) => ids.push(n.ardisId));
        return ids;
    }
    const doExplore = () => {
        clearStorage();
        resetResultFilter();
        const selectedNodes = Utils.getSelectedNodes(searchResults.result);
        validateSelectedNodes(selectedNodes);
    }

    const validateSelectedNodes = (selectedNodes) => {
        if (selectedNodes.length === 0) {
            showAlertDialog(nonSelectedNodesDialog);
        }

        const getMainNodes = Utils.getMainNodes(searchResults.result);
        const mainNodes = getMainNodes.mainNodes;
        const showDots = getMainNodes.showDots;

        const nodes = {
            selectedNodes: Utils.cloneNodes(selectedNodes),
            mainNodes: Utils.cloneNodes(mainNodes),
            showDots
        }

        if (selectedNodes.length > nodesExploreMaxCount) {
            showAlertDialog(maxSelectedNodesExceedDialog);
        } else {
            const selectedIds = getIdsFromNodes(selectedNodes)
            sessionStorage.setItem("selectedIds", selectedIds.join(', '));
            if (selectedNodes.length > 20 && selectedNodes.length < nodesExploreMaxCount) {
                showConfirmDialog({
                    title: maxSelectedNodesDialog.title,
                    message: maxSelectedNodesDialog.message,
                    onContinue: () => dispatch(showExploreDialog(nodes))
                });
            } else {
                dispatch(showExploreDialog(nodes));
            }
        }
    }

    const continueExactSearch = () => {
        if (searchQuery.trim() !== "") {
            doSearch(null, fuzzinessValues.EXACT.match)
        }
    }

    const continueSearch = (match) => {
        if (searchQuery.trim() !== "") {
            doSearch(null, match || fuzzinessValues.FUZZY_2.match);
        }
    }
    useEffect(() => {
        if (results.status === ProgressStatus.DONE) {
            if (results.resultCount === 0) {
                if (initialType.current !== null) {
                    setSearchResults({
                        resultCount: results.resultCount,
                        status: results.status,
                        result: results.results
                    });

                    setMessages((prevMessages) => {
                        if (prevMessages) {
                            return results.messages ? [...prevMessages, ...results.messages] : prevMessages;
                        } else {
                            return results.messages || [];
                        }
                    });

                } else {
                    !autoChange.current && changeTypeAndSearch();
                }
            } else {
                setSearchResults({
                        resultCount: results.resultCount,
                        status: results.status,
                        result: results.results
                });

                setMessages((prevMessages) => {
                    let localMessages ;
                    if (prevMessages) {
                        localMessages = results.messages ? [...prevMessages, ...results.messages] : prevMessages;
                    } else {
                        localMessages = results.messages || [];
                    }
                    //check
                    return Array.from(new Set(localMessages));
                });
            }
        } else {
            setSearchResults({
                resultCount: results.resultCount,
                status: results.status,
                result: results.results
            });
            setMessages((prevMessages) => {
                const isBackAction = results.status === ProgressStatus.NONE && results.messages?.length === 0;
                if (prevMessages) {
                    return isBackAction ? [] : results.messages ? [...prevMessages, ...results.messages] : prevMessages;
                } else {
                    return results.messages || [];
                }
            });
        }

    }, [results, changeTypeAndSearch, query])

    useEffect(() => {
        return () => {
            dispatch(clearStore())
        };
    }, [dispatch])

    const selectedFuzzinessDistance = Utils.getFuzzinessDistance(advancedSearch?.fuzziness);
    const existAdvancedFilter = advancedSearch?.countries.length > 0 || advancedSearch?.related.length > 0 || advancedSearch?.date.length > 0;
    const showNotRelevant = searchResults.resultCount > 0 && (searchResults.status === ProgressStatus.DONE || isCancelSearch);
    const noResult = searchResults.status === ProgressStatus.DONE && searchResults.resultCount === 0;
    const showLoader = query && !(searchResults.status === ProgressStatus.DONE || isCancelSearch || searchResults.status === ProgressStatus.NONE) && showExplore && Object.keys(dialog).length === 0;
    return (
        <div>
            <div className={c.searchBar}>
                <div className={c.searchBarWrapper}>
                    <div className={c.searchInputWrapper}>
                        <div className={c.searchInputInner}>
                            {
                                searchType === nodeTypes.PERSON ?
                                    <div className={`${c.searchTypeIcon} ${c.personSearchType}`}>
                                        <Person/>
                                    </div>
                                    :
                                    <div className={`${c.searchTypeIcon} ${c.businessSearchType}`}>
                                        <Business/>
                                    </div>
                            }
                            <input
                                value={searchQuery}
                                className={`${c.inputControl} ${c.formControl} ${c.searchInput}`}
                                placeholder={Utils.inputPlaceholder(searchType)}
                                onChange={handleQueryChange}
                                onKeyDown={doSearchKeyPress}
                                autoFocus
                                title={searchQuery}
                            />
                            <div
                                title={selectedFuzzinessDistance.title}
                                className={c.advancedFuzzinessTitle}
                            >
                                <div className={c.fuzzinessWrapper}>
                                    <div className={`${c.mainColor} ${c.fuzzinessInner}`}
                                         onClick={toggleClickIcons}
                                    >
                                        {selectedFuzzinessDistance.label}
                                    </div>
                                </div>
                                <div className={c.advancedRelatedRadius}
                                     title={`Related radius: ${advancedSearch.relatedRadius}`}>
                                    <img alt='relatedRadius'
                                         src={`/images/radiusSlider/relatedRadius${advancedSearch.relatedRadius}r.png`}
                                         className="icons pointer"
                                         onClick={toggleClickIcons}/>
                                </div>

                                {
                                    advancedSearch?.countries.length > 0 &&
                                    <div title={`Country: ${advancedSearch.countries.join(", ")}`}
                                         className={c.searchInputItem}>
                                        <LocationOn className={`${c.iconSearchInput} ${c.mainColor}`}
                                                    onClick={toggleClickIcons}/>
                                    </div>
                                }

                                {
                                    advancedSearch?.related.length > 0 &&
                                    <div title={`Related to: ${advancedSearch.related.join(", ")}`}
                                         className={c.searchInputItem}>
                                        <Timeline onClick={toggleClickIcons}
                                                  className={`${c.iconSearchInput} ${c.mainColor}`}/>
                                    </div>
                                }

                                {
                                    advancedSearch?.date.length > 0 &&
                                    <div title={`Related dates: ${advancedSearch.date.join(", ")}`}
                                         className={c.searchInputItem}>
                                        <DateRange onClick={toggleClickIcons}
                                                   className={`${c.iconSearchInput} ${c.mainColor}`}/>
                                    </div>
                                }
                                {
                                    advancedSearch.language.type === searchLanguageTypes.MANUAL &&
                                    <div className={c.searchInputItem}
                                         title={`Language: ${advancedSearch.language?.label}`}>
                                        <Translate className={`${c.iconSearchInput} ${c.mainColor}`}
                                                   onClick={toggleClickIcons}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={c.searchButtonWrapper}>
                        <div className={c.searchButton}>
                            <Button variant="contained" onClick={handleSearch}>Search</Button>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-6 col-sm-12">
                        <RadioGroup
                            row
                            name="type"
                            value={searchType}
                            defaultValue={searchType}
                            onChange={handleSearchTypeChange}>
                            <FormControlLabel
                                value="person"
                                disabled={showLoader}
                                label="Persons"
                                className={`${c.nodeRadio} ${c.personRadio} ${c.radioOption}`}
                                control={
                                    <Radio disableRipple
                                           className={searchType === nodeTypes.PERSON ? `${c.radioType} ${c.mainColor}` : `${c.radioType} ${c.secondaryColor}`}
                                    />
                                }
                            />
                            <FormControlLabel
                                value="organization"
                                disabled={showLoader}
                                label="Entities"
                                className={`${c.nodeRadio} ${c.radioOption}`}
                                control={
                                    <Radio disableRipple
                                           className={searchType === nodeTypes.ORGANIZATION ? `${c.radioType} ${c.mainColor}` : `${c.radioType} ${c.secondaryColor}`}
                                    />
                                }
                            />
                        </RadioGroup>
                    </div>
                    <div
                        className={`col-md-6 col-sm-12 ${c.advancedOptionsWrapper}`}>
                        {
                            searchResults.resultCount > 0 &&
                            <div className={c.pointer}>
                                <div onClick={handleAdvancedOptionsToggle}
                                     className={c.searchAdvanced}>
                                    Result filter
                                    {
                                        showResultFilterOption ? <KeyboardArrowUp/> : <KeyboardArrowDown/>
                                    }
                                </div>
                            </div>
                        }
                        <div className={`${c.pointer} ${c.advancedSearchLabel}`}>
                            <div onClick={toggleClickIcons} className={c.searchAdvanced}>
                                Advanced search options
                                {
                                    showAdvancedOption ? <KeyboardArrowUp/> : <KeyboardArrowDown/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={` row`}>
                        {
                            showResultFilterOption &&
                            <div className={`${c.marginMiddle} col-xs-12`}>

                                <SearchFilter
                                    results={results.results}
                                    resultFilter={filter}
                                    onFilter={onResultFilter}
                                />

                            </div>
                        }
                        <div className='col-xs-12'>
                            <AdvancedSearchOptions show={showAdvancedOption}
                                                   searchAttr={onAdvancedOptionsChange}
                                                   type={searchType}
                                                   doSearch={handleSearch}
                                                   match={matchType}
                                                   removeAdvancedSearchData={removeAdvancedSearchData}
                                                   onFetchFail={handleFetchFail}
                            />
                        </div>
                        {
                            !showAdvancedOption && existAdvancedFilter &&
                            <div className={c.textCenter}>
                                <div className={`${c.filtersChangeInfo} col-xs-12`}>
                                    Advanced search options were changed.
                                </div>
                            </div>
                        }
                        {
                            !showResultFilterOption && isChangedFilter &&
                            <div className={c.textCenter}>
                                <div className={`${c.filtersChangeInfo} col-xs-12`}>
                                    Search filter was changed.
                                </div>
                            </div>
                        }
                    </div>
                    {
                        alert && <div className={c.textCenter}>
                            <div className={c.filtersChangeInfo}>
                                {alertText}
                            </div>
                        </div>
                    }

                    {
                        isTypeChanged && !isRunSearch &&
                        <div className={c.textCenter}>
                            <div className={c.messagePadding}>
                                {
                                    noResult ?
                                        noResultAlert :
                                        showLoader || searchResults.resultCount === 0 ?
                                            `Your search in category ${initialType.current === nodeTypes.PERSON ? nodeTypes.PERSON.toUpperCase() : nodeTypes.ORGANIZATION.toUpperCase()} produced no results. We are now searching in category ${searchType === nodeTypes.PERSON ? nodeTypes.PERSON.toUpperCase() : nodeTypes.ORGANIZATION.toUpperCase()}`
                                            : `Your search in category ${initialType.current === nodeTypes.PERSON ? nodeTypes.PERSON.toUpperCase() : nodeTypes.ORGANIZATION.toUpperCase()} produced no results. ${searchResults.resultCount} results have been found in category ${searchType === nodeTypes.PERSON ? nodeTypes.PERSON.toUpperCase() : nodeTypes.ORGANIZATION.toUpperCase()}`
                                }
                            </div>
                        </div>
                    }
                    {
                        showQueryLengthAlert && <div className={c.textCenter}>
                            <div className={c.filtersChangeInfo}>{searchQueryMinLengthAlert}</div>
                        </div>
                    }

                    {
                        showMaxPipesCount && <div className={c.textCenter}>
                            <div className={c.filtersChangeInfo}>
                                {showPipeErrorText}
                            </div>
                        </div>
                    }
                    {
                        messages?.length > 0 &&
                        <div className={`${c.textCenter} ${c.filtersChangeInfo}`}
                             dangerouslySetInnerHTML={{__html: messages.join("</br>")}}/>
                    }

                    {
                        noResult && <div className={c.textCenter}>
                            <Button
                                onClick={generateEmptyReport}
                                className={`${c.exploreButton} ${c.noResultButton} ${c.pointer}`}
                            >Generate No Result Report
                            </Button>
                            <MediaScreening handleClick={goToNewsSearch} isDisable={true}/>

                        </div>
                    }

                    <div
                        className={showNotRelevant ? `${c.noRelevantWrapper} col-xs-12` : `${c.relevantWrapper} col-xs-12 `}>
                        {
                            showLoader &&
                                <Loader
                                    advancedOpen={showAdvancedOption}
                                    stopSearch={cancelSearching}
                                />
                        }
                    </div>

                    {
                        showNotRelevant &&
                        <div className={c.noRelevantInner}>
                            <Button onClick={generateEmptyReport} className={c.notRelevantButton}>Not
                                Relevant?</Button>
                        </div>
                    }
                </div>

                {
                    explore &&
                    <ExploreDialog handleGenerate={handleGenerateReport} selectedNodes={explore.selectedNodes}
                                   status={explore.status}
                                   mainNodes={explore.mainNodes} showDots={explore.showDots}
                    />
                }

                {
                    mergeNodes && <MergeNodesDialog
                        selectedNodes={mergeNodes?.selectedNodes}
                        doSearch={handleSearch}
                        query={query}
                    />
                }

                {
                    isRunSearch !== undefined && Utils.getSelectedNodes(searchResults.result).length > 0 &&
                    <div className={c.selectedNodesAction}>
                        {
                            isPlxUser && <button
                                onClick={mergeSubNodes}>
                                <span>Map nodes</span>
                            </button>
                        }
                        <button
                            onClick={generateReportNews}>
                            <span>News Search</span>
                        </button>
                        {
                            !isOnlyNewsSearch && <button
                                onClick={handleCardAddition}>
                                <span>Add to Cart</span>
                            </button>
                        }
                        {
                            !isOnlyNewsSearch && <button
                                onClick={doExplore}>
                                <span>Explore</span>
                            </button>
                        }
                    </div>
                }
            </div>

            {
                cartSnack.showSnack &&
                <div className={`${c.boxShadow} ${c.snackWrapper}`}>
                    {
                        cartSnack.snackNodes.map((n, index) => <div key={`${index}_snack_${n}`}>{n}</div>)
                    }
                </div>
            }
        </div>
    )
}

export default SearchBar;

import React from "react";
import PropTypes from "prop-types";

import Utils from "../../../utils";
import { getNextChartColor } from "../../../constants";

let Donut1InstanceIndex = 0;

export default class Donut1 extends React.Component {
    constructor( props ) {
        super( props );

        this.id = Donut1InstanceIndex++;
        this.color = "#6da6ce";
        const dataSlices = props.data.slices.slice( 0 );
        const data = dataSlices.sort( ( a, b ) => (b.value - a.value) );

        const dataSlicesH = [];
        const dataSlices0 = [];

        if ( props.data ) {
            props.data.slices.forEach( data => {

                if ( Utils.isNotNullOrUndefined( data.isHighlighted ) ) {
                    dataSlicesH.push( data );
                } else {
                    dataSlices0.push( data )
                }

            } )
        }

        const a = dataSlicesH.sort( ( a, b ) => (b.value - a.value) );
        const b = dataSlices0.sort( ( a, b ) => (b.value - a.value) );

        const dataLegend = a.concat( b );
        this.state = {
            donatData: null,
            data,
            dataLegend,
        }
    }

    render() {
        let legend = [];
        const bottom = this.props.data.slices.length > 10;

        this.state.dataLegend.forEach( data => {
            legend.push(
                <div key={ this.id + "_" + data.name }
                     className={ bottom ? "col-xs-4" : "col-xs-12" }
                     style={ {
                         color: data.isHighlighted ? '#ef4254' : '#6e7072',
                         marginLeft: 5,
                         marginRight: -5
                     } }
                >
                    <span style={ { color: data.isHighlighted ? '#ef4254' : '#6e7072' } }> &#x25CF; &nbsp;&nbsp;</span>
                    { data.name } ({ data.value })
                </div>
            )
        } );

        return (
            <div className="row">
                <div className={ bottom ? "pie col-xs-12 pie-full" : "pie col-xs-9" } id={ "pie-component-" + this.id }
                     style={ this.props.print === "" ? { height: '780px' } : {
                         height: '550px',
                         marginLeft: '-25%',
                         width: '130%'
                     } }/>
                <div className={ bottom ? "col-lg-12 pie-legend legend-inline" : "col-lg-3 pie-legend" }>
                    { legend }
                </div>
            </div>
        );
    }

    componentDidMount() {
        if ( this.props.data ) {
            const dataArray = [];
            const secondaryArray = [];

            let filnalArr = [];
            let first20 = [];
            let other = [];
            this.state.data.forEach( data => {

                if ( Utils.isNotNullOrUndefined( data.isHighlighted ) ) {
                    this.color = '#f00';
                    dataArray.push( {
                        'value': data.value,
                        'title': data.name,
                        'color': getNextChartColor(),
                        'color1': '#ef4254'
                    } )
                } else {
                    secondaryArray.push( {
                        'value': data.value,
                        'title': data.name,
                        'color': getNextChartColor(),
                        'color1': '#95989A'
                    } )
                }

                if ( secondaryArray.length > 25 ) {
                    first20 = secondaryArray.slice( 0, 20 );

                    other = secondaryArray.slice( 20 );
                    const hiddenCount = other.length;
                    filnalArr = dataArray.concat( first20 );
                    filnalArr.push( {
                        'value': 5,
                        'title': "Other ( " + hiddenCount + " ) ",
                        'color': getNextChartColor(),
                        'color1': '#95989A'
                    } )
                } else {
                    filnalArr = dataArray.concat( secondaryArray );
                }

            } );

            this.setState( {
                donatData: filnalArr
            } )

            var ctx = document.getElementById( "pie-component-" + this.id );
            window.AmCharts.makeChart( ctx, {
                "type": "pie",
                "dataProvider": filnalArr,
                "titleField": "title",
                "valueField": "value",
                "labelRadius": 30,
                "colorField": "color",
                "radius": "28%",
                'startDuration': '0',
                'fontFamily': 'Roboto',
                "innerRadius": "50%",
                "labelText": "[[title]]",
                "labelColorField": "color1",
                "borderAlpha": 0,
                "addClassNames": true,

                "export": {
                    "enabled": false
                }
            } );
        }
    }
}

Donut1.defaultProps = {
    isMaximize: false,
};
Donut1.propTypes = {
    data: PropTypes.object,
    isMaximize: PropTypes.bool,
    print: PropTypes.string
};

import {NewsAction} from "./newsAction";
import {INITIAL_STATE, ProgressState} from "../../../constants";

const newsSearch = (state = INITIAL_STATE.newsSearch, action) => {
    switch (action.type) {
        case NewsAction.SearchNews : {
            const prevArticles = state.articles;
            const parsedData = JSON.parse(action.payload.data);
            parsedData.pinnedWithoutContent = false;
            parsedData.pinnedWithContent = false;
            let loadState = action.payload.state;
            return Object.assign({}, state, {
                articles: {...prevArticles, [parsedData.id]: parsedData},
                state: loadState,
            });
        }

        case NewsAction.SetNews : {
            return Object.assign({}, state, {
                articles: {...action.payload.selected, ...action.payload.pinned}
            })
        }

        case NewsAction.SearchNewsStop : {
            return Object.assign({}, state, {
                state: action.payload.state
            });
        }

        case NewsAction.EmptyNewsSearch : {
            return Object.assign({}, state, {
                state: ProgressState.None,
                articles: {},
            })
        }

        default:
            return state;
    }

}

export default newsSearch;

import React from "react";
import PropTypes from "prop-types";

import {Box, Drawer} from "@mui/material";

export default class SideBar extends React.Component {

    constructor( props ) {
        super( props );

        this.state = { show: !this.props.isMobile };
    }

    render() {
        const items = this.props.items.map( item => {
            if ( item.isSection ) {
                return <div onClick={ () => this.props.onSelect( item.id ) }
                            key={ item.id }
                            id={`${item.id}_section`}
                            className={item.id === 's1' ? "sidebar-item section active " : "sidebar-item section"}>{ item.title }</div>;
            } else {
                const isActive = sessionStorage.getItem('isOnlyNews') && item.id.toLowerCase() === 'newssearch';

                return <div onClick={() => this.props.onSelect(item.id)}
                            id={`${item.id}_section`}
                            key={ item.id }
                            className={isActive ? "sidebar-item active" : "sidebar-item" }>
                    <img alt='onSelect' src={ "/images/components/" + item.icon + ".png" }/>
                    { item.title }
                </div>;
            }
        } );
        return (
            <Drawer open={ this.state.show } className="sidebar" variant="persistent" anchor="left">
                <Box className="sidebar-content">
                    { items }
                </Box>
            </Drawer>
        );
    }

    toggleSideBar() {
        const show = !this.state.show;
        this.setState( { show } );
    }
}

SideBar.propTypes = {
    items: PropTypes.array.isRequired,
    isMobile: PropTypes.bool,
    selected: PropTypes.string,
    onSelect: PropTypes.func
};

import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {updateSearchBar} from "../actions";
import Subnode from "./Subnode.jsx";
import Utils from "../../utils";
import NodeInner from "./NodeInner";
const mapDispatchToProps = (dispatch) => {
    return {
        updateSearchBar: () => dispatch(updateSearchBar())
    };
};

class Node extends React.Component {
    constructor(props) {
        super(props);
        this.isUserType = localStorage.getItem('type') === '3';
        this.pie = false;
        this.state = {
            node: this.props.node,
            status: props.status,
            statusCancel: props.statusCancel,
            showSubnodes: (localStorage.getItem('type') === '1' || localStorage.getItem('type') === '4')
        };
    }

    render() {
        const {type} = this.props;
        const isObg = this.state.node.isObg;
        const checked = Utils.isNullOrUndefined(this.state.node.checked) ? false : this.state.node.checked;
        const checkboxImage = checked ? "/images/center_checkbox_checked.png" : "/images/center_checkbox.png";
        const bgColorLight = checked ? "#ef4254" : "#EBEBEB";
        const bgColor = checked ? "#ef4254" : "#B0B2B4";
        const titleColor = checked ? "#ef4254" : "#7d7d7d";
        const index = this.props.index < 10 ? '0' + this.props.index : this.props.index;
        const subnodeImg = checked ? <img alt='bestGuess' src="/images/best_guess.png"/> : <img alt='uncheckBestGuess' src="/images/uncheck_best_guess.png"/>;

        const subnodes = !this.isUserType && this.state.node.metadata.map((subnode) => {
                return <Subnode key={subnode.id}
                                doSearch={this.props.doSearch}
                                mainNodeMetadata={this.state.node.metadata}
                                status={this.state.status}
                                statusCancel={this.state.statusCancel}
                                subnode={subnode}
                                subnodeLength={this.props.node.metadata.length}
                                onChange={() => this.onSubnodeChange()}
                                mouseEnter={(subnode) => this.props.mouseEnter(subnode)}
                />;
            });

        return (
            <div className={"node " + type}>
                <div className="content">
                    <div className="row">
                        <div className="title">
                            <div
                                className={index < 100 ? "col-xs-2" : "col-xs-3" && index > 999 ? "col-xs-4" : "col-xs-3"}>
                                <span className="node-index" style={{color: bgColorLight}}>{index}</span>
                            </div>
                            <div
                                className={index < 100 ? "col-xs-10" : "col-xs-9" && index > 999 ? "col-xs-8" : "col-xs-9"}>
                                <div className="node-name pointer" onClick={() => this.toggleCheckbox()}
                                     style={{color: titleColor}}>{this.state.node.nameEn}</div>

                                {this.state.node.nameEn.toLocaleLowerCase() !== this.state.node.nameOriginal.toLocaleLowerCase() &&
                                <h6 className="text-lighter"
                                    style={{color: titleColor}}>{this.state.node.nameOriginal}</h6>}

                                {this.state.node.ardisId &&
                                <h6 className="text-lighter text-right"
                                    style={{color: titleColor, marginRight: "19px"}}><span
                                    data-testid={this.state.node.ardisId}>ARDIS
                                    ID:</span> {this.state.node.ardisId}</h6>}
                            </div>
                        </div>
                    </div>
                    {isObg && <div className="best-guess">{subnodeImg} Our Best Guess </div>}

                    <div className={isObg ? "" : "title-border-top"}>
                        <div className="subnodes">
                            <table style={{verticalAlign: "top"}}>
                                <tbody>
                                <tr>
                                    <NodeInner node={this.state.node} />
                                </tr>
                                {!this.isUserType && <>
                                    <tr />
                                    <tr>
                                        <td colSpan="2">
                                            <div className="items">
                                                <div onClick={() => {
                                                    this.setState({showSubnodes: !this.state.showSubnodes})
                                                }} style={{paddingLeft: "16px", marginTop: "-2px"}}>
                                                    <i className="material-icons"
                                                       style={{color: "#ef4254", cursor: "pointer"}}
                                                       title="Toggle Entities">{this.state.showSubnodes ? "keyboard_arrow_up" : "keyboard_arrow_down"}</i>
                                                </div>
                                                {this.state.showSubnodes &&
                                                <div style={{marginTop: "-7px"}}
                                                     data-testid='subnode-list'>{subnodes}</div>}
                                            </div>
                                        </td>
                                    </tr>
                                </>}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div className="timeline">
                    <img alt='Arrow' src={"/images/" + this.props.type + "_arrow.png"} style={{backgroundColor: bgColor}}/>
                    <img alt='doneImage' src={checkboxImage}
                         className={(this.state.status === "DONE" || this.state.statusCancel === "DONE") ? "pointer sub-check" : "sub-check cursor-progress"}
                         onClick={() => this.toggleCheckbox()}/>
                </div>
            </div>
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({node: nextProps.node, status: nextProps.status, statusCancel: nextProps.statusCancel});
        if(!nextProps.node.checked){
            nextProps.node.metadata.forEach(subnode => {
                subnode.checked = nextProps.node.checked;
            });        }
    }

    toggleCheckbox() {
        if (this.state.status === "DONE" || this.state.statusCancel === "DONE") {
            const node = this.state.node;
            const checked = Utils.isNullOrUndefined(node.checked) ? false : node.checked;
            node.checked = !checked;

            node.metadata.forEach(subnode => {
                subnode.checked = node.checked;
            });
            this.setState({
                node
            }, () => {
                this.props.onChange();
                this.props.updateSearchBar();
            });
        }
    }

    onSubnodeChange() {
        const node = this.state.node;
        let isChecked = true;
        for (let i = 0; i < node.metadata.length; i++) {
            const subnode = node.metadata[i];
            const checked = Utils.isNullOrUndefined(subnode.checked) ? false : subnode.checked;
            if (!checked) {
                isChecked = true; //false
                break;
            }
        }
        node.checked = isChecked;
        this.setState({}, () => {
            this.props.onChange();
            this.props.updateSearchBar();
        });
    }
}


Node.propTypes = {
    node: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    updateSearchBar: PropTypes.func.isRequired,
    index: PropTypes.number,
    onChange: PropTypes.func,
    status: PropTypes.string,
    statusCancel: PropTypes.string,
    doSearch: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Node);

import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";

import { INITIAL_STATE } from "../../constants";

const mapStateToProps = ( state = INITIAL_STATE ) => {
    return {
        resultCount: state.application.search.resultCount,
        status: state.application.search.status,
    };
}

class Loader extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { show: true, open: props.advancedOpen || false };
    }

    render() {
        if ( this.props.status === "DONE" && this.props.resultCount === 0 ) {
            return null;
        } else {
            return (
                <div className="loader-container">
                    <div className="loader">
                        { this.renderCircularProgress() }
                    </div>
                </div>
            );
        }
    }

    renderCircularProgress() {
        if ( this.state.show ) {
            if ( this.props.status !== "DONE" ) {
                return <div onClick={ () => this.props.stopSearch() } className='stop-search'
                            style={ this.props.advancedOpen ? { marginTop: '-22px' } : { marginTop: -22 } }>
                    <span style={ {
                        position: 'absolute',
                        marginLeft: '13px',
                        marginTop: '22px',
                        color: '#7d7d7d'
                    } }>
                        STOP
                    </span>
                    <CircularProgress size={ 60 } style={ { color: "#ef4254" } }/>
                    <div style={ this.props.status === "RUNNING" ? {} : { marginLeft: "-10px" } }>
                        { this.props.status }
                    </div>
                </div>;
            }
        } else {
            return <div className="loader-temp"/>;
        }
    }

    componentDidUpdate( prevProps ) {
        if ( prevProps.status !== this.props.status ) {
            this.setState( { show: false }, () => {
                setTimeout( () => {
                    this.setState( { show: true } );
                }, 50 );
            } );
        }
    }
}

Loader.propTypes = {
    resultCount: PropTypes.number,
    status: PropTypes.string,
    stopSearch: PropTypes.func,
    advancedOpen: PropTypes.bool
};

export default connect( mapStateToProps )( Loader );

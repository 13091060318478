export const catchError = "Something went wrong";

export const pipeEnv = "prod"
export const userTypes = {
    superAdmin: '1',
    dataManager: '4',
}

export const axiosCodes = {
    TOKEN_EXPIRED: 122,
    REFRESH_EXPIRED: 125,
    TOKEN_INVALID: 123,
    EMPTY_ARTICLE: 111
};

export const clientType = "ARDIS";

export const ProgressState = {
    None: 0,
    Loading: 1,
    Success: 2,
    Failed: 3,
    Stopped: -1
};

export const ProgressStatus = {
    NONE: "NONE",
    DONE: 'DONE',
    FAIL: 'FAIL',
    RUNNING: 'RUNNING',
    STOPPED: "STOPPED",
    SEARCHING: "SEARCHING",
    ANALYSING: "ANALYSING",
    ERROR: "ERROR",
    STOP: "STOP"
}

export const searchLanguageTypes = {
    MANUAL: "man",
    AUTO: "auto",
    NONE: "-"
}
export const sseState = {
    DONE: 3,
    CLOSED: 2
};

export const NewsProgressState = {
    NONE: 0,
    MORE: 1,
    STOP: -1
}

export const postActions = {
    EXPLORE: "explore",
    GET: "get",
    SEARCH: "search",
    NEWS_GET: "news&get",
    DETAILS: "details",
    UNMAP: "unmap",
    STOP: "stop",
    GRAPH: "graph",
    MAP: "map",
    GRAPH_MORE: "graphmore"
}
export const BoundingBox = {
    width: 700,
    height: 700
};

export const GraphBreakpoints = {
    xSmall: 6,
    small: 50,
    medium: 130
};

export const GraphCategoriesFilterTitles = {
    SANCTION: 'Filter the graph by leaving persons or entities under direct sanctions.',
    INFO: 'Filter the graph by leaving entities that have specific alerts, such as Forbes-listed entities, entities that appear on certain warning lists, wanted lists, etc..',
    PEP: 'Filter the graph by leaving persons or entities under direct political exposure.',
    NONACTIVE: 'Filter the graph by leaving only closed entities.',
    OTHER: 'Filter the graph by leaving only '
}

export const maxSearchPipes = 2;
export const appEnv = process.env.REACT_APP_ENV;

export const searchResultsAddMore = 100;

export const hBarCategory = {
    width: 25,
    offset: 100
};

export const additionalSearchHeader = {
    key: "Action",
    value: "search"
};

export const newsSearchItems = {
    codes: {
        ENTER: 'Enter',
        NUM_ENTER: 'NumpadEnter',
        LEFT: 'ArrowLeft',
        RIGHT: 'ArrowRight'
    },
    expandTitle: "Search for ADDITIONAL AKAs."
}

export const nodeTypes = {
    PERSON: 'person',
    ORGANIZATION: 'organization',
    UNDETERMINED: 'undetermined'
}

export const INITIAL_STATE = {
    authentication: {
        isLoggedIn: false,
        noServer: false,
        accessToken: null,
        loginFailed: null,
        firstLogin: false,
        isSessionExpired: false,
        user: {
            bnli: null,
            type: null
        },
    },
    newsSearch: {
        state: ProgressState.None,
        articles: {},
        searchCancel: ProgressStatus.RUNNING
    },
    application: {
        search: {
            state: ProgressState.None,
            resultCount: 0,
            results: [],
        },
        generate: {},
        addComponentsScreenshotToCustom: 0,
        changeCanvasToImage: 0,
        newAppendix: 0,
        appendixTree: 0,
        judicialAppendixRemoveId: null,
        judicialAppendixData: null,
        errors: {
            loginfailed: null,
        },
        searchCancel: {
            status: ProgressStatus.RUNNING
        },
        cardNodes: {},
        pathSearch: {},
        pathSearchNodesRefresh: false,
        isSessionExpired: false,
        user: {
            bnli: null,
            type: null
        },
        fetchFail: false

    },
    dialog: {}
};

export const RowType = {
    Odd: "odd",
    Even: "even"
};

export const highlightAlerts = [
    'Related to another sanctioned entity through a third party',
    'Related to a sanctioned entity',
    'Equity exposure to a sanctioned entity',
    'Ownership stake in a sanctioned entity',
    'is sanctioned'
]

export const alertDialogState = {
    pdfGenerateFailState: {
        title: "Generate pdf",
        message: "Something went wrong.Please try again."
    },
    popUpBlockState: {
        title: "Attention!",
        message: "Popups blocked in your browser. Please allow popups in browser settings and try again.",
    }
}
export const getNextAvatar = gender => {
    let avatar;
    if (gender.toLowerCase() === "m") {
        avatar = "/images/nodeAvatars300/node_man_300.png";
    } else if (gender.toLowerCase() === "f") {
        avatar = "/images/nodeAvatars300/node_women_300.png";
    } else {
        avatar = "/images/nodeAvatars300/node_person_300.png";
    }

    return avatar;
};

export const getNextColor = (ColorIndex = 0) => {
    ColorIndex++;
    if (Colors.length === ColorIndex) {
        ColorIndex = 0;
    }
    return Colors[ColorIndex];
};

let ChartColorIndex = 0;
export const getNextChartColor = () => {
    ChartColorIndex++;
    if (ChartColors.length === ChartColorIndex) {
        ChartColorIndex = 0;
    }
    return ChartColors[ChartColorIndex];
};

const ChartColors = ['#869be0', '#50BBA5', '#73CCD2', '#dfed90', '#6da6ce', '#EB539E', '#929191', '#73CCD2', '#d5e69e'];

const Colors = [
    "#ef4254",
    "#8d1d89",
    "#4eb1b2",
    "#99ba98",
    "#3e7b97",
    "#85cdaa",
    "#85afcd",
    "#cca3b9",
    "#f5896b",
    "#ded376",
    "#5e73a1",
    "#7db574",
    "#78d2e6",
    "#f5896b",
    "#7db574",
    "#85cdaa",
    "#5e73a1",
    "#ded376",
    "#78d2e6",
];

let BracketsIndex = 1;
const BracketsCount = 3;
export const getNextBracket = () => {
    if (BracketsIndex === BracketsCount) {
        BracketsIndex = 1;
    } else {
        BracketsIndex++;
    }

    return BracketsIndex;
};

export const sanctionTypes = {
    active: {
        name: "ACTIVE",
        color: '#ff0000'
    },
    delisted: {
        name: "DELISTED",
        color: '#008000'
    }
}

export const nodeDateTypes = {
    dateOfBirth: "date of birth"
}

export const newsSearchType = {
    FUZZY: 'fuzzy',
    EXACT: 'exact',
    EXACT_FUZZY: 'exactAndFuzzy',
}

export const newsDisabledContent = "Please enable JS and disable any ad blocker";

export const newsAlerts = {
    emptyFields: "The fields are required.",
    emptySource: "Please select a source for news search.",
    noResult: "There is no result to show.",
    noResultExact: "Please, be advised that it is possible that your search produced no results due to exact name search parameter. We advise you to change this setting to\n" +
        "                        \"fuzzy\" in order to expand the search."
}

export const pieColors = {
    SANCTION : '#ef6473',
    PEP: "#1B89B7",
    INFO: "#8D6CAB",
    NON_ACTIVE: "#737577"

}

export const etcKey = "etc."

export const avatarSize = {
    BIG: "big",
    NORMAL: "normal",
    SMALL: "small",
}

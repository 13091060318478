import React from "react";
import {connect} from "react-redux";
import {
    Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid,
    TextField, InputAdornment, IconButton, Divider
} from "@mui/material";
import cyan from "@mui/material/colors/cyan";
import withStyles from "@mui/styles/withStyles";
import {INITIAL_STATE} from "../../../constants";
import {DialogTypes, closeDialog} from "../actions";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import PolixisRoutes from "../../../routes";
import Utils from "../../../utils";
const mapStateToProps = (state = INITIAL_STATE) => {
    return {
        isAuthenticated: state.authentication?.isLoggedIn,
        changePassError: state.authentication?.changeError,
        dialog: state.dialog,
    };
};

const dialogStyles = {
    input: {
        "& input": {
            fontSize: "16px"
        },
        "&>div::before": {
            borderBottom: "1px solid #ef4254!important"
        },
        "&>div::after": {
            borderColor: cyan[500]
        }
    }
};

class PolixisDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            value: null,
            description: null,
            newPassword: null,
            confirmPassword: null,
            invalidNewPass: false,
            validConfirmPass: false,
            firstLogin: props.firstLogin,
            showNewPassword:false,
            showConfirmPassword:false,
            newPassError: null,
        };
    }

    render() {
        const {type} = this.props.dialog;
        if (Utils.isNullOrUndefined(type)) {
            return null;
        }
        switch (type) {
            case DialogTypes.Alert:
                return this.renderAlertDialog();
            case DialogTypes.Confirm:
                return this.renderConfirmDialog();
            case DialogTypes.Timer:
                return this.renderTimerDialog();
            case DialogTypes.ScreenshotTitle:
                return this.renderScreenshotTitleDialog();
            case DialogTypes.GraphScreenshotEdit:
                return this.renderScreenshotDialogEdit();
            case DialogTypes.ChangePassword:
                return this.renderChangePassword();
            case DialogTypes.LegacyNewsShow:
                return this.renderShowLegacyNews();
            case DialogTypes.JudicialDetail:
                return this.renderJudicialContent();
            default:
                return null;
        }
    }

    renderJudicialContent() {
        const {title, content} = this.props.dialog;
        return <Dialog
            maxWidth="md"
            open={ this.state.open }
            style={{ zIndex: 1500, width: "75%", maxWidth: 832, margin: "0 auto"}}
            scroll="paper">
            <DialogTitle style={{ fontSize:22, color: 'white' }} className="explore-header">
                { title }
            </DialogTitle>
            <DialogContent style={{ maxHeight: 712}}>
                <DialogContentText component="div" className="explore-dialog-body">
                    <div className="row" style={{ fontSize: "16px", whiteSpace: "pre-line" , padding: "0 10px"}}>
                        { content}
                    </div>
                </DialogContentText>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button key="closeButtonLabel"
                        onClick={() => this.handleClose()}
                     className="btn btn-floating btn-large waves-effect waves-light "
                     style={{
                         borderRadius: 25,
                         backgroundColor: "#CDCDCD",
                         padding: "5px 20px!important",
                         marginRight: '20px',
                         color: "#fff"
                     }}
                >Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    renderAlertDialog() {
        const {title, message, closeButtonLabel, style, expired} = this.props.dialog;
        const closeButton = [
            <div key="closeButtonLabel"
                 className="btn btn-floating btn-large waves-effect waves-light "
                 onClick={() => this.handleClose(expired)}
                 style={{
                     borderRadius: 25,
                     backgroundColor: "#CDCDCD",
                     padding: "5px 20px!important",
                     marginRight: '20px',
                     color: "#fff"
                 }}
            ><span style={{padding: '0 10px'}}>{closeButtonLabel || "Close"}</span>
            </div>
        ];
        return (
            <Dialog maxWidth="md"
                    open={this.state.open}
                    onClose={() => this.handleClose(expired)}
                    style={Utils.isNullOrUndefined(style) ? {} : style}
                    PaperProps={{style: {width: "75%", maxWidth: 768, maxHeight: 900}}}>
                <DialogTitle>
                    <Box
                        style={{fontSize: "22px", lineHeight: "32px", fontWeight: 400}}>{title || "Message"}</Box>
                </DialogTitle>
                <DialogContent>
                    <Grid style={{fontSize: "15px", color:'#5e5e5e'}}>
                        {message}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {closeButton}
                </DialogActions>
            </Dialog>
        );
    }

    renderConfirmDialog() {
        const {
            title,
            message,
            onContinue,
            onSecondContinue,
            closeButtonLabel,
            continueButtonLabel,
            continueSecondButtonLabel
        } = this.props.dialog;
        const closeButton = [onSecondContinue ? [
            <Button className='flat_button confirm_dialog_actions' key="closeButtonLabel" color="primary" onClick={() => this.handleClose()}
                    style={{backgroundColor: "#CDCDCD"}}>
                {closeButtonLabel || "Close"}
            </Button>,
            <Button className='flat_button confirm_dialog_actions' key="continueButtonLabel" color="primary"
                    onClick={() => {
                        onContinue();
                        this.handleClose();
                    }}
                    style={{backgroundColor: "#ef4254"}}>
                {continueButtonLabel || "Continue"}
            </Button>,
            <Button className='flat_button confirm_dialog_actions' key="continueSecondButtonLabel"
                    onClick={() => {
                        onSecondContinue();
                        this.handleClose();
                    }}
                    style={{backgroundColor: "#ef4254"}}>
                {continueSecondButtonLabel || "Continue"}
            </Button>
        ] : [
            <Button className='flat_button confirm_dialog_actions' key="close" onClick={() => this.handleClose()}
                    style={{borderRadius: 25, marginRight: "10px", backgroundColor: "#CDCDCD"}}>
                {closeButtonLabel || "Close"}
            </Button>,
            <Button className='flat_button confirm_dialog_actions' key="continue"
                    onClick={() => {
                        onContinue();
                        this.handleClose();
                    }}
                    style={{backgroundColor: "#ef4254"}}>
                {continueButtonLabel || "Continue"}
            </Button>,
        ]
        ];
        return (
            <Dialog maxWidth="md"
                    open={this.state.open}
                    style={{zIndex: 2000}}
                    onClose={() => this.handleClose()}
                    PaperProps={{style: {width: "75%", maxWidth: "768px"}}}>
                <DialogTitle>
                    <Box
                        style={{fontSize: "22px", lineHeight: "32px", fontWeight: 400}}>{title || "Message"}</Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{fontSize: "16px"}}>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {closeButton}
                </DialogActions>
            </Dialog>
        );
    }

    renderTimerDialog() {
        const {title, message, onCanselSearch, closeButtonLabel, cancelSearchLabel} = this.props.dialog;
        const closeButton = [
            <Button onClick={() => this.handleClose()}
                    key="Keep Waiting"
                    style={{borderRadius: 25, marginRight: "10px", backgroundColor: "#CDCDCD"}}>
                {closeButtonLabel || "Keep Waiting"}
            </Button>,
            <Button onClick={() => {
                onCanselSearch();
                this.handleClose();
            }}
                    key="Cancel Search"
                    style={{borderRadius: 25, marginRight: "10px", backgroundColor: "#ef4254"}}>
                {cancelSearchLabel || "Cancel Search"}
            </Button>
        ];
        return (
            <Dialog maxWidth="md"
                    open={this.state.open}
                    onClose={() => this.handleClose()}
                    PaperProps={{style: {width: "75%", maxWidth: "768px"}}}>
                <DialogTitle>
                    <Box
                        style={{fontSize: "22px", lineHeight: "32px", fontWeight: 400}}>{title || "Message"}</Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{fontSize: "16px"}}>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {closeButton}
                </DialogActions>
            </Dialog>
        );
    }

    renderScreenshotTitleDialog() {
        const {title, onContinue, closeButtonLabel, continueButtonLabel} = this.props.dialog;
        const closeButton = [
            <div className="btn btn-floating btn-large waves-effect waves-light " key="close"
                 onClick={() => this.handleClose()}
                 style={{
                     borderRadius: 25,
                     backgroundColor: "#CDCDCD",
                     padding: "5px 10px!important",
                     marginRight: '10px',
                     color: "#fff",
                     textTransform: 'capitalize'
                 }}
            ><span style={{padding: '0 10px'}}>{closeButtonLabel || "Close"}</span></div>,
            <div className="btn btn-floating btn-large waves-effect waves-light " key="continue" onClick={() => {
                onContinue(this.state.value, this.state.description);
                this.handleClose();
            }}
                 style={{
                     borderRadius: 25,
                     backgroundColor: "#ef4254",
                     padding: "5px 10px!important",
                     color: "#fff",
                     textTransform: 'capitalize'
                 }}
            ><span style={{padding: '0 10px'}}>{continueButtonLabel || "Continue"}</span></div>
        ];
        return (
            <Dialog
                open={this.state.open}
                style={{zIndex: 6000}}
                onClose={() => this.handleClose()}
                PaperProps={{style: {width: "75%", maxWidth: "768px"}}}>
                <DialogTitle>
                    <Box pt={1} style={{fontSize: "22px", lineHeight: "32px", fontWeight: 400}}>{title}</Box>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <TextField
                            placeholder="Enter title"
                            value={this.state.value || ""}
                            variant={'standard'}
                            className={this.props.classes.input}
                            onChange={this.handleChange}/>
                    </Box>
                    <Box pt={2} pb={3}>
                        <TextField
                            value={this.state.description || ""}
                            onChange={this.handleChangeDesc}
                            className={this.props.classes.input}
                            placeholder="Enter description"
                            variant={'standard'}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    {closeButton}
                </DialogActions>
            </Dialog>
        );
    }

    renderScreenshotDialogEdit() {
        const {title, index, value, description, onContinue, closeButtonLabel, continueButtonLabel} = this.props.dialog;
        const val = this.state.value !== null ? this.state.value : value;
        const desc = this.state.description !== null ? this.state.description : description;

        const closeButton = [
            <div className="btn btn-floating btn-large waves-effect waves-light " key="close"
                 onClick={() => this.handleClose()}
                 style={{
                     borderRadius: 25,
                     backgroundColor: "#CDCDCD",
                     padding: "5px 10px!important",
                     marginRight: '10px',
                     color: "#fff",
                     textTransform: 'capitalize'
                 }}
            ><span style={{padding: '0 10px'}}>{closeButtonLabel || "Close"}</span></div>,
            <div className="btn btn-floating btn-large waves-effect waves-light " key="continue" onClick={() => {
                onContinue(index, val, desc);
                this.handleClose();
            }}
                 style={{
                     borderRadius: 25,
                     backgroundColor: "#ef4254",
                     padding: "5px 10px!important",
                     color: "#fff",
                     textTransform: 'capitalize'
                 }}
            ><span style={{padding: '0 10px'}}>{continueButtonLabel || "Continue"}</span></div>

        ];
        return (
            <Dialog open={this.state.open}
                    style={{zIndex: 6000}}
                    onClose={() => this.handleClose()}
                    PaperProps={{style: {width: "75%", maxWidth: "768px"}}}>
                <DialogTitle>
                    <Box style={{fontSize: "22px", lineHeight: "32px", fontWeight: 400}}>{title}</Box>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <Box pl={3}>
                            <TextField placeholder="Enter title"
                                       inputProps={{className: 'text-field'}}
                                       className={this.props.classes.input}
                                       value={val}
                                       variant={'standard'}
                                       onChange={this.handleChange}/>
                        </Box>
                        <Box pl={3}>
                            <TextField value={desc}
                                       onChange={this.handleChangeDesc}
                                       variant={'standard'}
                                       inputProps={{className: 'text-field'}}
                                       className={this.props.classes.input}
                                       placeholder="Enter description"
                            />
                        </Box>
                    </div>
                </DialogContent>
                <DialogActions>
                    {closeButton}
                </DialogActions>
            </Dialog>
        );
    }

    renderChangePassword() {
        const {onContinue} = this.props.dialog;
        const closeButton = [
            <div className="btn btn-floating btn-large waves-effect waves-light " key="close"
                 onClick={() => this.handleClose()}
                 style={{
                     borderRadius: 25,
                     backgroundColor: "#CDCDCD",
                     padding: "5px 10px!important",
                     marginRight: '10px',
                     color: "#fff",
                     textTransform: 'capitalize'
                 }}
            ><span style={{padding: '0 10px'}}>Close</span></div>,
            <div className="btn btn-floating btn-large waves-effect waves-light " key="continue" onClick={() => {
                if (this.validationPasswordForm(this.state.newPassword, this.state.confirmPassword)) {
                    onContinue(this.state.confirmPassword);
                }
            }}
                 style={{
                     borderRadius: 25,
                     backgroundColor: "#ef4254",
                     padding: "5px 10px!important",
                     color: "#fff",
                     textTransform: 'capitalize'
                 }}
            ><span style={{padding: '0 10px'}}>Change Password</span></div>
        ];
        return (
            <Dialog open={this.state.open}
                    style={{zIndex: 6000}}
                    onClose={() => this.handleClose()}
                    PaperProps={{style: {width: "75%", maxWidth: "768px"}}}>
                <DialogTitle>
                    <Box style={{fontSize: "22px", lineHeight: "32px", fontWeight: 400}}> Change Password </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        style={{fontSize: "14px", minHeight: '16px', lineHeight: "16px", color: "#ef4254"}}>
                        <span className='text-info' style={{fontSize:'15px',minHeight:'16px'}}>Successfully logged in. Please change your password to continue</span>
                        <span style={{marginTop:'15px',minHeight: '16px'}}>{this.state.newPassError && this.state.newPassError.reason}</span>
                    </DialogContentText>
                    {<div style={{marginTop:'30px'}}>
                        <Box style={{padding: "5px"}}>
                            <TextField type={this.state.showNewPassword ? "text" : "password"}
                                       title={"Password must be of minimum 12 characters length and contain uppercase, lowercase, special characters and digits."}
                                       placeholder="Enter New Password"
                                       inputProps={{className: 'text-field'}}
                                       className={this.props.classes.input}
                                       onChange={(event) => this.setState({newPassword: event.target.value})}
                                       InputProps={{
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   <IconButton
                                                       aria-label="toggle password visibility"
                                                       onClick={()=>this.setState({showNewPassword:!this.state.showNewPassword})}
                                                   >
                                                       {this.state.showNewPassword ? <Visibility fontSize='large'/> : <VisibilityOff fontSize='large'/>}
                                                   </IconButton>
                                               </InputAdornment>
                                           )
                                       }}
                                       variant={'standard'}
                            />

                            <div style={{minHeight: "20px"}}>
                                {this.state.invalidNewPass &&
                                <span style={{color: 'red', fontSize: "12px",}}>
                                    Password must be of minimum 12 characters length and contain uppercase, lowercase, special characters and digits.
                                </span>}
                            </div>
                        </Box>
                        <Box style={{padding: "5px"}}>
                            <TextField type={this.state.showConfirmPassword ? "text" : "password"}
                                       title={"Password must be of minimum 12 characters length and contain uppercase, lowercase, special characters and digits."}
                                       placeholder="Confirm New Password"
                                       inputProps={{className: 'text-field'}}
                                       className={this.props.classes.input}
                                       variant={'standard'}
                                       onChange={(event) => this.setState({confirmPassword: event.target.value})}
                                       InputProps={{
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   <IconButton
                                                       aria-label="toggle password visibility"
                                                       onClick={()=>this.setState({showConfirmPassword:!this.state.showConfirmPassword})}
                                                   >
                                                       {this.state.showConfirmPassword ? <Visibility fontSize='large'/> : <VisibilityOff fontSize='large'/>}
                                                   </IconButton>
                                               </InputAdornment>
                                           )
                                       }}
                            />
                            <div style={{minHeight: "20px"}}>
                                {this.state.validConfirmPass &&
                                <span style={{color: 'red', fontSize: "12px",}}>
                                Password does not match the confirmation password
                            </span>}
                            </div>
                        </Box>
                    </div>}
                    <span style={{color: 'red', fontSize: "12px",}}>{this.props.changePassError}</span>

                </DialogContent>
                <DialogActions>
                    {closeButton}
                </DialogActions>
            </Dialog>
        );
    }

    renderShowLegacyNews() {
        const {item} = this.props.dialog;
        const closeButton =
            <div className="btn btn-floating btn-large waves-effect waves-light " key="close"
                 onClick={() => this.handleClose()}
                 style={{
                     borderRadius: 25,
                     backgroundColor: "#CDCDCD",
                     padding: "5px 10px!important",
                     marginRight: '10px',
                     color: "#fff",
                     textTransform: 'capitalize'
                 }}
            ><span style={{padding: '0 10px'}}>Close</span></div>
        return (
            <div>
                return (
                <Dialog maxWidth="md"
                        open={this.state.open}
                        onClose={() => this.handleClose()}
                        PaperProps={{style: {width: "75%", maxWidth: "768px"}}}>
                    <DialogTitle>
                    </DialogTitle>
                    <DialogContent>
                        <p dangerouslySetInnerHTML={{__html: item.join(' ')}}/>
                    </DialogContent>
                    <DialogActions>
                        {closeButton}
                    </DialogActions>
                </Dialog>
                );
            </div>
            )

    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.newPassError !== nextProps.dialog.newPassError){
            this.setState({ newPassError: nextProps.dialog.newPassError})
        }
    }

    handleClose(expired = false) {
        if (expired) window.location.replace(PolixisRoutes.Website.Login);
        setTimeout(() => {
            this.setState({value: null, description: null});
            this.setState({invalidNewPass: false});
            this.setState({validConfirmPass: false});
            this.setState({open: false}, () => {
                setTimeout(() => {
                    closeDialog();
                    this.setState({open: true});
                }, 0);
            });
        }, 50)


    };

    handleChange = (event) => {
        this.setState({
            value: event.target.value,
        });
    };

    handleChangeDesc = (event) => {
        this.setState({
            description: event.target.value,
        });
    };

    invalidPassword(pass) { //return true if password invalid
        // eslint-disable-next-line no-useless-escape
        const passwordRegExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\.\+\*\{\]\{\[\-,;`<>':"=^#_|\/\\])(?=.*?[A-Za-z\d@$!%*+?&\.\+\*\{\]\{\[\-,;`<>':"=^#_|\/\\]).{12,}/);
        return !passwordRegExp.test(pass);
    }

    validationPasswordForm(newP, confirmP) {
        let valid = true;
        this.setState({invalidNewPass: false, validConfirmPass: false, newPassError:null});
        if (newP !== "" && this.invalidPassword(newP)) {
            this.setState({invalidNewPass: true})
            this.setState({validConfirmPass: true})
            valid = false
        }
        if (confirmP !== "" && this.invalidPassword(confirmP)) {
            this.setState({validConfirmPass: true})
            valid = false;
        }
        if (!newP || !confirmP || newP !== confirmP) {
            this.setState({validConfirmPass: true})
            valid = false
        }
        return valid
    }
}

export default withStyles(dialogStyles)(connect(mapStateToProps)(PolixisDialog));

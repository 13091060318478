import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {TextField, Button, InputAdornment, Grid} from '@mui/material';
import withStyles from "@mui/styles/withStyles";
import {cyan} from "@mui/material/colors";
import { login, newPassSend} from "../../authentication/actions";
import {INITIAL_STATE} from "../../constants";
import PolixisRoutes from "../../routes.js";
import {showChangePasswordDialog, closeDialog} from "../../shared/dialog/actions";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Utils from "../../utils";
const loginStyles = {
    input: {
        "&>div::after": {
            borderColor: cyan[500]
        }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: reguestData => dispatch(login(reguestData)),
        newPassSend: reguestData => dispatch(newPassSend(reguestData)),
    };
};

const mapStateToProps = (state = INITIAL_STATE.authentication) => {
    return {
        firstLogin: state.authentication.firstLogin,
        error: state.authentication.loginFailed,
        isLoggedIn: state.authentication.isLoggedIn,
        noServer: state.authentication.noServer
    };
};

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: null,
            errors: '',
            firstLogin: false,
        };
    }

    render() {
        return (
            <div className="login">
                <div className="container-fluid text-center">
                    <div className="row login-row">
                        <div className="col-xs-6 login-left"></div>
                        <div className="col-xs-6 login-right"></div>
                    </div>
                </div>
                <div className="container login-container">
                    <div className="row ">
                        <div className="col-xs-3 login-logo">
                            <img alt='loginLogo' src="/images/login_logo.png"/>
                        </div>
                        <div className="col-xs-5 login-body-text">
                            <h1 className="login-title"><span> CONTACT US </span></h1>
                            <Grid container wrap={'wrap'}>
                                <Grid item sm={3} style={{width:'170px'}} >
                                    <Grid className='main-color'>Contact Sales Team</Grid>
                                    <Grid container style={{color: '#a5a5a5', paddingTop:'.5rem'}}>
                                        <i className="material-icons red-text">phone</i>
                                        <span style={{paddingLeft:'1rem'}}>+ 4122 340 22 00</span>
                                    </Grid>
                                    <Grid alignItems={'center'} container style={{paddingTop:'.5rem'}}>
                                        <i className="material-icons red-text">mail_outline</i>
                                        <a className='emailToLink' href={`mailto:team@polixis.com`}>Email us</a>
                                    </Grid>
                                </Grid>
                                <Grid item sm={3} style={{width:'170px'}}>
                                    <Grid className='main-color'>User Feedback</Grid>
                                    <Grid container alignItems={'center'} style={{color: '#a5a5a5',paddingTop:'.5rem'}}>
                                        <i className="material-icons red-text">phone</i>
                                        <span style={{paddingLeft:'1rem'}}>+ 4122 340 22 00</span>
                                    </Grid>
                                    <Grid alignItems={'center'} container style={{paddingTop:'.5rem'}}>
                                        <i className="material-icons red-text">mail_outline</i>
                                        <a className='emailToLink' href={`mailto:team@polixis.com`}>Email us</a>
                                    </Grid>
                                </Grid>
                                <Grid item sm={12} style={{width:'170px', marginTop:'2rem'}}>
                                    <Grid className='main-color'>Contact Support Team</Grid>
                                    <Grid container alignItems={'center'} style={{color: '#a5a5a5',paddingTop:'.5rem'}}>
                                        <i className="material-icons red-text">phone</i>
                                        <span style={{paddingLeft:'1rem'}}> + 41 (0) 22 340 22 00   Switzerland</span>
                                    </Grid>
                                    <Grid alignItems={'center'} container style={{paddingTop:'.5rem'}}>
                                        <i className="material-icons red-text">mail_outline</i>
                                        <a className='emailToLink' href={`mailto:team@polixis.com`}>Email us</a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="col-xs-4 login-form" style={{marginLeft: "3%"}}>
                            <div className="text-center login-error"
                                 style={{minHeight: '20px'}}>{!this.state.firstLogin && this.state.error}</div>
                            <form>
                                <TextField autoFocus
                                           name="username"
                                           fullWidth
                                           type="text"
                                           margin='normal'
                                           autoComplete='on'
                                           placeholder='Username'
                                           className={this.props.classes.input}
                                           inputProps={{className: 'text-field'}}
                                           error={!Utils.isNullOrUndefined(this.state.errors.username)}
                                           helperText={Utils.isNullOrUndefined(this.state.errors.username) ? '' : this.state.errors.username}
                                           InputProps={{
                                               startAdornment: (
                                                   <InputAdornment position="start">
                                                       <PermIdentityIcon style={{color:'#ef4254'}} fontSize={'large'}/>
                                                   </InputAdornment>
                                               )
                                           }}
                                           onChange={(e) => this.handleChange(e)}
                                           variant={"standard"}
                                />
                                <TextField fullWidth
                                           name="password"
                                           margin='normal'
                                           type="password"
                                           autoComplete='on'
                                           placeholder='Password'
                                           inputProps={{className: 'text-field'}}
                                           className={this.props.classes.input + ' text-field'}
                                           error={!Utils.isNullOrUndefined(this.state.errors.password)}
                                           helperText={Utils.isNullOrUndefined(this.state.errors.password) ? '' : this.state.errors.password}
                                           InputProps={{
                                               startAdornment: (
                                                   <InputAdornment position="start">
                                                       <LockOpenIcon style={{color:'#ef4254'}} fontSize={'large'}/>
                                                   </InputAdornment>
                                               )
                                           }}
                                           onChange={(e) => this.handleChange(e)}
                                           variant={"standard"}
                                />
                                <Button onClick={() => this.handleClick()}
                                        style={{
                                            borderRadius: 25,
                                            marginTop: "40px",
                                            backgroundColor: "#ef4254",
                                            color: "#fff"
                                        }}>Login</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        if(!localStorage.getItem('bnli')){
            sessionStorage.clear();
            localStorage.clear();
        }
    }

    async handleClick() {
        if (this.validateLoginForm()) {
            const requestData = {
                username: this.state.username,
                password: this.state.password,
                grant_type:'password',
            };
           this.props.login(requestData);
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
            firstLogin: false,
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.error !== nextProps.error) {
            this.setState({error: nextProps.error});
        }
        if (this.state.firstLogin !== nextProps.firstLogin) {
            this.setState({firstLogin: nextProps.firstLogin});
        }
        (nextProps.firstLogin)
            ? showChangePasswordDialog({
                onContinue: (pass) => this.changePassword(pass),
                newPassError: nextProps.error,
            })
            : closeDialog();

        if (nextProps.isLoggedIn) {
            this.props.history.push(PolixisRoutes.App.Search);
        }
        if (nextProps.noServer) {
            this.props.history.push(PolixisRoutes.Website.NoServer);
        }
    }

    onKeyPress(event) {
        if (event.charCode === 13) {
            this.handleClick()
        }
    }

    validateLoginForm() {
        let isFormValid = true;
        if (this.state.password.trim().length === 0) {
            isFormValid = false;
            this.setState({errors: {password: 'Please provide your password.'}});
        }
        if (this.state.username.trim().length === 0) {
            isFormValid = false;
            this.setState({errors: {username: 'Please provide your username.'}})
        }
        return isFormValid
    }

    changePassword(pass) {
        const requestData = {
            oldPassword: this.state.password,
            newPassword: pass
        };
        this.props.newPassSend(requestData);
    }
}

Login.propTypes = {
    error: PropTypes.string,
    login: PropTypes.func,
    newPassSend: PropTypes.func,
    firstLogin: PropTypes.bool,
};

export default withStyles(loginStyles)(connect(mapStateToProps, mapDispatchToProps)(Login));

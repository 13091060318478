import Utils from "../utils";

export default class Edge {
    constructor( edgeData = null ) {
        if ( Utils.isNotNullOrUndefined( edgeData ) ) {
            if ( edgeData.title.length > 39 ) {
                let space = edgeData.title.indexOf( ' ', 40 );
                if ( space !== -1 ) {
                    this.label = edgeData.title.substring( 0, space ) + ' ...';
                    this.titleHover = edgeData.title;
                } else {
                    this.label = edgeData.title;
                    this.titleHover = edgeData.title;
                }
            } else {
                this.label = edgeData.title;
            }
            this.sourceId = edgeData.source.replace( /(\.)|(,)/g, '_' );
            this.targetId = edgeData.target.replace( /(\.)|(,)/g, '_' );
            this.thickness = edgeData.thickness;
            this.style = edgeData.style.toLowerCase();
            this.color = Utils.isNotNullOrUndefined( edgeData.color ) ? edgeData.color : "#d7d7d7";
            this.id = edgeData.id;
            this.isHistorical = edgeData.isHistorical || false;
            this.category = edgeData.category ?  edgeData.category.startsWith("#") ? edgeData.category.substr(1).toLowerCase() : edgeData.category.toLowerCase() : null;
        }
    }

    isVisible() {
        if ( Utils.isNotNullOrUndefined( this.source ) && Utils.isNotNullOrUndefined( this.target ) ) {
            return this.source.isVisible && this.target.isVisible;
        } else {
            return false;
        }
    }

    getCyEdge() {
        return {
            data: {
                id: this.id,
                source: this.sourceId,
                target: this.targetId,
                label: this.label,
                titleHover: this.titleHover,
                width: this.thickness,
                color: this.color,
                style: this.style,
                isHistorical: this.isHistorical,
            }
        };
    }
}

import React from "react";
import PropTypes from "prop-types";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class Custom3 extends React.Component {

    constructor( props ) {
        super( props );
        let pages = JSON.parse( sessionStorage.getItem( "ckdata" ) ) || '';
        this.state = {
            pages,
        };
    }

    render() {
        let content;
        if ( this.props.print !== '' ) {
            content = this.renderViewMode();
        } else {
            content = this.renderEditMode();

        }

        return (
            <div className="custom3">
                { content }
            </div>
        );
    }

    renderEditMode() {
        const custom_config = {
            toolbar: {
                items: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'blockQuote',
                    'insertTable',
                    '|',
                    // 'imageUpload',
                    'undo',
                    'redo'
                ]
            },
            table: {
                contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
            }
        }
        return (
            <div >
                <CKEditor
                   editor={ ClassicEditor }
                   data={this.state.pages}
                   config={custom_config}
                   onBlur={ ( event, editor ) => {
                        const data = editor.getData();
                        this.setState( { pages: data } );
                        sessionStorage.setItem('ckdata', JSON.stringify(data))

                   }}
                />
            </div>
        )
    }

    renderViewMode() {
        let content = "<div class='custom-page-content'>";
        if ( this.state.pages !== '' ) {
            content += "<div class='custom-text-content'  style='max-width:100%'>" + this.state.pages + "</div>";
        }
        content += "</div>";

        return <div dangerouslySetInnerHTML={ { __html: content } }/>;
    }
}

Custom3.defaultProps = {};

Custom3.propTypes = {
    print: PropTypes.string
};

import React from "react";
import PropTypes from "prop-types";

export default class Section extends React.Component {
    render() {
        const { id, title } = this.props.node;
        return (
            <div id={ id }>
                <div className="clearfix"></div>
                <div className="section text-right">
                    { title }
                </div>
            </div>
        );
    }
}

Section.propTypes = {
    node: PropTypes.object.isRequired
};
import {combineReducers} from "redux";
import application from "../application/reducer";
import authentication from "../authentication/reducer";
import newsSearch from "../application/pressa/pressaStore/newsReducer";
import dialog from "../shared/dialog/reducer";

export const rootReducer = combineReducers({
    application,
    authentication,
    newsSearch,
    dialog,
});

import React from "react";
import PropTypes from "prop-types";

import LinearProgress from '@mui/material/LinearProgress';
import {ThemeProvider, createTheme} from "@mui/material/styles";

const progressTheme = createTheme({
    components: {
        MuiLinearProgress: {
            styleOverrides: {
                bar : {
                    backgroundColor: 'rgb(189, 189, 189)'
                },
                root: {
                    backgroundColor: '#ef4254',
                    height: 6,
                    padding:0
                },

            }
        }
    }
})
export default class Footer extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            completed: 0,
        };
    }

    componentDidMount() {
        this.timer = setTimeout( () => this.progress( 5 ), 2000 );
    }

    componentWillUnmount() {
        clearTimeout( this.timer );
    }

    progress( completed ) {
        if ( completed > 100 ) {
            this.setState( { completed: 100 } );
        } else {
            this.setState( { completed } );
            const diff = Math.random() * 10;
            this.timer = setTimeout( () => this.progress( completed + diff ), 2000 );
        }
    }

    render() {
        const items = this.props.items.map( item => {
            if ( !item.isSection ) {
                if ( this.props.state === 1 ) {
                    return <img alt='reportFooter1' key={ item.id } src={ "/images/report_footer/" + item.icon + ".png" }/>
                } else {
                    return <img alt='reportFooter2' key={ item.id } src={ "/images/report_footer/" + item.icon + "_selected.png" }/>
                }

            }
            return null
        } );

        return (
            <div className="footer-part">
                <ThemeProvider theme={progressTheme} >
                    <LinearProgress mode="indeterminate" />

                </ThemeProvider>
                <div>
                    { items }
                </div>
            </div>
        );
    }

}

Footer.propTypes = {
    items: PropTypes.array.isRequired,
    state: PropTypes.number,
    isMobile: PropTypes.bool,
    selected: PropTypes.string,
    onSelect: PropTypes.func
};

import moment from "moment/moment";

export const getFormattedDate = (dateString) => {
    let date;

    if (moment(dateString, 'DD/MMM/YYYY', true).isValid()) {
        date = moment(dateString, 'DD/MMM/YYYY', true);
    } else if (/^\d{4}$/.test(dateString)) {
        date = moment(`01/JAN/${dateString}`, 'DD/MMM/YYYY');
    } else if (moment(dateString, 'MMM/YYYY', true).isValid()) {
        date = moment(dateString, 'MMM/YYYY', true).startOf('month');
    }
    return date;
}

import React from "react";
import {sanctionTypes} from "../../../../constants";

const SanctionProperties = ({data, item}) => {
    const titleSplit = item.split(' ');
    const title = titleSplit.slice(2, titleSplit.length - 1).join(' ');
    const properties = [];
    data.forEach((e, i) => {
        const inner = [];
        Object.keys(e).forEach(eKey => {
            const highlightedKey = eKey.trim().toLowerCase() === 'sanction status';
            const color = highlightedKey ? sanctionTypes[e[eKey][0].toLowerCase()].color : '#717171';
            const styleValue = {
                fontWeight: highlightedKey ? 'bold' : 'normal',
                color,
            }

            inner.push(<li key={`${e}_${eKey}_${i}`} className='sanction-property-mg' style={{textAlign: 'justify'}}><b>{eKey} :</b>
                <span style={styleValue} className={highlightedKey ? 'sanction-status': ''}> {e[eKey].join(' ')} </span>
            </li>)
        })
        properties.push(<div className='sanction-inner-property-mg' key={`outer_${title}_${i}`}>
            <ul className='sanction-wrapper-inner-list'>{inner}</ul>
        </div>)
    });


    return (
        <li key={item} className='sanction-mgb-small sanction-properties'>
            <p className='sanction-property-mg sanction-paragraph'>&#x2022; {item}</p>
            <div>{properties}</div>
        </li>
    )
};

export default SanctionProperties;

import React from "react";
import PropTypes from "prop-types";

import Utils from "../../../utils";
import Graph from "./Graph2.jsx";
import RelationTree from "./Tree.jsx";

export default class Relationship extends React.Component {
    constructor( props ) {
        super( props );
        this.dataEdges = [];
        this.mainNode = {};
        const view = Utils.isNotNullOrUndefined( sessionStorage.getItem( "RelationView" ) ) ? sessionStorage.getItem( "RelationView" ) : "graph";
        this.treeData = {};
        this.state = {
            view: props.automation ? "tree" : view,
            data: props.data,
            isMaximize: props.isMaximize,
            print: props.print,
            mainNodeId: "",
            tree: {}
        };
    }

    render() {

        return (
            <div>
                { (!this.props.automation && !this.state.isMaximize && this.props.print === "") &&
                <div title={'The data can be visualized in various formats: graph view and tree view.'}  style={ { display: "flex", flexWrap: "nowrap" } }>
                    <div
                        className={ this.state.view === "graph" ? "relation-tab-selected relation-tab" : "relation-tab" }
                        onClick={ () => this.changeTab( "graph" ) }>Graph View
                    </div>
                    <div className={ this.state.view === "tree" ? "relation-tab-selected relation-tab" : "relation-tab" }
                         onClick={ () => this.changeTab( "tree" ) }>Tree View
                    </div>
                </div>
                }

                { (this.state.view === "tree" && Object.keys( this.state.tree ).length > 0) &&
                <RelationTree
                    data={ this.state.tree }
                    isMaximize={ this.props.isMaximize }
                    isScreenshot={ this.props.isScreenshot }
                    bgColor={ this.borderColor }
                    print={ this.props.print }
                    bnli={ this.props.bnli }
                    projectId={ this.props.projectId }
                    toggleMaximize={ () => this.props.toggleMaximize() }
                    cachedState={ this.contentState }
                    status={ this.props.status }
                    getScreenshot={ ( base, title, description ) => this.props.getScreenshot( base, title, description ) }
                    changeCanvasToImage={ this.props.changeCanvasToImage }
                    addToCard={ ( nodes ) => this.props.addToCard( nodes ) }
                    sessionExpired={ () => this.props.sessionExpired() }
                    automation={ this.props.automation }
                    isTreeAppendix={ this.props.isTreeAppendix }
                    refreshTreeAppendixData={ () => this.props.refreshTreeAppendixData() }/>
                }

                { this.state.view === "graph" &&
                <Graph
                    data={this.props.data}
                    isMaximize={this.props.isMaximize}
                    isScreenshot={this.props.isScreenshot}
                    bgColor={this.borderColor}
                    print={this.props.print}
                    bnli={this.props.bnli}
                    projectId={this.props.projectId}
                    ref="componentContent"
                    toggleMaximize={() => this.props.toggleMaximize()}
                    cachedState={this.props.cachedState}
                    status={this.props.status}
                    getScreenshot={(base, title, description) => this.props.getScreenshot(base, title, description)}
                    changeCanvasToImage={ this.props.changeCanvasToImage}
                    addToCard={(nodes) => this.props.addToCard(nodes)}
                    sessionExpired={() => this.props.sessionExpired()}
                    automation={this.props.automation}/>
                }
            </div>
        );
    }

    getContentState() {
        if ( this.refs.componentContent.getContentState ) {
            return this.refs.componentContent.getContentState();
        }
    }

    componentDidMount() {
        if ( !this.props.isMaximize  ) {
            this.dataToTree().then(() => {
                setTimeout(()=>this.setState({tree: this.treeData}), 0)
            });
        }
    }

    dataToTree() {
        let nodeMap = {};

        return new Promise( ( resolve ) => {
            let nodesPromises = [];
            this.props.data.nodes.forEach( nodeData => {
                let node = Object.assign( {}, nodeData );
                node.subNodes = {};
                node.subNodeIds = [];
                node.edges = [];
                node.pieNames = nodeData.pie ? Object.keys( nodeData.pie ) : [];

                nodesPromises.push( nodeMap[nodeData.id] = node );

            } );
            Promise.all( nodesPromises ).then( () => {
                resolve();
            } );

        } ).then( () => {
            this.setState( { mainNodeId: this.props.data.nodes[0].id }, () => {
                let node = Object.assign( {}, this.props.data.nodes[0] );
                node.subNodes = {};
                node.subNodeIds = [];
                node.edges = [];
                node.pieNames = this.props.data.nodes[0].pie ? Object.keys( this.props.data.nodes[0].pie ) : [];

                this.subNodesToMap( node, nodeMap );

            } )
        } );
    }


    subNodesToMap( node, data ) {
        return new Promise( ( resolve ) => {
            let subNodesPromises = [];
            var subNodeIds = [];
            if ( this.dataEdges.length < this.props.data.edges.length ) {
                this.props.data.edges.forEach( edge => {
                    if ( (edge.source === node.id || edge.target === node.id) && this.dataEdges.indexOf( edge.id ) === -1 ) {
                        this.dataEdges.push( edge.id );
                        subNodeIds.push( edge );

                    }
                } )
                node.subNodeIds = subNodeIds;
                node.nodeType = node.nodeType.toLowerCase();

                subNodeIds.forEach( e => {
                    node.edges.push( e );
                    if ( Utils.isNotNullOrUndefined( data[e.target] ) && e.target !== node.id ) {
                        let newNode = Object.assign( {}, data[e.target] );
                        newNode.subNodes = {};
                        newNode.arrow = "right";
                        newNode.edge = e.title;
                        newNode.color = e.color;
                        newNode.parent = node.title;
                        newNode.nodeType = data[e.target].nodeType.toLowerCase();
                        newNode.collapse = true;
                        node.subNodes[data[e.target].id] = newNode;
                    }
                    if ( Utils.isNotNullOrUndefined( data[e.source] ) && e.source !== node.id ) {
                        let newNode = Object.assign( {}, data[e.source] );
                        newNode.subNodes = {};
                        newNode.arrow = "left";
                        newNode.edge = e.title;
                        newNode.color = e.color;
                        newNode.parent = node.title;
                        newNode.nodeType = data[e.source].nodeType.toLowerCase();
                        newNode.collapse = true;
                        node.subNodes[data[e.source].id] = newNode;
                    }
                } )
                subNodesPromises.push( data[node.id] = node );
            }
            Promise.all( subNodesPromises ).then( () => {
                resolve( node );
            } );
        } ).then( ( node ) => {
            Object.keys( node.subNodes ).forEach( id => {
                this.subNodesToMap( node.subNodes[id], data )
            } )
            this.treeData = Object.assign( this.treeData, data[this.state.mainNodeId] );
        } );
    }

    changeTab( value ) {
        this.setState( { view: value }, () => {
            sessionStorage.setItem( "RelationView", value );
        } )
    }

}

Relationship.defaultProps = {
    isMaximize: false,
};

Relationship.propTypes = {
    data: PropTypes.object.isRequired,
    isMaximize: PropTypes.bool,
    toggleMaximize: PropTypes.func.isRequired,
    bgColor: PropTypes.string,
    cachedState: PropTypes.object,
    bnli: PropTypes.string,
    projectId: PropTypes.string,
    print: PropTypes.string,
    getScreenshot: PropTypes.func,
    isScreenshot: PropTypes.number,
    changeCanvasToImage: PropTypes.number,
    goToLogin: PropTypes.func,
    sessionExpired: PropTypes.func,
    addToCard: PropTypes.func,
    status: PropTypes.string,
    automation: PropTypes.bool,
    isTreeAppendix: PropTypes.number,
    refreshTreeAppendixData: PropTypes.func,
}; 

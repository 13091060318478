import RelatedSanction from "./RelatedSanctions";

const SanctionExposure = ({data, print}) => {
    const {sanctionDatasourceHeader, sanctionDatasource, rootSanctionHeader, rootSanction, relatedSanctions,rootNodeUnsanctioned, rootCardHeader,relatedSanctionHeader, relatedCardHeader} = data;
    const isPrint = print === "?print=true";

    return (
        <div className='sanction-exposure'>
            <p className='bold-title'>{sanctionDatasourceHeader}</p>
            <div>
                <ul className='list-style'>
                    {
                        sanctionDatasource.map((item, i) =>
                            <li key={`${item}_${i}`} className='list-style-color'><i> {item}</i></li>
                        )
                    }
                </ul>
                {
                    !rootSanction  ?  <div className='sanction-wrapper sanction-mgb-med' >
                        <div className='sanction-root-header'>{rootCardHeader?.toUpperCase()}</div>
                        <p  className='bold-title sanction-inner-property-mg sanction-mgt-small'>{rootSanctionHeader}</p>
                        <ul className='unsanctioned-list'>
                            {
                                rootNodeUnsanctioned &&  rootNodeUnsanctioned.map((item,i) => (
                                    <li key={`${item}_${i}`}>{item}</li>
                                ))
                            }
                        </ul>
                    </div> :
                        <div className='sanction-wrapper sanction-mgb-med main-sanction'>
                            <div className='sanction-root-header'>{rootCardHeader.toUpperCase()}</div>
                                <ul className='sanction-wrapper-list'>
                                    <RelatedSanction data={rootSanction} isMain={true} index={0} print={isPrint}/>
                             </ul>
                        </div>
                }

                {
                    relatedSanctionHeader && <>

                        <div className='sanction-wrapper related-wrapper'>
                            <div className='sanction-root-header'>{relatedCardHeader?.toUpperCase()}</div>
                            <p className='bold-title gray-back sanction-pdt-small sanction-inner-property-pd' >{relatedSanctionHeader}</p>
                            {
                                relatedSanctions.length > 0 && relatedSanctions.map((item, i) => {
                                    return (
                                        <div className='sanction-wrapper sanction-wrapper-unset sanction-mgt-small' key={`${item}_${i}`}>
                                            <RelatedSanction data={item} isMain={false} index={i} print={isPrint}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default SanctionExposure;

import React from "react";
import PropTypes from "prop-types";

import {Box , Icon, Dialog, DialogContent, DialogTitle, DialogActions, FormControlLabel, Checkbox} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import Utils from "../../utils";


const styles = {
    checkboxStyle: {
        "& .material-icons": {
            fontSize: "24px!important",
            backgroundColor: "#fff0",
        }
    }
};

class SelectComponentsToPrint extends React.Component {
    constructor( props ) {
        super( props );
        let componentsChecked = [];
        let sections = [];
        const newSearchLength = Utils.isNotNullOrUndefined( sessionStorage.getItem( 'PinnedItemsNews' ) ) ? JSON.parse( sessionStorage.getItem( 'PinnedItemsNews' ) ).length : 0;

        Object.keys( this.props.componentsList ).forEach( ( component ) => {
            if (component === 'judicial') {
                const pinnedJudicial = sessionStorage.getItem("pinnedJudicial") ||  [];
                const existJudicial =  pinnedJudicial.length > 0 ? JSON.parse(pinnedJudicial) : pinnedJudicial;
                if (existJudicial.length > 0) {
                    componentsChecked.push( component );
                }
            } else if ( !(newSearchLength === 0 && component === 'newssearch') ) {
                componentsChecked.push( component );
            }

            let section = this.props.componentsList[component][0];
            sections.push( section );
        } );

        sessionStorage.setItem( 'components', JSON.stringify( componentsChecked ) );
        sessionStorage.setItem( 'sections', sections );

        this.state = {
            open: true,
            componentsChecked,
        };
    }

    render() {
        const actions = [
            <button key="cancel" style={ {
                borderRadius: 25,
                color: "#fff",
                fontFamily: "Roboto, sans-serif",
                textTransform: 'capitalize',
                margin: "5px"
            } } onClick={ () => this.props.handleClose() }
                    className="btn btn-floating btn-large waves-effect waves-light">Cancel</button>,
            <button key="continue" style={ {
                borderRadius: 25,
                color: "#fff",
                fontFamily: "Roboto, sans-serif",
                textTransform: 'capitalize',
                backgroundColor: "#ef4254",
                margin: "5px"
            } } onClick={ () => {
                this.props.toPdf();
                setTimeout( () => {
                    this.props.handleClose()
                }, 500 )
            } } className="btn btn-floating btn-large waves-effect waves-light">Continue</button>
        ];
        return (
            <Dialog
                open={this.state.open}
                scroll="body"
                style={{zIndex: 1500}}
                PaperProps={{style: {maxWidth: "768px", width: "75%"}}}
            >
                <DialogTitle style={{color: 'white', fontSize: 22}} className="explore-header">
                    Sections included in the report
                </DialogTitle>
                <DialogContent className="explore-dialog-body">
                    <div style={{padding: '20px'}}>
                        {this.renderContent()}
                    </div>
                </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>
            </Dialog>
        );
    }

    renderContent() {
        let content = [];
        const newSearchLength = Utils.isNotNullOrUndefined( sessionStorage.getItem( 'PinnedItemsNews' ) ) ? JSON.parse( sessionStorage.getItem( 'PinnedItemsNews' ) ).length : 0;
        const isOnlyNews = sessionStorage.getItem( "isOnlyNews" );

        Object.keys( this.props.componentsList ).forEach( ( component ) => {
            let checked = this.state.componentsChecked.indexOf( component ) !== -1;
            if ( isOnlyNews ) {
                if ( component === "newssearch" || component === "custom" ) {
                    if ( !(newSearchLength === 0 && component === 'newssearch') ) {
                        content.push( <div style={ { width: '50%', float: 'left' } } key={ component }>
                            <FormControlLabel
                                style={ { fontSize: '16px', marginBottom: 0, marginTop: "-5px" } }
                                control={ <Checkbox
                                    className={ this.props.classes.checkboxStyle }
                                    checked={ checked }
                                    style={ { color: checked ? "#ef4254" : "rgba(0, 0, 0, 0.6)" } }
                                    checkedIcon={ <Icon className="material-icons"
                                                        style={ { color: "#ef4254" } }>check_box</Icon> }
                                    icon={ <Icon className="material-icons"
                                                 style={ { color: "#6e7072" } }>check_box_outline_blank</Icon> }
                                    onChange={ () => this.updateCheck( component ) }
                                /> }
                                label={ <Box
                                    style={ { fontSize: "16px" } }>{ this.props.componentsList[component][1] }</Box> }
                            />
                        </div> )
                    }
                }
            } else {
                if ( !(newSearchLength === 0 && component === 'newssearch') ) {
                    content.push(<div style={{ width: '50%', float: 'left' }} key={component}>
                        <FormControlLabel
                            style={{ fontSize: '16px', marginBottom: 0, marginTop: "-10px" }}
                            control={<Checkbox
                                className={this.props.classes.checkboxStyle}
                                checked={checked}
                                style={{color: checked ? "#ef4254" : "rgba(0, 0, 0, 0.6)"}}
                                checkedIcon={<Icon className="material-icons"
                                                    style={{ color: "#ef4254"}}>check_box</Icon>}
                                icon={<Icon className="material-icons"
                                             style={{ color: "#6e7072"}}>check_box_outline_blank</Icon>}
                                onChange={() => this.updateCheck(component)}
                            /> }
                            label={<Box pl={1}
                                         style={{fontSize: "16px" }}>{ this.props.componentsList[component][1]}</Box>}
                        />
                    </div> )
                }
            }
        } );
        return content;
    }

    updateCheck( component ) {
        let sections = new Set();
        let componentsChecked = this.state.componentsChecked;
        if ( componentsChecked.indexOf( component ) === -1 ) {
            componentsChecked.push( component );
        } else {
            let pos = componentsChecked.indexOf( component );
            componentsChecked.splice( pos, 1 );
        }
        this.setState( { componentsChecked } );
        componentsChecked.forEach( item => {
            let section = this.props.componentsList[item][0];
            sections.add(section )
        } )
        if (component === "judicial" && componentsChecked.includes(component)) {
            sessionStorage.setItem("judicialFromSelected", component)
        } else {
            sessionStorage.removeItem("judicialFromSelected")
        }

        sessionStorage.setItem( 'components', JSON.stringify( componentsChecked ) );
        sessionStorage.setItem( 'sections', JSON.stringify(Array.from(sections)));
    }
}

SelectComponentsToPrint.propTypes = {
    toPdf: PropTypes.func,
    componentsList: PropTypes.object,
    handleClose: PropTypes.func,
};

export default withStyles( styles )( SelectComponentsToPrint );

import React from "react";
import PropTypes from "prop-types";

let ListPieInstanceIndex = 0;

export default class ListPie extends React.Component {
    constructor( props ) {
        super( props );
        this.id = ListPieInstanceIndex++;
    }

    render() {
        return (
            <div className="list-pie">
                <div className="legend">
                    <span>Reviews: </span>
                    { this.getReviewLegend() }
                </div>
                <hr/>
                <div className="content">
                    <table>
                        <tbody>
                        { this.getContent() }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    componentDidMount() {
        if ( this.props.map ) {
            const { pie_data, pie_color, pie_title } = this.props.map;

            for ( let i = 0; i < pie_data.length; i++ ) {
                const pieData = pie_data[i];
                const slices = {};
                const dataArray = [];

                dataArray.push( [ 'Type', 'Score' ] );
                for ( let k = 0; k < pieData.length; k++ ) {
                    slices[k] = { color: pie_color[k] };
                    dataArray.push( [ pie_title[k], pieData[k] ] );
                }
                let data = window.google.visualization.arrayToDataTable( dataArray );
                let options = {
                    chartArea: { width: "90%", top: "1%", left: "1%" },
                    legend: { position: "none" },
                    pieHole: 0.4,
                    slices
                };

                let chart = new window.google.visualization.PieChart( document.getElementById( 'list_pie_' + this.id + '_' + i ) );
                if ( this.props.print !== "" ) {
                    window.google.visualization.events.addListener( chart, 'ready', () => {
                        document.getElementById( 'list_pie_' + this.id + '_' + i ).innerHTML = '<img alt="getImage" src="' + chart.getImageURI() + '">';
                    } );
                }
                chart.draw( data, options );
            }
        }
    }

    getReviewLegend() {
        const legend = [];
        if ( this.props.map ) {
            const { pie_color, pie_title } = this.props.map;
            for ( let i = 0; i < pie_color.length; i++ ) {
                legend.push( <span key={ i } style={ { color: pie_color[i] } }>
                    <img alt='review' src="/images/review.png" style={ { backgroundColor: pie_color[i] } }/>
                    { pie_title[i] }
                </span> );
            }
        }

        return legend;
    }

    getContent() {
        const content = [];

        if ( this.props.list ) {
            const list_data = this.props.list;
            for ( let i = 0; i < list_data.length; i++ ) {
                content.push(
                    <tr key={ "data_" + i }>
                        <td>
                            <div className="list-item" dangerouslySetInnerHTML={ { __html: list_data } }/>
                        </td>
                        <td>
                            <div id={ "list_pie_" + this.id + "_" + i }/>
                        </td>
                    </tr>
                );
            }
        }

        return content;
    }
}

ListPie.defaultProps = {
    isMaximize: false,
};

ListPie.propTypes = {
    list: PropTypes.array,
    map: PropTypes.object,
    isMaximize: PropTypes.bool,
    print: PropTypes.string
};

import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {Grid, Dialog, DialogTitle, DialogContent} from "@mui/material";
import Utils from "../../utils";
import Org from "./components/Org.jsx";
import List from "./components/List.jsx";
import Card from "./components/Card.jsx";
import Donut from "./components/Donut.jsx";
import Custom3 from "./components/Custom3.jsx";
import Lighter from "./components/Lighter.jsx";
import ListPie from "./components/ListPie.jsx";
import HtmlText from "./components/HtmlText.jsx";
import StackBar from "./components/StackBar.jsx";
import NewsSearch2 from "../pressa/NewsSearch2";
import Relationship from "./components/Relationship.jsx";
import TreeAppendix from "./components/TreeAppendix.jsx";
import GraphAppendix from "./components/GraphAppendix.jsx";
import {ProgressState, INITIAL_STATE} from "../../constants";
import JudicialAppendix from "./components/judicial/JudicialAppendix.jsx";
import {
    addComponentsScreenshotToCustom,
    newAppendix,
    sessionExpired,
    addToCard,
    appendixTree
} from "../actions";
import SanctionExposure from "./components/sanction/SanctionExposure";
import Regulatory from "./components/regulatory/Regulatory";
import Judicial from "./components/judicial/Judicial";
import CountryRisk from "./components/countryRisk/CountryRisk";

const mapStateToProps = (state = INITIAL_STATE) => {
    return {
        bnli: state.application.bnli,
        projectId: state.application.search.projectId,
        isScreenshot: state.application.addComponentsScreenshotToCustom,
        isAppendix: state.application.newAppendix,
        judicialAppendixData: state.application.judicialAppendixData,
        isTreeAppendix: state.application.appendixTree,
        avatar: Utils.isNullOrUndefined(state.application.generate.nodeProps) ? null : state.application.generate.nodeProps.avatar,
        judicialAppendixRemoveId: state.application.judicialAppendixRemoveId,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        checkScreenshot: () => dispatch(addComponentsScreenshotToCustom()),
        toggleCheck: (item) => dispatch(newAppendix(item)),
        sessionExpired: () => dispatch(sessionExpired()),
        addToCard: (nodes) => dispatch(addToCard(nodes)),
        appendixTree: (index) => dispatch(appendixTree(index)),
    };
};

class Component extends React.PureComponent {
    constructor(props) {
        super(props);
        let pages = [];
        this.stateNewSearch = null;
        this.contentState = null;
        this.contentStatePageSelected = null;
        this.contentStateStartSelected = null;
        let hitsCheck = JSON.parse(sessionStorage.getItem("HITSCHECK"));
        if (Utils.isNullOrUndefined((hitsCheck))) {
            hitsCheck = [];
        }
        this.state = {
            isMaximize: this.props.isMaximize || false,
            progressState: ProgressState.None,
            pages, open: false,
            hit: hitsCheck,
            hits: [],
            pinnedHits: [],
            value: '',
            screenshot: JSON.parse(sessionStorage.getItem("Graph")),
            judicialAppendixRemoveId: null
        };
    }

    render() {
        const {isMaximize} = this.state;
        if (Utils.isNullOrUndefined(this.props.data)) {
            return null;
        }
        if (isMaximize) {
            return (
                <Dialog
                    style={{zIndex: 1200}}
                    fullScreen
                    open={isMaximize}
                    onClose={() => this.toggleMaximize()}
                >
                    <DialogTitle style={{
                        backgroundColor: "#ef4254",
                        color: "#ffffff",
                        height: "50px",
                        paddingRight: 0,
                        zIndex: 1
                    }}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item style={{
                                fontSize: '18px', fontWeight: 400,
                                marginTop: '-5px', letterSpacing: 0
                            }}>
                                {this.props.data.title}
                            </Grid>
                            <Grid item style={{marginTop: "-14px"}}>
                                {this.renderClose()}
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={{padding: 0, color: "#464646"}}>
                        <div className="full-screen-component">
                            {(this.props.data.view.toLowerCase().trim() !== "graph" && this.props.data.view.toLowerCase().trim() !== "elastic" && this.props.data.view.toLowerCase().trim() !== "search") ?
                                <p className="component-header">{this.props.data.header}</p> : ''}
                            {this.renderContent()}
                            {(this.props.data.view.toLowerCase().trim() !== "graph" && this.props.data.view.toLowerCase().trim() !== "elastic" && this.props.data.view.toLowerCase().trim() !== "search") ?
                                <p className="component-footer">{this.props.data.footer}</p> : ''}
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else {
            return this.renderComponent();
        }
    }

    renderComponent() {
        let {id, title, header, footer, view} = this.props.data;
        const icon = id.toLowerCase().replace(" ", "_");
        const newSearchLength = Utils.isNotNullOrUndefined(sessionStorage.getItem('PinnedItemsNews')) ? Object.keys(JSON.parse(sessionStorage.getItem('PinnedItemsNews'))).length : 0;

        if (this.props.print !== "" && view.toLowerCase().trim() === "custom") {
            title = "Analyst Note";
        }
        if (view.toLowerCase().trim() === "search") {
            if ((this.props.print === "?print=true" && newSearchLength === 0 && Utils.isNotNullOrUndefined(header) && header.toLocaleLowerCase().startsWith("warning"))) {
                return null;
            } else {
                return (
                    <div className="component" id={id}>
                        <div className="row">
                            <div style={{position: 'relative', pointerEvents: 'all'}}>
                                <div className="component-title">
                                    <img alt='Pink' src={"/images/components/" + icon + "_pink.png"}/>
                                    <span style={{
                                        textTransform: 'uppercase',
                                        position: 'relative',
                                        top: '2px',
                                        paddingLeft: '10px'
                                    }}>{title}</span>
                                </div>
                                {<div className="component-header" dangerouslySetInnerHTML={{__html: header}}/>}

                                {this.renderContent()}

                                {<div className="component-footer" dangerouslySetInnerHTML={{__html: footer}}/>}
                            </div>

                        </div>

                    </div>
                );
            }
        } else {
            return (
                <div className="component" id={id}>
                    <div className="row">
                        <div style={{position: 'relative', pointerEvents: 'all'}}>
                            <div className="component-title">
                                <img alt='PinkImage' src={"/images/components/" + icon + "_pink.png"}/>
                                <span style={{
                                    textTransform: 'uppercase',
                                    position: 'relative',
                                    top: '2px',
                                    paddingLeft: '10px'
                                }}>{title}</span>
                            </div>
                            {<div className="component-header" dangerouslySetInnerHTML={{__html: header}}/>}

                            {this.renderContent()}

                            {<div className="component-footer" dangerouslySetInnerHTML={{__html: footer}}/>}
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderContent() {
        const bnli = localStorage.getItem('bnli');
        const {progressState, isMaximize} = this.state;
        if (progressState === ProgressState.Loading) {
            return <div>Loading ...</div>;
        } else {
            const {view, valueMap, valueList, data, title, id} = this.props.data;
            try {
                switch (view.toLowerCase().trim()) {
                    case "list":
                        if (id === 'regulatory') return <Regulatory data={this.props.data.regulators} />
                        return <List map={valueMap}
                                     nodeType={this.props.nodeType}
                                     avatar={this.props.avatar}
                                     list={valueList}
                                     data={data}
                                     title={title}
                                     isMaximize={isMaximize}
                                     toggleMaximize={() => this.toggleMaximize()}
                                     ref="componentContent"
                                     print={this.props.print}
                                     sectionId={id}
                        />;
                    case "elastic":
                        if (this.props.data.header === "loading...") {
                            if (Boolean(sessionStorage.getItem("pinnedJudicial"))) sessionStorage.removeItem("pinnedJudicial");
                            if (Boolean(sessionStorage.getItem("includedJudicial")))  sessionStorage.removeItem("includedJudicial");
                        }
                        if (id === 'judicial') return <Judicial data={this.props.data} print={this.props.print} toggleInclude={(item) => this.props.toggleCheck(item)}/>
                        else return;

                    case "list_pie":
                        return <ListPie list={valueList}
                                        map={valueMap}
                                        isMaximize={isMaximize}
                                        ref="componentContent"
                                        print={this.props.print}/>;

                    case "map":
                        return <CountryRisk data={this.props.data}/>

                    case "pie":
                        return valueMap ? <Donut data={data}
                                                  isMaximize={isMaximize}
                                                  ref="componentContent"
                                                  print={this.props.print}/> : null;
                    case "mix":
                        let subComponent = [];

                        Object.keys(data).forEach((name, index) => {
                            let subView = data[name].type;
                            if (subView.toLowerCase().trim() === 'table') {
                                subComponent.push(<Org key={"mix" + index}
                                                       data={data[name]}
                                                       isMaximize={isMaximize}
                                                       ref="componentContent"
                                                       print={this.props.print}/>);
                            } else if (subView.toLowerCase().trim() === 'card') {

                                subComponent.push(<Card key={"mix1" + index}
                                                        data={data[name]}
                                                        title={id}
                                                        isMaximize={isMaximize}
                                                        isExtraSources={id === 'extrasources'}
                                                        isPoliticalProfile={id === 'politicalprofile'}
                                                        isBusinessProfile={id === 'businessprofile'}
                                                        isDeclaration={id === 'declarations'}
                                                        ref="componentContent"
                                                        print={this.props.print}/>);
                            } else if (subView.toLowerCase().trim() === 'hbar') {
                                subComponent.push(<StackBar key={"mix1" + index}
                                                            data={data[name]}
                                                            isMaximize={isMaximize}
                                                            ref="componentContent"
                                                            print={this.props.print}/>);
                            } else if (subView.toLowerCase().trim() === 'list') {

                                let vList = Utils.isNotNullOrUndefined(data[name].valueList) ? data[name].valueList : []
                                let vMap = Utils.isNotNullOrUndefined(data[name].valueMap) ? data[name].valueMap : {}
                                let vdata = Utils.isNotNullOrUndefined(data[name].data) ? data[name].data : {}
                                let vtitle = Utils.isNotNullOrUndefined(data[name].title) ? data[name].title : '';

                                subComponent.push(<List map={vMap}
                                                        key={name + index}
                                                        avatar={this.props.avatar}
                                                        list={vList}
                                                        data={vdata}
                                                        sectionId={id}
                                                        title={vtitle}
                                                        isMaximize={isMaximize}
                                                        toggleMaximize={() => this.toggleMaximize()}
                                                        ref="componentContent"
                                                        print={this.props.print}/>);
                            }
                        });
                        return subComponent;

                    case "search":
                        return <NewsSearch2
                            node={this.props.nodeProps}
                            data={this.props.data}
                            bnli={bnli}
                            isMaximize={isMaximize}
                            toggleMaximize={() => this.toggleMaximize()}
                            reference={(comp) => {
                                this.stateNewSearch = comp;
                            }}
                            cachedState={this.stateNewSearch}
                            ref="componentContent"
                            print={this.props.print}
                            changeCanvasToImage={this.props.changeCanvasToImage}
                            sessionExpired={() => this.props.sessionExpired()}
                            automation={this.props.automation}
                            status={this.props.status}
                            isDisable={this.props.showFooter}
                            nodeSearch={this.props.nodeSearch}
                        />;

                    case "text":
                        return <HtmlText data={valueList}
                                         isMaximize={isMaximize}
                                         ref="componentContent"
                                         print={this.props.print}/>;

                    case "graph":
                        if (data.nodes.length > 0) {
                            return <Relationship data={data}
                                                 isMaximize={isMaximize}
                                                 isScreenshot={this.props.isScreenshot}
                                                 bgColor={this.borderColor}
                                                 print={this.props.print}
                                                 bnli={bnli}
                                                 projectId={this.props.projectId}
                                                 ref="componentContent"
                                                 toggleMaximize={() => this.toggleMaximize()}
                                                 cachedState={this.contentState}
                                                 status={this.props.status}
                                                 getScreenshot={(base, title, description) => this.getScreenshot(base, title, description)}
                                                 changeCanvasToImage={this.props.changeCanvasToImage}
                                                 addToCard={(nodes) => this.props.addToCard(nodes)}
                                                 sessionExpired={() => this.props.sessionExpired()}
                                                 automation={this.props.automation}
                                                 isTreeAppendix={this.props.isTreeAppendix}
                                                 refreshTreeAppendixData={() => this.refreshTreeAppendixData()}/>;

                        } else {
                            return null;
                        }

                    case "card":
                        if (id === 'sanctionexposure') return <SanctionExposure data={this.props.data} print={this.props.print}/>
                        else
                            return <Card data={data}
                                         ref="componentContent"
                                         isDeclaration={id === 'declarations'}
                                         print={this.props.print}/>;

                    case "lighter":
                        return <Lighter data={data}
                                        ref="componentContent"
                                        print={this.props.print}/>;

                    case "custom":
                        return <Custom3 ref="componentContent"
                                        print={this.props.print}/>;

                    case "appendix": {
                        const judicial = this.props.print === "?print=true" ?  JSON.parse(sessionStorage.getItem("judicialAppendix")) : this.props.judicialAppendixData;
                        return <JudicialAppendix judicialData={judicial} print={this.props.print}/>;
                    }
                    case "appendixgraph": {
                        const data = JSON.parse(sessionStorage.getItem("Graph"));
                        return <GraphAppendix data={data}
                                              ref="componentContent"
                                              print={this.props.print}
                                              isScreenshot={this.props.isScreenshot}
                                              refreshShcreenshotData={() => this.refreshShcreenshotData()}/>;
                    }
                    case "appendixtree": {
                        const data = JSON.parse(sessionStorage.getItem("appendixTree"));
                        return <TreeAppendix data={data}
                                             ref="componentContent"
                                             print={this.props.print}
                                             refreshTreeAppendixData={() => this.refreshTreeAppendixData()}/>;
                    }
                    default:
                        return null;
                }
            } catch (error) {
                return null;
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContent) {
        if (this.props.judicialAppendixRemoveId !== nextProps.judicialAppendixRemoveId) {
            this.setState({judicialAppendixRemoveId: nextProps.judicialAppendixRemoveId})
        }
    }

    componentDidMount() {
        if (this.props.print !== "" && document.getElementsByClassName('custom-text-content').length === 0) {
            if (document.getElementById('custom')) document.getElementById('custom').style.display = 'none';
        }
    }

    renderClose() {
        return (
            <div style={{color: "#ffffff", margin: '-8px 24px -8px 16px'}}>
                <img alt='minimize' src="/images/minimize.png" className="pointer" style={{marginTop: "9px"}}
                     onClick={() => this.toggleMaximize()}/>
            </div>
        );
    }

    toggleMaximize() {
        const {isMaximize} = this.state;
        if (Utils.isNotNullOrUndefined(sessionStorage.getItem('searchValue'))) {
            sessionStorage.removeItem('searchValue');
        }

        if (this.refs.componentContent.getContentState) {
            this.contentState = this.refs.componentContent.getContentState();
        }
        if (this.refs.componentContent.getContentStatePageSelected && this.refs.componentContent.getContentStateStartSelected) {
            this.contentStatePageSelected = this.refs.componentContent.getContentStatePageSelected();
            this.contentStateStartSelected = this.refs.componentContent.getContentStateStartSelected();
        }
        if (Utils.isNotNullOrUndefined(this.stateNewSearch)) {
            this.stateNewSearch = this.stateNewSearch.getContentStateNewSearch();
        }
        this.setState({isMaximize: !isMaximize, progressState: ProgressState.Loading}, () => {
            setTimeout(() => {
                this.setState({progressState: ProgressState.Success});
            }, 50);
        });
    }

    getScreenshot(base, title, description) {
        let data = {base, title, description}
        let graphScreenshots = [];

        let screenshot = sessionStorage.getItem("Graph");
        if (Utils.isNotNullOrUndefined((screenshot))) {
            graphScreenshots = JSON.parse(screenshot);
            graphScreenshots.push(data);
            sessionStorage.setItem("Graph", JSON.stringify(graphScreenshots));
        } else {
            graphScreenshots.push(data);
            sessionStorage.setItem("Graph", JSON.stringify(graphScreenshots));
        }
        this.refreshShcreenshotData();
    }

    refreshShcreenshotData() {
        let screenshot = JSON.parse(sessionStorage.getItem("Graph"));
        this.setState({screenshot});
        this.props.checkScreenshot();

    }

    refreshTreeAppendixData() {
        this.props.appendixTree();
    }
}

Component.defaultProps = {
    first: false,
};

Component.propTypes = {
    data: PropTypes.object,
    nodeProps: PropTypes.object,
    first: PropTypes.bool,
    projectId: PropTypes.string,
    print: PropTypes.string,
    checkScreenshot: PropTypes.func,
    appendixTree: PropTypes.func,
    isScreenshot: PropTypes.number,
    changeCanvasToImage: PropTypes.number,
    isAppendix: PropTypes.number,
    isTreeAppendix: PropTypes.number,
    status: PropTypes.string,
    toggleCheck: PropTypes.func,
    togglePin: PropTypes.func,
    goToLogin: PropTypes.func,
    avatar: PropTypes.string,
    sessionExpired: PropTypes.func,
    nodeType: PropTypes.string,
    automation: PropTypes.bool,
    addToCard: PropTypes.func,
    judicialAppendixRemoveId: PropTypes.string,
    isMaximize: PropTypes.bool,
    showFooter: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(Component);

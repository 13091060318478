import React, {useCallback, useMemo, useState} from "react";
import {Paper, Checkbox, FormGroup, TextField, FormControlLabel} from '@mui/material';
import {ThemeProvider} from "@mui/material/styles";
import {checkboxFilterTheme} from "../search/constants/searchStyles";
import {searchFilterTypes} from "../search/constants/search";
import c from "../search/search.module.css";

const SearchFilter = ({results, resultFilter, onFilter}) => {
    const [filter, setFilter] = useState(resultFilter);
    const countries = useMemo(() => {
        const countriesSet = new Set();
        results.forEach(r => {
            if (r.countries) {
                r.countries.forEach(c => countriesSet.add(c));
            }
        })

        return Array.from(countriesSet);
    }, [results])

    const handleChange = (e) => {
        let filters = {...filter};
        filters[e.target.name] = e.target.checked;
        getFilterResult(filters)
    }

    const changeCountry = (e) => {
        const {name} = e.target;
        let selectedCountries = [...filter.country];

        if (selectedCountries.indexOf(name) === -1) {
            selectedCountries.push(name);
        } else {
            selectedCountries = selectedCountries.filter(_country => name !== _country);
        }

        let filters = {...filter};
        filters.country = selectedCountries;
        getFilterResult(filters)
    }

    const handleDateChange = (e) => {
        const filters = {...filter}
        const {name, value} = e.target;
        const valueTrim = value.trim();
        if (valueTrim === '') {
            filters[name] = null;
        } else {
            filters[name] = valueTrim.toUpperCase();
        }

        getFilterResult(filters)
    }

    const getFilterResult = useCallback((filters) => {
        setFilter(filters);
        onFilter(filters);
    }, [onFilter]);

    return (
        <div>
            <Paper elevation={1}>
                <div>
                    <ThemeProvider theme={checkboxFilterTheme}>
                        <div className="row">
                            <div className={`col-xs-12 ${c.filterResultInfo}`}>
                                Show only the results containing the following info
                            </div>
                            <div className="col-xs-4" style={{paddingLeft: '3rem'}}>
                                <FormGroup>
                                    {
                                        Object.keys(searchFilterTypes).map(t => (
                                            <FormControlLabel
                                                key={`${t}_filter`}
                                                title={searchFilterTypes[t].title}
                                                control={<Checkbox
                                                    className={c.radioType}
                                                    name={searchFilterTypes[t].name}
                                                    checked={filter[searchFilterTypes[t].name]}
                                                    onChange={handleChange}/>}
                                                label={searchFilterTypes[t].label}/>
                                        ))
                                    }
                                </FormGroup>
                            </div>
                            <div className="col-xs-8">
                                <FormGroup row>
                                    {countries.map((country, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    className={c.radioType}
                                                    name={country}
                                                    checked={filter.country.indexOf(country) !== -1}
                                                    onChange={changeCountry}
                                                />
                                            }
                                            label={country}/>
                                    ))
                                    }

                                </FormGroup>
                                <TextField
                                    value={filter.dateOfBirth || ""}
                                    fullWidth
                                    variant={"standard"}
                                    margin='dense'
                                    inputProps={{className: 'styled-input'}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="dateOfBirth"
                                    placeholder="Related Date"
                                    label={<div className={c.filterDates}>Related Date (e.g. 01/JAN/1900)</div>}
                                    onChange={handleDateChange}
                                />
                            </div>
                        </div>
                    </ThemeProvider>
                </div>

            </Paper>
        </div>
    )
}

export default SearchFilter;

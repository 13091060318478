import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {Radio, Paper, Select, Slider, Button, MenuItem, RadioGroup, FormControlLabel} from '@mui/material';
import {MuiChipsInput} from 'mui-chips-input'
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {DateRange} from '@mui/icons-material';
import DialogDatePicker from '../../shared/dialog/components/DialogDatePicker.jsx';
import moment from "moment";

import Utils from "../../utils";
import {API, doGet} from "../../api";
import {loadCountries} from "../actions.js";
import PolixisRoutes from "../../routes";
import {
    advancedCountryOptionTitle,
    advancedLanguageOptionTitle, advancedMatchOptionTitle,
    fuzzinessValues, initialStartDate,
    initialStartYear, relatedRadiusTitles,
    searchDateLabelText,
    searchRelatedLabel
} from "../search/constants/search";
import {getFormattedDate} from "../search/constants/getFormattedDate";

import c from "./styles/advancedOptions.module.css"

const mapStateToProps = store => {
    return {
        countries: store.application.countries,
        fetchFail: store.application.fetchFail,
        noServer: store.application.noServer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadCountries: () => dispatch(loadCountries())
    };
};

const muiTheme = createTheme({
    palette: {
        primary: {main: '#ef4254'},
    }
});

const muiTheme1 = createTheme({
    palette: {
        primary: {main: '#464646'},
    }
});


class AdvancedSearchOptions extends React.Component {
    constructor(props) {
        super(props);
        let options = {};
        this.forOtherBrowsers = 'forOtherBrowsers';
        this.state = {
            isFailHandled: false,
            countries: Utils.isNotNullOrUndefined(options.country) ? options.country : [],
            related: Utils.isNotNullOrUndefined(options.related) ? options.related : [],
            language: Utils.isNotNullOrUndefined(options.lng) ? options.lng : "-",
            match: Utils.isNotNullOrUndefined(props.match) ? props.match : fuzzinessValues.FUZZY_0.match,
            charactersCount: Utils.isNotNullOrUndefined(options.ld) ? options.ld : 2,
            dateOfBirth: Utils.isNotNullOrUndefined(options.date) ? options.date : [],
            languageCode: Utils.isNotNullOrUndefined(options.lng) ? options.lng : 'en',
            languageLabel: Utils.isNotNullOrUndefined(options.languageLabel) ? options.languageLabel : 'English',
            allLanguages: {},
            relatedRadius: Utils.isNotNullOrUndefined(options.related) ? options.related : 2,
            showDatePicker: false,
            invalidDateMessage: ''
        };
    }

    getMuiTheme = () =>
        createTheme({
            components: {
                WAMuiChipInput: {
                    styleOverrides: {
                        input: {
                            fontSize: "14px"
                        }
                    }
                },
                MuiIconButton: {
                    styleOverrides: {
                        root: {
                            marginTop: 10,
                            marginRight: -15
                        }
                    }
                }
            }
        });

    componentDidMount() {
        if (Utils.isNullOrUndefined(this.props.countries)) {
            this.props.loadCountries();
        }
        let chrome = !Utils.isNullOrUndefined(navigator.userAgent.match(/Chrome/)) ? navigator.userAgent.match(/Chrome/)[0] : false;
        let vivaldi = navigator.userAgent.match(/Vivaldi/) ? navigator.userAgent.match(/Vivaldi/)[0] : false;
        let edge = navigator.userAgent.match(/Edge/) ? navigator.userAgent.match(/Edge/)[0] : false;
        if (chrome === 'Chrome' && vivaldi === false && edge === false) {
            this.forOtherBrowsers = '';
        }

        doGet(API.LANGUAGE, null, false).then(response => {
            if (Utils.isNullOrUndefined(response.data.error)) {
                this.setState({allLanguages: response.data});
            }
        }).catch(error => {
            console.log(error);
        });
    }

    handleDeleteAll(chips, type) {
        if (chips.length === 0) {
            const newState = this.state;
            newState[type] = [];
            newState.invalidDateMessage = '';
            this.props.searchAttr(this.state);
        }
    }

    getValidDateFormat(e) {
        try {
            return getFormattedDate(e);
        } catch (err) {
            this.setState({invalidDateMessage: `Please provide format date (e.g. 01/JAN/${initialStartYear}, JAN/${initialStartYear}, ${initialStartYear})`})
        }
    }

    handleDateOption(e, position = null) {
        const date = this.getValidDateFormat(e)
        if (date) {
            const now = moment();
            const isBetween = this.isBetweenDates(now, date)
            if (!isBetween) {
                this.setState({invalidDateMessage: `Enter date from 01/JAN/${initialStartYear} to ${now.format('DD/MMM/YYYY')}`})
            } else {
                let dates = []
                if (position !== null) {
                    dates = [...this.state.dateOfBirth];
                    dates[position] = e.toUpperCase();
                } else {
                    dates = new Set([...this.state.dateOfBirth, e.toUpperCase()]);
                }
                this.setState({
                    dateOfBirth: Array.from(dates),
                    invalidDateMessage: null
                }, () => this.props.searchAttr(this.state));
            }
        } else {
            this.setState({invalidDateMessage: `Please provide format date (e.g. 01/JAN/${initialStartYear}, JAN/${initialStartYear}, ${initialStartYear})`})
        }
    }

    handleDateFromDialog(date) {
        this.setState({showDatePicker: !this.state.showDatePicker})
        this.handleDateOption(date)
    }

    cancelDateFromDialog() {
        this.setState({showDatePicker: false});
    }

    handleDateChipEdit(currentChip, index) {
        this.handleDateOption(currentChip, index)
    }

    handleChipEdit(currentChip, index, name) {
        const copyOfNamedState = [...this.state[`${name}`]];
        copyOfNamedState[`${index}`] = currentChip;

        this.setState({[name]: copyOfNamedState}, () => this.props.searchAttr(this.state));
    }

    onRelatedBlur(event) {
        const text = event.target.value;
        if (text.trim() !== "") {
            this.addItem("related", text);
        }
        this.props.searchAttr(this.state);
    }

    changeSlider(event, value) {
        this.setState({charactersCount: value}, () => {
            this.props.searchAttr(this.state);
        });
    }

    changeRadiusSlider(event, value) {
        this.setState({relatedRadius: value}, () => this.props.searchAttr(this.state));
    }

    onCountriesBlur(event) {
        const text = event.target.value;
        if (text.trim() !== "") {
            this.addItem("countries", text);
        }
        this.props.searchAttr(this.state);
    }

    onDateOfBirthBlur(event) {
        const text = event.target.value;
        if (text.trim() !== "") {
            this.handleDateOption(text)
        }
    }

    isBetweenDates(now, inputtedDate) {
        const initialStartDateMoment = moment.unix(initialStartDate);
        return inputtedDate.isBetween(initialStartDateMoment, now);
    }

    addItem(name, data) {
        const newData = data.toUpperCase();
        const items = this.state[name];
        items.push(newData);
        const uniqueItems = new Set(items);
        const newState = this.state;
        newState[name] = Array.from(uniqueItems);
        newState.invalidDateMessage = '';
        this.props.searchAttr(this.state);
    }

    deleteItem(type, data, removeIndex) {
        const items = [];

        this.state[type].forEach((item, index) => {
            if (removeIndex !== index) {
                items.push(item);
            }
        });

        const newState = this.state;
        newState[type] = items;

        this.setState(newState);
        this.props.searchAttr(this.state);

    }

    clear() {
        this.setState({
            countries: [],
            related: [],
            language: "-",
            charactersCount: 2,
            dateOfBirth: [],
            relatedRadius: 2,
            invalidDateMessage: ''
        });
        this.props.removeAdvancedSearchData();
        sessionStorage.removeItem('advancedSearchData');
    }

    handleLanguageChange = (event) => {
        const languageLabel = Object.keys(this.state.allLanguages).filter(l => this.state.allLanguages[l] === event.target.value);

        this.setState({
            languageCode: event.target.value,
            languageLabel: languageLabel[0]
        }, () => this.props.searchAttr(this.state));
        this.forceUpdate()
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContent) {
        if ((nextProps.fetchFail || nextProps.noServer) && !this.state.isFailHandled) {
            this.setState({isFailHandled: true})
            this.props.onFetchFail(PolixisRoutes.Website.NoServer);
        }

        if (nextProps.match !== this.state.match) {
            if (nextProps.match === fuzzinessValues.EXACT.match) {
                this.setState({match: nextProps.match, charactersCount: -1});
            } else {
                this.setState({match: nextProps.match})
            }
        }
    }

    renderPersonOptions() {
        return (
            <div className={c.personAdvancedOptions}>
                <div className="row">
                    <div className="col-xs-5">{this.renderCountryChipInput()}</div>
                    <div
                        title={advancedLanguageOptionTitle}
                        className={`${c.advancedOptionItem} col-xs-7`}>{this.renderLanguageOption()}</div>
                </div>
                <div className="row">
                    <div className="col-md-5" id='chipInputRelated'>
                        {this.renderRelatedDatesOption()}

                        {
                            this.state.invalidDateMessage !== '' && <p className={c.advancedOptionWarning}>{this.state.invalidDateMessage}</p>
                        }
                    </div>
                    <div
                        title={advancedMatchOptionTitle}
                        className={`${c.advancedOptionItem} col-md-7`}>{this.renderDistance()}</div>
                </div>
                <div className="row">
                    <div className="col-xs-5">{this.state.relatedRadius !== 0 && this.renderRelatedOption()}</div>
                    <div className={`${c.advancedOptionItem} col-md-7`}>{this.renderRadius()}</div>
                </div>
                <div className={`${c.advancedActions} ${c.textRight}` + this.forOtherBrowsers}>
                    <Button onClick={() => this.clear()}>Clear</Button>
                    <Button onClick={() => this.props.doSearch()}>Search</Button>
                </div>
                {
                    this.state.showDatePicker && <DialogDatePicker
                        isOpen={this.state.showDatePicker}
                        onSave={(date) => this.handleDateFromDialog(date)}
                        onCancel={() => this.cancelDateFromDialog()}
                    />
                }
            </div>
        );
    }

    renderRelatedOption() {
        return (
            <ThemeProvider theme={this.getMuiTheme()}>
                <MuiChipsInput
                    fullWidth
                    label={<span className={c.labelChip}>Related to</span>}
                    variant="standard"
                    title={searchRelatedLabel}
                    className={`${this.forOtherBrowsers} ${c.chipInput}`}
                    value={this.state.related}
                    onBlur={(event) => this.onRelatedBlur(event)}
                    onEditChip={(current, position) => this.handleChipEdit(current, position, "related")}
                    onPaste={(event) => {
                        event.preventDefault()
                        this.addItem("related", ...event.clipboardData.getData('Text').split('\n'))
                    }}
                    clearInputOnBlur
                    onChange={(e) => this.handleDeleteAll(e, 'related')}
                    onAddChip={(item) => this.addItem("related", item)}
                    onDeleteChip={(item, index) => this.deleteItem("related", item, index)}/>
            </ThemeProvider>
        );

    }

    renderRelatedDatesOption() {
        return (
            <ThemeProvider theme={this.getMuiTheme()}>
                <div className={c.advancedDatesWrapper}>
                    <DateRange className={`${c.pointer} ${c.dateIcon}`} onClick={() => this.setState({showDatePicker: !this.state.showDatePicker})}/>
                </div>
                <MuiChipsInput
                    sx={{"& .MuiInputBase-root": {paddingRight: "0"}}}
                    title={searchDateLabelText}
                    fullWidth
                    variant="standard"
                    label={<span className={c.labelChip}>Related dates (e.g. 01/JAN/1900)</span>}
                    className={`${this.forOtherBrowsers} ${c.chipInput}`}
                    value={this.state.dateOfBirth}
                    onChange={(e) => this.handleDeleteAll(e, 'dateOfBirth')}
                    onBlur={(event) => this.onDateOfBirthBlur(event)}
                    clearInputOnBlur
                    onEditChip={(current, e) => this.handleDateChipEdit(current, e)}
                    onAddChip={(item) => this.handleDateOption(item)}
                    onDeleteChip={(item, index) => this.deleteItem("dateOfBirth", item, index)}/>
            </ThemeProvider>
        )
    }

    renderDistance() {
        const title = Utils.getFuzzinessDistance(this.state.charactersCount).title;

        const ValueLabelComponent = (props) => {
            const {children, value, className} = props;
            const selectedFuzzinessDistance = Utils.getFuzzinessDistance(value)

            return <React.Fragment>
                {children}
                <span title={selectedFuzzinessDistance.title} className={ `${className} ${c.distanceLabel}`} style={{left:children.props.style.left}}>{selectedFuzzinessDistance.label}</span>
            </React.Fragment>
        };

        return (
            <div>
                <div className="row">
                    <div className="col-xs-4">
                        <label className={c.advancedOptionLabel}>Match tolerance</label>
                    </div>
                    <div className="col-xs-8">
                        <ThemeProvider theme={this.state.charactersCount === "-1" ? muiTheme1 : muiTheme}>
                            <div className={c.exactMatch}>
                                <div className={c.exactMax}>Exact</div>
                                <Slider
                                    title={title}
                                    slots={{
                                        valueLabel: ValueLabelComponent,
                                    }}
                                    valueLabelDisplay="on"
                                    step={1} min={-1} max={3} value={this.state.charactersCount}
                                    onChange={(event, value) => this.changeSlider(event, value)}
                                    onFocus={(e) => e.target.focus()}
                                    onMouseLeave={(event) => event.target.onmouseleave}
                                    onMouseOut={(event) => event.target.onmouseout}
                                    className={c.slider}
                                />
                                <div className={c.matchMin}>Fuzzy</div>
                            </div>
                        </ThemeProvider>
                    </div>
                </div>
            </div>
        )
    }

    renderRadius() {
        let title = '';
        if (this.state.relatedRadius === 0) {
            title = relatedRadiusTitles.minRelatedRadius;
        } else if (this.state.relatedRadius === 1) {
            title = relatedRadiusTitles.directRelatedRadius;
        } else if (this.state.relatedRadius === 2) {
            title = relatedRadiusTitles.secondaryRelatedRadius;
        } else {
            title = `Related radius: ${this.state.relatedRadius.toString()}`;
        }


        const ValueLabelComponent = (props) => {
            const {children, value} = props;
            return <React.Fragment>
                {children}
                <span title={title} className={c.radiusOption} style={{left:children.props.style.left}}>
                     <img alt='relatedRadius0r' style={{display: value === 0 ? "initial" : "none"}}
                          src={`/images/radiusSlider/relatedRadius0r.png`} className="icons"/>
                     <img alt='relatedRadius1r' style={{display: value === 1 ? "initial" : "none"}}
                          src={`/images/radiusSlider/relatedRadius1r.png`} className="icons"/>
                     <img alt='relatedRadius2r' style={{display: value === 2 ? "initial" : "none"}}
                          src={`/images/radiusSlider/relatedRadius2r.png`} className="icons"/>
                </span>
            </React.Fragment>
        };

        return (
            <div>
                <div className="row">
                    <div className="col-xs-4">
                        <label className={c.advancedOptionLabel}>Related radius</label>
                    </div>
                    <div className="col-xs-8">
                        <ThemeProvider theme={muiTheme}>
                            <div className={c.relatedRadius}>
                                <div className={c.relatedRadiusInnerMin}>0</div>
                                <Slider title={title}
                                        className={`${c.slider} ${c.radiusSlider}`} step={1} min={0} max={2}
                                        value={this.state.relatedRadius}
                                        onChange={(event, value) => this.changeRadiusSlider(event, value)}
                                        onFocus={(e) => e.target.focus()}
                                        onMouseLeave={(event) => event.target.onmouseleave}
                                        onMouseOut={(event) => event.target.onmouseout}
                                        slots={{
                                            valueLabel: ValueLabelComponent,
                                        }}
                                        valueLabelDisplay="on"/>
                                <div className={c.matchMin}>2</div>
                            </div>
                        </ThemeProvider>
                    </div>
                </div>
            </div>
        )
    }

    renderLanguageOption() {
        const languageDropDown = [];
        // eslint-disable-next-line no-unused-expressions
        Utils.isNotNullOrUndefined(this.state.allLanguages) ? Object.keys(this.state.allLanguages).forEach((lang, index) => {
            languageDropDown.push(<MenuItem
                key={index + lang}
                className={this.state.languageCode === this.state.allLanguages[lang]  ? `${c.selectedLanguage} ${c.valueColor}`: `${c.selectedLanguage} ${c.unselectedValue}` }
                value={this.state.allLanguages[lang]}>
                {lang}
            </MenuItem>);
        }) : null;
        return (
            <div className="row">
                <div className="col-xs-4">
                    <label className={c.advancedOptionLabel}>Language</label>
                </div>
                <div className={`col-xs-8 ${c.languageWrapper}`}>
                    <RadioGroup row name="match" value={this.state.language}
                                onChange={(event, language) => this.setState({language}, () => this.props.searchAttr(this.state))}>
                        <FormControlLabel
                            value="-"
                            label="Auto"
                            className={c.languageControl}
                            control={<Radio className={this.state.language === "man" ? `${c.languageRadioButton}` : `${c.valueColor} ${c.languageRadioButton}`}/>}
                        />
                        <FormControlLabel
                            value='man'
                            label="Manual"
                            id="lang"
                            className={c.languageControl}
                            control={<Radio className={this.state.language === "man" ? `${c.languageRadioButton} ${c.valueColor} ` : `${c.languageRadioButton}`}/>}
                        />
                    </RadioGroup>
                    {
                        this.state.language === "man" &&
                            <Select value={this.state.languageCode} MenuProps={{
                                PaperProps: {
                                    style: {maxHeight: "500px", width: "150px"},
                                }
                            }} className={`${c.newFont} ${c.languageSelect} `} onChange={this.handleLanguageChange}
                                    variant={'standard'}
                            >
                                {languageDropDown}
                            </Select>
                     }
                </div>
            </div>
        );
    }

    renderCountryChipInput() {
        return (
            <ThemeProvider theme={this.getMuiTheme()}>
                <MuiChipsInput
                    variant="standard"
                    title={advancedCountryOptionTitle}
                    fullWidth
                    label={<span className={c.labelChip}>Country</span>}
                    className={`${this.forOtherBrowsers} ${c.chipInput}`}
                    onPaste={(event) => {
                        event.preventDefault()
                        this.addItem("countries", ...event.clipboardData.getData('Text').split('\n'))
                    }}
                    clearInputOnBlur
                    onEditChip={(current, position) => this.handleChipEdit(current, position, "countries")}
                    value={this.state.countries}
                    onBlur={(event) => this.onCountriesBlur(event)}
                    onAddChip={(item) => this.addItem("countries", item)}
                    onChange={(e) => this.handleDeleteAll(e, 'countries')}
                    onDeleteChip={(item, index) => this.deleteItem("countries", item, index)}/>
            </ThemeProvider>
        );
    }

    render() {
        if (this.props.show) {
            return (
                <Paper elevation={2} className={`${c.expandSearchOptions}  ` + this.forOtherBrowsers}>
                    {this.renderPersonOptions()}
                </Paper>
            );
        } else {
            return null;
        }
    }
}

AdvancedSearchOptions.propTypes = {
    doSearch: PropTypes.func.isRequired,
    countries: PropTypes.array,
    loadCountries: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    searchAttr: PropTypes.func,
    removeAdvancedSearchData: PropTypes.func,
    match: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(AdvancedSearchOptions);

import {createTheme} from "@mui/material/styles";

export const checkboxFilterTheme = createTheme({
    components: {
        MuiCheckbox: {
            styleOverrides: {
                colorSecondary: {
                    color: '#7c7c7c',
                    '&$checked': {
                        color: '#ef4254',
                    },
                },
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '22px'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontSize: "14px"
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    marginBottom:'3rem',
                    '&:after':{
                        borderBottom:'2px solid #ef4254',
                        width: '400px',
                    },
                    '&:before': {
                        width: '400px',
                    },
                }
            }
        }
    }
})

export const datePickerStyles = createTheme({
    components : {
        MuiInputBase : {
            styleOverrides: {
                input : {
                    padding:"13px 14px!important"
                }
            }
        }
    }
})

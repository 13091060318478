import React from "react";
import Button from '@mui/material/Button';

import PolixisRoutes from "../../routes.js";

export default class NoServer extends React.Component {

    render() {
        return (
            <div className="login">
                <div className="container-fluid text-center">
                    <div className="row login-row">
                        <div className="col-xs-6 login-left">
                        </div>
                        <div className="col-xs-6 login-right">
                        </div>
                    </div>
                </div>
                <div className="container login-container">
                    <div className="row ">
                        <div className="col-xs-3 login-logo">
                            <img alt='loginLogo' src="/images/login_logo.png"/>
                        </div>
                        <div className="col-xs-5 login-body-text">
                            <h1 className="login-title"><span> CONTACT US </span></h1>
                            <table className="login-text login-table">
                                <tbody>
                                <tr>
                                    <td className="red-text" width="170px">Contact Sales Team</td>
                                    <td className="red-text">User Feedback</td>
                                </tr>
                                <tr>
                                    <td><i className="material-icons red-text">phone</i> &nbsp; + 4122 340 22 00</td>
                                    <td><i className="material-icons red-text">phone</i> &nbsp; + 4122 340 22 00</td>
                                </tr>
                                <tr>
                                    <td><i className="material-icons red-text">mail_outline</i> &nbsp; email us</td>
                                    <td><i className="material-icons red-text">mail_outline</i> &nbsp; email us</td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="red-text" style={ { marginTop: "20px" } }>Contact Support Team</div>
                            <div className="login-text">
                                <i className="material-icons red-text">phone</i>
                                &nbsp; + 41 (0) 22 340 22 00 &nbsp; Switzerland
                            </div>
                            <div className="login-text"><i
                                className="material-icons red-text">mail_outline</i> &nbsp; email us
                            </div>
                        </div>
                        <div className="col-xs-4 login-form" style={ { marginLeft: "3%", marginTop: '18%' } }>
                            <div className="red-text" style={ { marginTop: "20px", fontSize: '18px' } }>
                                <div>The server is under maintenance.</div>
                                Please come back later.
                            </div>

                            <Button
                                style={ {
                                    borderRadius: 25,
                                    marginTop: "40px",
                                    backgroundColor: "#ef4254",
                                    color: "#fff"
                                } }
                                onClick={ () => this.goToHome() }
                            >Back</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    goToHome () {
        this.props.history.push(PolixisRoutes.Website.Home)
    }
}

import appStore from "../redux/store.js";
import {API, doPost} from "../api.js";
import {INITIAL_STATE, ProgressState, catchError} from "../constants";
import Utils from "../utils";

import {
    AuthenticationActions,
    loginSuccess,
    loginFailed,
    firstPassword,
    newPasswordSuccess,
    newPasswordFailed,
    noServer
} from "./actions.js";
import ReportIndexed from "../application/report/components/ReportIndexed";

const authentication = ( state = INITIAL_STATE.authentication = {
    isAuthenticated: !!localStorage.getItem('bnli')
}, action) => {
    switch (action.type) {
        case AuthenticationActions.Login: {
            const queryData = {
                username: action.payload.requestData.username,
                password: action.payload.requestData.password,
                grant_type: action.payload.requestData.grant_type,
            };
            doPost(API.LOGIN, queryData, false).then(response => {
                if (response.status === 200) {
                    if (response.data.type.code === 0) {
                        const bnli = response.data.data.access_token;
                        const data = response.data.data;
                        localStorage.setItem('bnli', bnli);

                        if (data.firstLogin || data.passwordExpired) appStore.dispatch(firstPassword(true));
                        else {
                            appStore.dispatch(loginSuccess(response.data.data));
                            const type = response.data.data.type;
                            const refresh_bnli = response.data.data.refresh_token;

                            localStorage.setItem('type', type);
                            localStorage.setItem('refresh_bnli',refresh_bnli)
                            if (Utils.isNullOrUndefined(response.data.data.isOnlyNewsSearch)) {
                                sessionStorage.setItem('isOnlyNewsSearch', 'false');
                            } else {
                                sessionStorage.setItem('isOnlyNewsSearch', response.data.data.isOnlyNewsSearch);
                            }
                        }
                    } else {
                        appStore.dispatch(loginFailed(response.data.type.message));
                    }
                }
                else if((/^5[0-9][0-9]/g).match(response.status))  {
                    appStore.dispatch(noServer());
                } else {
                    console.log(catchError)
                }
            }).catch(() => {
                    appStore.dispatch(loginFailed(catchError));
            });
            return Object.assign({}, state, {
                loginFailed: null,
                firstLogin: null
            });
        }
        case AuthenticationActions.LoginSuccess: {
            return Object.assign({}, state, {
                user: {
                    bnli: action.payload.data.access_token,
                    type: action.payload.data.type,
                },
                accessToken:action.payload.data.access_token,
                isLoggedIn: true
            });
        }

        case AuthenticationActions.LoginFailed: {
            return Object.assign({}, state, {
                loginFailed: action.payload,
                firstLogin: false,
                isLoggedIn: false
            });
        }

        case AuthenticationActions.Logout: {
            ReportIndexed.dropDb();
            localStorage.clear();
            sessionStorage.clear();
            return Object.assign({}, state, {
                authentication: {
                    iLoggedIn: false
                },
                search: {
                    state: ProgressState.None,
                    resultCount: 0,
                    status: null,
                    results: [],
                    messages: [],
                },
                newsSearch: {
                    state: ProgressState.None,
                    status: "NONE",
                    resultCount: 0,
                    articles: {},
                },
            });
        }

        case AuthenticationActions.FirstPassword: {
            return Object.assign({}, state, {
                firstLogin: action.payload.data,
                loginFailed: null,
            });
        }

        case AuthenticationActions.NewPasswordSend: {
            doPost(API.CHANGE_PASSWORD, action.payload, true).then(response => {
                if (response.status === 200) {
                    if (response.data.type.code === 0) appStore.dispatch(newPasswordSuccess(action.payload));
                    else appStore.dispatch(newPasswordFailed(response.data.type.message));
                }
            }).catch((error) => {
                if (!Utils.isNullOrUndefined(error.response.data.reason)) {
                    appStore.dispatch(newPasswordFailed(error.response.data));
                }
            });
            return Object.assign({}, state, {
                loginFailed: null,
            });
        }

        case AuthenticationActions.NewPasswordSuccess: {
            return Object.assign({}, state, {
                firstLogin: false,
                isLoggedIn: true
            });
        }

        case AuthenticationActions.NewPasswordFailed: {
            return Object.assign({}, state, {
                loginFailed: null,
                changeError: action.payload.data
            });
        }

        case AuthenticationActions.NoServer: {
            return Object.assign({}, state, {
                loginFailed: null,
                isLoggedIn: false,
                noServer: true
            });
        }

        default:
            return state;
    }
};

export default authentication;

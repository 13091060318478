import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Checkbox, RadioGroup, Radio, Grid, Button, IconButton, FormGroup, Typography, FormControlLabel, CircularProgress } from '@mui/material';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Search, Clear } from '@mui/icons-material';
import Utils from "../../utils";
import { API, doGet, doPost, doPostPdf } from "../../api";
import { axiosCodes, INITIAL_STATE, NewsProgressState, ProgressState, sseState, clientType, newsAlerts, newsSearchType} from "../../constants";
import { searchNews, cancelNewsSearch, emptyNewsState, setNews } from './pressaStore/newsAction';
import CheckboxesTags from "./components/SelectCheckboxList";
import NewsSelect from "./components/NewsSelect";
import FilterSelect from "./components/FilterSelect";
import Article from "./components/Article";
import MediaScreening from "./components/MediaScreening";
import { SSE } from './Sse'
import { showAlertDialog } from "../../shared/dialog/actions";
import { loadCountries } from "../actions";

const theme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '2.5rem',
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                iconSizeMedium: {
                    margin: '0',
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    border: 'none',
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    color: 'black',
                    height: '100%',
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    fontSize: '1.5rem',
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                outlined: {
                    backgroundColor: 'white',
                    fontSize: '13px',
                }
            }
        },
        MuiNativeSelect: {
            styleOverrides: {
                root: {
                    height: 'auto'
                },
                select: {
                    padding: "4px 0 5px 5px !important"
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.54)',
                    "&.Mui-checked": {
                        "color": "#ef4254"
                    }
                },

            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.54)',
                    "&.Mui-checked": {
                        "color": "#ef4254"
                    }
                },

            }
        }
    }
});

const mapDispatchToProps = dispatch => {
    return {
        cancelNewsSearch: (params) => dispatch(cancelNewsSearch(params)),
        searchNews: (params) => dispatch(searchNews(params)),
        emptyNewsState: () => dispatch(emptyNewsState()),
        setNews: (params) => dispatch(setNews(params)),
        loadCountries: () => dispatch(loadCountries())
    };
};

const mapStateToProps = (state = INITIAL_STATE) => {
    return {
        searchState: state.newsSearch.state,
        pressaNew: state.newsSearch,
        countries: state.application.countries
    };
};

class NewsSearch2 extends React.Component {
    constructor(props) {
        super(props);
        props.reference(this);
        this.source = {};
        const articles = {};

        if (Utils.isNotNullOrUndefined(props.data) && Utils.isNotNullOrUndefined(props.data.results) && Utils.isNotNullOrUndefined(props.data.results.article)) {
            props.data.results.article.forEach((article) => {
                articles[article.id] = article;
            });
        }
        this.selectedItems = Utils.isNotNullOrUndefined(JSON.parse(sessionStorage.getItem("PinnedItemsNews"))) ? JSON.parse(sessionStorage.getItem("PinnedItemsNews")) : articles;

        this.periodData = ['All', 'Recent Date', 'Day', 'Week', 'Month', 'Year'];
        this.orderData = ['Relevance', 'Date'];

        this.sentimentData = [
            { label: 'Positive', value: 'positive' },
            { label: 'Negative', value: 'negative' },
            { label: 'Mixed', value: 'mixed' },
            { label: 'Neutral', value: 'neutral' },
        ];
        this.topicData = [
            { label: 'Sanction', value: 'sanction' },
            { label: 'Judicial', value: 'judicial' },
            { label: 'Fraud', value: 'fraud' },
            { label: 'Corruption', value: 'corruption' }
        ];

        let primaryMain = this.props.node?.title || '';
        let primary = this.props.data.rootNames.length > 0 ? [this.props.data.rootNames[0]] : [];

        let secondary = [];
        let source = ['polixis', 'google'];
        let newsOnly = false;
        let period = this.periodData[0];
        let order = this.orderData[0];
        let searchType = newsSearchType.EXACT;
        let cachedState = null;
        let filterBySentiment = 'all';
        let filterByTopic = "all";
        let language = '';
        let country = '';
        let exclude = '';
        let location = '';
        let showReadMore = false;
        let page = 0;
        let isCancelSearch = false;
        let pressa = props.pressaNew;
        let reportData = {};
        this.all = {}
        this.needStop = false;
        this.allRef = React.createRef();
        this.allRef.current = 0;
        let allPins = false;
        let allLanguages = {};
        if (Utils.isNotNullOrUndefined(props.cachedState)) {
            cachedState = props.cachedState;
            pressa = cachedState.pressa || pressa;
            primary = cachedState.primary || primary;
            primaryMain = cachedState.primaryMain || primaryMain;
            secondary = cachedState.secondary || [];
            source = cachedState.source || ['polixis', 'google'];
            newsOnly = cachedState.newsOnly || false;
            period = cachedState.period || this.periodData[0];
            order = cachedState.order || this.orderData[0];
            searchType = cachedState.searchType || newsSearchType.FUZZY;
            filterBySentiment = cachedState.filterBySentiment || 'all';
            filterByTopic = cachedState.filterByTopic || 'all';
            language = cachedState.language || '';
            exclude = cachedState.exclude || '';
            showReadMore = cachedState.showReadMore || false;
            page = cachedState.page || 0;
            country = cachedState.country || '';
            location = cachedState.location || '';
            isCancelSearch = cachedState.isCancelSearch || false;
            reportData = cachedState.reportData || reportData;
            allPins = cachedState.allPins || allPins
            allLanguages = cachedState.allLanguages || allLanguages
        }

        this.state = {
            isMaximize: props.isMaximize,
            searchCancel: props.pressaNew.searchCancel,
            isCancelSearch,
            pressa,
            reportData,
            showAdvancedOptions: true,
            allLanguages,
            allCountries: [],
            searchType,
            primary,
            primaryMain,
            secondary,
            country,
            language,
            location,
            period,
            source,
            newsOnly,
            order,
            exclude,
            showReadMore,
            filterBySentiment,
            filterByTopic,
            page,
            downloading: false,
            showAlert: false,
            showSourceAlert: false,
            showMessages: true,
            doSearch: false,
            showMore: false,
            showMoreFuzzy: false,
            samePageWithNoData: false,
            streamState: NewsProgressState.NONE,
            allPins,
            typeFromAdvanced: false
        };
    }

    render() {
        const body = document.body;
        if (this.state.isMaximize) {
            body.classList.add("bodyOverflow");
        } else {
            body.classList.remove("bodyOverflow");
        }
        const pdfDisabled = this.state.downloading || Object.keys(this.state.pressa.articles).length === 0;

        return (
            <Grid container item lg={12} className="list-component news-search" justifyContent={'center'}>
                {this.renderMaximizeButton()}
                {this.state.isMaximize &&
                    <Grid container item xs={12} justifyContent={'center'} className='searchPadding fixedAdvanced'>
                        {this.renderSearchOptions()}
                        {this.state.showAdvancedOptions && this.renderAdvancedOptions()}
                        {this.state.showAlert && this.renderAlert(newsAlerts.emptyFields)}

                        {this.state.showSourceAlert && this.renderAlert(newsAlerts.emptySource)}

                        {!this.state.showAlert && this.state.doSearch && this.renderLoading()}

                        {(Object.keys(this.state.pressa.articles).length === 0 && this.state.pressa.state !== ProgressState.Loading
                            && (this.state.isCancelSearch || this.state.pressa.state === ProgressState.Success)) &&
                            this.renderNoData()
                        }
                        {(Object.keys(this.state.pressa.articles).length > 0 && (this.state.isCancelSearch || this.state.pressa.state !== ProgressState.Loading)) && !this.state.doSearch &&

                            <Grid container item lg={12} justifyContent={'space-between'}
                                className='pinsSection'
                            >
                                <Button
                                    className='pinsButtonBackground'
                                    variant="contained"
                                    size={'large'}
                                    onClick={() => {
                                        this.setState({ allPins: !this.state.allPins }, () => {
                                            this.toggleAllPined();
                                        })
                                    }}>
                                    <Typography className='colorLight' variant={'h6'}>{this.state.allPins ? 'Unpin all' : 'Pin up all'}</Typography>
                                </Button>

                                <ThemeProvider theme={theme}>
                                    {this.renderFilters()}
                                </ThemeProvider>

                                <Button
                                    className='pinsButtonBackground'
                                    variant="contained"
                                    size={'large'}
                                    disabled={pdfDisabled}
                                    onClick={() => this.genPdf()}>
                                    <Typography className='colorLight' variant={'h6'}>{this.state.downloading ? "Generating" : "Export PDF"}</Typography>
                                </Button>
                            </Grid>
                        }
                    </Grid>
                }

                <Grid justifyContent={'center'} container item xs={12} className='articleComponent'>
                    {this.renderContent()}
                </Grid>
            </Grid>
        );
    }

    renderMaximizeButton() {
        const fromReport = sessionStorage.getItem('newsFromReport');
        const ready = !fromReport ? false : !this.props.nodeSearch
        if (this.props.isMaximize || this.props.print !== "" || this.props.status !== "DONE") {
            return null;
        } else {
            return (
                <div className={Utils.isNotNullOrUndefined(this.selectedItems) && Object.keys(this.selectedItems).length > 0 ? "col-xs-12 pull-right text-right maximizeButton" : ""}>
                    <MediaScreening handleClick={this.props.toggleMaximize} isDisable={!this.props.isDisable && !ready} />
                </div>
            );
        }
    }

    renderSearchOptions() {
        const related = this.props.data.relatedNames;
        return (
            <Grid container item lg={12} className='searchOptions'>
                <ThemeProvider theme={theme}>
                    <Grid item lg={5} md={5} sm={5} className='fullHeight'>
                        <CheckboxesTags data={this.props.data.rootNames}
                            label={this.state.primary.length > 0 ? '' : "Type/Select one or more names to search"}
                            setValue={(val) => this.setState({ primary: val, showMessages: false })}
                            colorMode='light'
                            value={this.state.primary}
                            selectFirst={true}
                            enable={this.state.primary.length > 0}
                        />
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} className='fullHeight'>
                        <CheckboxesTags data={related}
                            label={this.state.secondary.length > 0 ? '' : "Type/Select one or more related search names "}
                            setValue={(val) => this.setState({ secondary: val })}
                            selectFirst={related.length === 1}
                            value={this.state.secondary}
                            enable={this.state.secondary.length > 0}
                        />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} className='fullHeight'>
                        <Button onClick={() => this.doNewsSearch(false)}
                            variant="contained"
                            color="primary"
                            startIcon={<Search className='midFont' />}
                            className='searchButton fullHeight colorLight'
                        />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} className='fullHeight'>
                        <Button
                            variant="contained"
                            color="primary"
                            title={'Filter'}
                            onClick={() => this.setState({ showAdvancedOptions: !this.state.showAdvancedOptions })}
                            startIcon={<img alt='filterNews' src="/images/filterNews.png" className='filterHeight' />}
                            className='filterButton fullHeight colorLight'
                        />
                    </Grid>
                </ThemeProvider>
            </Grid>
        )
    }

    renderLoading() {
        return (
            <div className='loading'>
                <div className='stop'>STOP</div>
                <CircularProgress size={50}
                    className='loadColor'
                    thickness={5}
                    onClick={() => this.cancelSearch()} />
                <div className='loadColor analysis'>NEWS ANALYSIS</div>
            </div>
        )
    }

    handleSearchTypeChange(type) {
        this.setState({ searchType: type, typeFromAdvanced: type === newsSearchType.FUZZY})
    }

    renderAdvancedOptions() {
        let periodTime = [];
        let languages = [];
        let countries = [<option key={'countriesDefault'} value=''>Default</option>];
        let countryList = Utils.isNotNullOrUndefined(this.props.data.countryDetails) && this.props.data.countryDetails.length > 0 ? this.props.data.countryDetails : this.state.allCountries
        this.periodData.forEach((period, index) => {
            periodTime.push(<option key={index + period} value={period}>{period}</option>);
        });

        languages.push(<option key={'languagesDefault'} value=''>Default</option>)

        if (Utils.isNotNullOrUndefined(this.state.allLanguages)) {
            Object.keys(this.state.allLanguages).sort().forEach((lang, index) => {
                languages.push(<option key={index + lang} value={this.state.allLanguages[lang]}>{lang}</option>);
            })
        }

        if (countryList?.length > 0){
            countryList.forEach((country, index) => {
                countries.push(<option key={index + country.countryCode} value={country.countryCode}>{country.countryName}</option>);
            })
        }

        return (
            <ThemeProvider theme={theme}>
                <Grid container item lg={12} className='advancedOptions'>
                    <Grid container item lg={12} justifyContent='flex-start' alignItems='center'>
                        <Grid container className='searchTypeSection'>
                            <RadioGroup row name="searchType" value={this.state.searchType}
                                onChange={(event, searchType) => this.handleSearchTypeChange(searchType)}>
                                <FormControlLabel value="fuzzy"
                                    label={<Typography variant={'h5'}>{newsSearchType.FUZZY.charAt(0).toUpperCase() +newsSearchType.FUZZY.substring(1)}</Typography>}
                                    control={<Radio />}
                                    className='searchType'
                                />
                                <FormControlLabel value='exact'
                                    label={<Typography variant={'h5'}>{newsSearchType.EXACT.charAt(0).toUpperCase() +newsSearchType.EXACT.substring(1)}</Typography>}
                                    className='searchType'
                                    control={<Radio />}
                                />
                            </RadioGroup>
                        </Grid>
                        <IconButton aria-label="close" onClick={() => this.setState({ showAdvancedOptions: false })} className='showAction'>
                            <Clear />
                        </IconButton>
                    </Grid>

                    <Grid container item lg={12} wrap={'nowrap'} alignItems='flex-start' className='filterSection'>
                        <Grid item container className='sectionItem'>
                            <Grid item container lg={12} wrap={'nowrap'}>
                                <NewsSelect setPropsValue={(val) => this.setState({ location: val })} label="Search Priority Country"
                                    options={countries}
                                    oldValue={this.state.location}
                                />
                            </Grid>
                            <Grid item container lg={12} className='itemMg'>
                                <NewsSelect key={'Default'} setPropsValue={(val) => this.setState({ language: val })}
                                    label="Language"
                                    oldValue={this.state.language}
                                    options={languages} />
                            </Grid>
                        </Grid>

                        <Grid container item className='sectionItem itemBorder'>
                            <Grid container item lg={12} alignItems={'center'}>
                                <NewsSelect setPropsValue={(val) => this.handlePeriodChange(val)} label="Time Period"
                                    oldValue={this.state.period}
                                    options={periodTime} />
                            </Grid>
                            <Grid container item lg={12} alignItems={'center'} className='itemMg'>
                                <Grid item lg={4} className='itemPadding'>
                                    <label><h6>Exclude</h6></label>
                                </Grid>
                                <Grid item lg={8}>
                                    <input id="exclude"
                                        type="text"
                                        placeholder="Exclude term"
                                        value={this.state.exclude}
                                        className="form-control excludeInput"
                                        onChange={(event) => this.setState({ exclude: event.target.value })}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid container item lg={6} className='sourceSection'
                        >
                            <Grid container className='sectionLabel'>
                                <Typography variant={'h6'}>Source</Typography>
                            </Grid>
                            <Grid container>
                                <FormGroup>
                                    <FormControlLabel className='sourceItems'
                                        label={<Grid><img src="/images/searchEngine/polixis.png"
                                            alt="polixisLogo" /></Grid>}
                                        control={<Checkbox data-testid='sourcePolixisCheckbox'
                                            className={this.state.source.indexOf('polixis') !== -1 ? 'loadColor itemCheck' : 'itemCheck'}
                                            checked={this.state.source.indexOf('polixis') !== -1}
                                            onChange={(e) => this.handleSourceChange(e.target.checked, 'polixis')} />
                                        } />
                                    <FormControlLabel className='sourceItems'
                                        label={<Grid><img src="/images/searchEngine/google.png"
                                            alt="polixisLogo" /></Grid>}
                                        control={<Checkbox data-testid='sourceGoogleCheckbox'
                                            className={this.state.source.indexOf('polixis') !== -1 ? 'loadColor itemCheck' : 'itemCheck'}
                                            checked={this.state.source.indexOf('google') !== -1}
                                            onChange={(e) => this.handleSourceChange(e.target.checked, 'google')} />
                                        } />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Button size={'large'}
                        onClick={() => this.doNewsSearch(false)}
                        className='searchAction'
                    >Search</Button>
                </Grid>
            </ThemeProvider>
        )
    }

    renderContent() {
        const dataState = Object.assign({}, this.state.pressa.articles);
        const dataStorage = Object.assign({}, this.selectedItems);
        const newsSearchData = this.state.isMaximize ? dataState : dataStorage;
        const line = 10;
        const articles = Object.values(newsSearchData).map((article, index) => {
            let includeSentiment = false;
            let includeTopic = false;
            let sentiment = '';
            let topic = '';
            let borderColor = '#eee';
            article.analytics.forEach((i) => {
                if (i.title.toLowerCase() === 'topic') {
                    includeTopic = true;
                    if (i.values.length > 1) {
                        topic = i.values.reduce((name, item) => {
                            return `${name.title} ,` + item.title
                        })
                    } else {
                        topic = i.values[0].title.toLowerCase();

                    }
                } else if (i.title && i.title.toLowerCase() === 'sentiment') {
                    borderColor = i.values[0].color.toLowerCase();
                    sentiment = i.values[0].title.toLowerCase()
                    includeSentiment = true;
                }
            });
            if (!includeTopic) {
                article.analytics.push({ title: 'Topic', values: [{ title: 'Not detected', color: 'grey' }] })
            }

            if (!includeSentiment) {
                borderColor = '#eee'
                article.analytics.push({ title: 'Sentiment', values: [{ title: 'Not detected', color: 'grey' }] })
            }

            let a;
            let b;
            switch (this.state.filterBySentiment) {
                case "":
                    a = true;
                    break;
                case 'positive':
                    a = !!(Utils.isNotNullOrUndefined(sentiment) && sentiment.toLowerCase() === 'positive');
                    break;
                case 'neutral':
                    a = !!(Utils.isNotNullOrUndefined(sentiment) && sentiment.toLowerCase() === 'neutral');
                    break;
                case 'negative':
                    a = !!(Utils.isNotNullOrUndefined(sentiment) && sentiment.toLowerCase() === 'negative');
                    break;
                case 'mixed':
                    a = !!(Utils.isNotNullOrUndefined(sentiment) && sentiment.toLowerCase() === 'mixed');
                    break;
                default:
                    a = true;
                    break;
            }

            switch (this.state.filterByTopic) {
                case "":
                    b = true;
                    break;
                case 'sanction':
                    b = topic.includes('sanction');
                    break;
                case 'judicial':
                    b = topic.includes('judicial');
                    break;
                case 'fraud':
                    b = topic.includes('fraud');
                    break;
                case 'corruption':
                    b = topic.includes('corruption');
                    break;
                default:
                    b = true;
                    break;
            }

            if (!this.props.isMaximize) {
                a = true;
                b = true;
            }
            const hasClassLine = (index + 1) % line === 0;
            const classLine = hasClassLine ? `${(index + 1) / line}` : '';
            if (a && b) {
                article.show = true;
                return (
                    <Article
                        key={`article_${article.id}`}
                        data={article}
                        isMaximize={this.state.isMaximize}
                        print={this.props.print}
                        classLine={classLine}
                        borderColor={borderColor}
                        togglePinned={(id, withContent) => this.togglePinned(id, withContent)}
                    />
                )
            } else {
                article.show = false;
                return null;
            }
        })
        return (
            <div className={this.state.isMaximize ? this.state.showAdvancedOptions ? "row right-entity newsSearch-heightCalc articlesSectionMax" :
                "row right-entity newsSearch-heightCalc articlesMaxMarginWithAdvanced"
                : "row articlesSection"}
            >
                <div style={{ marginTop: 15 }} />
                {articles}
                {(this.state.showMore && this.state.isMaximize) ?
                    <div className="text-center read-more pressa-more"
                        onClick={() => this.doNewsSearch(true)}>Load More<br />
                        <i className="material-icons"> expand_more</i>
                    </div> : null
                }
                {(this.state.showMoreFuzzy && this.state.isMaximize) ?
                    <div className="text-center read-more pressa-more"
                        onClick={() => this.setState({ searchType: newsSearchType.FUZZY }, () => this.doNewsSearch(true, true))}>Click here to get more press media by fuzzy search.<br />
                        <i className="material-icons"> expand_more</i>
                    </div> : null
                }
            </div>
        )
    }

    renderFilters() {
        return (
            <Grid>
                <div className='filters'>
                    <Grid>
                        <FilterSelect label="Filter by sentiment"
                            options={this.sentimentData}
                            valueDef={this.state.filterBySentiment === 'all' ? '' : this.state.filterBySentiment}
                            setPropsValue={(val) => this.setState({
                                filterBySentiment: val
                            })} />
                    </Grid>
                    <Grid>
                        <FilterSelect label="Filter by topic"
                            options={this.topicData}
                            valueDef={this.state.filterByTopic === 'all' ? '' : this.state.filterByTopic}
                            setPropsValue={(val) => this.setState({
                                filterByTopic: val
                            })} />
                    </Grid>
                </div>
            </Grid>
        )
    }

    renderAlert(alert) {
        return (
            <div className='alert'>{alert}</div>
        );
    }

    renderNoData() {
        return (
            <div className='alert'>
                <p className={this.state.searchType === newsSearchType.EXACT ? 'fuzzyColor' : ''}>{newsAlerts.noResult}</p>
                {this.state.searchType === newsSearchType.EXACT &&
                    <p>{newsAlerts.noResultExact}</p>}
            </div>
        );
    }

    getLanguages() {
        doGet(API.PRESSA_LANGUAGE, null, true).then(response => {
            if (Utils.isNullOrUndefined(response.data.error)) {
                this.setState({ allLanguages: response.data });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            pressa: nextProps.pressaNew,
            doSearch: nextProps.pressaNew.state === ProgressState.Loading,
            allCountries: nextProps.countries
        })
        const articles = Object.keys(nextProps.pressaNew.articles);
        const doAutoFuzzy = nextProps.pressaNew.state === ProgressState.Success && articles.length === 0 && this.state.searchType === newsSearchType.EXACT && !this.state.isCancelSearch;
        if (doAutoFuzzy) {
            this.setState({ searchType: newsSearchType.FUZZY }, () => {
                setTimeout(() => this.doNewsSearch(), 0)
            })
        }

        if (articles.length > 0 && (nextProps.pressaNew.state === ProgressState.Stopped || nextProps.pressaNew.state === ProgressState.Success)) {
            this.setState({ showMore: true });
        }
    }

    componentDidMount() {
        const exist = Utils.isNotNullOrUndefined(JSON.parse(sessionStorage.getItem("PinnedItemsNews"))) ? JSON.parse(sessionStorage.getItem("PinnedItemsNews")) : {}
        const existAppCountries = this.props.countries && this.props.countries.length > 0;
        const existNewsCountries = this.props.data && this.props.data.countryDetails && this.props.data.countryDetails.length > 0
        if (Object.keys(this.props.pressaNew.articles) <= 0) {
            this.props.setNews({ selected: this.selectedItems, pinned: exist })
        }

        if (Object.keys(this.state.allLanguages).length === 0) {
            this.getLanguages();
        }

        if (!existNewsCountries && !existAppCountries) {
            this.props.loadCountries();
        }

        if (this.state.isMaximize && Object.keys(this.props.pressaNew.articles).length === 0 && this.props.data.rootNames.length > 0) {
            this.doNewsSearch(false);
        }
    }

    componentWillUnmount() {
        if (this.state.isMaximize) {
            this.cancelSearch()
        }
    }

    handlePeriodChange(value) {
        if (value.toLowerCase() === 'recent date') {
            this.setState({ order: 'date', period: this.periodData[0].toLowerCase() })
        } else {
            this.setState({ period: value, order: this.orderData[0].toLowerCase() })
        }
    }

    handleSourceChange(value, title) {
        let source = this.state.source;
        if (!value && source.indexOf(title) !== -1) {
            source.splice(source.indexOf(title), 1)
        } else {
            source.push(title)
        }
        this.setState({ source })
    }

    async doNewsSearch(fromReadMore = false, resetPage = false, defaultPage = null) {
        let page = this.state.page;
        if (this.state.source.length === 0) {
            this.setState({ showSourceAlert: true });
            return;
        }
        if (this.state.primary.length === 0) {
            this.setState({ showAlert: true, isCancelSearch: false });
            this.allRef.current = 0;
            this.all = {};
            this.needStop = false;
        } else {
            if (!fromReadMore) {
                page = 0;
                await this.props.emptyNewsState();
                this.allRef.current = 0;
                this.all = {}
                this.needStop = false;

                this.setState({ showMore: false, showMoreFuzzy: false, doSearch: true, showAlert: false, isCancelSearch: false, allPins: false, showSourceAlert: false })
            } else {
                page = resetPage ? 0 : defaultPage ? defaultPage : page + 1
                this.needStop = false;
                this.setState({ showMore: false, showMoreFuzzy: false, doSearch: true, showAlert: false, isCancelSearch: false, allPins: false, showSourceAlert: false })
            }
            this.resetFilter();

            const primary = this.state.primary.length > 0 ? this.state.primary.join(",") : this.props.data.main[0];
            const secondary = this.state.secondary.length > 0 ? this.state.secondary.join(",") : '';
            const raw = {
                "primary": {
                    "value": primary
                },
                "secondary": {
                    "value": secondary
                },
                "primaryMain": this.state.primaryMain,
                "order": this.state.order.toLowerCase(),
                "period": this.state.period.toLowerCase(),
                "source": this.state.source.length > 1 ? this.state.source.join(',') : this.state.source.join(''),
                "newsOnly": this.state.newsOnly,
                "searchType": this.state.typeFromAdvanced ? newsSearchType.EXACT_FUZZY : this.state.searchType,
                "page": page,
                "country": [],
                "location": this.state.location,
                "sentiment": "all",
                "language": this.state.language,
                "exclude": this.state.exclude
            };

            await this.getSseData(raw, fromReadMore, page);

            this.setState({ reportData: raw, page });
        }
    }

    getSseData(raw, fromReadMore, page) {
        const headers = {
            'Content-Type': "application/json;charset=UTF-8",
            'Authorization': `Bearer ${localStorage.getItem('bnli')}`
        };

        this.source = new SSE(API.PRESS_SEARCH, {
            headers,
            payload: JSON.stringify(raw),
            method: 'POST',
            withCredentials: true
        });
        try {
            this.source.stream();
            this.source.onmessage = async (e) => {
                this.handleSse(e);
            }

            this.source.onreadystatechange = async (e) => {
                this.setState({ streamState: e.readyState }, async () => {
                    if (e.readyState === sseState.DONE) {
                        await this.handleSseStateChange(e, fromReadMore, page)
                    }
                })
            }

            this.source.onerror = async () => {
                this.cancelSearch(ProgressState.None)
            }

        } catch {
            this.setState({ showAlert: true });
        }
    }

    handleSse(e) {
        const { data } = e;
        if (data && this.state.stream !== ProgressState.Success && !this.needStop) {
            const newParsed = JSON.parse(data);
            const obj = { ...this.all, [newParsed.id]: newParsed };
            const addedCount = Object.keys(this.all).length === Object.keys(obj).length;
            this.needStop = false;
            if (!addedCount) {
                this.setState({ samePageWithNoData: false })

                if ((this.allRef.current + 1) % 10 === 0) {
                    this.allRef.current = this.allRef.current + 1;
                    this.all = { ...this.all, [newParsed.id]: newParsed }
                    this.needStop = true;
                    this.props.searchNews({ data: e.data, state: ProgressState.Stopped });
                    this.cancelSearch();

                } else {
                    this.allRef.current = this.allRef.current + 1;
                    this.all = { ...this.all, [newParsed.id]: newParsed }
                    this.needStop = false;
                    this.props.searchNews({ data: e.data, state: ProgressState.Loading });
                }
            } else {
                this.setState({ samePageWithNoData: true })
            }
        }
    }

    async handleSseStateChange(e, fromReadMore, page) {
        const { error } = e;
        if (error && Object.keys(error).length > 0) {
            if (error.type) {
                if (error.type.code === axiosCodes.TOKEN_EXPIRED) {
                    this.cancelSearch(ProgressState.None, true);
                    try {
                        const data = {
                            refresh_token: localStorage.getItem('refresh_bnli'),
                            grant_type: 'refresh_token'
                        }
                        const refreshRequest = await doPost(API.LOGIN, data, false);
                        const bnli = refreshRequest.data.data.access_token;
                        const refresh_bnli = refreshRequest.data.data.refresh_token;
                        localStorage.setItem('bnli', bnli);
                        localStorage.setItem('refresh_bnli', refresh_bnli);
                        await this.doNewsSearch(fromReadMore, false, page);
                    } catch {
                        return;
                    }
                } else if (error.type.code === axiosCodes.REFRESH_EXPIRED || error.type.code === axiosCodes.TOKEN_INVALID) {
                    showAlertDialog({ title: "Your session expired", expired: true });
                    sessionStorage.clear();
                    localStorage.clear();
                }
            }
            if (error.code === axiosCodes.EMPTY_ARTICLE) {
                if (this.state.searchType === newsSearchType.EXACT && fromReadMore) {
                    this.cancelSearch(ProgressState.None);
                    this.setState({ showMoreFuzzy: true })
                } else if (this.state.searchType === newsSearchType.FUZZY) {
                    this.cancelSearch(fromReadMore ? ProgressState.None : ProgressState.Success);
                    this.setState({ showMoreFuzzy: false })
                } else {
                    this.cancelSearch(ProgressState.Success);
                }
            }
        } else {
            if (!this.needStop) {
                if ((Object.keys(this.state.pressa.articles).length % 10 !== 0) && !this.state.isCancelSearch) {
                    await this.cancelSearch(ProgressState.None)
                    await this.doNewsSearch(true);
                } else {
                    if (this.state.samePageWithNoData || Object.keys(this.state.pressa.articles).length === Object.keys(this.all).length) {
                        await this.cancelSearch(ProgressState.None)
                        await this.doNewsSearch(true);
                    } else {
                        this.cancelSearch(ProgressState.Success);
                    }
                }
            }
        }
    }

    resetFilter() {
        this.setState({
            filterBySentiment: 'all',
            filterByTopic: 'all'
        })
    }

    toggleAllPined() {
        const fromAnotherProfile = {...JSON.parse(sessionStorage.getItem("PinnedItemsNews"))};
        const items = { ...this.state.pressa.articles };
        Object.keys(items).forEach((index) => {
            if (items[index].show) {
                if (this.state.allPins) {
                    this.selectedItems[index] = items[index];
                } else {
                    delete fromAnotherProfile[index]
                }

                items[index].pinnedWithoutContent = this.state.allPins;
                items[index].pinnedWithContent = false;
            }
        })

        let newStore = Object.assign(this.state.pressa, { articles: items });
        this.setState({ pressa: newStore });
        if (!this.state.allPins) {
            this.selectedItems = fromAnotherProfile;
        }

        sessionStorage.setItem("PinnedItemsNews", JSON.stringify(this.selectedItems));
    }

    checkForSelected(newStore) {
        const fromState = Object.keys(newStore.articles).length;
        if (fromState > 0) {
            const all = {...newStore.articles}
            const selected = {...JSON.parse(sessionStorage.getItem("PinnedItemsNews"))};
            Object.keys(all).forEach((id) => {
                if ((all[id].pinnedWithoutContent || all[id].pinnedWithContent)  && all[id].show) {
                    selected[id] = all[id]
                } else {
                    delete selected[id]
                }
            });
            this.selectedItems = selected;
            sessionStorage.setItem("PinnedItemsNews", JSON.stringify(selected));
            this.setState({pressa: newStore})
            if (fromState.length !== Object.keys(this.selectedItems).length && this.state.allPins) this.setState({ allPins: false })
        }
    }

    togglePinned(index, withContent) {
        const fromStorage = {...JSON.parse(sessionStorage.getItem("PinnedItemsNews"))};

        const all = {...this.state.pressa.articles, ...fromStorage}
        const selected = all[index];
        selected.pinnedWithContent = withContent && !selected.pinnedWithContent
        selected.pinnedWithoutContent = !withContent && !selected.pinnedWithoutContent;

        let newStore = Object.assign(this.state.pressa, {articles:{...this.state.pressa.articles, [index]: selected}});
        this.checkForSelected(newStore);
    }

    getSearchParams() {
        const { reportData } = this.state;
        const primary = reportData.primary && reportData.primary.value.length !== 0 ? reportData.primary.value : this.state.primary.length > 0 ? this.state.primary.join(',') : this.state.primary;
        const secondary = reportData.secondary && reportData.secondary.value.length !== 0 ? reportData.secondary.value : this.state.secondary.length > 0 ? this.state.secondary.join(',') : this.state.secondary;
        const primaryVal = primary.length === 0 ? '<b style="margin-left: 10px">Main Names Spellings:  </b> Default' : '<b style="margin-left: 10px">Main Names Spellings:  </b>' + primary;
        const relatedVal = secondary.length === 0 ? '<b style="margin-left: 10px">Related To:  </b> Default' : '<b style="margin-left: 10px">Related To:  </b>' + secondary;
        const countryVal = reportData.location && reportData.location.length > 0 ? '<b style="margin-left: 10px">Search Priority Country:  </b>' + reportData.location :
            this.state.location.length > 0 ? this.state.location : '';
        const excludeVal = reportData.exclude && reportData.exclude !== '' ? '<b style="margin-left: 10px">Exclude:  </b>' + reportData.exclude :
            this.state.exclude || '';
        const languageVal = reportData.language && reportData.language.length > 0 ? '<b style="margin-left: 10px">Language:  </b>' + reportData.language : this.state.language || '';
        const newsOnlyVal = reportData.newsOnly || this.state.newsOnly ? '<b style="margin-left: 10px">Searched in news articles</b>' : '';
        const periodVal = reportData.order === 'date' ? '<b style="margin-left: 10px">Period:  </b>' + this.periodData[1] : '<b style="margin-left: 10px">Period:  </b>' + (reportData.period || this.state.period)
        const sourceVal = reportData.source || this.state.source.length > 1 ? this.state.source.join(',') : this.state.source.join('');
        return '<div style="width: 100%; text-align: center; font-size: 16px; margin-top: 10px; margin-bottom: 20px">' + primaryVal + relatedVal +
            '<b style="margin-left: 10px">Source:  </b>' + sourceVal + '<b style="margin-left: 10px">Search type:  </b>' + (reportData.searchType || this.state.searchType) + countryVal +
            periodVal + excludeVal + languageVal + newsOnlyVal + '</div>';
    }

    generatePdf(html, entityIds) {
        doPostPdf(API.GENERATE_PDF, { html, entityIds, requestedDate: Date.now(), requestedFrom: clientType }, true).then(response => {
            const { data, headers } = response;
            this.setState({ downloading: true })
            this.downloadFile(data, headers['content-type'])
        }).catch(() => {
            this.setState({ downloading: false });
        });
    }

    genPdf() {
        const searchParams = this.getSearchParams();
        const head = document.getElementsByTagName('head')[0].innerHTML;
        const entityIds = sessionStorage.getItem('selectedIds') || "";
        setTimeout(() => {
            let articleClass = Object.keys(this.selectedItems).length > 0 ? 'news-pinned-article' : 'news-article';
            let theResult = Utils.newsSearchPdfContent(articleClass);

            this.setState({ downloading: true });
            const pdfContent = '<!DOCTYPE html><html lang="en"><meta charset="utf-8"> <head><base href=' + process.env.REACT_APP_BASE + ' />' +
                '<meta http-equiv="conent-type" content="text/html;carset=UTF-8"><meta name="viewport" content="initial-scale=1, width=1200">' +
                head + '<title></title></head><body style="margin: 0; padding: 0;">' +
                '<div class="row"><img src="/images/no_result_banner.png" style="width: 100%" alt=""/></div>' +
                '<div  style="width: 100%; text-align: center; font-size: 20px; color: #ef4254; margin-top: 30px "/> SEARCHED ARTICLES WITH THE FOLLOWING PARAMETERS</div>' +
                searchParams + theResult + '</body></html>';

            this.generatePdf(pdfContent, entityIds);
        }, 100);
    }

    downloadFile(data, contentType) {
        const fileName = 'mediaScreeningReport.pdf';
        Utils.downloadFileAndClose(fileName, data, contentType);
        this.setState({ downloading: false })
    }

    cancelSearch(state = ProgressState.Stopped, cancel = false) {
        this.props.cancelNewsSearch({ state });
        const cancelled = state === ProgressState.Stopped;
        this.needStop = true;
        this.setState({ isCancelSearch: cancelled, doSearch: cancel }, () => {
            if (cancelled) {
                this.source.close();
            }
        });
    }

    getContentStateNewSearch() {
        const pressa = this.state.pressa;
        const reportData = this.state.reportData;
        const primary = this.state.primary;
        const primaryMain = this.state.primaryMain;
        const secondary = this.state.secondary;
        const source = this.state.source;
        const newsOnly = this.state.newsOnly;
        const order = this.state.order;
        const period = this.state.period;
        const filterBySentiment = this.state.filterBySentiment;
        const filterByTopic = this.state.filterByTopic;
        const sessionId = this.state.sessionId;
        const language = this.state.language;
        const location = this.state.location;
        const showReadMore = this.state.showReadMore;
        const page = this.state.page;
        const exclude = this.state.exclude;
        const isCancelSearch = this.state.isCancelSearch;
        const searchType = this.state.searchType;
        const allPins = this.state.allPins;
        const allLanguages = this.state.allLanguages
        return {
            pressa,
            reportData,
            primary,
            primaryMain,
            secondary,
            source,
            newsOnly,
            order,
            period,
            filterBySentiment,
            filterByTopic,
            sessionId,
            language,
            location,
            showReadMore,
            page,
            exclude,
            isCancelSearch,
            searchType,
            allPins,
            allLanguages
        };
    }
}

NewsSearch2.defaultProps = {
    isMaximize: false,
};
NewsSearch2.propTypes = {
    data: PropTypes.object,
    node: PropTypes.object,
    pressaNew: PropTypes.any,
    isMaximize: PropTypes.bool,
    cachedState: PropTypes.object,
    print: PropTypes.string,
    title: PropTypes.string,
    toggleMaximize: PropTypes.func.isRequired,
    changeCanvasToImage: PropTypes.number,
    cancelSearch: PropTypes.func,
    searchCancel: PropTypes.string,
    reference: PropTypes.func,
    doNewsSearch: PropTypes.func,
    emptyState: PropTypes.func,
    bnli: PropTypes.string,
    status: PropTypes.string,
    isDisable: PropTypes.bool,

};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(NewsSearch2);

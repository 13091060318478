import React from "react";
import PropTypes from "prop-types";

import {Icon, Checkbox, FormControlLabel, FormGroup} from '@mui/material';

export default class TreeToolbar extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            checked: props.checked,
        };
    }

    render() {
        const labelPin = "Show Detail";
        return (
            <div className="inline " style={ {} }>
                <FormGroup>
                    <FormControlLabel
                        title={ labelPin }
                        className="IncludeContent-Checkbox"
                        style={ { margin: "-7px -7px 0 0 !important" } }
                        control={
                            <Checkbox
                                checkedIcon={
                                    <Icon style={ { color: '#ef4254', fontSize: "24px" } } className="material-icons">
                                        info
                                    </Icon>
                                }
                                icon={
                                    <Icon style={ { color: '#8f9093', fontSize: "24px" } } className="material-icons">
                                        info
                                    </Icon>
                                }
                                checked={ this.state.checked }
                                style={ { zIndex: "100px", marginRight: "-14px", marginTop: "-8px" } }
                                onChange={ () => this.props.showNodeContent() }
                            />
                        }
                        label=""
                    />
                </FormGroup>
                <Icon
                    className="material-icons pointer"
                    style={ { fontSize: '25px', float: 'right', color: "#ef4254" } }
                    onClick={ () => this.props.searchNode() }
                    title="Search Entity">search</Icon>
            </div>
        )
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( this.props.checked !== nextProps.checked ) {
            this.setState( { checked: nextProps.checked } )
        }
    }

}

TreeToolbar.propTypes = {
    showNodeContent: PropTypes.func,
    searchNode: PropTypes.func,
    checked: PropTypes.bool,
};

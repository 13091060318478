import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';

import Utils from "../../utils";
import Footer from "./Footer.jsx";
import Header from "./Header.jsx";
import SideBar from "./SideBar.jsx";
import Section from "./Section.jsx";
import Component from "./Component.jsx";
import {API, doPostPdf } from '../../api';
import SideBarItem from "../../models/SideBarItem";
import CoverPage from "./components/CoverPage.jsx";
import { showAlertDialog } from "../../shared/dialog/actions";
import { INITIAL_STATE, ProgressState, ProgressStatus, alertDialogState, clientType, postActions } from "../../constants";
import { showConfirmDialog } from "../../shared/dialog/actions";
import SelectComponetsToPrint from "../search/SelectComponentsToPrint.jsx";
import { doGeneration, canvasToImage, cancelGenerateReport, generateDirectPdf, sessionExpired, handleFail, stopReport } from "../actions";
import ReportIndexed from "./components/ReportIndexed";
import { logout } from "../../authentication/actions";
import PolixisRoutes from "../../routes";

const mapStateToProps = (state = INITIAL_STATE) => {
    return {
        generate: state.application.generate,
        changeCanvasToImage: state.application.changeCanvasToImage,
        isScreenshot: state.application.addComponentsScreenshotToCustom,
        isAppendix: state.application.newAppendix,
        isTreeAppendix: state.application.appendixTree,
        isSessionExpired: state.application.isSessionExpired,
        fetchFail: state.application.fetchFail,
        judicialAppendixData: state.application.judicialAppendixData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        generateDirectPdf: (data) => dispatch(generateDirectPdf(data)),
        handleFail: () => dispatch(handleFail()),
        sessionExpired: () => dispatch(sessionExpired()),
        doGeneration: (ids, isEmptyReport, isInReport, action) => dispatch(doGeneration(ids, isEmptyReport, isInReport, action)),
        canvasToImage: () => dispatch(canvasToImage()),
        cancelGenerate: () => dispatch(cancelGenerateReport()),
        stopReport: (data) => dispatch(stopReport(data)),
        logout: () => dispatch(logout())
    };
};
let componentsList = {};

class Report2 extends React.Component {
    constructor(props) {
        super(props);
        this.fileName = null;
        this.selectedComponent = null;
        this.state = {
            showFooter: true,
            status: props.generate.status,
            state: ProgressState.None,
            showPdfDialog: false,
            graphAppendix: false,
            judicialAppendix: false,
            treeAppendix: false,
            node: null,
            mobile: Utils.isMobile(),
            generatePdf: "0",
            value: '',
            isSessionExpired: false,
            messages: Utils.isNotNullOrUndefined(sessionStorage.getItem("messages")) ? JSON.parse(sessionStorage.getItem("messages")) : [],
            downloadLinkReady: false,
            nodeSearch: false,
            getPdf: {},
            selectedSideItem: null,
            pdfError: false
        };
        this.indexedReport = new ReportIndexed();
        this.indexedReport.init();

    }

    render() {
        const { state, node, nodeProps } = this.props.generate;
        let sideBarItems = [];
        let content;
        let footer;
        const isOnlyNews = sessionStorage.getItem("isOnlyNews");
        if (Utils.isNotNullOrUndefined(node)) {
            const analytics = node;

            Object.keys(analytics).forEach(name => {
                const data = analytics[name];
                const isSection = data.view.toLowerCase() === "section";
                if (isOnlyNews) {
                    if (name === "newssearch" || name === "custom") {
                        if (name === "custom") {
                            data.title = "User Notes";
                        }
                        sideBarItems.push(new SideBarItem(data.id, data.title, isSection));
                    }
                } else {
                    if (data.view.toLowerCase().trim() === "custom") {
                        data.title = "User Notes";
                    }
                    sideBarItems.push(new SideBarItem(data.id, data.title, isSection));
                }
            });
            content = this.renderContent();

        } else {
            if (isOnlyNews) {
                sideBarItems.push(new SideBarItem("s1", "Section 1", true));
                sideBarItems.push(new SideBarItem("newssearch", "News live", false));
                sideBarItems.push(new SideBarItem("custom", "User Notes", false));
                content = this.renderOnlyNews();
            }
        }
        if (this.state.showFooter && !this.props.location.search) {
            footer = Utils.isNotNullOrUndefined(sideBarItems) ?
                <Footer items={sideBarItems} state={state} /> : null;
            if ((Utils.isNotNullOrUndefined(this.props.generate) && this.props.generate.status === ProgressStatus.DONE) || (isOnlyNews && Utils.isNullOrUndefined(this.props.generate.ids))) {
                setTimeout(() => {
                    this.footerHide();
                }, 500);
            }
        } else {
            footer = null
        }
        return (
            <div className="report2">
                {!this.props.location.search &&
                    <Header node={nodeProps} toggleSideBar={() => this.toggleSideBar()} isMobile={this.state.mobile}
                        toPdf={() => this.selectComponentsToPrint()}
                        postNote={() => this.postNote(EventTarget)}
                        loadingStatus={this.props.generate.status}
                        handleLogout={() => this.props.logout()}
                        clearPdf={() => this.clearPdfData()}
                        onRouteChange={(path) =>this.handleRouting(path)}
                    />
                }
                <div>
                    <div className='remove-report'>
                        {this.props.location.search &&
                            <div className="backdrop fade in">
                                {
                                    this.state.pdfError && <div className="failing-pdf">
                                        <div className="ltext">Something went wrong. Please close and generate again.</div>
                                    </div>
                                }
                            </div>
                        }
                        {(this.props.location.search === "?print=true" && (this.props.generate.status === ProgressStatus.DONE || isOnlyNews) && !this.state.downloadLinkReady) && !this.state.pdfError &&
                            <div className="loading-pdf backdrop">
                                <CircularProgress size={180} thickness={2} style={{ color: "#ef4254" }} className="lpdf" />
                                <div className="ltext1">Generating PDF</div>
                            </div>
                        }
                    </div>
                    {(this.props.location.search === "?print=true" && this.state.downloadLinkReady && (this.props.generate.status === ProgressStatus.DONE || isOnlyNews)) &&
                        <div className="text-center pdf-button pointer" style={{
                            position: 'fixed',
                            marginTop: '150px',
                            zIndex: '5000',
                            fontSize: "18px",
                            width: '100%'
                        }}>
                            <span className="red-text" onClick={() => this.downloadFileAndClose()}>
                                <div>The report {(this.props.generate && this.props.generate.nodeProps && this.props.generate.nodeProps.title) ? "for " + this.props.generate.nodeProps.title : ""} is ready.</div>
                                <div style={{ color: "#777" }}>Click here to download the pdf</div>  </span>
                        </div>
                    }
                    {content}
                    {footer}
                    {this.state.showPdfDialog && <SelectComponetsToPrint
                        componentsList={componentsList}
                        handleClose={() => this.handleClose()}
                        toPdf={() => this.toPdf()}
                    />}
                </div>
            </div>
        );
    }

    footerHide() {
        if (this.state.showFooter) {
            this.setState({ showFooter: false });
        }
    }

    renderContent() {
        componentsList = {};
        const { node, nodeProps } = this.props.generate;
        const avatar = Utils.isNotNullOrUndefined(node) && Utils.isNotNullOrUndefined(node.avatar) ? node.avatar : null;
        const nodeType = Utils.isNotNullOrUndefined(node) && Utils.isNotNullOrUndefined(nodeProps.type) ? nodeProps.type : null;
        const isOnlyNews = sessionStorage.getItem("isOnlyNews");
        if (this.props.location.search ? this.props.generate.status === ProgressStatus.DONE : Utils.isNotNullOrUndefined(node)) {
            const analytics = node;
            let sideBarItems = [];
            Object.keys(analytics).forEach(name => {
                const data = analytics[name];
                const isSection = data.view.toLowerCase() === "section";
                if (isOnlyNews) {
                    if (name === "newssearch" || name === "custom") {
                        if (name === "custom") {
                            data.title = "User Notes";
                        }
                        sideBarItems.push(new SideBarItem(data.id, data.title, isSection));
                    }
                } else {
                    if (data.view.toLowerCase().trim() === "custom") {
                        data.title = "User Notes";
                    }
                    sideBarItems.push(new SideBarItem(data.id, data.title, isSection));
                }
            });

            let components = Object.keys(analytics).map((name, index) => {
                let componentsForPrint = JSON.parse(sessionStorage.getItem("components"));
                let sectionsForPrint = sessionStorage.getItem("sections");
                //TODO get from reducer state
                const data = analytics[name];
                if (data.view.toLowerCase() === "section") {
                    if (isOnlyNews) {
                        return null;
                    } else {
                        if (this.props.location.search === "?print=true") {
                            if (sectionsForPrint.indexOf(name) !== -1) {
                                return <Section key={data.id} node={data} print={this.props.location.search} />
                            } else {
                                return null;
                            }
                        } else {
                            if (!isOnlyNews) {
                                return <Section key={data.id} node={data} print={this.props.location.search} />
                            }
                            return null;
                        }
                    }

                } else {
                    //TODO fix component list in pdf
                    if (!componentsList.hasOwnProperty(name)) {
                        componentsList[name] = [];

                    }
                    if (isOnlyNews) {
                        if (name === "newssearch" || name === "custom") {
                            componentsList[name].push(analytics[name].sectionId);
                            componentsList[name].push(analytics[name].title);
                        }
                    } else {
                        componentsList[name].push(analytics[name].sectionId);
                        componentsList[name].push(analytics[name].title);
                    }

                    if (isOnlyNews) {
                        if (name === "newssearch" || name === "custom") {
                            return <Component key={data.id}
                                avatar={avatar}
                                automation={false}
                                nodeType={nodeType}
                                data={data}
                                nodeProps={nodeProps}
                                first={index === 0}
                                print={this.props.location.search}
                                changeCanvasToImage={this.props.changeCanvasToImage}
                                status={this.props.generate.status}
                                showFooter={this.state.showFooter}
                                nodeSearch={this.state.nodeSearch} />
                        } else {
                            return null;
                        }
                    } else {
                        if (this.props.location.search === "?print=true") {
                            if (componentsForPrint.indexOf(name) !== -1) {
                                return <Component key={data.id}
                                    avatar={avatar}
                                    automation={false}
                                    nodeType={nodeType}
                                    data={data}
                                    nodeProps={nodeProps}
                                    first={index === 0}
                                    print={this.props.location.search}
                                    changeCanvasToImage={this.props.changeCanvasToImage}
                                    status={this.props.generate.status}
                                    showFooter={this.state.showFooter}
                                    nodeSearch={this.state.nodeSearch} />
                            } else {
                                return null;
                            }
                        } else {
                            return <Component key={data.id}
                                avatar={avatar}
                                automation={false}
                                nodeType={nodeType}
                                data={data}
                                nodeProps={nodeProps}
                                first={index === 0}
                                print={this.props.location.search}
                                changeCanvasToImage={this.props.changeCanvasToImage}
                                status={this.props.generate.status}
                                showFooter={this.state.showFooter}
                                nodeSearch={this.state.nodeSearch} />
                        }

                    }
                }
            });

            return (
                <div
                    className={this.props.location.search === "?print=true" ? 'content print marginforpdf' : 'content'}
                    style={{ paddingLeft: this.state.mobile || this.props.location.search ? "0" : "200px" }}>
                    {!this.props.location.search &&
                        <SideBar items={sideBarItems} ref="sidebar"
                            onSelect={(id) => this.selectSideItem(id)} isMobile={this.state.mobile}
                        />
                    }
                    <div
                        className={this.props.location.search === "?print=true" ? 'components componentsPadding widthForPdf' : 'components componentsPadding max-width'}>
                        {this.props.location.search ? <CoverPage /> : null}
                        <div className="row">
                            {(this.props.location.search && this.state.messages.length > 0) &&
                                <div style={{ textAlign: "center", color: "#ef4254", marginBottom: "20px" }}
                                    dangerouslySetInnerHTML={{ __html: this.state.messages.join("</br>") }} />
                            }
                        </div>
                        {components}
                    </div>

                    {(this.props.location.search === "?print=true" && !isOnlyNews) &&
                        <div>
                            <div className="title text-center" style={{ color: "#ef4254", marginBottom: '20px' }}>Sections
                                included in the report
                            </div>
                            {this.renderSelectedComponents()}
                        </div>
                    }
                </div>
            );
        }

    }

    renderOnlyNews() {
        componentsList = { 'newssearch': ["s1", "News Search"], 'custom': ["s1", "User Notes"] };
        const isOnlyNews = sessionStorage.getItem("isOnlyNews");
        const searchQuery = sessionStorage.getItem("queryQ");
        const { nodeProps } = this.props.generate;
        let components = [];
        let sideBarItems = [];
        const data = {
            "view": "SEARCH",
            "id": "newssearch",
            "title": "News Search",
            countryDetails: [],
            rootNames: [],
            relatedNames: []
        }

        if (searchQuery) {
            data.rootNames.push(searchQuery)
        }

        let maximizeNews = Utils.isNullOrUndefined(this.props.generate.ids) && this.props.location.search === "";
        components.push(<Component key="newssearch"
            isMaximize={maximizeNews}
            nodeType="person"
            data={data}
            nodeProps={nodeProps}
            first={true}
            print={this.props.location.search}
            changeCanvasToImage={this.props.changeCanvasToImage}
            status={ProgressStatus.DONE}
            showFooter={this.state.showFooter}
            nodeSearch={this.state.nodeSearch} />)

        components.push(<Component key="custom"
            nodeType="person"
            data={{ "view": "CUSTOM", "id": "custom", "title": "User Notes" }}
            nodeProps={nodeProps}
            first={false}
            print={this.props.location.search}
            changeCanvasToImage={this.props.changeCanvasToImage}
            status={ProgressStatus.DONE}
            showFooter={this.state.showFooter}
            nodeSearch={this.state.nodeSearch} />)

        sideBarItems.push(new SideBarItem("newssearch", "News Search", false));
        sideBarItems.push(new SideBarItem("custom", "User Notes", false));

        return (

            <div className={this.props.location.search ? 'content print marginforpdf' : 'content'}
                style={{ paddingLeft: this.state.mobile || this.props.location.search ? "0" : "200px" }}>
                {!this.props.location.search &&
                    <SideBar items={sideBarItems}
                        onSelect={(id) => this.selectSideItem(id)} isMobile={this.state.mobile} ref="sidebar" />
                }
                <div className={this.props.location.search ? 'components ' : 'components max-width '}
                    style={isOnlyNews ? { paddingTop: "20px" } : { paddingTop: "104px" }}>
                    {this.props.location.search ?
                        <div className="row"><img alt='noResultBanner' src="/images/no_result_banner.png" style={{ width: '1000px' }} />
                        </div> : null}
                    {components}
                </div>

            </div>
        )
    }

    renderSelectedComponents() {
        let componentsForPrint = JSON.parse(sessionStorage.getItem("components"));
        let content = [];
        const newSearchLength = Utils.isNotNullOrUndefined(sessionStorage.getItem('PinnedItemsNews')) ? JSON.parse(sessionStorage.getItem('PinnedItemsNews')).length : 0;

        Object.keys(componentsList).forEach((component) => {
            if ((newSearchLength === 0 && component === 'newssearch')) {
                return null;
            } else {
                componentsForPrint.indexOf(component) !== -1
                    ?
                    content.push(<div key={component} style={{ width: '30%', float: 'left', margin: '5px' }}>
                        <img width={20} height={20} alt='compChecked' src="/images/components/comp_checked.png" /> {this.props.generate.node[component].title}
                    </div>)
                    :
                    content.push(<div key={component} style={{ width: '30%', float: 'left', margin: '5px' }}>
                        <img width={20} height={20} alt='compUnchecked' src="/images/components/comp_uncheck.png" /> {this.props.generate.node[component].title}
                    </div>)
            }
        });

        return content;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.generate.isInReport && !nextProps.generate.isOnlyNews) {
            let ids;
            setTimeout(() => {
                ids = sessionStorage.getItem("ids");
                if (!Utils.isNotNullOrUndefined(ids)) {
                    this.props.doGeneration(ids, this.props.generate.isEmptyReport, this.props.generate.isInReport, postActions.EXPLORE);
                }
            }, 100)
            return false;
        }
        return true;
    }

    componentDidMount() {
        let ids;
        const isOnlyNews = sessionStorage.getItem("isOnlyNews");

        setTimeout(() => {
            ids = sessionStorage.getItem("ids");
            if (ids !== "null") {
                if (this.props.location.search === "?print=true") {
                    if (!isOnlyNews) {
                        this.handlePdfGeneration(ids);
                    }
                } else {
                    this.props.doGeneration(ids, this.props.generate.isEmptyReport, this.props.generate.isInReport, postActions.EXPLORE);
                }
            }
        }, 1000);

        if (this.props.location.search === "?print=true" && isOnlyNews) {
            setTimeout(() => {
                this.genPdf();
            }, 2000)
        }

        window.addEventListener("resize", () => {
            const show = this.state.mobile;
            const newShow = Utils.isMobile();
            if (show !== newShow) {
                this.toggleSideBar();
            }
            this.setState({ mobile: newShow });
        });

    }

    handleRouting(path) {
        this.props.history.push(path);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // automatic generation
        const isOnlyNews = sessionStorage.getItem("isOnlyNews");
        if (nextProps.fetchFail) {
            this.props.handleFail();
            this.handleRouting(PolixisRoutes.App.Search);
        }

        if (this.props.isScreenshot !== nextProps.isScreenshot) {

            this.setState({ graphAppendix: true });
        }
        if (this.props.isAppendix !== nextProps.isAppendix) {
            this.setState({ judicialAppendix: true });
        }
        if (this.props.appendixTree !== nextProps.appendixTree) {
            this.setState({ treeAppendix: true });
        }
        if (this.props.isSessionExpired !== nextProps.isSessionExpired && nextProps.isSessionExpired) {
            this.setState({ isSessionExpired: nextProps.isSessionExpired }, () => {
                showConfirmDialog({
                    title: "Warning",
                    message: "Current report session expired, please generate again!",
                    onContinue: () => {
                        // eslint-disable-next-line no-restricted-globals
                        history.go(-1);
                        this.props.sessionExpired();
                    },
                    continueButtonLabel: "Go Back"
                });
            });
        }
        if (this.props.location.search === "?print=true" && nextProps.generate.status === ProgressStatus.DONE && !isOnlyNews) {
            setTimeout(() => {
                this.genPdf();
            }, 2000)

        } else if (this.props.location.search === "?print=true" && nextProps.generate.status === ProgressStatus.FAIL) {
            this.setState({ pdfError: !this.state.pdfError })
        }
        if (this.props.generate.node !== nextProps.generate.node) {
            this.setState({ nodeSearch: true })
        }
    }

    clearPdfData() {
        this.indexedReport.dropDbInstance();
    }

    handlePdfGeneration(id) {
        this.indexedReport.query(id)
            .then(query => this.props.generateDirectPdf({ data: query }))
            .catch(() => this.setState({ pdfError: true }));
    }

    toggleSideBar() {
        this.refs.sidebar.toggleSideBar();
    }

    selectSideItem(id) {
        if (this.state.mobile) {
            this.toggleSideBar();
        }

        const selectedItem = document.getElementById(id);
        const selectedBar = document.getElementById(`${id}_section`);
        const existActive = document.getElementsByClassName('active')[0];
        existActive && existActive.classList.remove('active')
        selectedBar.classList.add('active');
        window.scrollTo({
            top: selectedItem.offsetTop - 64,
            behavior: "smooth"
        });
    }

    strRemove(theTarget) {
        const html = document.getElementsByTagName('html')[0];
        const cloned = html.cloneNode(true);
        const remove = cloned.getElementsByClassName(theTarget)[0];
        let i =0;
        const articleContent = cloned.getElementsByClassName("article-pdf-content");
        while(i < articleContent.length) {
            articleContent[i].classList.remove("article-content-hide")
            i++;
        }
        i=0;
        const viewContents =  cloned.getElementsByClassName("pdf-view-content");
        while(i < viewContents.length) {
            viewContents[i].classList.add("pdf-hide-content");
            i++;
        }

        i = 0;

        const articlePins = cloned.getElementsByClassName("remove-inPdf");
        while(i < articlePins.length) {
            articlePins[i].innerHTML = ''
            i++;
        }
        remove.parentNode.removeChild(remove);

        return cloned.innerHTML;
    }

    async toPdf() {
        let id = new Date().getTime();
        const ids = sessionStorage.getItem("ids");
        const isOnlyNews = sessionStorage.getItem("isOnlyNews");
        if (ids !== 'null') {
            id = ids;
        }
        sessionStorage.removeItem('judicialAppendix');
        await this.props.canvasToImage();
        if (!isOnlyNews) {
            if (this.props.judicialAppendixData) {
                sessionStorage.setItem('judicialAppendix', JSON.stringify(this.props.judicialAppendixData));
            }
            this.indexedReport.insert(this.props.generate, id)
                .then(() => {
                    setTimeout(() => {
                        this.handlePopUpBlock();
                    }, 1000)
                })
                .catch(async () => {
                    showAlertDialog(alertDialogState.pdfGenerateFailState);
                    sessionStorage.removeItem('judicialAppendix');
                    await this.indexedReport.resetDbs();
                });
        } else {
            this.handlePopUpBlock();
        }
    }

    handlePopUpBlock() {
        const newWin = window.open("/report?print=true");
        if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
            showAlertDialog(alertDialogState.popUpBlockState);
        }
    }

    generatePdf(html, entityIds) {
        doPostPdf(API.GENERATE_PDF, { html, entityIds, requestedDate: Date.now(), requestedFrom: clientType }, true).then(response => {
            const { data, headers } = response;
            this.setState({ downloadLinkReady: true, getPdf: { data, headers } });
        }).catch(() => {
            this.setState({ pdfError: !this.state.pdfError }, () => {
                this.indexedReport.resetDbs();
            })
        })
    }

    genPdf() {
        this.setState({ generatePdf: "2" }, () => {
            setTimeout(() => {
                let theResult = this.strRemove('remove-report');
                let pdfContent = '<!DOCTYPE html><html lang="en"><base href=' + process.env.REACT_APP_BASE + '>' + theResult + '</html>';
                const entityIds = this.props.generate.node?.main?.valueMap['ARDIS ID'] ? this.props.generate.node.main.valueMap['ARDIS ID'][0] : sessionStorage.getItem('selectedIds') || "";
                this.setState({ generatePdf: "1" });
                this.generatePdf(pdfContent, entityIds)
            }, 6500);
        });
    }

    downloadFileAndClose() {
        const ids = sessionStorage.getItem("ids");
        const isOnlyNews = sessionStorage.getItem("isOnlyNews");
        const fileName = isOnlyNews ? 'mediaScreeningReport.pdf' : `${this.props.generate.nodeProps.title}.pdf`;
        sessionStorage.removeItem('judicialAppendix');
        sessionStorage.removeItem("judicialFromSelected");
        Utils.downloadFileAndClose(fileName, this.state.getPdf.data, this.state.getPdf.headers['content-type'])
        setTimeout(async () => {
            if (ids !== "null") {
                await this.indexedReport.delete(ids);
            }
            window.close();
        }, 500)
    }

    selectComponentsToPrint() {
        const isOnlyNews = sessionStorage.getItem("isOnlyNews");

        if (isOnlyNews) {
            this.toPdf();
        } else {
            this.setState({ showPdfDialog: true })
        }
    }

    handleClose() {
        this.setState({
            showPdfDialog: false
        }, () => {
            componentsList = [];
        });
    }

    cancelGenerateReport() {
        let prId = sessionStorage.getItem('prId');
        let bnli = localStorage.getItem('bnli');

        this.props.cancelGenerate();

        const requestData = {
            action: postActions.STOP,
            time: new Date().getTime(),
            bnli,
            projectId: prId
        };

        this.props.stopReport(requestData);
    }

    componentWillUnmount() {
        sessionStorage.removeItem('newsFromReport');
        if (this.props.generate.status === ProgressStatus.RUNNING) {
            this.cancelGenerateReport();
        }
    }
}

Report2.propTypes = {
    generate: PropTypes.object.isRequired,
    doGeneration: PropTypes.func,
    data: PropTypes.object,
    canvasToImage: PropTypes.func,
    changeCanvasToImage: PropTypes.number,
    isScreenshot: PropTypes.number,
    isAppendix: PropTypes.number,
    appendixTree: PropTypes.number,
    isSessionExpired: PropTypes.bool,
    doAutoGenarateReport: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Report2);

import React from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";

import Utils from "../../../utils";
import {API, doPost} from '../../../api';
import {getNextAvatar, postActions, ProgressState} from "../../../constants";
import TreeToolbar from "./graph/TreeToolbar.jsx";
import TreeNodeDelail from "./graph/TreeNodeDelail.jsx";

export default class RelationTree extends React.PureComponent {
    constructor(props) {
        super(props);
        this.avatar = null;
        this.state = {
            data: props.data,
            isMaximize: props.isMaximize,
            print: props.print,
            showContent: false,
            selectedId: null,
            nodeDetails: {},
            nodeDetailId: "",
            hovered: {id: null, parentId: null},
            nodeDetailParentId: null,
            appendixTreeData: Utils.isNotNullOrUndefined(sessionStorage.getItem("appendixTree")) ? JSON.parse(sessionStorage.getItem("appendixTree")) : {},
            searchKey: "",
        };
    }

    render() {
        return (
            <div>
                {Utils.isNotNullOrUndefined(this.state.data) && this.renderContent()}
            </div>
        );
    }

    renderContent() {
        let detailContent;
        const checked = Object.keys(this.state.appendixTreeData).indexOf(this.state.data.id) !== -1;
        if (this.state.data.pie) {

            let value = '';
            detailContent = Object.keys(this.state.data.pie).map((key, index) => {
                const values = this.state.data.pie[key];
                if (Utils.isNotNullOrUndefined(values.name)) {
                    value = values.name + ": ";
                }
                let color;
                switch (key) {
                    case 'pep':
                        color = '#6da6ce';
                        break;
                    case 'sanction':
                        color = '#F05F7C';
                        break;
                    case 'info':
                        color = '#8d6cab';
                        break;
                    case 'non_active':
                        color = '#757575';
                        break;
                    default:
                        color = '#000';
                        break;
                }

                return <div key={index + key}><b
                    style={{color: color, textTransform: 'capitalize'}}>&#9632; {key.replace(/_/g, " ")} </b>
                    <div> {value} <span dangerouslySetInnerHTML={{__html: values.value}}/></div>
                </div>;
            });

        } else {
            detailContent = <div key="detailCont"
                                 className='tooltipNode'
                                 style={{maxHeight: '500px!important', color: '#95989A'}}>
                <p style={{color: '#555'}}>
                    {this.state.data.title}
                </p>
            </div>;
        }

        let subContent = this.renderSubContent(this.state.data.subNodes, this.state.data.id);
        return <div key={this.state.data.title} style={{padding: "10px"}}>
            {(!this.props.automation && this.props.print === "") && <div>
                <TextField
                    id="search-entity"
                    placeholder="Search"
                    minRows={1}
                    className='input-redUnderline'
                    InputProps={{style: {fontSize: 13, marginTop: "12px"}}}
                    style={{width: '100%', height: "48px", borderColor: "#ef4254"}}
                    onChange={(event) => this.searchItem(event)}
                    variant="standard"
                />
            </div>
            }
            {(!this.props.automation && this.props.print === "") && <div style={{
                position: "absolute",
                right: "20px",
                borderRadius: "2px",
                padding: "2px 5px",
                height: "25px"
            }}>
                <TreeToolbar includeNodeContent={() => this.includeNodeContent(this.state.data.id)}
                             showNodeContent={() => this.showDetails(this.state.data.id)}
                             searchNode={() => {
                                 this.searchNodeInNewWindow(this.state.data)
                             }}
                             checked={checked}
                />


                {Utils.isNotNullOrUndefined(this.state.nodeDetails[this.state.nodeDetailId]) && this.state.nodeDetailId === this.state.data.id &&
                    <TreeNodeDelail
                        data={this.state.nodeDetails[this.state.data.id]}
                        id={this.state.data.id}
                        includeNodeContent={() => this.includeNodeContent(this.state.data.id)}
                        closeDialog={() => this.setState({nodeDetailId: ""})}
                        checked={checked}
                        marginMinus={false}
                    />}
            </div>}
            <div className="hover"
                 style={{
                     padding: "5px 10px ",
                     color: "#ef4254",
                     borderRadius: "2px",
                     fontWeight: "bold",
                     display: "inline-flex",
                     width: "100%"
                 }}
            >

                {<div
                    style={this.state.data.pieNames.length > 0 ?
                        {
                            backgroundImage: "url('/images/pie_graph/" + this.selectPieImage(this.state.data) + ".png')",
                            width: '20px',
                            float: "left",
                            maxHeight: '20px',
                            position: 'relative',
                            padding: '5px',
                            marginRight: "5px",
                            backgroundRepeat: 'norepeat',
                            backgroundSize: 'cover'
                        }
                        : {
                            float: "left",
                            width: '20px',
                            height: '20px',
                            position: 'relative',
                            marginRight: "5px",
                            marginTop: "5px"
                        }}>
                    <div style={{marginTop: "-7px", marginLeft: "-1px"}}>{this.genderImage(this.state.data)}</div>
                </div>}
                <span
                    onMouseEnter={() => this.handleMouseHover(this.state.data.id, null)}
                    onMouseLeave={() => this.handleMouseLeave()}>{this.state.data.title}</span></div>

            {(this.state.hovered.id === this.state.data.id) &&
                <div className="shandow" key={"hover" + this.state.data.id} style={{
                    position: "absolute",
                    marginLeft: "10%",
                    zIndex: "20000",
                    backgroundColor: "#ffffff",
                    borderRadius: "2px",
                    padding: "5px",
                    maxWidth: "500px"
                }}>
                    {detailContent}

                </div>
            }
            <div style={{padding: "10px"}}>{subContent}</div>
        </div>;
    }

    renderSubContent(nodes, parentId) {
        if (Utils.isNotNullOrUndefined(nodes)) {
            return Object.keys(nodes).map((id, i) => {
                const checked = Object.keys(this.state.appendixTreeData).indexOf(id) !== -1;
                const subnode = nodes[id];
                const mLeft = Object.keys(subnode.subNodes).length > 0 ? "-6px" : "0px";
                let content;
                const bgColor = this.state.searchKey && subnode.title.toLowerCase().includes(this.state.searchKey.toLowerCase()) ? "#fff281" : ""

                if (subnode.pie) {

                    let value = '';
                    content = Object.keys(subnode.pie).map((key, index) => {
                        const values = subnode.pie[key];
                        if (Utils.isNotNullOrUndefined(values.name)) {
                            value = values.name + ": ";
                        }
                        let color;
                        switch (key) {
                            case 'pep':
                                color = '#6da6ce';
                                break;
                            case 'sanction':
                                color = '#F05F7C';
                                break;
                            case 'info':
                                color = '#8d6cab';
                                break;
                            case 'non_active':
                                color = '#757575';
                                break;
                            default:
                                color = '#000';
                                break;
                        }
                        return <div key={index}><b style={{
                            color: color,
                            textTransform: 'capitalize'
                        }}>&#9632; {key.replace(/_/g, " ")} </b>
                            <div> {value} <span dangerouslySetInnerHTML={{__html: values.value}}/></div>
                        </div>;
                    });

                } else {
                    content = <div className='tooltipNode'
                                   style={{maxHeight: '500px!important', color: '#95989A'}}>
                        <p style={{color: '#555'}}>{subnode.title}</p>
                    </div>;

                }
                return <div style={{
                    marginLeft: "30px",
                    marginTop: "-3px",
                    color: "#777777",
                    borderLeft: "1px solid #e6e6e6",
                    marginBottom: 10
                }} key={subnode.title + subnode.id + i}>
                    {(!this.props.automation && this.props.print === "") && <div style={{
                        position: "absolute",
                        right: "20px",
                        borderRadius: "2px",
                        padding: "2px 5px",
                        height: "25px"
                    }}>
                        <TreeToolbar includeNodeContent={() => this.includeNodeContent(id)}
                                     showNodeContent={() => this.showDetails(id, parentId)}
                                     searchNode={() => {
                                         this.searchNodeInNewWindow(subnode)
                                     }}
                                     checked={checked}
                        />

                        {Utils.isNotNullOrUndefined(this.state.nodeDetails[this.state.nodeDetailId]) && this.state.nodeDetailId === id && this.state.nodeDetailParentId === parentId &&
                            <TreeNodeDelail
                                data={this.state.nodeDetails[id]}
                                id={id}
                                includeNodeContent={() => this.includeNodeContent(id)}
                                closeDialog={() => this.setState({nodeDetailId: ""})}
                                checked={checked}
                                marginMinus={true}
                            />
                        }

                    </div>}
                    <div
                        className={this.state.selectedId === subnode.id ? "relation-select avoid-break hover" : "avoid-break hover"}
                        style={Object.keys(subnode.subNodes).length > 0 ? {
                            border: "0px solid #d7d7d7",
                            borderRadius: "2px",
                            padding: "3px 2px",
                            marginBottom: "15px",
                            height: "30px"
                        } : {height: "30px", marginLeft: "12px", padding: "3px 8px", display: 'flex'}}>

                        {Object.keys(subnode.subNodes).length > 0 &&
                            <img alt='downUp' src={subnode.collapse ? "/images/down.png" : "/images/up.png"}
                                 onClick={() => this.collapseSubnodes(this.state.data.subNodes, subnode.id)}
                                 style={{
                                     cursor: "pointer",
                                     marginLeft: "-17px",
                                     marginRight: "11px",
                                     width: "30px",
                                     float: "left",
                                     marginTop: "-3px"
                                 }}/>
                        }
                        <div
                            style={subnode.pieNames.length > 0 ? {
                                    backgroundImage: "url('/images/pie_graph/" + this.selectPieImage(subnode) + ".png')",
                                    float: "left",
                                    width: '20px',
                                    maxHeight: '20px',
                                    position: 'relative',
                                    padding: '5px',
                                    marginRight: "5px",
                                    backgroundRepeat: 'norepeat',
                                    backgroundSize: 'contain',
                                    marginLeft: mLeft
                                }
                                : {
                                    float: "left",
                                    width: '20px',
                                    height: '20px',
                                    position: 'relative',
                                    marginRight: "5px",
                                    marginLeft: mLeft
                                }}>
                            <div
                                style={(subnode.pieNames.length > 0) ? {marginTop: "-7px", marginLeft: "0px"} : {marginTop: "-1px"}}>{this.genderImage(subnode)}</div>
                        </div>
                        <p
                            onClick={() => this.highlightNodes(subnode.id)}
                            onMouseEnter={() => this.handleMouseHover(subnode.id, parentId)}
                            onMouseLeave={() => this.handleMouseLeave()}
                            className={(subnode.title.length > 80 && this.props.print !== "") ? "font-13" : ""}
                            style={subnode.nodeType === "person" ? {
                                color: "#1b89b7",
                                cursor: "pointer",
                                textTransform: "capitalize",
                                backgroundColor: bgColor
                            } : {
                                backgroundColor: bgColor,
                                color: "#078635",
                                cursor: "pointer",
                                textTransform: "capitalize"
                            }}>
                            {subnode.title.toLowerCase()}
                            &nbsp;&rarr;&nbsp;
                            <span
                                style={{color: subnode.color ? this.makeColorDark(subnode.color): "#5fbafd"}}>{subnode.edge}</span>
                        </p>

                        {(this.state.hovered.id === subnode.id && this.state.hovered.parentId === parentId) &&
                            <div className="shandow hover" key={subnode.id + parentId}
                                 style={{
                                     position: "absolute",
                                     marginLeft: "10%",
                                     zIndex: "20000",
                                     backgroundColor: "#ffffff",
                                     borderRadius: "2px",
                                     padding: "5px",
                                     maxWidth: "500px"
                                 }}>{content}</div>
                        }
                    </div>
                    {subnode.collapse && this.renderSubContent(subnode.subNodes, subnode.id)}
                </div>
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({data: nextProps.data})
        if (this.props.isTreeAppendix !== nextProps.isTreeAppendix) {
            const appendixTree = sessionStorage.getItem("appendixTree");
            if (Utils.isNotNullOrUndefined((appendixTree))) {
                const appendixTreeData = JSON.parse(appendixTree);
                this.setState({appendixTreeData})
            }
        }
    }

    highlightNodes(nodeId) {
        if (this.state.selectedId === nodeId) {
            this.setState({selectedId: null})
        } else {
            this.setState({selectedId: nodeId})
        }
    }

    collapseSubnodes(nodes, nodeId) {
        Object.keys(nodes).forEach((id) => {
            let subnode = nodes[id];
            if (nodeId === id) {
                subnode.collapse = !subnode.collapse;
            } else {
                this.collapseSubnodes(subnode.subNodes, nodeId)
            }
        })
        this.forceUpdate()
    }

    genderImage(node) {
        if (Utils.isNullOrUndefined(node.image)) {
            if (node.nodeType === "person") {
                if (Utils.isNullOrUndefined(node.gender)) {
                    return <img alt='nodeAv' src="/images/node_avatar.png" style={{position: "relative", width: "100%"}}/>;
                } else {
                    let avatar = getNextAvatar(node.gender);
                    return <img alt='relativeImage' src={avatar} style={{position: "relative", width: "100%"}}/>;
                }
            } else {
                return <img alt='nodeCom' src="/images/node_company.png" style={{position: "relative", width: "100%"}}/>;
            }
        } else {
            if (node.image.startsWith("../")) {
                let a = node.image.replace("..", API.BASE_IMG_URL);
                return <img alt='grayscale' className="grayscale" src={a}
                            style={{borderRadius: "50%", position: "relative", width: "100%"}}/>;
            }
            return <img alt='grayscaleImg' className="grayscale" src={node.image}
                        style={{borderRadius: "50%", position: "relative", width: "100%"}}/>;
        }
    }

    selectPieImage(node) {
        let pies = [];
        let name = '';
        node.pieNames.forEach(pieItem => {
            if (pieItem === "CLOSED" || pieItem === "Closed" || pieItem.toLowerCase() === "closed") {
                pieItem = 'non_active';
            }
            pies.push(pieItem.toLowerCase());
        });
        pies.sort();
        for (let i = 0; i < pies.length; i++) {
            if (name.indexOf(pies[i]) === -1) {
                name += pies[i] + '_';
            }
        }
        return name;
    }

    makeColorDark(hex) {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let res = result ? {
            r: Math.floor(parseInt(result[1], 16) * 50 / 100),
            g: Math.floor(parseInt(result[2], 16) * 70 / 100),
            b: Math.floor(parseInt(result[3], 16) * 80 / 100)
        } : null;
        let {r, g, b} = res
        const newColor = g | (b << 8) | (r << 16);
        return "#" + newColor.toString(16);
    }

    handleMouseHover(id, parentId) {
        let hovered = {id, parentId}
        this.setState({hovered})
    }

    handleMouseLeave() {
        this.setState({hovered: {id: null, index: null}})
    }

    includeNodeContent(id) {
        let appendixTreeData = {};

        let appendixTree = sessionStorage.getItem("appendixTree");
        if (Utils.isNotNullOrUndefined((appendixTree))) {
            appendixTreeData = JSON.parse(appendixTree);

            if (Object.keys(appendixTreeData).indexOf(id) === -1) {
                appendixTreeData[id] = this.state.nodeDetails[id];
            } else {
                delete appendixTreeData[id];

            }

        } else {
            appendixTreeData[id] = this.state.nodeDetails[id];
        }
        sessionStorage.setItem("appendixTree", JSON.stringify(appendixTreeData));
        this.setState({appendixTreeData});
        this.props.refreshTreeAppendixData();
    }

    showDetails(id, parentId = null) {
        this.setState({nodeDetailId: id, nodeDetailParentId: parentId}, () => {
            this.loadContent(id);
        })
    }

    loadContent(id) {
        const prId = sessionStorage.getItem('prId');
        const bnli = localStorage.getItem('bnli');
        let nodeDetails = this.state.nodeDetails;

        const requestData = {
            action: postActions.DETAILS,
            time: new Date().getTime(),
            nid: id,
            projectId: prId,
            bnli: bnli,

        };
        this.setState({state: ProgressState.Loading});
        doPost(API.GET_NODE_DATA, {qry: JSON.stringify(requestData)}, true).then(response => {
            if (Utils.isNotNullOrUndefined(response.data)) {
                nodeDetails[id] = response.data
                this.setState({nodeDetails, state: ProgressState.Success});
            }
        }).catch(error => {
            console.error(error);
            this.props.sessionExpired()
        });
    }

    searchItem(event) {
        let searchKey = event.target.value;
        this.setState({searchKey})
    }

    searchNodeInNewWindow(node) {
        if (node.nodeType.toLowerCase() === 'organization' || node.nodeType.toLowerCase() === 'person') {
            let searchValue = {'value': node.title, 'type': node.nodeType};
            sessionStorage.setItem('searchValue', JSON.stringify(searchValue));
            window.open("/search", "_blank");
            setTimeout(() => {
                sessionStorage.removeItem('searchValue');
            }, 1000)
        }
    }
}

RelationTree.defaultProps = {
    isMaximize: false,
};

RelationTree.propTypes = {
    data: PropTypes.object.isRequired,
    isMaximize: PropTypes.bool,
    toggleMaximize: PropTypes.func.isRequired,
    bgColor: PropTypes.string,
    cachedState: PropTypes.object,
    bnli: PropTypes.string,
    projectId: PropTypes.string,
    print: PropTypes.string,
    getScreenshot: PropTypes.func,
    isScreenshot: PropTypes.number,
    changeCanvasToImage: PropTypes.number,
    sessionExpired: PropTypes.func,
    addToCard: PropTypes.func,
    status: PropTypes.string,
    automation: PropTypes.bool,
    refreshTreeAppendixData: PropTypes.func,
    isTreeAppendix: PropTypes.number,
}; 

import appStore from "../redux/store.js";
import PolixisRoutes from "../routes";
import {API, doPost, doGet} from "../api.js";
import {INITIAL_STATE, postActions, ProgressState, ProgressStatus} from "../constants";
import Utils from "../utils";
import {emptyNewsState} from "./pressa/pressaStore/newsAction";
import {
    ApplicationActions,
    addAppendixComponent,
    sessionExpired,
    doSearchSuccess,
    closeExploreDialog,
    searchResult,
    searchResultSuccess,
    generateResultSuccess,
    generateResult,
    generateSuccess,
    loadCountriesSuccess,
    doPathSearch,
    doPathSearchSuccess,
    cancelSearch,
    newAppendix,
    handleFail,
    handleNoServer,
    stopSearchSuccess,
    cancelGenerateReport,
    stopPathSearch
} from "./actions.js";

let controller;
let signal;

const application =  (state = INITIAL_STATE.application, action) => {
    switch (action.type) {
        case ApplicationActions.LoadCountries: {
            doGet(API.COUNTRIES, null, false).then(response => {
                if (!Utils.isNullOrUndefined(response.data.error)) {
                    appStore.dispatch(handleFail());
                } else {
                    appStore.dispatch(loadCountriesSuccess(response.data));

                    if (action.payload && action.payload.checkServer) {
                        appStore.dispatch(handleFail());
                    }
                }
            }).catch(error => {
                appStore.dispatch(handleNoServer());
                console.log(error);
            });
            return state;
        }

        case ApplicationActions.LoadCountriesSuccess: {
            return Object.assign({}, state, {
                countries: action.payload.countries
            });
        }

        case ApplicationActions.UpdateSearchBar: {
            const updateSearchBar = Utils.isNullOrUndefined(state.updateSearchBar) ? true : state.updateSearchBar;

            return Object.assign({}, state, {
                updateSearchBar: !updateSearchBar
            });
        }

        case ApplicationActions.GenerateDirectPdf: {
            try {
                const node = JSON.parse(action.payload.data.node);
                const nodeProps = JSON.parse(action.payload.data.nodeProps)
                return Object.assign({}, state, {
                    generate: {
                        state: ProgressState.Loading,
                        status: ProgressStatus.DONE,
                        node: node,
                        nodeProps: nodeProps
                    }
                });
            } catch {
                return Object.assign({}, state, {
                    generate: {
                        state: ProgressState.Failed,
                        status: ProgressStatus.FAIL,
                    }
                });
            }
        }

        case ApplicationActions.GenerateReport: {
            setTimeout(() => {
                appStore.dispatch(cancelSearch());
            }, 10);
            setTimeout(() => {
                sessionStorage.setItem("ids", action.payload.isEmptyReport ? null : action.payload.ids);
                if (action.payload.isEmptyReport) {
                    appStore.dispatch(emptyNewsState());
                }
                if (action.payload.isOnlyNews) {
                    sessionStorage.setItem("isOnlyNews", action.payload.isOnlyNews);
                    appStore.dispatch(closeExploreDialog());
                } else {
                    if (action.payload.newTab) {
                        window.open(PolixisRoutes.App.Report, '_blank');
                    } else {
                        if (action.payload.isInReport) {
                            window.location.reload();
                        } else {
                            appStore.dispatch(closeExploreDialog());
                        }
                    }
                }
            }, 20);

            return Object.assign({}, state, {
                generate: {
                    state: ProgressState.Loading,
                    node: null,
                    ids: action.payload.ids,
                    isEmptyReport: action.payload.isEmptyReport,
                    isInReport: action.payload.isInReport,
                    isOnlyNews: action.payload.isOnlyNews
                }
            });
        }

        case ApplicationActions.DoGeneration: {
            sessionStorage.removeItem("judicialFromSelected");
            const bnli = localStorage.getItem('bnli');
            const prId = Utils.isNullOrUndefined(sessionStorage.getItem('prId')) ? "SEdb0a1971-0b47-4d15-8ad1-88cc5adf7a50" : sessionStorage.getItem('prId');
            let isOnlyNews = sessionStorage.getItem("isOnlyNews");
            let actionValue = Utils.isNullOrUndefined(isOnlyNews) ? action.payload.action : postActions.NEWS_GET;
            const queryData = {
                action: actionValue,
                keyword: action.payload.ids,
                time: new Date().getTime(),
                bnli: bnli,
                projectId: prId,
            };
            if (Utils.isNullOrUndefined(sessionStorage.getItem('prId'))) {
                sessionStorage.setItem("prId", "SEdb0a1971-0b47-4d15-8ad1-88cc5adf7a50");
            }

            signal = getAbortControllerInstance();
            doPost(API.GENERATE_REPORT, {qry: JSON.stringify(queryData)}, true, true, signal).then(response => {
                if (!Utils.isNullOrUndefined(response.data.error)) {
                    appStore.dispatch(handleFail());
                } else {
                    if (!Utils.isNullOrUndefined(response.data.projectId)) {
                        sessionStorage.setItem("prId", response.data.projectId);
                    }
                    appStore.dispatch(generateSuccess());
                }
            }).catch(error => {
                console.error(error);
                appStore.dispatch(sessionExpired());
            });

            return Object.assign({}, state, {
                generate: {
                    state: ProgressState.Loading,
                    node: null,
                    ids: action.payload.ids,
                }
            });
        }

        case ApplicationActions.GenerateResult: {
            const bnli = localStorage.getItem('bnli');
            const prId = sessionStorage.getItem('prId');
            const queryData = {
                action: postActions.GET,
                time: new Date().getTime(),
                bnli: bnli,
                projectId: prId
            };

            signal = getAbortControllerInstance();
            doPost(API.GENERATE_REPORT, {qry: JSON.stringify(queryData)}, true, true, signal).then(response => {
                if (!Utils.isNullOrUndefined(response.data.error)) {
                    appStore.dispatch(handleFail());

                } else {
                    appStore.dispatch(generateResultSuccess(response.data));
                }
            }).catch(error => {
                console.error(error);
                appStore.dispatch(sessionExpired());
            });
            return state;
        }

        case ApplicationActions.GenerateSuccess: {
            setTimeout(() => {
                appStore.dispatch(generateResult());
            }, 500);

            return Object.assign({}, state, {
                generate: {
                    state: ProgressState.Loading,
                    node: null,
                    status: ProgressStatus.RUNNING
                }
            });
        }

        case ApplicationActions.GenerateResultSuccess: {
            const status = action.payload.status;
            if (status === ProgressStatus.RUNNING) {
                setTimeout(() => {
                    appStore.dispatch(generateResult());
                }, 800);
                return Object.assign({}, state, {
                    generate: {
                        state: ProgressState.Loading,
                        status,
                        node: action.payload.analytics,
                        nodeProps: action.payload.node
                    }
                });
            } else {
                let node = action.payload.analytics;
                let nodeProps = action.payload.node;
                const doneResults = action.payload.analytics;
                if (!Utils.isNullOrUndefined(doneResults)) {
                    node = doneResults;
                }
                const gdata = JSON.parse(sessionStorage.getItem("Graph"));
                const jdata = JSON.parse(sessionStorage.getItem("includedJudicial"));
                const treeData = JSON.parse(sessionStorage.getItem("appendixTree"));
                let payload = [];
                if (!Utils.isNullOrUndefined(gdata) && gdata.length > 0) {
                    payload.push("graph");
                }
                if (!Utils.isNullOrUndefined(jdata) && jdata.length > 0) {
                    payload.push("judicial");
                }
                if (!Utils.isNullOrUndefined(treeData) && Object.keys(treeData).length > 0) {
                    payload.push("appendixTree");
                }
                setTimeout(() => {
                    appStore.dispatch(addAppendixComponent(payload));
                }, 100);
                return Object.assign({}, state, {
                    generate: {
                        state: ProgressState.Success,
                        status,
                        node,
                        nodeProps
                    }
                });
            }
        }

        case ApplicationActions.StopReport : {
            doPost(API.GENERATE_REPORT_STOP, {
                qry: JSON.stringify(action.payload)
            }, true, true, signal)
                .then(()=> {
                    appStore.dispatch(cancelGenerateReport())
                })
                .catch((e) => {
                    console.error(e);
                    appStore.dispatch(handleFail());
                })
            return state;
        }

        case ApplicationActions.CancelGenerateReport: {
            removeAbortControllerInstance();
            return Object.assign({}, state, {
                generate: {}
            });
        }

        case ApplicationActions.AddAppendixComponent: {
            let generate = Object.assign({}, state.generate);
            const analytics = generate.node;
            if (action.payload.indexOf("judicial") !== -1) {
                analytics["Appendix: Judicial Decisions"] = {
                    view: "appendix",
                    title: "Appendix: Judicial Decisions",
                    id: "judicialAppendix"
                };
            }
            if (action.payload.indexOf("graph") !== -1) {
                analytics["Appendix: Screenshot from relation explorer"] = {
                    view: "appendixGraph",
                    title: "Appendix: Screenshot from relation explorer",
                    id: "appendixGraph"
                };
            }

            if (action.payload.indexOf("appendixTree") !== -1) {
                analytics["Appendix: Relation Entities"] = {
                    view: "appendixTree",
                    title: "Appendix: Relation Entities",
                    id: "appendixTree"
                };
            }
            return state;
        }

        case ApplicationActions.RefreshJudicialDataFromAppendix: {
            setTimeout(() => {
                appStore.dispatch(newAppendix(action.payload.id));
            }, 200);
            return Object.assign({}, state, {
                judicialAppendixRemoveId: action.payload.id
            });
        }

        case ApplicationActions.DoSearch: {
            let {q, type, options} = action.payload;
            if (type !== "person" && type !== "organization") {
                type = "person";
            }

            const bnli = localStorage.getItem('bnli');
            const prevPrId = sessionStorage.getItem('prId');
            let queryData = {
                action: postActions.SEARCH,
                keyword: q,
                type: type,
                bnli: bnli,
                time: new Date().getTime(),
            };
            if (Utils.isNotNullOrUndefined(prevPrId)) {
                queryData.prevPrId = prevPrId
            }
            if (!Utils.isNullOrUndefined(options)) {
                try {
                    options = JSON.parse(options);
                    queryData.country = options.country;
                    queryData.related = options.related;
                    queryData.ld = options.ld;
                    queryData.date = options.date;
                    queryData.match = options.match;
                    queryData.lng = options.lng;
                    queryData.relatedRadius = options.relatedRadius;
                } catch (err) {
                    console.error(err);
                }
            }
            signal = getAbortControllerInstance();
            doPost(API.SEARCH, {qry: JSON.stringify(queryData)}, true, true, signal, true).then(response => {
                if (!Utils.isNullOrUndefined(response.data.error)) {
                    appStore.dispatch(handleFail());

                } else {
                    appStore.dispatch(doSearchSuccess(response.data));
                    sessionStorage.setItem('prId', response.data.projectId);
                }
            }).catch(error => {
                console.error(error);
            });
            return Object.assign({}, state, {
                search: {
                    state: ProgressState.Loading,
                    resultCount: 0,
                    status: null,
                    results: [],
                    messages: [],
                },
                searchCancel: {
                    status: ProgressStatus.RUNNING
                }
            });
        }

        case ApplicationActions.DoSearchSuccess: {
            setTimeout(() => {
                appStore.dispatch(searchResult());
            }, 500);
            return Object.assign({}, state, {
                search: {
                    state: state.search.state,
                    resultCount: 0,
                    results: [],
                    status: ProgressStatus.RUNNING,
                    messages: [],
                }
            });
        }

        case ApplicationActions.SearchResult: {
            const bnli = localStorage.getItem('bnli');
            const prId = sessionStorage.getItem('prId');
            sessionStorage.removeItem("isOnlyNews");
            const queryData = {
                action: postActions.GET,
                bnli: bnli,
                projectId: prId,
                time: new Date().getTime()
            };

            doPost(API.SEARCH, {qry: JSON.stringify(queryData)}, true).then(response => {
                if (!Utils.isNullOrUndefined(response.data.error)) {
                    appStore.dispatch(handleFail());
                } else {
                    appStore.dispatch(searchResultSuccess(response.data));
                }
            }).catch(error => {
                console.error(error);
            });
            return state;
        }

        case ApplicationActions.SearchResultSuccess: {
            const status = action.payload.results.status;

            if (status === ProgressStatus.RUNNING || status === ProgressStatus.SEARCHING || status === ProgressStatus.ANALYSING) {
                setTimeout(() => {
                    appStore.dispatch(searchResult());
                }, 800);
                return Object.assign({}, state, {
                    search: {
                        state: ProgressState.Loading,
                        resultCount: action.payload.results.results.length,
                        status,
                        results: action.payload.results.results,
                        messages: action.payload.results.messages,
                    }
                });
            } else {
                let resultCount = action.payload.results.results.length;
                const results = action.payload.results.results;
                const messages = action.payload.results.messages;
                return Object.assign({}, state, {
                    search: {
                        state: ProgressState.Success,
                        resultCount: resultCount,
                        status,
                        results,
                        messages
                    }
                });
            }
        }

        case ApplicationActions.StopSearch : {
            doPost(API.STOP, {
                qry: JSON.stringify(action.payload)
            }, true)
                .then(()=> {
                    appStore.dispatch(stopSearchSuccess())
                })
                .catch((e) => {
                    console.error(e);
                    appStore.dispatch(handleFail());
                })
            return state;

        }

        case ApplicationActions.StopSearchSuccess : {
            removeAbortControllerInstance();
            return Object.assign({}, state, {
                search: {
                    state: ProgressState.Stopped,
                    status : ProgressStatus.STOPPED,
                    resultCount: state.search.resultCount,
                    results: state.search.results,
                    messages : state.search.messages
                },
                searchCancel: {
                    status: "DONE"
                }
            });
        }

        case ApplicationActions.DoPathSearch: {
            signal = getAbortControllerInstance();
            doPost(API.PATH, {qry: JSON.stringify(action.payload)}, true, true, signal).then(response => {
                if (!Utils.isNullOrUndefined(response.data.error)) {
                    appStore.dispatch(handleFail());
                } else {
                    appStore.dispatch(doPathSearchSuccess(response.data, action.payload));
                }
            }).catch(error => {
                console.error(error);
                appStore.dispatch(sessionExpired());
            });
            return Object.assign({}, state, {});
        }

        case ApplicationActions.RunPathSearch: {
            let data = {path: [], progress: [], status: ProgressStatus.RUNNING.toLowerCase()};
            return Object.assign({}, state, {
                pathSearch: data
            });
        }

        case ApplicationActions.DoPathSearchSuccess: {
            const bnli = localStorage.getItem('bnli');
            let {queryData} = action.payload;
            let requestData = {
                bnli: bnli,
                time: queryData.time,
                sessionId: queryData.sessionId,
            }
            const status = action.payload.response.status;

            if (status.toLowerCase() !== ProgressStatus.STOP.toLowerCase()) {
                if (status.toLowerCase() === ProgressStatus.RUNNING.toLowerCase()) {
                    setTimeout(() => {
                        requestData.action = postActions.GET;
                        appStore.dispatch(doPathSearch(requestData));
                    }, 500);
                    return Object.assign({}, state, {
                        pathSearch: action.payload.response
                    });
                } else {
                    return Object.assign({}, state, {
                        pathSearch: action.payload.response
                    });
                }
            } else {
                let data = {path: [], progress: [], status: ProgressStatus.STOP.toLowerCase()};

                return Object.assign({}, state, {
                    pathSearch: data
                });
            }
        }

        case ApplicationActions.CancelRelationsPathSearch : {

            doPost(API.PATH, {
                qry: JSON.stringify(action.payload)
            }, true).then(response => {
                appStore.dispatch(stopPathSearch());
            }).catch(error => {
                console.error(error);
                appStore.dispatch(handleFail)
            });

            return state;
        }

        case ApplicationActions.StopPathSearch: {
            removeAbortControllerInstance();
            let data = {path: [], progress: [], status: ProgressStatus.STOP.toLowerCase()};
            return Object.assign({}, state, {
                pathSearch: data
            });
        }


        case ApplicationActions.NewAppendix: {
            const newAppendix = Utils.isNullOrUndefined(state.newAppendix) ? true : state.newAppendix;
            const prevJudicial = Utils.isNullOrUndefined(state.judicialAppendixData) ? [] : state.judicialAppendixData;
            const id = action.payload.id || action.payload;
            let data = prevJudicial;
            let generate = Object.assign({}, state.generate);
            const analytics = generate.node;
            analytics["Appendix: Judicial Desicions"] = {
                view: "appendix",
                title: "Appendix: Judicial Desicions",
                id: "judicialAppendix"
            };

            const appendixDataStored = JSON.parse(sessionStorage.getItem("includedJudicial"));
            if (appendixDataStored) {
                if (Object.keys(appendixDataStored).length === 0) {
                    delete analytics["Appendix: Judicial Desicions"];
                }
            } else {
                delete analytics["Appendix: Judicial Desicions"];
            }
            data = prevJudicial.filter(j => j.id === id);

            if (data.length > 0) {
                data = prevJudicial.filter(j => j.id !== id);
            } else {
                prevJudicial.push(action.payload);
                data = prevJudicial;
            }

            return Object.assign({}, state, {
                newAppendix: newAppendix + 1,
                judicialAppendixRemoveId: null,
                judicialAppendixData: [...data]
            });
        }

        case ApplicationActions.AppendixTree: {
            const appendixTree = Utils.isNullOrUndefined(state.appendixTree) ? true : state.appendixTree;
            let generate = Object.assign({}, state.generate);
            const analytics = generate.node;
            analytics["Appendix: Relation Entities"] = {
                view: "appendixTree",
                title: "Appendix: Relation Entities",
                id: "appendixTree"
            };
            const appendixTreeDataStored = JSON.parse(sessionStorage.getItem("appendixTree"));
            if (Object.keys(appendixTreeDataStored).length === 0) {
                delete analytics["Appendix: Relation Entities"];
            }
            return Object.assign({}, state, {
                appendixTree: appendixTree + 1
            });
        }

        case ApplicationActions.ShowExploreDialog: {
            return Object.assign({}, state, {
                explore: {
                    selectedNodes: action.payload.nodes.selectedNodes,
                    mainNodes: action.payload.nodes.mainNodes,
                    showDots: action.payload.nodes.showDots,
                    status: ProgressStatus.DONE,
                    closeFilter: true
                }
            });
        }

        case ApplicationActions.CloseExploreDialog: {
            return Object.assign({}, state, {
                explore: null
            });
        }

        case ApplicationActions.ShowMergeNodesDialog: {
            return Object.assign({}, state, {
                mergeNodes: {
                    selectedNodes: action.payload.selectedNodes,
                    closeFilter: true
                }
            });
        }

        case ApplicationActions.CloseMergeNodesDialog: {
            return Object.assign({}, state, {
                mergeNodes: null
            });
        }

        case ApplicationActions.AddComponentsScreenshotToCustom: {
            const addComponentsScreenshotToCustom = Utils.isNullOrUndefined(state.addComponentsScreenshotToCustom) ? true : state.addComponentsScreenshotToCustom;
            let generate = Object.assign({}, state.generate);

            const analytics = generate.node;
            analytics["Appendix: Screenshot from relation explorer"] = {
                view: "appendixGraph",
                title: "Appendix: Screenshot from relation explorer",
                id: "appendixGraph"
            };
            const appendixGraphDataStored = JSON.parse(sessionStorage.getItem("Graph"));
            if (appendixGraphDataStored.length === 0) {
                delete analytics["Appendix: Screenshot from relation explorer"];
            }
            return Object.assign({}, state, {
                addComponentsScreenshotToCustom: addComponentsScreenshotToCustom + 1
            });
        }

        case ApplicationActions.CanvasToImage: {
            const changeCanvasToImage = Utils.isNullOrUndefined(state.changeCanvasToImage) ? true : state.changeCanvasToImage;
            return Object.assign({}, state, {
                changeCanvasToImage: changeCanvasToImage + 1
            });
        }


        case ApplicationActions.SessionExpired: {
            const isSessionExpired = Utils.isNullOrUndefined(state.isSessionExpired) ? false : state.isSessionExpired;
            return Object.assign({}, state, {
                isSessionExpired: !isSessionExpired
            });
        }

        case ApplicationActions.AddToCard: {
            let cardNodesMap = Utils.isNullOrUndefined(JSON.parse(sessionStorage.getItem('cardNodesMap'))) ? {} : JSON.parse(sessionStorage.getItem('cardNodesMap'));
            action.payload.nodes.forEach((node) => {
                let title = node.nameEn || node.titleEn;
                let id = node.id;
                let ardisId = node.ardisId || "";
                let subnodes = node.metadata || [];
                let avatar = Utils.isNullOrUndefined(node.avatar) ? "" : node.avatar;
                let nodeType = Utils.isNullOrUndefined(node.nodeType) ? "" : node.nodeType;
                let subnodeIds = Utils.isNullOrUndefined(node.subnodeIdList) ? subnodes.map(sub => sub.id) : node.subnodeIdList;
                cardNodesMap[node.id] = {id, title, avatar, subnodeIds, nodeType, ardisId};
            });
            sessionStorage.setItem('cardNodesMap', JSON.stringify(cardNodesMap))
            return Object.assign({}, state, {
                cardNodes: cardNodesMap
            });
        }

        case ApplicationActions.EmptyCard: {
            return Object.assign({}, state, {
                cardNodes: {}
            });
        }

        case ApplicationActions.RefreshCard: {
            const pathSearchNodesRefresh = Utils.isNullOrUndefined(state.pathSearchNodesRefresh) ? false : state.pathSearchNodesRefresh;
            return Object.assign({}, state, {
                pathSearchNodesRefresh: !pathSearchNodesRefresh
            });
        }

        case ApplicationActions.EmptyResult: {
            sessionStorage.removeItem('notRelevant');
            sessionStorage.setItem('searchData', JSON.stringify(action.payload.searchData));
            sessionStorage.setItem('resultFrom', 'search')

            if (action.payload.notRelevant) {
                sessionStorage.setItem('notRelevant', action.payload.notRelevant)
            }
            return state;
        }

        case ApplicationActions.HandleFail: {
            return Object.assign({}, state, {
                fetchFail: !state.fetchFail
            });
        }

        case ApplicationActions.NoServer: {
            return Object.assign({}, state, {
                noServer: true
            });
        }


        case ApplicationActions.ClearStore: {
            return Object.assign({}, state, {
                search: {
                    state: ProgressState.None,
                    status: ProgressStatus.NONE,
                    resultCount: 0,
                    results: [],
                    messages: [],
                }
            });
        }

        default:
            return state;
    }
};

function getAbortControllerInstance() {
    controller = new AbortController();
    return controller.signal;
}

function removeAbortControllerInstance() {
    controller.abort();
    signal = null;
}

export default application;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cytoscape from 'cytoscape'

import dagre from "cytoscape-dagre";
import fcose from 'cytoscape-fcose';
import {
    Box, Icon, Grid, Paper, Dialog, Button, MenuItem, Checkbox, TextField, DialogContent, DialogTitle, InputAdornment,
    CircularProgress, FormControlLabel, Tabs, Tab, Fab
} from "@mui/material";
import {ThemeProvider } from "@mui/material/styles"
import ContentRemove from '@mui/icons-material/Remove';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import Utils from "../../utils";
import Relations from "./Relations.jsx";
import {API, doPostPdf} from '../../api';
import {INITIAL_STATE, getNextColor, GraphCategoriesFilterTitles, clientType, postActions} from "../../constants";
import {
    doPathSearch,
    generateReport,
    runPathSearch,
    emptyCard,
    cancelRelationsPathSearch
} from "../actions";
import {
    showScreenshotTitleDialog,
    showGraphScreenshotEditDialog,
    showConfirmDialog,
    showAlertDialog
} from "../../shared/dialog/actions";
import PolixisRoutes from "../../routes";
import {ArdisBadge, cardNodesColor, ReportArdisBadge, styleMenu} from "./constants/cardNodesStyles";


cytoscape.use(dagre);
cytoscape.use(fcose);
cytoscape.warnings(false)

const mapDispatchToProps = dispatch => {
    return {
        doPathSearch: query => dispatch(doPathSearch(query)),
        cancelRelationsPathSearch: (data) => dispatch(cancelRelationsPathSearch(data)),
        emptyCard: () => dispatch(emptyCard()),
        generateReport: (ids, empty, isInReport, isOnlyNews, newTab) => dispatch(generateReport(ids, empty, isInReport, isOnlyNews, newTab)),
        runPathSearch: () => dispatch(runPathSearch())
    };
};

const mapStateToProps = (state = INITIAL_STATE) => {
    return {
        pathSearch: state.application.pathSearch,
        projectId: state.application.search.projectId,
        cardNodes: state.application.cardNodes
    };
};

class CardNodes extends React.Component {
    constructor(props) {
        super(props);
        this.statusBar = null;
        const nodes = (Utils.isNotNullOrUndefined(props.cardNodes) && props.cardNodes.length > 0) ? props.cardNodes : JSON.parse(sessionStorage.getItem('cardNodesMap'));
        this.nodes = {};
        this.edges = {};
        this.state = {
            isMaximize: false,
            showList: false,
            nodes,
            selectedNodes: {},
            from: {},
            pathSearch: {},
            completed: 0,
            tab: 'search',
            historyNode: '',
            btnLabel: "Search Relations",
            titlesFrom: [],
            selectedAll: false,
            layout: 'cose',
            logs: [],
            getImage: false,
            pdfView: false,
            pdfItems: [],
            downloading: false,
            loading: false,
            dragIndexFrom: -1,
            dragIndexTo: -1,
            pathItemHeight: false,
            selectedInPdf: -1,
            base: "",
            filter: {},
            doFilter: false,
            showFilter: false,
            updateGraph: 0,
            showOrHideTransitionNodes: false,
            showFormerNodes: true,
            refreshGraph: false,
            defaultGraph: false,
            anotherFilter: [],
            selectedFilter: '',
            filtered: null,
            changeHistoryNodes: false,
            graphView: false
        }
    }

    render() {
        const body = document.body;
        if (this.state.isMaximize) {
            body.classList.add("bodyOverflow");
        } else {
            body.classList.remove("bodyOverflow");
        }
        let logMessages = [];
        this.state.logs.forEach((log, i) => {
            logMessages.push(<h5 key={i}>{log}</h5>);
        });

        if (Utils.isNotNullOrUndefined(this.statusBar)) {
            if (this.statusBar.scrollHeight - this.statusBar.scrollTop !== this.statusBar.clientHeight) {
                this.statusBar.scrollTop = this.statusBar.scrollHeight;
            }
        }

        const sourceNodes = [];
        if (Utils.isNotNullOrUndefined(this.state.from)) {
            Object.keys(this.state.from).forEach(item => sourceNodes.push(this.state.from[item].title))
        }
        if (this.state.isMaximize) {
            return (
                <Dialog open={true} fullScreen className='cartNodes-fullscreen-dialog' style={{ zIndex: 1500 }}>
                    <DialogTitle className='title-cartNodes'>
                        <Grid container justifyContent='space-between'>
                            <Grid item>Your Cart</Grid>
                            <Grid item>{this.renderRightPart()}</Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <div className="full-screen-component" style={{
                            height: "100%",
                            width: '100%',
                            position: 'absolute',
                            textAlign: "left",
                            lineHeight: "normal!important"
                        }}>

                            {this.state.pathSearch.status !== "DONE" &&
                                <div className='log-container'>
                                    <h3>Log Messages</h3>
                                    <div ref={ref => this.statusBar = ref} className='logger'>{logMessages}</div>
                                </div>
                            }

                            {this.state.pathSearch.hasOwnProperty('path') > 0 &&
                                <div className='path' />
                            }
                            {Utils.isNotNullOrUndefined(this.state.pathSearch.progress) && Object.keys(this.state.pathSearch.progress).length > 0 && this.state.pathSearch.status === 'RUNNING' &&
                                this.renderLoading()
                            }
                            {(this.state.pathSearch.status === 'DONE' || (this.state.pathSearch.status === 'stop' && Utils.isNotNullOrUndefined(this.state.pathSearch.graph) && Utils.isNotNullOrUndefined(this.state.pathSearch.graph.nodes) && this.state.pathSearch.graph.nodes.length > 0)) &&
                                <div className='cardNodes-container'>
                                    {this.state.loading &&
                                        <div className='generating-message'> Generating Graph...</div>
                                    }
                                    {this.renderGraphTools()}
                                    <Relations
                                        nodes={this.state.pathSearch.graph.nodes}
                                        edges={this.state.pathSearch.graph.edges}
                                        ref="relation"
                                        layout={this.state.layout}
                                        getImage={this.state.getImage}
                                        setFilter={(filter) => this.setFilter(filter)}
                                        filter={this.state.filter}
                                        selectedFilter={this.state.selectedFilter}
                                        filtered={this.state.filtered}
                                        status={this.state.pathSearch.status}
                                        doFilter={this.state.doFilter}
                                        sourceNodes={sourceNodes}
                                        updateGraph={this.state.updateGraph}
                                        showFormerNodes={this.state.showFormerNodes}
                                        changeHistoryNodes={this.state.changeHistoryNodes}
                                        showOrHideTransitionNodes={this.state.showOrHideTransitionNodes}
                                        refreshGraph={this.state.refreshGraph}
                                        defaultGraph={this.state.defaultGraph}
                                        onClearAll={(filter) => this.setFilter(filter)}
                                        onGraphClick={() => this.handleClick()}
                                    />
                                </div>
                            }
                            {this.state.pdfView && this.state.pathSearch.status === 'DONE' &&
                                <div className='pdf-report'>
                                    <div className="remove-from-pdf">
                                        <button className="btn btn-floating btn-large waves-effect waves-light btn-generate"
                                            onClick={() => this.genPdf()}>
                                            <span
                                                style={{ padding: '0 10px' }}>{this.state.downloading ? "Downloading" : "Generate pdf"}</span>
                                        </button>
                                    </div>

                                    <div id="itemsToPdf" style={{ marginTop: "50px" }}>
                                        {Utils.isNotNullOrUndefined(this.state.pathSearch.graph) && Utils.isNotNullOrUndefined(this.state.pathSearch.graph.nodes) && this.state.pathSearch.graph.nodes.length > 0 &&
                                            <div style={{ marginBottom: "30px" }}>
                                                <h4 style={{ color: "#ef4254", textAlign: "center" }}>Relation Graph
                                                    Complete</h4>
                                                <div style={{
                                                    textAlign: "center",
                                                    marginBottom: "20px",
                                                    lineHeight: "normal"
                                                }}>
                                                    All nodes involved in paths connecting selected entities
                                                </div>
                                                <div className="avoid-break"><img alt='avoidImg' src={this.state.base} width="100%" /></div>
                                            </div>}
                                        {this.renderPdfView()}
                                    </div>
                                </div>}
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else {
            return this.renderCard();
        }
    }

    renderCard() {
        let badgeCount = Utils.isNotNullOrUndefined(this.state.nodes) ? Object.keys(this.state.nodes).length : 0;
        return (
            <div style={(this.props.isInReport && !this.state.isMaximize) ? { padding: "10px 0 0 20px" } : {}}>
                {this.props.isInReport ? <ReportArdisBadge
                    badgeContent={badgeCount}
                    onClick={() => badgeCount > 0 && this.setState({ showList: !this.state.showList })}
                    className={badgeCount > 1 ? 'pointer' : ''}>
                    <Button
                        key="btn_4"
                        style={(this.state.isMaximize || this.props.isInReport) ? {
                            color: '#ffffff',
                            marginTop: "-4px"
                        } : { color: '#7d7d7d', marginTop: "-1px" }}
                    >Cart Nodes</Button>
                </ReportArdisBadge>
                    : <ArdisBadge
                        badgeContent={badgeCount}
                        onClick={() => badgeCount > 0 && this.setState({ showList: !this.state.showList })}
                        className={badgeCount > 1 ? 'pointer' : ''}>
                        <Button
                            key="btn_4"
                            style={(this.state.isMaximize || this.props.isInReport) ? {
                                color: '#ffffff',
                                marginTop: "-4px"
                            } : { color: '#7d7d7d', marginTop: "-1px" }}
                        >Cart Nodes</Button>
                    </ArdisBadge>
                }
                {this.state.showList && this.renderList()}
            </div>
        );
    }

    renderList() {
        let menuItem = [];
        for (let i in this.state.nodes) {
            let node = this.state.nodes[i];
            const from = Object.keys(this.state.from);
            let disabledRemove = this.state.pathSearch.status === 'RUNNING';
            menuItem.push(
                <Grid container key={node.title + i} style={{ padding: '5px 15px' }} justifyContent='space-between'
                    alignItems='flex-start'>
                    <ThemeProvider theme={cardNodesColor}>
                        <Grid item xs={10}>
                            <FormControlLabel
                                key={"to" + node.titleEn}
                                data-testid='endpoints-checkbox'
                                control={<Checkbox
                                    className='checkbox-app'
                                    checked={from.indexOf(i) !== -1}
                                    onChange={() => this.selectCardNode(i, node.title, node.subnodeIds, "from")} />}
                                label={<Box style={{
                                    maxWidth: '400px',
                                    fontSize: '14px',
                                    color: 'rgba(0, 0, 0, 0.87)'
                                }}>{node.title} <span
                                    style={{ color: "#ef4254", fontSize: "11px" }}>({node.ardisId})</span> </Box>} />
                        </Grid>
                    </ThemeProvider>
                    <Grid item xs={2} container justifyContent="center">
                        <Fab size="small" className="fab-button" disabled={disabledRemove} component="div"
                            style={{
                                transform: "scale(0.6)",
                                marginTop: '-11px',
                                backgroundColor: "#ef4254",
                                color: '#fff'
                            }}
                            onClick={() => this.cardNodeDelete(i)}>
                            <ContentRemove />
                        </Fab>
                    </Grid>
                </Grid>
            );
        }

        let disableSearchPath = Object.keys(this.state.from).length < 1;

        return (
            <Paper
                style={this.props.isInReport ? styleMenu.paper : this.state.isMaximize ? styleMenu.paper1 : styleMenu.paperMin}>
                <ThemeProvider theme={cardNodesColor}>
                    <Tabs
                        value={this.state.tab}
                        style={{ marginTop: "0px", backgroundColor: "#efefef" }}
                        centered>
                        <Tab
                            label="Search Relations"
                            value="search"
                            style={{
                                color: "#ef4254",
                                marginTop: "0px",
                                fontSize: '14px',
                                width: '100%',
                                maxWidth: '100%'
                            }} />
                    </Tabs>
                </ThemeProvider>
                <div>
                    <br />
                    <div style={{
                        padding: this.props.isInReport ? "15px" : "10px",
                        maxHeight: "80vh",
                        overflow: "hidden auto"
                    }}>
                        <div className="row" style={{ marginBottom: "20px" }}>
                            <div className="col-xs-12" style={{ fontSize: "20px", marginLeft: '8px' }}>Endpoints</div>
                        </div>
                        {menuItem}

                        <Grid container justifyContent='flex-end' spacing={1}>
                            <Grid item>
                                <Button
                                    onClick={() => this.doPathSearch()}
                                    disabled={disableSearchPath}
                                    className="fab-explore cartNodes-actions"
                                    style={{ padding: this.props.isInReport ? "0 16px" : "6px 15px" }}
                                >
                                    <span className='label-actions'>{this.state.btnLabel}</span>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => this.showTabDialog()}
                                    disabled={disableSearchPath}
                                    className="fab-explore cartNodes-actions"
                                    style={{ padding: this.props.isInReport ? "0 16px" : "6px 15px" }}
                                >
                                    <span className='label-actions'>Explore</span>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => this.emptyState()}
                                    className="fab-explore cartNodes-actions"
                                    style={{ padding: this.props.isInReport ? "0 16px" : "6px 15px" }}
                                >
                                    <span className='label-actions'>Empty Cart</span>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => this.setState({ showList: false })}
                                    className="fab-explore cartNodes-actions"
                                    style={{ padding: this.props.isInReport ? "0 16px" : "6px 15px" }}
                                >
                                    <span className='label-actions'>Close</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Paper>
        );
    }

    renderLoading() {
        let progress = [];
        let marginTop = this.props.isInReport ? 7 : 17
        Object.keys(this.state.pathSearch.progress).forEach((p, i) => {
            let level = this.state.pathSearch.progress[p];
            let size = 100 + (i + 3) * 50;
            progress.push(<div key={"ab" + p + level.distance + i} className='progress-item'>

                <CircularProgress
                    style={{
                        position: "absolute", marginLeft: -size / 2, marginTop: -size / 2, transform: "rotate(-90deg)",
                        WebkitTransform: "rotate(-89deg)",
                        color: getNextColor(i)
                    }}
                    key={"ci" + level.distance + i}
                    value={level}
                    size={size}
                    variant="determinate"
                    thickness={1}
                />
                <div style={{
                    position: "absolute", width: "100px",
                    marginTop: -size / 2 - marginTop, marginLeft: -13,
                    color: '#ef4254', fontWeight: "bold", zIndex: '200'
                }}>{level}</div>
            </div>)
        });
        return (
            <div className='progress-container'>
                {progress}
                <div className='progress-main-img'>
                    <img alt='relation' src="/images/relation.png" style={{ width: "200px" }} />
                </div>
            </div>
        );
    }


    renderPdfView() {
        const onDragEnd = (result) => {
            const { destination, draggableId } = result;
            if (!result.destination) {
                return
            }
            const removed = this.state.pdfItems.filter((item, index) => index !== parseInt(draggableId));
            removed.splice(destination.index, 0, this.state.pdfItems[parseInt(draggableId)]);
            this.setState({ pdfItems: [...removed] });
        }
        return (
            <div>
                <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                    <Droppable droppableId='question-list' type='list' data-react-beautiful-dnd-scroll-container='1'>
                        {provided => (
                            <div {...provided.droppableProps}
                                ref={provided.innerRef}
                                className='pdfWrapper'
                            >
                                {
                                    this.state.pdfItems.length > 0 &&
                                    this.state.pdfItems.map((item, index) => {
                                        return (
                                            <div key={index} className='draggablePdf avoid-break'>
                                                <Draggable draggableId={String(index)} index={index}>
                                                    {provided => (
                                                        <div {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            <div key={item.id} className='innerItem'>
                                                                <span>
                                                                    <i className="material-icons remove-from-pdf itemDelete"
                                                                        title="Remove Item"
                                                                        onClick={() => this.removeCardItem(index)}
                                                                    >close</i>
                                                                    <i className="material-icons remove-from-pdf itemEdit"
                                                                        title="Edit Item"
                                                                        onClick={() => this.editCardItem(index, item.title, item.description)}
                                                                    >edit</i>
                                                                </span>
                                                                <h4 style={{ color: "#ef4254", textAlign: "center" }}>{item.title}</h4>
                                                                <div style={{ textAlign: "center", marginBottom: "20px" }}>{item.description}</div>
                                                                <div className="avoid-break"><img alt='breakImage' src={item.base} width="100%" /></div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            </div>
                                        )
                                    })
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        )
    }

    renderRightPart() {
        return (
            <div className='right-part'>
                {this.state.isMaximize && this.state.showList && this.renderList()}
                {!this.state.isMaximize && this.state.showList && this.renderCard()}

                {Utils.isNotNullOrUndefined(this.state.pathSearch) && this.state.pathSearch.status === "DONE" && Utils.isNotNullOrUndefined(this.state.pathSearch.graph) && Utils.isNotNullOrUndefined(this.state.pathSearch.graph.nodes)
                    && this.state.pathSearch.graph.nodes.length > 0 && this.state.base.length > 0 &&
                    <img src="/images/pdf_path.png" className="pointer" title="Toggle pdf view" alt="Toggle pdf view"
                        style={{ position: "absolute", marginTop: "-5px", marginLeft: '-80px', }}
                        onClick={() => this.togglePdfView()} />
                }

                <img src={this.state.showList ? "/images/up.png" : "/images/down.png"} className="pointer"
                    title="Toggle list" alt="Toggle list"
                    onClick={() => this.setState({ showList: !this.state.showList, pdfView: false })}
                    style={{ position: "absolute", marginTop: '-5px', marginLeft: '-40px' }} />

                <img src="/images/minimize.png" className="pointer" title="Minimize the window"
                    alt="Minimize the window" style={{ position: "absolute", marginTop: "-5px" }}
                    onClick={() => this.minimize()} />
            </div>
        );
    }

    renderGraphTools() {
        return (
            Utils.isNotNullOrUndefined(this.state.pathSearch) && this.state.pathSearch.status === "DONE" && Utils.isNotNullOrUndefined(this.state.pathSearch.graph) && Utils.isNotNullOrUndefined(this.state.pathSearch.graph.nodes) && this.state.pathSearch.graph.nodes.length > 0 &&
            <div className='graph-filter-container'>
                <TextField
                    SelectProps={{
                        SelectDisplayProps: {
                            style: { paddingLeft: "56px", marginLeft: "-56px", paddingBottom: "3px" }
                        },
                        MenuProps: {
                            style: { zIndex: 2000 },
                            PaperProps: {
                                elevation: 2,
                                style: { margin: "23px 0 0 15px" },
                                className: "graphSelectPaper"
                            },
                            MenuListProps: { style: { padding: "16px 0" } }
                        },
                        style: {
                            fontSize: "15px",
                            letterSpacing: "0px",
                            lineHeight: "28px",
                            color: '#000000de',
                            backgroundColor: "#fff!important"
                        }
                    }}
                    select
                    margin='dense'
                    variant={'standard'}
                    value={this.state.layout}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><span className='adornment'
                            style={{ color: "#000000de" }}>Layout:</span></InputAdornment>,
                    }} style={{ marginTop: "7px", marginLeft: "10px", marginRight: "20px" }}
                    className='text-field-layout'
                    onChange={(event) => this.changeLayout(event.target.value)}>
                    <MenuItem value="grid" className='graphSelectItem' style={{
                        fontSize: '14px',
                        backgroundColor: "#fff",
                        color: this.state.layout === "grid" ? "#ef4254" : "rgba(0, 0, 0, 0.87)"
                    }}>Grid</MenuItem>
                    <MenuItem value="circle" className='graphSelectItem' style={{
                        fontSize: '14px',
                        backgroundColor: "#fff",
                        color: this.state.layout === "circle" ? "#ef4254" : "rgba(0, 0, 0, 0.87)"
                    }}>Circle</MenuItem>
                    <MenuItem value="concentric" className='graphSelectItem' style={{
                        fontSize: '14px',
                        backgroundColor: "#fff",
                        color: this.state.layout === "concentric" ? "#ef4254" : "rgba(0, 0, 0, 0.87)"
                    }}>Concentric</MenuItem>
                    <MenuItem value="breadthfirst" className='graphSelectItem' style={{
                        fontSize: '14px',
                        backgroundColor: "#fff",
                        color: this.state.layout === "breadthfirst" ? "#ef4254" : "rgba(0, 0, 0, 0.87)"
                    }}>Breadthfirst</MenuItem>
                    <MenuItem value="cose" className='graphSelectItem' style={{
                        fontSize: '14px',
                        backgroundColor: "#fff",
                        color: this.state.layout === "cose" ? "#ef4254" : "rgba(0, 0, 0, 0.87)"
                    }}>Smart</MenuItem>
                    <MenuItem className="graphSelectItem" value="dagre" style={{
                        fontSize: '14px',
                        backgroundColor: "#fff",
                        color: this.state.layout === "dagre" ? "#ef4254" : "rgba(0, 0, 0, 0.87)"
                    }}>Dagre (beta)</MenuItem>
                </TextField>

                <div className='graph-filter'>
                    {this.state.pathSearch.status === "DONE" && Utils.isNotNullOrUndefined(this.state.pathSearch.graph) && Utils.isNotNullOrUndefined(this.state.pathSearch.graph.nodes) && this.state.pathSearch.graph.nodes.length > 0 && this.state.showFilter && this.renderFilter()}
                    <div style={{ color: "#000000de" }} className='filter-content' onClick={() => this.toggleFilter()}>
                        Filter Graph
                        <i className="material-icons filter-icon"> arrow_drop_down</i>
                    </div>
                </div>

                <div className="graph-buttons pull-right">
                    <img src="/images/graph_refresh.png" alt="Autoarrange" title="Autoarrange"
                        onClick={() => this.getRefreshOptions()} className="pointer" />
                    <img src="/images/graph_download.png" className="pointer" alt="DownloadImage"
                        title="Download image" style={{}}
                        onClick={() => this.refs.relation.savePng()} />
                    <img src="/images/screenshot.png" alt="Do Screenshot" title="Take a screenshot of the graph and save it as an Appendix to your PDF report."
                        onClick={() => this.getScreenshotGraph()} className="pointer"
                        style={{}} />
                    <img src="/images/graph_download.png" alt="Back" title="Back" style={{ transform: 'rotate(90deg)' }}
                        onClick={() => this.changeHistoryNodes()} className="pointer" />
                    <img src={this.state.showOrHideTransitionNodes ? "/images/showHideH.png" : "/images/showHideS.png"}
                        alt="Show" className="pointer"
                        title={this.state.showOrHideTransitionNodes ? "Show hidden relations in transparent mode" : "Hide transparent relations"}
                        onClick={() => this.showHideTransitionNodes()} />
                    <span
                        className="pointer"
                        style={{
                            color: "#c6c6c6",
                            fontWeight: "bold",
                            backgroundColor: "#ffffff",
                            padding: "7px 7px 9px",
                            marginTop: "30px"
                        }}
                        title={this.state.showFormerNodes ? "Click to hide the former relations" : "Click to show the former relations"}
                        onClick={() => this.showHideFormerNodes()}>
                        {this.state.showFormerNodes ? "Hide All Formers" : "Show All Formers"}
                    </span>
                </div>
            </div>
        );
    }

    showHideFormerNodes() {
        this.setState({ showFormerNodes: !this.state.showFormerNodes });
    }

    changeHistoryNodes() {
        this.setState({ changeHistoryNodes: !this.state.changeHistoryNodes });
    }

    showHideTransitionNodes() {
        this.setState({ showOrHideTransitionNodes: !this.state.showOrHideTransitionNodes });
    }

    getRefreshOptions() {
        showConfirmDialog({
            title: "Warning",
            message: "Choose arrange position of elements",
            continueButtonLabel: 'Autoarrange',
            continueSecondButtonLabel: 'Cancel all changes',
            onContinue: () => {
                this.setState({ refreshGraph: !this.state.refreshGraph })
            },
            onSecondContinue: () => this.setState({ defaultGraph: !this.state.defaultGraph })
        });
    }

    renderFilter() {
        const time = new Date().getTime();

        return (<div key={"cat" + time} style={{
            color: "#ffffff",
            backgroundColor: "#ffffff",
            padding: "10px 10px 20px 10px",
            width: '200px',
            position: "absolute",
            zIndex: "300",
            border: "1px solid #ececec",
            borderRadius: "2px",
            boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px"
        }}>
            <i className="material-icons"
                style={{ zIndex: "500", color: "#ef4254", position: "absolute", right: "5px", top: "5px" }}
                onClick={() => this.toggleFilter()}>clear</i>
            {this.renderFilterItems()}
        </div>)
    }

    renderFilterItems() {
        return Object.keys(this.state.filter).map((item, index) => {
            const label = item.startsWith("#") ? item.substr(1) : item;
            let labelText = label.startsWith("SHAREHOLDING") ? "shareholding" : label.toLowerCase().replace(/_/g, ' ');
            let title;
            switch (labelText) {
                case 'info':
                    title = GraphCategoriesFilterTitles.INFO;
                    break;
                case 'pep':
                    title = GraphCategoriesFilterTitles.PEP;
                    break;
                case 'sanction':
                    title = GraphCategoriesFilterTitles.SANCTION;
                    break;
                case 'non active':
                    title = GraphCategoriesFilterTitles.NONACTIVE;
                    break;
                default:
                    title = GraphCategoriesFilterTitles.OTHER + labelText + '.';
            }

            const filterContent = <FormControlLabel
                title={title}
                key={labelText}
                style={{ textTransform: "capitalize", color: "rgba(0, 0, 0, 0.87)" }}
                label={<div style={{ fontSize: "14px" }}>{labelText}</div>}
                control={<Checkbox
                    style={{ marginLeft: "2px", marginRight: "7px" }}
                    checkedIcon={<CheckBoxIcon className="material-icons"
                        style={{ fontSize: "24px", color: "#ef4254" }} />}
                    icon={<CheckBoxOutlineBlankIcon className="material-icons"
                        style={{ fontSize: "24px", color: "#6e7072" }} />}
                    checked={this.state.filter[item]}
                    onChange={() => this.selectFilterItem(item)}
                />}
            />;
            if (index === 0 || index === 5) {
                const icon = index === 0 ? 'panorama_fish_eye_icon' : 'linear_scale';
                const text = index === 0 ? 'Entity' : 'Relationships';

                return <div key={labelText + 'container' + index}>
                    <div className='filter-title-container' style={{ marginBottom: "0px" }}>
                        <div className='filter-title'>
                            <Icon fontSize="large" className="material-icons filter-icon"
                                style={{ position: "relative", margin: 0, color: "#ef4254" }}>{icon}</Icon>
                        </div>
                        <div>{text}</div>
                    </div>
                    <div style={{
                        width: "170px",
                        minWidth: "170px",
                        marginBottom: "2px",
                        zIndex: "100px",
                        height: "30px"
                    }}>
                        {filterContent}
                    </div>
                </div>
            } else {
                return <div key={index}
                    style={{
                        width: "170px",
                        minWidth: "170px",
                        marginBottom: "2px",
                        zIndex: "100px",
                        height: "33px"
                    }}>
                    {filterContent}
                </div>;
            }
        })
    }

    getGraphSelector() {
        const graph = document.getElementById('cytoscape_1');
        return graph?.querySelector('canvas[data-id=layer2-node]');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (Utils.isNotNullOrUndefined(nextProps.cardNodes) && Object.keys(nextProps.cardNodes).length > 0) {
            this.setState({ nodes: nextProps.cardNodes, selectedAll: false })
        }
        let logs1 = this.state.logs;
        if (Utils.isNotNullOrUndefined(nextProps.pathSearch) && Utils.isNotNullOrUndefined(nextProps.pathSearch.log)) {
            nextProps.pathSearch.log.forEach(l => {
                logs1.push(l)
            })
        }
        this.setState({ pathSearch: nextProps.pathSearch, logs: logs1 })
        if (Utils.isNotNullOrUndefined(nextProps.pathSearch) && nextProps.pathSearch.status === 'DONE') {
            this.setState({ loading: true, graphNodes: nextProps.pathSearch.graph.nodes }, () => {
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 3000)
            })
            setTimeout(() => {
                if (Utils.isNotNullOrUndefined(nextProps.pathSearch.graph) && Utils.isNotNullOrUndefined(nextProps.pathSearch.graph.nodes) && nextProps.pathSearch.graph.nodes.length > 0) {
                    const imageSrc = this.getGraphSelector();
                    if (Utils.isNotNullOrUndefined(imageSrc)) {
                        let imageSrcData = imageSrc.toDataURL("image/png");
                        this.setState({ base: imageSrcData });
                    }
                }
            }, 5000)
            this.nodes = {};
            this.edges = {};

            this.setState({
                relationNodes: this.nodes,
                relationEdges: this.edges,
                btnLabel: "Search Relations"
            }, () => {
                this.setState({ pathSearch: nextProps.pathSearch, showList: false })
            })
        }
    }

    minimize() {
        this.cancelSearch()
        this.setState({
            isMaximize: false,
            pathSearch: {},
            btnLabel: "Search Relations",
            titlesFrom: [],
            logs: [],
            relationNodes: {},
            relationEdges: {},
            historyNode: '',
            base: '',
            pdfView: false,
            pdfItems: [],
            graphView: false
        });
    }

    maximize() {
        this.setState({ isMaximize: true, showList: true, from: {} })
    }

    togglePdfView() {
        let src;
        if (this.state.graphView) {
            src = this.state.base;
        } else {
            const graph = this.getGraphSelector();
            src = graph.toDataURL("image/png");
        }

        this.setState({ pdfView: !this.state.pdfView, showList: false, base: src, graphView: true })
    }

    cardNodeDelete(id) {
        let nodes = this.state.nodes;
        delete nodes[id];
        this.setState({ nodes });

        let selectedNodeMap = this.state.selectedNodes;
        let selectFromOrToMap = this.state.from;

        if (Object.keys(selectedNodeMap).indexOf(id) !== -1) {
            delete selectedNodeMap[id];
        }
        if (Object.keys(selectFromOrToMap).indexOf(id) !== -1) {
            delete selectFromOrToMap[id];
            this.setState({ from: selectFromOrToMap })
        }

        sessionStorage.setItem('cardNodesMap', JSON.stringify(nodes))
    }

    selectCardNode(nodeId, title, subnodeIds, isFrom) {
        let selectFromOrToMap = this.state[isFrom];

        if (Object.keys(selectFromOrToMap).indexOf(nodeId) !== -1) {
            delete selectFromOrToMap[nodeId];
        } else {
            selectFromOrToMap[nodeId] = { id: nodeId, title: title, subnodeIds };
        }
        this.setState({ [isFrom]: selectFromOrToMap })
    }

    doPathSearch() {
        if (Object.values(this.state.from).length < 2 || Object.values(this.state.from).length > 10) {
            showAlertDialog({ title: "The search path limit for selected entities is between 2 to 10.", style: { zIndex: "5000" } })
        } else {
            let log = [];
            const bnli = localStorage.getItem('bnli');
            this.setState({ logs: log, doFilter: false, selectCategory: [] }, () => {
                if (!this.state.isMaximize) this.setState({ isMaximize: true });
                if (this.state.btnLabel === "Search Relations") {
                    this.setState({ btnLabel: "Stop Search", logs: log, showList: false })
                    let fromIds = [];
                    let titlesFrom = [];
                    Object.values(this.state.from).forEach(val => {
                        if (val.subnodeIds.length > 0) {
                            fromIds.push(val.subnodeIds);
                        } else {
                            fromIds.push([val.id]);
                        }
                        titlesFrom.push(val.title);
                    })

                    this.setState({ titlesFrom })
                    const requestData = {
                        action: "search",
                        time: new Date().getTime(),
                        endpoints: fromIds,
                        sessionId: "testweb_" + new Date().getTime(),
                        logLevel: 2,
                        radius: 3,
                        bnli: bnli,
                    };
                    sessionStorage.setItem("pathSessionId", requestData.sessionId);
                    this.props.runPathSearch();
                    let pathSearch = this.state.pathSearch;
                    pathSearch.status = 'RUNNING';
                    this.setState({ pathSearch }, () => {
                        this.props.doPathSearch(requestData);
                    })
                } else {
                    this.setState({ btnLabel: "Search Relations" });
                    this.cancelSearch();
                }
            })
        }
    }
    changeLayout(value) {
        this.setState({ layout: value });
    }

    showTabDialog() {
        showConfirmDialog({
            title: "Explore selected entities",
            onContinue: () => this.doExplore(false),
            onSecondContinue: () => this.doExplore(true),
            continueButtonLabel: "Continue",
            continueSecondButtonLabel: "Continue in new tab"
        });
    }

    doExplore(newTab) {
        const subnodes = [];
        Object.values(this.state.from).forEach(selectedNode => {
            if (selectedNode.subnodeIds.length > 0) {
                selectedNode.subnodeIds.forEach(id => {
                    subnodes.push(id);
                });
            } else {
                subnodes.push(selectedNode.id);

            }
        });
        this.props.generateReport(subnodes, false, this.props.isInReport, false, newTab);
        !newTab && this.props.onReport(PolixisRoutes.App.Report)
    }

    cancelSearch() {
        const prId = sessionStorage.getItem('prId');
        const bnli = localStorage.getItem('bnli');

        const requestData = {
            action: postActions.STOP,
            time: new Date().getTime(),
            bnli: bnli,
            projectId: prId,
            sessionId: sessionStorage.getItem("pathSessionId"),
        };
        this.props.cancelRelationsPathSearch(requestData);
    }

    getScreenshotGraph() {
        const graphSelector  = this.getGraphSelector();
        if (graphSelector) {
            const imageSrcData = graphSelector.toDataURL("image/png");

            showScreenshotTitleDialog({
                title: "Title for screenshot from relation explorer",
                onContinue: (value, description) => this.getScreenshot(imageSrcData, value, description)
            });
        }
    }

    getScreenshot(base, title, description) {
        let data = { base, title, description }
        let screenshot = this.state.pdfItems.slice(0);
        screenshot.push(data);
        this.setState({ pdfItems: screenshot })

    }

    editCardItem(index, value, description) {
        showGraphScreenshotEditDialog({
            title: "Title for screenshot from relation explorer", index, value, description,
            onContinue: (index, value, description) => this.setScreenshot(index, value, description)
        });
    }

    setScreenshot(index, value, description) {
        let screenshotData = this.state.pdfItems.slice(0);
        screenshotData[index].title = value;
        screenshotData[index].description = description;
        this.setState({ pdfItems: [] }, () => {
            this.setState({ pdfItems: screenshotData })
        })
    }

    removeCardItem(index) {
        let screenshot = this.state.pdfItems.slice(0);
        screenshot.splice(index, 1);
        this.setState({ pdfItems: [] }, () => {
            this.setState({ pdfItems: screenshot })
        })
    }

    genPdf() {
        setTimeout(() => {
            const theResult = this.strRemove('remove-from-pdf');
            let endpoints = [];
            this.state.titlesFrom.forEach(title => {
                endpoints.push('<div style="text-align: center; font-size:16px"><span class="circle0">' + title + '</span></div>')
            })
            this.setState({ downloading: true });
            const entityIds = Object.keys(this.state.from).join(", ");
            const pdfContent = '<!DOCTYPE html><html lang="en"><meta charset="utf-8"> <head><base href="' + process.env.REACT_APP_BASE + '/" />' +
                '<meta http-equiv="conent-type" content="text/html;carset=UTF-8"><meta name="viewport" content="initial-scale=1, width=1200">' +
                '<link rel="stylesheet" type="text/css" href="/libs/bootstrap/dist/css/bootstrap.min.css"/>' +
                '<link rel="stylesheet" type="text/css" href="/libs/bootstrap/dist/css/bootstrap-theme.min.css"/>' +
                '<link rel="stylesheet" type="text/css" href="/css/polixis1.css"/><title></title></head><body style="margin: 0; padding: 0;">' +
                '<div class="row"><img alt="noResultBanner" src="/images/no_result_banner.png" style="width: 100%"/></div>' +
                '<div  style="width: 100%; text-align: center; font-size: 20px; color: #ef4254; margin-top: 30px; margin-bottom: 30px "/> SEARCHED RELATIONS WITHIN THE FOLLOWING ENDPOINTS' + endpoints.join(" ") + '</div>' +
                theResult + '</body></html>';
            this.generatePdf(pdfContent, entityIds);
        }, 100);
    }

    generatePdf(html, ids) {
        doPostPdf(API.GENERATE_PDF, { html, entityIds: ids, requestedDate: Date.now(), requestedFrom: clientType }, true).then(response => {
            const { data, headers } = response;
            this.setState({ downloading: true })
            this.downloadFile(data, headers['content-type']);
        }).catch(() => {
            this.setState({ downloading: false });
        });
    }

    strRemove(theTarget) {
        const html = document.getElementById('itemsToPdf');
        const cloned = html.cloneNode(true);
        const remove = cloned.getElementsByClassName(theTarget);
        for (let child of remove) {
            if (child.parentNode) {
                child.innerHTML = ''
            }
        }
        const div = document.createElement('div');
        div.appendChild(cloned);
        return div.innerHTML;
    }

    downloadFile(data, contentType) {
        const fileName = `relationGraph.pdf`;
        Utils.downloadFileAndClose(fileName, data, contentType);
        this.setState({ downloading: false });
    }

    emptyState() {
        this.setState({
            isMaximize: false,
            showList: false,
            nodes: [],
            selectedNodes: {},
            from: {},
            pathSearch: {},
            completed: 0,
            tab: 'search',
            historyNode: '',
            btnLabel: "Search Relations",
            titlesFrom: [],
            selectedAll: false,
            layout: 'concentric',
            logs: [],
            getImage: false,
            pdfView: false,
            pdfItems: [],
            downloading: false,
            loading: false,
            dragIndexFrom: -1,
            dragIndexTo: -1,
            pathItemHeight: false,
            selectedInPdf: -1,
            base: "",
        }, () => {
            sessionStorage.removeItem('cardNodesMap');
            this.props.emptyCard();
        });
    }

    setFilter(filter) {
        this.setState({ filter })
    }

    toggleFilter() {
        this.setState({ showFilter: !this.state.showFilter })
    }

    handleClick() {
        if (this.state.showFilter) {
            this.setState({ showFilter: !this.state.showFilter })
        }
    }

    selectFilterItem(item) {
        let cat;
        cat = this.state.filter;
        cat[item] = !cat[item];

        this.setState({ filter: cat, selectedFilter: item, filtered: cat[item], doFilter: true, updateGraph: this.state.updateGraph + 1 }, () => {
        })
    }
}

CardNodes.propTypes = {
    cardNodes: PropTypes.object,
    projectId: PropTypes.string,
    cancelSearch: PropTypes.func,
    doPathSearch: PropTypes.func,
    runPathSearch: PropTypes.func,
    pathSearch: PropTypes.object,
    isInReport: PropTypes.oneOf([true, false]),
    generateReport: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardNodes);

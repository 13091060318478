import { DialogActions } from "./actions.js";
import { INITIAL_STATE } from "../../constants";

const dialog = (state = INITIAL_STATE.dialog, action) => {
    switch (action.type) {
        case DialogActions.ShowAlertDialog:
            return Object.assign({}, state, action.payload);
            case DialogActions.ShowJudicialDialog:
            return Object.assign({}, state, action.payload);
        case DialogActions.ShowConfirmDialog:
            return Object.assign({}, state, action.payload);
        case DialogActions.ShowTimerDialog:
            return Object.assign({}, state, action.payload);
        case DialogActions.ShowScreenshotTitleDialog:
            return Object.assign({}, state, action.payload);
        case DialogActions.ShowGraphScreenshotEditDialog:
            return Object.assign({}, state, action.payload);
        case DialogActions.ShowChangePasswordDialog:
            return Object.assign({}, state, action.payload);
        case DialogActions.CloseDialog:
            return {};
        case DialogActions.ShowLegacyNewsDialog:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
};

export default dialog;

import axios from "axios";
import Utils from "./utils";
import {additionalSearchHeader, clientType, axiosCodes} from './constants';

export const API = {
    BASE_URL: process.env.REACT_APP_BASE,
    BASE_AI_URL: "https://192.168.1.13:8180/api",
    BASE_IMG_URL: process.env.REACT_APP_IMG,
    LOGIN: "/oauth/token",
    SEARCH: "/core/search",
    NEWS: "/core/search",
    NEWS_SEARCH: "/core/news",
    PRESS_SEARCH: process.env.REACT_APP_PRESSA_BASE,
    STOP_NEWS: "/core/news",
    STOP: "/core/search",
    MAP_NODES: "/core/mapping",
    UNMAP_NODE: "/core/unmapping",
    ELASTIC_HITS: "/core/search",
    COUNTRIES: "/core/country",
    LANGUAGE: "/core/language",
    PRESSA_LANGUAGE: "/language",
    REPORT_SUGGESTIONS: "/ai_mapper",
    GENERATE_REPORT: "/core/explore",
    GET_RELATED_NODES: "/core/explore",
    GET_NODE_DATA: "/core/explore",
    GENERATE_REPORT_STOP: "/core/explore",
    PATH: "/core/pathmulti",
    GENERATE_PDF: '/pdf/generate',
    CHANGE_PASSWORD: '/oauth/changePassword',
};

export const doGet = (uri, data, isPrivate = true, isBlob = false) => {
    const requestData = data || {};
    const options = createInstance(isPrivate, isBlob);
    options.data = requestData;
    options.url = uri;
    options.method = "get";

    return axios(options);
};

export const doPost = (uri, data, isPrivate = true, isAxiosCancel = false, signal = null, extraHeader = false) => {
    const options = createInstance(isPrivate, false, extraHeader);
    options.data = data;
    options.url = uri;
    options.method = "post";

    if (isAxiosCancel ) {
        options.signal = signal;
    }
    return axios(options);
};

export const doPostPdf = (uri, data, isPrivate, isAlternative = false) => {
    const options = createInstanceArdisPdf(isAlternative);
    options.baseURL = API.BASE_URL;
    options.data = data;
    options.url = uri;
    options.method = "post";
    options.responseType = isAlternative ? 'arraybuffer' : 'blob';

    return axios(options);
}

const createInstance = (isPrivate, isBlob = false, extraHeader = false) => {
    const options = {
        baseURL: API.BASE_URL,
        responseType: isBlob ? 'blob' : "json",
        withCredentials: true
    };
    let headers = {};
    const token = localStorage.getItem('bnli');
    headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
    headers['client_type'] = 'ardis'
    options["transformRequest"] = (data) => {
        const str = [];

        Object.keys(data).forEach(item => {
            str.push(encodeURIComponent(item) + "=" + encodeURIComponent(data[item]));
        });
        return str.join("&");
    }
    if (extraHeader) headers[additionalSearchHeader.key] = additionalSearchHeader.value;

    if (isPrivate) {
        headers["Authorization"] = `Bearer ${token}`
    } else {
        headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
        headers['Authorization'] = `Basic ${btoa('polixis_oauth:(J[py?-4Z_Q->%d>')}`;
    }

    options.headers = headers;
    return options;
};
const createInstanceArdisPdf = () => {
    const options = {
        withCredentials: false,
        cache: false,
    };
    const access_token = localStorage.getItem('bnli');
    options.headers = {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "true",
        "Cache-Control": "no-cache",
        "Pragma": "no-cache",
        "Accept": "application/json,application/x-www-form-urlencoded, text/plain, */*",
        'Content-Disposition': 'attachment;filename=' + encodeURI('tutorial.pdf'),
        'Authorization': `Bearer ${access_token}`,
        'client_type': clientType
    };
    return options;

};

axios.interceptors.response.use(async (response) => {
    const isBlob = response.data instanceof Blob;
    const isArrayBuffer = response.data instanceof ArrayBuffer;
    const structuredResponse = isBlob ? await Utils.getJSONFromBlob(response.data) : isArrayBuffer ? Utils.getJSONFromBuffer(response.data) : response.data;

    if (structuredResponse && structuredResponse.type) {
        if (structuredResponse.type.code === axiosCodes.TOKEN_EXPIRED) {
            let respData = response.config.data;
            let params = Object.fromEntries(new URLSearchParams(respData));
            try {
                const data = {
                    refresh_token: localStorage.getItem('refresh_bnli'),
                    grant_type: 'refresh_token'
                }
                const refreshRequest = await doPost(API.LOGIN, data, false);
                const bnli = refreshRequest.data.data.access_token;
                const refresh_bnli = refreshRequest.data.data.refresh_token;

                localStorage.setItem('bnli', bnli);
                localStorage.setItem('refresh_bnli', refresh_bnli);
                const headers = { ...response.config.headers, 'Authorization': `Bearer ${bnli}` }

                if (params.qry) {
                    let qry = JSON.parse(params.qry);
                    params = { ...qry, bnli: bnli }
                    respData = { qry: JSON.stringify(params) }
                }
                response.config.headers = headers;
                response.config.data = respData;
                return axios(response.config)
            } catch {
                return;
            }
        } else if (structuredResponse.type.code === axiosCodes.REFRESH_EXPIRED || structuredResponse.type.code === axiosCodes.TOKEN_INVALID) {
            localStorage.clear();
            sessionStorage.clear();
            return;
        }
    }
    return response
});




import React from "react";

export default class Error404 extends React.Component {

    render() {
        return  (
            <div style={{display:"flex", alignItems:"center", flexDirection:"column", padding:"15% 0", backgroundColor:"#efefef", height:"100vh"}}>
                <img alt='polixisLogo'  className="polixis-logo pull-left" src="/images/polixis-logo.png"/>
                <header>
                    <h1 style={{color: '#ef4254'}}>404 ERROR</h1>
                    <span>Sorry, this page isn't available</span>
                </header>
                <div style={{margin:"20px"}}>
                    <a className="back-home" href="/">Back to Home Page</a>
                </div>
            </div>
        );
    }
}

import {Switch} from 'react-router-dom';
import ApplicationLayout from "./layouts/ApplicationLayout";
import WebsiteLayout from "./layouts/WebsiteLayout";
import NoResultReport from "./application/report/NoResultReport";
import Report2 from "./application/report/Report2";
import Search from "./application/search/Search";
import NoServer from "./website/components/NoServer";
import Home from "./website/components/Home"
import Login from "./website/components/Login";
import PublicRoute from "./publicRoutes";
import PrivateRoute from "./privateRoutes";
import Error404 from "./shared/components/Error404";

const PolixisRoutes = {
    Website: {
        Home: "/",
        Login: "/login",
        NoServer: "/noserver",

    },
    App: {
        Search: "/search",
        Report: "/report",
        EmptyResult: "/noresult",
        TestPath: "/test"
    },
};

export default PolixisRoutes;


function isLogin() {
    return localStorage.getItem('bnli');
}

export const useCustomRoutes = () => {
    return (
            <Switch>
                <PublicRoute restricted={true} component={Home} layout={WebsiteLayout} path="/" isLoggedIn={isLogin()} exact />
                <PublicRoute restricted={true} component={Login} layout={WebsiteLayout} path="/login" isLoggedIn={isLogin()} exact />
                <PublicRoute component={NoServer} layout={WebsiteLayout} path={PolixisRoutes.Website.NoServer} isLoggedIn={isLogin()}  />
                <PrivateRoute component={Search} layout={ApplicationLayout} path="/search"  isLoggedIn={isLogin()}/>
                <PrivateRoute exact component={Search} layout={ApplicationLayout} path="/" isLoggedIn={isLogin()}/>
                <PrivateRoute component={ Report2 } layout={ApplicationLayout} path={ PolixisRoutes.App.Report } isLoggedIn={isLogin()}/>
                <PrivateRoute component={ NoResultReport } layout={ApplicationLayout} path={ PolixisRoutes.App.EmptyResult }  isLoggedIn={isLogin()}/>
                <PrivateRoute path="*" component={ Error404 } layout={ApplicationLayout}/>
            </Switch>
        )

};

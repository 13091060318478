import React from "react";
import PropTypes from "prop-types";


export default class MediaScreening extends React.Component {
    render(){
        return(
            <div className="main-color font-16"
                 style={{width: '580px', margin: '0 auto', marginTop: '4rem'}}>
                <div onClick={() => this.props.isDisable && this.props.handleClick()}
                     style={{
                         border: '1px solid silver',
                         borderRadius: '7px',
                         cursor: this.props.isDisable ? 'pointer' : 'not-allowed',
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         flexDirection: 'column'
                     }}>
                    <div style={{margin: '2rem 0 0 0',fontSize:'25px', fontWeight: "bold", }}>CLICK HERE</div>
                    <div style={{margin: '0 8px 8px', fontSize:'19px', fontWeight: "bold"}}>for Media Screening</div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        margin: '2rem',
                        width: '100%',
                        alignItems: 'center'
                    }}>
                        <div style={{width: '80px'}}>
                            <img alt='newsSearchBtn' src="/images/searchEngine/polixis.png" style={{width: '100%'}}/>
                        </div>
                        <div style={{width: '80px'}}>
                            <img alt='newsSearchBtn' src="/images/searchEngine/google.png" style={{width: '100%'}}/>
                        </div>
                        <div style={{width: '80px'}}>
                            <img alt='newsSearchBtn' src="/images/searchEngine/bing.png" style={{width: '100%'}}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

MediaScreening.propTypes = {
    handleClick: PropTypes.func.isRequired,
    isDisable: PropTypes.bool,
}

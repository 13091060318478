import React, {useCallback, useState} from "react";
import Utils from "../../../../utils";
import {API} from "../../../../api";
import SanctionProperties from "./SanctionProperties";
import SearchIcon from '@mui/icons-material/Search';
import {nodeTypes} from "../../../../constants";

const RelatedSanction = (props) => {
    const [showMore, setShowMore] = useState(false);
    const sanctionProperties = props.data.sanctionsProperties;
    const isMainSanction = props.isMain;
    const name = props.data?.nameEn;
    const nameOriginal = props.data?.nameOriginal;
    const uni = props.data?.uni;
    const dateKeys = Object.keys(props.data?.dates);
    const countries = props.data?.countries;
    const showPaths = [];

    const getDefaultImg = (type) => {
        if (type.toLowerCase() === nodeTypes.PERSON) {
                return "/images/sanction/node_avatar.png";
        } else {
            return "/images/sanction/company.jpeg";
        }
    }
    const changeShowMore = useCallback(() => {
        setShowMore(!showMore);
    }, [showMore]);
    const avatar = Utils.isNotNullOrUndefined(props.data.avatar) ? props.data.avatar.startsWith("../") ? props.data.avatar.replace("..", API.BASE_IMG_URL) : props.data.avatar : props.data.isPep && getDefaultImg(props.data.nodeType);

    if (props.data.relationPaths) {
        if (props.data.relationPaths.length > 5) {
            props.data.relationPaths.forEach((item, i) => {
                if (i < 5) showPaths.push(<span key={`${item}_${i}`}><i>{item}</i><br/></span>);
                if (i === 4 && !props.print && !showMore) showPaths.push(<p key={`show_${name}`} className='sanction-property-mg show-more' onClick={changeShowMore}>... Show
                    more</p>);
                if (i >= 5) showPaths.push(<span key={`${item}_${i}`} className={props.print ? "show-path" : showMore ? "show-path" : "hide-path"}><i>{item}</i><br/></span>)
            })
            !props.print && showPaths.push(<p key={`hide_${name}`} className='sanction-property-mg show-more' onClick={changeShowMore}>{showMore && 'Show less'}</p>)
        } else {
            props.data.relationPaths.forEach((item, i) => {
                showPaths.push(<span key={`${item}_${i}`}><i>{item}</i><br/></span>);
            })
        }
    }

    const searchId = useCallback(() => {
        const isPerson = props.data.ardisId.toLowerCase().startsWith('p')
        const isOrg = props.data.ardisId.toLowerCase().startsWith('l')
        if (isPerson || isOrg) {
            Utils.searchById({value: props.data.ardisId, type: isPerson ? nodeTypes.PERSON : nodeTypes.ORGANIZATION})
        }
    }, [props.data.ardisId]);


    const isOrganization = props.data.nodeType.toLowerCase() === nodeTypes.ORGANIZATION;
    const isPep = props.data.isPep;
    return (
        <div className='related-sanctions'>

            <div className={!isMainSanction ? 'row related-sanctions-header related-sanctions-general ' : 'row related-sanctions-header'} style={{breakInside: 'avoid'}}>
                <div className='row col-xs-11'>
                    {!isMainSanction && <div className='col-xs-2 related-sanctions-numbering'><p className='related-sanctions-numbering-mg'>{props.index +1 <10 ? `0${props.index + 1}` :`${props.index + 1}`}</p></div>}
                    <div className='col-xs-10'>
                        <span className='related-sanctions-name sanction-property-mg'>{name}</span>
                        <p>{nameOriginal}<br/></p>
                        {showPaths.length > 0 && <p className='bold-title sanction-property-mg relation-path-title'>Relation path: </p>}
                        <div className='sanction-inner-property-mgm'>{showPaths}</div>
                    </div>
                </div>

                <div className='related-sanctions-wrapper'>
                    {
                        avatar && !isMainSanction ? !isPep ? <div className='related-sanctions-avatar'>
                            <img
                                alt='sanctionImg'
                                src={avatar}
                            />
                        </div> : <div className='related-sanctions-avatar related-sanctions-pep'>
                            <img src={avatar} className='relate-sanctions-avatar-img' alt='pepSanction'/>
                            <div className={isOrganization ? '' : 'sanction-mgt-opp'}>PEP</div>
                        </div> : null
                    }
                </div>

            </div>

            <div className={!isMainSanction ? 'related-sanctions-main' : 'sanction-mgt-med'}>
                {
                    !isMainSanction && <div className='sanction-identifiers'>
                        <p className='sanction-property-mg bold-title'>Key identifiers: </p>

                        <div className='row'>
                            <div className='col-xs-11'>
                                <div className='col-xs-6'>
                                    <span className='col-xs-4'>ARDIS ID:</span>
                                    <div className='col-xs-8 search-id'>
                                        <span>{props.data.ardisId}</span>
                                        {
                                            !props.print && <SearchIcon className='search-id-icon' onClick={searchId}/>
                                        }
                                    </div>
                                </div>

                                {
                                    props.data.dates && dateKeys.length > 0 && dateKeys.map(date => (
                                        <div key={`${date}_${name}`} className='col-xs-6'>
                                            <span className='col-xs-4'>{date}:</span>
                                            <span className='col-xs-8'>{props.data.dates[date].join(', ')}</span>
                                        </div>
                                    ))
                                }

                                {
                                    uni && Object.keys(uni).map((u, i) => (
                                        <div className='col-xs-6' key={`${u}_${i}`}>
                                            <span className='col-xs-4'> {u}:</span>
                                            <span className='col-xs-8'>{uni[u].join(', ')}</span>
                                        </div>
                                    ))
                                }

                                {
                                    countries && countries.length > 0 && <div className='col-xs-6'>
                                        <span className='col-xs-4'>Country:</span>
                                        <span className='col-xs-8'>{props.data.countries.join(', ')}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                <ul className='sanction-wrapper-list sanction-mgt-med'>
                    {
                        Object.keys(sanctionProperties).map(item => {
                            return (
                                <SanctionProperties data={sanctionProperties[item]} item={item} key={item}/>
                            )
                        })
                    }
                </ul>

            </div>


        </div>
    )
}

export default RelatedSanction;

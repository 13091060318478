import React from "react";
import PropTypes from "prop-types";

import {Icon, Checkbox, FormControlLabel} from '@mui/material';

import Utils from "../../../../utils";
import {API} from "../../../../api";
import {nodeTypes} from "../../../../constants";

const styles = {

    block: {
        maxWidth: 180,
    },
    thumbOff: {
        backgroundColor: '#6e7072',
    },
    trackOff: {
        backgroundColor: 'lightgray',
    },
    thumbSwitched: {
        backgroundColor: '#ef4254',
    },
    trackSwitched: {
        backgroundColor: '#ff9d9d',
    },
    inputStyle: {
        backgroundColor: '#ff9d9d',
        color: '#ff9d9d',
    },
    iconStyle: {
        marginRight: "0px!important"
    }

};
export default class RelationTree extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            checked: props.checked,
        }
    }

    render() {
        let content = [];
        let time = new Date().getTime();
        let nodeData = this.props.data;

        let avatar = Utils.isNotNullOrUndefined(nodeData.avatar) ? nodeData.avatar.startsWith("../") ? nodeData.avatar.replace("..", API.BASE_IMG_URL) : nodeData.avatar : null;
        let isPerson = Utils.isNotNullOrUndefined(avatar);

        if (Utils.isNotNullOrUndefined(avatar)) {
            content.push(
                <div className="col-xs-12" key="avatar">
                    <div className={isPerson ? 'avatar-gray' : 'avatar-color'}>
                        <img alt='avColor' src={avatar}
                             style={isPerson ? {
                                     width: '100%',
                                     maxWidth: "50px",
                                     borderRadius: '50%',
                                     filter: 'grayscale(100%)',
                                     marginBottom: "10px"
                                 }
                                 : {
                                     width: '100%',
                                     maxWidth: "50px",
                                     borderRadius: '50%',
                                     marginBottom: "10px"
                                 }}/>
                    </div>
                </div>
            )
        }

        if (nodeData) {
            if (nodeData.ardisId) {
                content.push(
                    <div key="ardisId" className="col-sm-12">
                        <span className="main-color">ARDIS ID: </span>
                        <span className="text-lighter word-break">{nodeData.ardisId}</span>
                    </div>
                );

                if (nodeData.uni) {
                    content.push(
                        <div key={`${nodeData.ardisId}_unis`} className="col-xs-12">
                            {
                                (Object.keys(nodeData.uni).length >= 2 ? Object.keys(nodeData.uni).slice(0, 2) : Object.keys(nodeData.uni)).map((u) => {
                                    return (
                                        <div key={`${nodeData.ardisId}_${u}_detail`}>
                                            <span className="main-color">{u}: &nbsp;</span>
                                            <span className="text-lighter word-break"
                                                 style={{wordBreak: "break-all"}}>{nodeData.uni[u]}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    );
                }

                if (nodeData.names) {
                    content.push(
                        <div key={`${nodeData.ardisId}_names`}  className="col-sm-12">
                            <span className="main-color">AKA:&nbsp;</span>
                            <span  className="text-lighter word-break">{nodeData.names.join(", ")}</span>
                        </div>
                    );
                }
                if (nodeData.nodeType.toLowerCase() === nodeTypes.PERSON && nodeData.dates["Date of Birth"]) {
                    content.push(
                        <div key={`${nodeData.ardisId}_birth`} className="col-xs-12">
                            <span className="main-color">Date of Birth:&nbsp;</span>
                            <span  className="text-lighter word-break">{nodeData.dates["Date of Birth"].join(", ")}</span>
                        </div>
                    );
                }

            }

        }
        const labelCheck = this.props.checked ? "Remove content" : "Include content";

        return (
            <div className="container bottom-shadow"
                 style={{
                     borderRadius: '7px',
                     backgroundColor: "#ececec",
                     width: '600px',
                     position: "absolute",
                     right: "100px",
                     padding: "10px 0",
                     marginTop: this.props.marginMinus ? "-30px" : "-30px",
                     zIndex: "100"
                 }}
                 key={"detailContent_" + time}>
                <div style={{position: "absolute", right: "10px", zIndex: "100", display: "inline-flex"}}>
                    <FormControlLabel
                        style={{...styles.labelStyle, margin: "-4px 0 0 0"}}
                        control={<Checkbox
                            checkedIcon={<Icon className="material-icons" title={labelCheck} style={{
                                zIndex: 100,
                                fontSize: "16px",
                                height: "14px!important",
                                color: "#ef4254"
                            }}>attach_file</Icon>}
                            icon={<Icon className="material-icons " title={labelCheck} style={{
                                zIndex: 100,
                                fontSize: "16px",
                                height: "14px!important",
                                color: "#6e7072"
                            }}>attach_file</Icon>}
                            style={{
                                width: "25px",
                                height: "14px!important",
                                padding: 5,
                                backgroundColor: "#fff0"
                            }}
                            checked={this.state.checked}
                            onChange={() => this.props.includeNodeContent()}
                        />
                        }
                    />
                    <div style={{cursor: "pointer", width: "24px"}} onClick={() => this.props.closeDialog()}>
                        <Icon className="material-icons" style={{color: "#ef4254", fontSize: "16px"}}>close</Icon>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="node-content">
                            {content}
                        </div>
                    </div>
                </div>
            </div>);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({checked: nextProps.checked})
    }

}

RelationTree.propTypes = {
    data: PropTypes.object.isRequired,
    includeNodeContent: PropTypes.func,
    closeDialog: PropTypes.func,
    checked: PropTypes.bool,
    marginMinus: PropTypes.bool,

}; 

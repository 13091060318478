import React from "react";
import PropTypes from "prop-types";

import Icon from '@mui/material/Icon';

import Utils from "../../../../utils";

export default class CardItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {showMore: false};
    }

    render() {
        const {id, title, comment, body} = this.props.card;
        const titleClass = this.props.doubleColumn ? "" : " double-column-title";

        const items = body.map((itemData, index) => {
            if (itemData.type === "text") {
                return this.renderText(itemData, index);
            } else if (itemData.type === "propertyValueList") {
                return this.renderPropertyList(itemData, index);
            } else {
                return <div key={index}>Unknown</div>;
            }
        });

        return (
            <div className="card-item" key={this.props.index}>
                <div className="row card-item-header" style={{breakInside: 'avoid'}}>
                    <div
                        className={(this.props.print !== "") ? 'col-xs-3 card-item-header-number' : 'col-md-3 card-item-header-number'}>
                        {this.props.card.icon && !this.props.isPoliticalProfile ?
                            <img alt='business' className="card-icon" src={"/images/business/" + this.props.card.icon + ".png"}/> :
                            this.props.isPoliticalProfile ?
                                <span className='node-square'>{this.props.index}</span> : id
                        }
                    </div>
                    <div
                        className={(this.props.print !== "") ? 'col-xs-9 card-item-header-title' : 'col-md-9 card-item-header-title' + titleClass}>
                        <div>{title}</div>
                        <div>{comment}</div>
                    </div>
                </div>
                {items}
            </div>
        );
    }

    renderText(data, index) {
        const {name, value} = data;
        const content = [];
        if (Utils.isNotNullOrUndefined(name)) {
            content.push(
                <div className="row" key={index}>
                    <div className="col-md-12 card-item-subtitle dot-icon">{name}</div>
                </div>
            );
        }
        content.push(<p key={index} dangerouslySetInnerHTML={{__html: value}}/>);
        return content;
    }

    doSearch(item) {
        const itemType = item.toLowerCase().startsWith('p') ? 'person' : 'organization';
        Utils.searchById({value: item, type: itemType});
    }

    renderPropertyList(data, index) {
        const {name, values} = data;
        const {isBusinessProfile, isDeclaration} = this.props
        const content = [];
        const dobleContent = [];
        let spacesAreBlank = isDeclaration ? "" : this.props.print === "?print=true" ? "spaces-blank-print" : "spaces-blank";
        let finalValues = values;
        let moreValues = [];

        if (Utils.isNotNullOrUndefined(name)) {
            content.push(
                <div className="row" key={index}>
                    <div className="col-md-12 card-item-subtitle dot-icon">{name} </div>
                </div>
            );
        }
        if (name.toLowerCase().startsWith("financial") && values.length > 25 && this.props.print === "") {
            finalValues = values.slice(0, 20);
            moreValues = values.slice(20);
        }
        const ifLengthOne = finalValues.length === 1;
        finalValues.forEach((value, index) => {
            let showSearchTool = false;
            if (value.value.indexOf(' ') >= 0) {
                spacesAreBlank = "";
            }
            if (isBusinessProfile && value.property.toLowerCase().startsWith('ardis') && this.props.print !== '?print=true') {
                showSearchTool = true;
            }
            dobleContent.push(
                <div key={"property-items-" + index}
                     className={`${ifLengthOne || isDeclaration ? "col-xs-12" : "col-xs-6"} ${showSearchTool ? 'searchWrapper' : ''}`}
                     style={{paddingRight: '0'}}
                >
                    <div className={`col-xs-${isDeclaration ? "12" : ifLengthOne ? "3" : "6"} ${isDeclaration ? 'card-declaration-row' : ''}`}  >{value.property}:</div>
                    <div
                        className={`col-xs-${isDeclaration ? "12" : ifLengthOne ? "9" : "6"} card-item-value dot-icon ${spacesAreBlank} ${isDeclaration ? 'card-declaration-item' : ''}`}
                         style={{marginLeft: '-23px', paddingLeft: '15px', paddingRight: 0}}
                    >
                        {
                            isDeclaration ?
                                Object.keys(value.value).length > 0 && value.value.map((item, index) => {
                                    return (
                                        <div key={`declaration-value-${index}`} className='declaration-item-values'>
                                            <p> {item.name} : {item.value}  </p>
                                            <p style={{marginTop: '-10px'}}>{item.description}</p>
                                            <br/>
                                        </div>
                                    )
                                })
                                :
                                value.value
                        }
                    </div>
                    {showSearchTool &&
                        <div className='businessSearch'>
                            <Icon>
                                <Icon
                                    onClick={() => this.doSearch(value.value)}
                                    style={{
                                        cursor: "pointer",
                                        color: "#b0b0b0",
                                        fontSize: 16
                                    }}
                                >
                                    search
                                </Icon>
                            </Icon>
                        </div>

                    }
                </div>
            );
        });
        if (name.toLowerCase().startsWith("financial") && values.length > 25 && this.state.showMore && this.props.print === "") {
            moreValues.forEach((value, index) => {
                if (value.value.indexOf(' ') >= 0) {
                    spacesAreBlank = "";
                }
                dobleContent.push(
                    <div key={"property-items-more" + index} className="col-xs-6" style={{paddingRight: '0'}}>
                        <div className="col-xs-6">{value.property}:</div>
                        <div className={"col-xs-6 card-item-value dot-icon" + spacesAreBlank} style={{
                            marginLeft: '-23px',
                            paddingLeft: '15px',
                            paddingRight: '0'
                        }}>{value.value}</div>
                    </div>
                );
            });
        }
        this.props.doubleColumn
            ?
            finalValues.forEach((value, index) => {
                if (value.value.indexOf(' ') >= 0) {
                    spacesAreBlank = "";
                }
                dobleContent.push(
                    <div key={"property-items-" + index} className="col-xs-6">
                        <div className="col-xs-6">{value.property}:</div>
                        <div className={"col-xs-6 card-item-value dot-icon" + spacesAreBlank}
                             style={{marginLeft: '-15px'}}>{value.value}</div>
                    </div>
                );
                content.push(
                    <div key={"property-item-" + index} className="row card-item-data-row">
                        {
                            isDeclaration ?
                                <>
                                    <div className="col-md-12 card-declaration-row card-declaration-prop">{value.property}</div>

                                    <div className={"col-xs-12 card-item-value dot-icon"}
                                         style={{marginTop: 10}}>

                                        {
                                            Object.keys(value.value).length > 0 && value.value.map((item, index) => {
                                                return (
                                                    <div key={`declaration-value-${index}`} className='declaration-item-values'>
                                                        <p> {item.name} : {item.value}  </p>
                                                        <p style={{marginTop: '-10px'}}>{item.description}</p>
                                                        <br/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-md-6" style={{marginLeft: '-15px'}}>{value.property}</div>

                                    <div className={"col-xs-6 card-item-value dot-icon" + spacesAreBlank}
                                         style={{marginLeft: '-15px'}}>{value.value}</div>
                                </>

                        }

                    </div>
                )
            })
            :
            content.push(
                <div key={"property-item-"} className="row card-item-data-row">
                    {dobleContent}
                </div>
            );
        if (name.toLowerCase().startsWith("financial") && values.length > 25 && this.props.print === "") {

            content.push(<div key={"arrow-point" + index} className="pointer text-center main-color "
                              style={{margin: "15px"}}
                              onClick={() => this.setState({showMore: !this.state.showMore})}>show {this.state.showMore ? "less" : "more"}
                <img alt='arrowPoint' src="/images/arrow-point-to-right.png" style={this.state.showMore ? {
                    transform: 'rotate(-90deg)',
                    margin: '0px 7px',
                    width: '15px'
                } : {transform: 'rotate(90deg)', margin: '0px 7px', width: '15px'}}/>
            </div>)
        }
        return content;
    }
}

CardItem.propTypes = {
    card: PropTypes.object,
    doubleColumn: PropTypes.bool,
    print: PropTypes.string,
    isPoliticalProfile: PropTypes.bool,
    isExtraSources: PropTypes.bool,
    index: PropTypes.number
};

import React, {useCallback, useState} from 'react';
import PropTypes from "prop-types";
import {Grid, FormControl, Autocomplete, TextField} from "@mui/material";

export default function FilterSelect({ setPropsValue, label, options, valueDef }) {
    const [value, setValue] = useState(valueDef);

    const showOptionLabel = useCallback((option) => (
        option.label ? option.label.charAt(0).toUpperCase() + option.label.substring(1) :  value.charAt(0).toUpperCase() + value.substring(1)
        ) ,[value])
    return (
        <FormControl >
            <Grid item lg={8}>
                <Autocomplete
                    size='small'
                    id={`combo-box-demo${label}`}
                    value={valueDef}
                    freeSolo
                    options={options}
                    getOptionLabel={(option) => showOptionLabel(option)}
                    style={{ width: 220, backgroundColor:(value === '' || !value)?'#fff':'#c5d1e7'}}
                    onChange={(event, newValue, reason) => {
                        if (reason === 'clear') {
                            setValue('')
                            setPropsValue('' );
                            return;
                        }
                            setValue(newValue.value)
                            setPropsValue(newValue.value);
                    }}
                    renderInput={(params) =>
                        <TextField {...params}
                                   variant="outlined"
                                   placeholder={label}/>}
                />
            </Grid>
        </FormControl>
    );
}

FilterSelect.propTypes = {
    setPropsValue: PropTypes.func,
    label: PropTypes.string,
    options: PropTypes.array
};

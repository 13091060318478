import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import Appendix from "./Appendix.jsx";
import {refreshJudicialDataFromAppendix} from "../../../actions";
import c from "./judicial.module.css"

const JudicialAppendix = ({judicialData, print}) => {
    const dispatch = useDispatch();
    const judicial = judicialData || [];

    const removeTreeAppendixData = (id) => {
        dispatch(refreshJudicialDataFromAppendix(id));
    }
    const data = judicial.map((appendix, index) => {
        return <Appendix key={"appendix" + appendix.id}
                         appendix={appendix}
                         print={print}
                         index={index}
                         removeTreeAppendixData={() => removeTreeAppendixData(appendix.id)}/>;
    } );

    return (
        judicial && judicial.length > 0 &&
        <div className={c.judicialAppendix} >
            { data }
        </div>
    );
}

JudicialAppendix.propTypes = {
    print: PropTypes.string,
    judicialData: PropTypes.array
};

export default JudicialAppendix;

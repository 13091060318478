import React from "react";
import PropTypes from "prop-types";

export const MenuPosition = {
    Left: 1,
    Right: 2
};

export const MenuSize = {
    Small: 1,
    Middle: 2,
    Large: 3
};

export default class Menu extends React.Component {
    render() {
        let isOnlyNews = sessionStorage.getItem( "isOnlyNews" );

        return (
            <div>
                { (this.props.loadingStatus === "DONE" || isOnlyNews) &&
                <div>
                    <img alt = 'menuImage' src="./images/menu.png" className="menu-icon"/>
                    <img alt = 'pdfImage' src="./images/pdf.png"
                         className="pdf pointer"
                         title="View for pdf"
                         style={ { marginTop: "-2px", marginLeft: "10px" } }
                         onClick={ () => this.props.toPdf() }
                    />
                </div>
                }
            </div>

        );
    }
}

Menu.defaultProps = {
    position: MenuPosition.Right,
    size: MenuSize.Small,
};

Menu.propTypes = {
    position: PropTypes.number,
    size: PropTypes.number,
    toPdf: PropTypes.func,
    postNote: PropTypes.func,
    loadingStatus: PropTypes.string,
};

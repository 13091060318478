export const AuthenticationActions = {
    Login: "Authentication.Actions.Login",
    LoginSuccess: "Authentication.Actions.LoginSuccess",
    LoginFailed: "Authentication.Actions.LoginFailed",
    Logout: "Authentication.Actions.Logout",
    FirstPassword: "Authentication.Actions.FirstPassword",
    ChangeFirstPassword: "Authentication.Actions.ChangeFirstPassword",
    NewPasswordSend: "Authentication.Actions.NewPasswordSend",
    NewPasswordSuccess: "Authentication.Actions.NewPasswordSuccess",
    NewPasswordFailed: "Authentication.Actions.NewPasswordFailed",
    NoServer: "Authentication.Actions.NoServer",
};

export const login = requestData => {
    return { type: AuthenticationActions.Login, payload:{requestData}};
};
export const loginSuccess = (data) => {
    return {
         type: AuthenticationActions.LoginSuccess,
         payload:{data}
    };
};
export const loginFailed = data => {
    return { type: AuthenticationActions.LoginFailed, payload:data};
};

export const logout = (checkServer) => {
    return {type: AuthenticationActions.Logout, payload: {checkServer}};
};

export const firstPassword = (data) => {
    return {type: AuthenticationActions.FirstPassword, payload: {data}};
};

export const newPassSend = (newPass) => {
    return {
        type: AuthenticationActions.NewPasswordSend,
        payload:newPass
    };
};
export const newPasswordSuccess = (newPass) => {
    return {
        type: AuthenticationActions.NewPasswordSuccess,
        payload:{newPass}
    };
};

export const newPasswordFailed = (data) => {
    return { type: AuthenticationActions.NewPasswordFailed, payload:{data}};
};

export const noServer = () => {
    return {
        type : AuthenticationActions.NoServer
    }
}

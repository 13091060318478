import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

import Utils from "../../../utils";
import { RowType } from "../../../constants";
import CardItem from "./card/CardItem.jsx";

let ITEMS_PER_PAGE = 200;
let CardInstanceIndex = 0;

export default class Card extends React.Component {
    constructor( props ) {
        super( props );
        this.id = CardInstanceIndex++;
        this.state = { currentPage: 0 };
    }

    render() {
        const doubleColumn = this.props.data.doubleColumn ?
            <div className="col-xs-6" style={this.props.print === '?print=true' ? {float: 'right'} : null}>{ this.renderItems( RowType.Odd ) }</div> : null;
        const themeClass = (Utils.isNotNullOrUndefined( this.props ) && Utils.isNotNullOrUndefined( this.props.title )) ? this.props.title.toLowerCase().trim() + '_card card' : 'card ';
        return (
            <div className={ themeClass } id={ "card-component-" + this.id }>
                <div className="row">
                    <div className="card-title" dangerouslySetInnerHTML={ { __html: this.props.data.title } }></div>
                    <div className="text-lighter" dangerouslySetInnerHTML={ { __html: this.props.data.header } }></div>

                    <div style={ this.props.isPoliticalProfile ? {
                        padding: '20px 20px 0 20px',
                        backgroundColor: '#eff0f4'
                    } : {} }
                         className={ this.props.data.doubleColumn ? "col-xs-6" : "col-xs-12" }> { this.props.isPoliticalProfile &&
                    <div className='header'>
                        <span className='title'>affilation to politically exposed persons/state-owned companies</span>
                    </div> }{ this.renderItems( RowType.Even ) }</div>
                    { doubleColumn }
                    <div className="col-xs-12">{ this.renderPagination() }</div>
                </div>
            </div>
        );
    }

    renderItems( type ) {
        let start = 0;
        if ( type === RowType.Odd ) {
            start = 1;
        }
        let cards = this.props.data.cards;
        const totalCount = cards.length;
        if ( this.props.print !== "" ) {
            ITEMS_PER_PAGE = totalCount;
        }
        if ( totalCount > ITEMS_PER_PAGE ) {
            cards = cards.slice( this.state.currentPage * ITEMS_PER_PAGE, this.state.currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE );
        }
        let items = [];
        if ( Utils.isNotNullOrUndefined( cards ) ) {
            for ( let index = start; index < cards.length; index += this.props.data.doubleColumn ? 2 : 1 ) {
                const card = cards[index];
                items.push( <CardItem isExtraSources={ this.props.isExtraSources }
                                      isPoliticalProfile={ this.props.isPoliticalProfile }
                                      isBusinessProfile={this.props.isBusinessProfile}
                                      isDeclaration={this.props.isDeclaration}
                                      index={ index + 1 }
                                      print={ this.props.print }
                                      doubleColumn={ this.props.data.doubleColumn }
                                      key={ "card-item-" + index }
                                      card={ card }
                                      double/> );
            }
        }
        return items;
    }

    renderPagination() {
        let cards = this.props.data.cards;
        const totalCount = cards.length;
        if ( totalCount > ITEMS_PER_PAGE ) {
            return <div className="pagination-div">
                <ReactPaginate
                    containerClassName="pagination pull-right"
                    pageCount={ totalCount / ITEMS_PER_PAGE }
                    breakLabel={ <a href='/#' onClick={(e)=>e.preventDefault()}>...</a> }
                    breakClassName={ "break-me" }
                    subContainerClassName={ "pages pagination" }
                    activeClassName={ "active" }
                    pageRangeDisplayed={ ITEMS_PER_PAGE }
                    marginPagesDisplayed={ ITEMS_PER_PAGE }
                    onPageChange={ ( data ) => this.changePage( data ) }/>
            </div>
        } else {
            return null;
        }
    }

    changePage( data ) {
        this.setState( { currentPage: data.selected } );
    }
}

Card.propTypes = {
    data: PropTypes.object,
    title: PropTypes.string,
    print: PropTypes.string,
    isPoliticalProfile: PropTypes.bool,
    isExtraSources: PropTypes.bool
};

import React from "react";
import PropTypes from "prop-types";

import {Grid, AppBar, Button, IconButton} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import Utils from "../../utils";
import Menu from '../components/Menu.jsx';
import Avatar from '../components/Avatar.jsx';
import CardNodes from "../search/CardNodes.jsx";
import PolixisRoutes from "../../routes";
import {avatarSize} from "../../constants";

export default class Header extends React.Component {
    render() {
        return (
            <AppBar className="appBar"
                    style={{
                        boxShadow: "none",
                        position: "fixed",
                        zIndex: "1000",
                        backgroundColor: "#ef4254",
                        padding: "0px",
                        height: "50px",
                        header: {height: "30px!important"},
                        title: {height: "30px!important"}
                    }}
            >
                <Grid container justifyContent="space-between">
                    {this.props.isMobile ? <Grid item xs={1}>
                            <IconButton size="medium" color="inherit" onClick={() => this.props.toggleSideBar()}>
                                <MenuIcon fontSize="large"/>
                            </IconButton>
                        </Grid>
                        : this.renderTitle()}
                    {this.renderRightContent()}
                </Grid>
            </AppBar>
        );
    }

    renderTitle() {
        return (
            <Grid item xs={3} sm={4} md={3} lg={4} container alignItems="center">
                <Grid item style={{paddingLeft: "29px"}} className="pointer" onClick={() => this.historyBack()}>
                    <img alt='logoReport' src="/images/logo_report.png" className="report-logo pull-left pointer"/>
                </Grid>
                <Grid item style={{paddingTop: "7px"}}>
                    <Menu toPdf={this.props.toPdf} postNote={this.props.postNote}
                          loadingStatus={this.props.loadingStatus}/>
                </Grid>
            </Grid>
        );
    }

    handleLogout () {
        this.props.handleLogout();
    }

    renderRightContent() {
        let rightContent;
        let inOnlyNews = JSON.parse(sessionStorage.getItem('isOnlyNewsSearch'));
        if (Utils.isNotNullOrUndefined(this.props.node)) {
            rightContent = (
                <Grid item xs={9} sm={8} md={9} lg={8} container justifyContent="flex-end" wrap={'nowrap'}
                      className="report-header-info inline">
                    <div className="name"
                         style={this.props.isMobile ? {maxWidth: "117px"} : {}}>{this.props.node.title}</div>
                    <div className="header-avatar pull-left"
                         style={{marginRight: this.props.isMobile ? "0" : "24px"}}>
                        <Avatar node={this.props.node} size={avatarSize.SMALL}/>
                    </div>
                    {!inOnlyNews && <CardNodes isInReport={true} onReport={() => this.props.onRouteChange(PolixisRoutes.App.Report)}/>}

                    <div className=" pull-right" style={{marginLeft: this.props.isMobile ? "0" : "20px"}}>
                        <a href='/login' onClick={() => this.handleLogout()}>
                            <Button key="btn_4" style={{
                                margin: this.props.isMobile ? "6px 15px 0 0" : '6px 32px 0 20px',
                                color: '#ffffff'
                            }}>Logout</Button>
                        </a>
                    </div>
                </Grid>
            );
        } else {
            rightContent = (<Grid item className="report-header-info inline">
                <div className=" pull-right inline">
                    <i className="material-icons pointer" style={{color: "#ffffff", margin: "10px 25px 10px 0"}}
                       onClick={() => this.historyBack()}>close</i>
                </div>
            </Grid>)
        }

        return rightContent;
    }

    historyBack() {
        sessionStorage.removeItem('searchValue');
        sessionStorage.removeItem('filter');
        sessionStorage.removeItem('components');
        sessionStorage.removeItem('sections');
        sessionStorage.removeItem('appendixTree');
        sessionStorage.removeItem('Graph');
        sessionStorage.removeItem('appendixTree');
        sessionStorage.removeItem('includedJudicial');
        sessionStorage.removeItem('judicialAppendix');
        sessionStorage.removeItem('RelationView');
        sessionStorage.removeItem('queryQ');

        this.props.onRouteChange(PolixisRoutes.App.Search)
    }
}

Header.propTypes = {
    node: PropTypes.object,
    isMobile: PropTypes.bool,
    toggleSideBar: PropTypes.func,
    toPdf: PropTypes.func,
    postNote: PropTypes.func,
    loadingStatus: PropTypes.string,
};

import React, {useEffect, useState, useCallback} from "react";
import PropTypes from "prop-types";
import IconButton from '@mui/material/IconButton';
import Utils from "../../../../utils";
import c from "./judicial.module.css";

const Appendix = ({appendix, print, removeTreeAppendixData}) => {
    const [appendixItem, setAppendixItem] = useState(appendix);
    const removePinned = useCallback(() => {
        let includedItems = sessionStorage.getItem("includedJudicial");
        includedItems = Utils.isNotNullOrUndefined(includedItems) ? JSON.parse(includedItems) : [];
        includedItems = includedItems.filter(_item =>  _item !== appendixItem.id);
        sessionStorage.removeItem("includedJudicial");
        sessionStorage.setItem("includedJudicial", JSON.stringify(includedItems));
        removeTreeAppendixData();
    },[appendixItem?.id, removeTreeAppendixData]);

    useEffect(() => {
        setAppendixItem(appendix);
    }, [appendix]);

    return (
        <div>
            {
                appendixItem && <div className="content paper">
                    {print === "" &&
                        <div className={c.appendixRemove}>
                            <IconButton className={c.appendixRemoveIcon} onClick={removePinned}>
                                <i className="material-icons main-color" title="Remove">delete</i>
                            </IconButton>
                        </div>
                    }
                    <h4>{appendixItem.matchTitle}</h4>
                    <div style={{fontFamily: "Arial, Helvetica, sans-serif"}} dangerouslySetInnerHTML={{__html: appendixItem.content}}
                    />
                </div>
            }
        </div>
    );
}

export default Appendix;

Appendix.propTypes = {
    appendix: PropTypes.object.isRequired,
    print: PropTypes.string,
    removeTreeAppendixData: PropTypes.func,
};

import React from "react";
import Avatar from "./Avatar";
import ReactCountriesFlags from "react-countries-flags";
import {avatarSize, etcKey, highlightAlerts, nodeDateTypes, sanctionTypes} from "../../constants";

const NodeInner = ({node}) => {

    const containEtc = (field) => {
        return field.filter(f => f.toLowerCase() === etcKey).length > 0;
    }

    const replaceLastItem = (field) => {
        const copyField = [...field];
        if (containEtc(copyField)) {
            copyField.pop();
            copyField[copyField.length-1] = copyField[copyField.length-1] + " , " + etcKey;
        }
        return copyField;
    }

    return (
        <>
            <td height={120} width={30} id={'nodes' + node.ardisId} style={{verticalAlign: 'top'}}>
                <Avatar node={node} size={avatarSize.NORMAL}/>
            </td>
            <td rowSpan="2" className="node-content">
                <div className="content">
                    <table>
                        <tbody>
                        {
                            node.names && node.names.length > 0 && <tr className="node-inner-detail">
                                <td className="detail-label label-lighter node-search-key">AKA: </td>
                                <td className="detail-desc">
                                    <div className="td-margin">
                                        <div className="detail-value">
                                            {
                                                replaceLastItem(node.names).map((n, i) => <p key={`${node.ardisId}_${n}_${i}`}>{n}</p>)
                                            }
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }

                        {
                            node.dates && Object.keys(node.dates).map((d, i) =>{
                                const isDateOfBirth = d.trim().toLowerCase() === nodeDateTypes.dateOfBirth;
                                return (
                                    <tr className="node-inner-detail" key={`date_${d}_${i}`}>
                                        <td className="detail-label label-lighter node-search-key">{d}:</td>
                                        <td className="detail-desc">
                                            <div className="td-margin">
                                                <div className={isDateOfBirth ? "dateOfBirth font-bolder" : "detail-value"}>
                                                    {node.dates[d].join(", ")}</div></div>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        {
                            node?.biography && <tr className="node-inner-detail">
                                <td className="detail-label label-lighter node-search-key">Biography:</td>
                                <td className="detail-desc">
                                    <div  className="td-margin"><p className="detail-value node-bio ">{node.biography}</p></div>
                                </td>
                            </tr>
                        }

                        {
                            node?.description && <tr className="node-inner-detail">
                                <td className="detail-label label-lighter node-search-key">Description:</td>
                                <td className="detail-desc">
                                    <div  className="td-margin"><p className="detail-value node-bio ">{node.description}</p></div>
                                </td>
                            </tr>
                        }

                        {
                            node.uni && Object.keys(node.uni).map((u, i) =>
                                <tr className="node-inner-detail" key={`uni_${u}_${i}`}>
                                    <td className="detail-label label-lighter node-search-key">{u}:</td>
                                    <td className="detail-desc">
                                        <div className="td-margin"><p className="detail-value">{node.uni[u].join(", ")}</p></div>
                                    </td>
                                </tr>
                            )
                        }

                        {
                            node?.pepDetails &&  <tr className="node-inner-detail">
                                <td className="detail-label label-lighter">
                                    <span className="node-pep-label">Pep:</span>
                                </td>
                                <td className="detail-desc">
                                    <div className="td-margin">{Object.keys(node.pepDetails).map((p, i) => <p className="detail-value" key={`${node.ardisId}_${p}_${i}`}>
                                        <span className='detail-bold '> {p.charAt(0).toUpperCase() + p.substring(1)}: </span>{node.pepDetails[p].join(", ")}</p>)}</div>
                                </td>
                            </tr>
                        }

                        {
                            node?.sanctionDetails && <tr className="node-inner-detail">
                                <td className="detail-label label-lighter">
                                    <span className="node-sanctions-label">Sanctions:</span>
                                </td>
                                <td className="detail-desc">
                                    <div className="td-margin">
                                        {node.sanctionDetails.map((s, i) => {
                                            const isoCode = s.country.toLowerCase() === "uk" ? "gb" : s.country;
                                            return (
                                                <div className="node-sanction detail-value" key={`sanction_${node.ardisId}_${s.country}_${i}`}>
                                                    <ReactCountriesFlags isoCode={isoCode} height={12} width={18}/>
                                                    <p>&nbsp;&nbsp;{s.country}<span className="font-bolder">{s.sanctionStatus.toLowerCase() === sanctionTypes.delisted.name.toLowerCase() && ` - ${s.sanctionStatus}`}</span></p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </td>
                            </tr>
                        }

                        {
                            node.alerts && node.alerts.length > 0 && <tr className="node-inner-detail">
                                <td className="detail-label label-lighter">
                                    <span className="node-alerts-label">Alerts:</span>
                                </td>
                                <td className="detail-desc">
                                    <div className="node-alerts td-margin">{
                                        node.alerts.map((n, i) => {
                                            const isHighlight = highlightAlerts.includes(n);
                                            return (
                                                <p key={`${node.ardisId}_${n}_${i}`} className={isHighlight ? "node-alerts-highlight " : "detail-value"}>{n}</p>
                                            )
                                        })}
                                    </div>
                                </td>
                            </tr>
                        }

                        {
                            node.countries && node.countries.length > 0 &&  <tr className="node-inner-detail">
                                <td className="detail-label label-lighter node-search-key">Affiliated Jurisdictions:</td>
                                <td className="detail-desc">
                                    <div className="td-margin">{node.countries.map((n, i) => <p className="detail-value" key={`${node.ardisId}_${n}_${i}`}>{n}</p>)}</div>
                                </td>
                            </tr>
                        }
                        {
                            node.positions && node.positions.length > 0 && <tr className="node-inner-detail">
                                <td className="detail-label label-lighter node-search-key">Position:</td>
                                <td className="detail-desc">
                                    <div className="td-margin">
                                        {replaceLastItem(node.positions).map((n, i) => <p className="detail-value" key={`${node.ardisId}_${n}_${i}`}>{n}</p>)}
                                    </div>
                                </td>
                            </tr>
                        }

                        {
                            node.addresses && node.addresses.length > 0 && <tr className="node-inner-detail">
                                <td className="detail-label label-lighter node-search-key">Address:</td>
                                <td className="detail-desc">
                                    <div className="td-margin">{node.addresses.map((n, i) => <p className="detail-value" key={`${node.ardisId}_${n}_${i}`}>{n}</p>)}</div>
                                </td>
                            </tr>
                        }

                        {
                            node.relatedPeople && node.relatedPeople.length > 0 && <tr className="node-inner-detail">
                                <td className="detail-label label-lighter node-search-key">Related People:</td>
                                <td className="detail-desc">
                                    <div className="td-margin">
                                        {replaceLastItem(node.relatedPeople).map((n, i) => <p className="detail-value" key={`${node.ardisId}_${n}_${i}`}>{n}</p>)}
                                    </div>
                                </td>
                            </tr>

                        }

                        {
                            node.relatedOrganizations && node.relatedOrganizations.length > 0 && <tr className="node-inner-detail">
                                <td className="detail-label label-lighter node-search-key">Related Organizations:</td>
                                <td className="detail-desc">
                                    <div className="td-margin">
                                        {replaceLastItem(node.relatedOrganizations).map((n, i) => <p className="detail-value" key={`${node.ardisId}_${n}_${i}`}>{n}</p>)}
                                    </div>
                                </td>
                            </tr>
                        }
                        <tr>

                        </tr>
                        </tbody>
                    </table>

                </div>
            </td>
        </>


    )
}

export default React.memo(NodeInner);

import appStore from "../../redux/store";

export const DialogActions = {
    ShowAlertDialog: "Dialog.Actions.ShowAlertDialog",
    ShowJudicialDialog: "Dialog.Actions.ShowJudicialDialog",
    CloseDialog: "Dialog.Actions.CloseDialog",
    ShowConfirmDialog: "Dialog.Actions.ConfirmDialog",
    ShowTimerDialog: "Dialog.Actions.TimerDialog",
    ShowScreenshotTitleDialog: "Dialog.Actions.ScreenshotTitleDialog",
    ShowGraphScreenshotEditDialog: "Dialog.Actions.GraphScreenshotEditDialog",
    ShowChangePasswordDialog: "Dialog.Actions.ShowChangePasswordDialog",
    ShowLegacyNewsDialog: "Dialog.Actions.ShowLegacyNewsDialog"
};
export const DialogTypes = {
    Alert: 1,
    Confirm: 2,
    Timer: 3,
    ScreenshotTitle: 4,
    GraphScreenshotEdit: 5,
    ChangePassword: 6,
    LegacyNewsShow: 7,
    JudicialDetail: 8
};

export const showAlertDialog = options => {
    appStore.dispatch({
        type: DialogActions.ShowAlertDialog,
        payload: Object.assign({}, options, { type: DialogTypes.Alert })
    });
};

export const showJudicialDialog = options => {
    appStore.dispatch({
        type: DialogActions.ShowJudicialDialog,
        payload: Object.assign({}, options, { type: DialogTypes.JudicialDetail })
    });
};

export const showLegacyNewsDialog = options => {
    appStore.dispatch({
        type: DialogActions.ShowLegacyNewsDialog,
        payload: Object.assign({}, options, { type: DialogTypes.LegacyNewsShow })
    });
};

export const showConfirmDialog = options => {
    appStore.dispatch({
        type: DialogActions.ShowConfirmDialog,
        payload: Object.assign({}, options, { type: DialogTypes.Confirm })
    });
};
export const showTimerDialog = options => {
    appStore.dispatch({
        type: DialogActions.ShowTimerDialog,
        payload: Object.assign({}, options, { type: DialogTypes.Timer })
    });
};
export const showScreenshotTitleDialog = options => {
    appStore.dispatch({
        type: DialogActions.ShowScreenshotTitleDialog,
        payload: Object.assign({}, options, { type: DialogTypes.ScreenshotTitle })
    });
};
export const showGraphScreenshotEditDialog = options => {
    appStore.dispatch({
        type: DialogActions.ShowGraphScreenshotEditDialog,
        payload: Object.assign({}, options, { type: DialogTypes.GraphScreenshotEdit })
    });
};

export const showChangePasswordDialog = options => {
    appStore.dispatch({
        type: DialogActions.ShowChangePasswordDialog,
        payload: Object.assign({}, options, { type: DialogTypes.ChangePassword })
    });
};
export const closeDialog = () => {
    appStore.dispatch({
        type: DialogActions.CloseDialog
    });
};

import React from 'react';
import {makeStyles, withStyles} from '@mui/styles';
import {FormControl,NativeSelect, InputBase,Typography,Grid}  from '@mui/material';
import PropTypes from "prop-types";
import {ExpandMore} from "@mui/icons-material";

const BootstrapInput = withStyles((theme) => ({

    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },

    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 13,
        padding: '5px 20px 5px 5px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
        MuiSvgIcon: {
            root: {
                width: '1.5rem',
                height: '1.5rem',
            }
        },
    },
}))(InputBase);

const useStyles = makeStyles(() => ({
    myStyles: {
        display:'flex !important',
        flexDirection:'row !important',
        alignItems:'center',
        width: '100%',
        backgroundColor: 'inherit',
        height: 'auto',
        justifyContent: 'space-between',
    },
}));


export default function NewsSelect({ setPropsValue, label, options, oldValue }) {

    const classes = useStyles();
    const [value, setValue] = React.useState(oldValue);
    const handleChange = (event) => {
        setValue(event.target.value);
        setPropsValue(event.target.value);
    };
    return (

        <FormControl className={classes.myStyles}>
            {label && <Grid item lg={4} style={{paddingLeft: '1rem'}}>
                <Typography variant={'h6'}>{label}</Typography>
            </Grid>}
            <Grid item lg={8}>
                <NativeSelect
                    fullWidth
                    IconComponent={ExpandMore}
                    value={value}
                    style={{minWidth:'140px', boxShadow: "0 0 7px 0px lightgrey", border: 'none', color:'#7d7d7d', alignItems:'center', backgroundColor: '#fff',borderRadius:4}}
                    onChange={handleChange}
                    input={<BootstrapInput />}
                >
                    {options}
                </NativeSelect>
            </Grid>
        </FormControl>
    );
}

NewsSelect.propTypes = {
    setPropsValue: PropTypes.func,
    label: PropTypes.string,
    options: PropTypes.array,
    oldValue: PropTypes.string,
};

import React ,{useCallback} from "react";
import {Button, Checkbox} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import c from "./judicial.module.css"
import { showJudicialDialog} from "../../../../shared/dialog/actions";


const JudicialDetails = ({details, onJudicialPin, onJudicialInclude, isPrint}) => {
    const addedFromDialog = sessionStorage.getItem("judicialFromSelected") === "judicial"
    const pinnedJudicial = sessionStorage.getItem("pinnedJudicial") ? JSON.parse(sessionStorage.getItem("pinnedJudicial")) : [];

    const JudicialItem = ({data, onPin, onInclude}) => {
        const handleInclude = useCallback(() => {
            const includedJudicial = sessionStorage.getItem("includedJudicial") || [] ;
            let judicial = includedJudicial.length > 0 ? JSON.parse(includedJudicial) : includedJudicial;
            if (judicial.includes(data.id)){
                judicial = judicial.filter(j => j!== data.id );
            } else {
                judicial.push(data.id)
            }
            sessionStorage.setItem("includedJudicial", JSON.stringify(judicial));
            onInclude(data);
            }, [data, onInclude]);

        const handlePin = useCallback(() => {
            const pinnedJudicial = sessionStorage.getItem("pinnedJudicial") || [] ;
            let judicial = pinnedJudicial.length > 0 ? JSON.parse(pinnedJudicial) : pinnedJudicial;
            if (judicial.includes(data.id)){
                judicial = judicial.filter(j => j!== data.id );
            } else {
                judicial.push(data.id)
            }
            sessionStorage.setItem("pinnedJudicial", JSON.stringify(judicial));
            onPin();
        }, [data, onPin]);

        const handleShowContent = useCallback(() => {
            showJudicialDialog({title: data.matchTitle,content: data.content});
        },[data])

        const itemContent = Boolean( data?.snippet ) ? data.snippet.map( ( value, _index ) => {
            const snippet = "<p>..." + value + "...</p>";
            return (
                <div key={"value-" + _index} dangerouslySetInnerHTML={{__html: snippet}} onClick={handleShowContent}/>
            );
        } ) : [];
        return <div className={c.judicialItem}>
            <div className={c.itemBodyHeader}>

                <div className={c.titleWrapper}> <div className={c.vLine}/> <h4>{data.matchTitle}</h4></div>
                {
                    !isPrint  && <div className={c.itemActions}>
                        <div>
                            <Checkbox
                                icon={ <AttachFileIcon className={c.attachIcon}/> }
                                checkedIcon={ <AttachFileIcon className={c.attachCheckedIcon}/> }
                                checked={data.checked}
                                onChange={handleInclude}
                            />
                        </div>
                        <div>
                            <Checkbox
                                color="secondary"
                                icon={ <img alt='pin' src="/images/pin.svg" /> }
                                checkedIcon={ <img alt='pinRed' src="/images/pin-red.svg"/> }
                                checked={data.pinned}
                                onChange={handlePin}
                            />
                        </div>
                        <div>
                            <Button onClick={handleShowContent}>View Content</Button>
                        </div>
                    </div>
                }

            </div>
            <div className={c.itemBody}>
                <div >
                    <div className={c.itemCountries}>
                        <p>{data?.country} { data?.year}</p>
                        <p>{data?.courtType}</p>
                    </div>

                </div>

                <div className={c.itemBodyContent}>
                    {itemContent}
                </div>

            </div>
        </div>
    }

    const handlePin = () => {
        onJudicialPin()
    }

    const handleInclude = (data) => {
        onJudicialInclude(data);
    }
    return (
        <div className={c.avoidBreakInside }>
            {
                details && <div>
                    {
                        details.map((d,index) => {
                            const isVisibleInPdf = (addedFromDialog && pinnedJudicial.length === 0 ) ? true : d.pinned;
                            if (isPrint ) {
                                if (isVisibleInPdf) return <JudicialItem data={d} key={`${d.country}_${index}_root`} index={index} onPin={handlePin} onInclude={handleInclude}/>
                                else return null;
                            } else return <JudicialItem data={d} key={`${d.country}_${index}_root`} index={index} onPin={handlePin} onInclude={handleInclude}/>
                        })
                    }
                </div>
            }
        </div>
    )
}

export default JudicialDetails;

import React from "react";
import PropTypes from "prop-types";

import {Box, TextField, IconButton} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import EntityItem from "./EntityItem.jsx";

export default class Legend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedNode: this.props.selectedNode,
            open: true,
            nodes: this.props.nodes,
        };
    }

    render() {
        const elementIds = [];
        const legendItems = [];
        this.state.nodes.forEach((elementData, index) => {
            if (elementIds.indexOf(elementData.id) === -1) {
                const isSelected = this.state.selectedNode ? this.state.selectedNode.id === elementData.id : false;
                elementIds.push(elementData.id);
                legendItems.push(<EntityItem checked={elementData.isVisible}
                                             elementIds={elementIds}
                                             key={"item-" + index}
                                             isSelected={isSelected}
                                             checkboxClick={(node) => this.props.checkboxClick(node)}
                                             onNodeSelect={(node) => this.props.onNodeSelect(node)}
                                             toggleMaximize={() => this.props.toggleMaximize()}
                                             node={elementData}/>);
            }
        });

        return (
            <div className="legend">
                <Box className="entities"> Entities </Box>
                <TextField
                    id="search-entity"
                    placeholder="Search"
                    InputLabelProps={{style: {fontSize: 13}}}
                    minRows={1}
                    className="input-redUnderline"
                    style={{width: '100%', fontSize: 13, borderColor: '#ef4254', marginBottom: "10px"}}
                    InputProps={{
                        style: {fontSize: 13},
                        startAdornment: (
                            <IconButton color='primary' title='Enter for search'
                                        onClick={() => this.searchItemClick()}>
                                <SearchIcon/>
                            </IconButton>
                        )
                    }}
                    onKeyDown={(event) => this.searchItem(event)}
                    variant={"standard"}
                />
                <br/>
                <div className="entity">
                    {legendItems}
                </div>
            </div>
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({selectedNode: nextProps.selectedNode});
        if (nextProps.nodes !== this.props.nodes) {
            this.setState({nodes: nextProps.nodes});
        }
    }

    searchItem(event) {
        if (event.keyCode === 13) {
            let searchKey = event.target.value;
            let matchedNodes = [];
            if (searchKey.length === 0) {
                this.setState({nodes: this.props.nodes});
            } else {
                this.props.nodes.forEach((elementData, index) => {
                    if (elementData.title.toLowerCase().indexOf(searchKey.toLowerCase()) !== -1) {
                        matchedNodes.push(elementData);
                    }
                    this.searchBySubnodes(elementData, matchedNodes, searchKey);
                });
            }
        }
    }

    searchItemClick() {
        let searchKey = document.getElementById("search-entity").value;
        let matchedNodes = [];
        if (searchKey.length === 0 || !searchKey) {
            this.setState({nodes: this.props.nodes});
        } else {
            this.props.nodes.forEach((elementData, index) => {
                if (elementData.title.toLowerCase().indexOf(searchKey.toLowerCase()) !== -1) {
                    matchedNodes.push(elementData);
                }
                this.searchBySubnodes(elementData, matchedNodes, searchKey);
            });
        }
    }

    searchBySubnodes(node, matchedNodes, searchKey) {
        return new Promise((resolve) => {
            let filterMatchedNodes = [];

            if (node.subNodes.length > 0) {
                node.subNodes.forEach(subnode => {
                    if (subnode.title.toLowerCase().indexOf(searchKey.toLowerCase()) !== -1) {

                        filterMatchedNodes.push(matchedNodes.push(subnode));
                    }
                    if (subnode.subNodes.length > 0) {
                        this.searchBySubnodes(subnode, matchedNodes, searchKey)
                    }
                });
            } else {
                resolve();
            }
            Promise.all(filterMatchedNodes).then(() => {
                resolve();
            });

        }).then(() => {
            this.setState({nodes: matchedNodes})

        });

    }

    selectEntityItem(index) {
        if (this.props.isMaximize) {
            this.props.onNodeSelect(index)
        } else {
            this.props.toggleMaximize();
        }
    }

}

Legend.propTypes = {
    nodes: PropTypes.array,
    selectedNode: PropTypes.object,
    onNodeSelect: PropTypes.func,
    checkboxClick: PropTypes.func,
    isMaximize: PropTypes.bool,
    toggleMaximize: PropTypes.func.isRequired,
    bgColor: PropTypes.string,
    filterNodes: PropTypes.func,
    cachedFilter: PropTypes.object,
    update: PropTypes.func,
};

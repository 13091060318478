import React from 'react'
import {ResponsiveChoropleth} from '@nivo/geo'
import {countryFeatures} from "./constants/countryFeatures";
import {riskScores} from "./constants/risks";

const MyResponsiveChoropleth = ({data}) => (
    <ResponsiveChoropleth
        data={data}
        margin={{top: 0, right: 0, bottom: 0, left: 0}}
        colors={["#65B741","#FFDA6B", "#FE0000","#454545"]}
        domain={[ 1,9]}
        unknownColor="#cdcdcd"
        label="properties.name"
        valueFormat=".2s"
        projectionTranslation={[0.5, 0.5]}
        projectionRotation={[0, 0, 0]}
        enableGraticule={true}
        graticuleLineWidth={0}
        graticuleLineColor="#dddddd"
        borderWidth={0.7}
        borderColor="#fff"
        defs={[]}
        legends={[]}
        features={countryFeatures.features}
    />
)
const CountryRisk = ({data}) => {

    const nameWithRisks = Object.keys(data.riskCountries).map(r => {
        return  {
            id: data.riskCountries[`${r}`].countryCodeAlpha3.toUpperCase(),
            value:data.riskCountries[`${r}`].riskScore
        }
    });
    return (
        <div className="col-xs-12 row geomap">
            <div>
                {
                    data.countryRiskAssessment.map((a,i) => <p key={`${data.id}_${i}`}>{a}</p>)
                }
            </div>
            <div style={{height: 600, display: "flex"}} className="col-xs-8">
                <MyResponsiveChoropleth data={nameWithRisks} />
            </div>
            <div className="col-xs-4 risks">
                {
                    data.riskCountries &&  Object.keys(data.riskCountries).map(r => {
                        return (
                            <div key={`${r}_${data.riskCountries[`${r}`].riskScore}`} className="risk-item">
                                <p style={{color:riskScores[`${data.riskCountries[r].riskText.toLowerCase()}`].color }}>{r}</p>
                                <div>
                                    <div className="risk-item-country" style={{backgroundColor: riskScores[`${data.riskCountries[r].riskText.toLowerCase()}`].color}}/>
                                    <div className="risk-item-body">
                                        <div className="risk-item-name">
                                            <div  className="risk-item-box" style={{backgroundColor: riskScores[`${data.riskCountries[r].riskText.toLowerCase()}`].color}} />
                                            <span style={{color:riskScores[`${data.riskCountries[r].riskText.toLowerCase()}`].color }}>{data.riskCountries[r].riskText}</span>
                                        </div>

                                        <p style={{color:riskScores[`${data.riskCountries[r].riskText.toLowerCase()}`].color }}>{data.riskCountries[r].riskScore}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>

    )
}

export default React.memo(CountryRisk);

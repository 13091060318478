const Regulatory = ({data}) => {

    const RegulatoryItem = ({data}) => {
        return (
            <div className='regulatory-section-item col-xs-12'>
                {
                    Object.keys(data).map((i, index) => {
                        const innerContent = [];
                        data[i].forEach((i,c) => innerContent.push(<span key={`${i}-${c}`}>{i}<br/></span>))
                        return (
                            <div  className='col-xs-12' key={`${i}-${index}`}>
                                <p className='col-xs-2 regulatory-section-item-key'>{i}</p>
                                <p className='col-xs-10'>{innerContent}</p>
                            </div>
                        )
                    })
                }
            </div>
        )

    }
    return (
            data && <div className='regulatory-section'>
            {
                data.map((i,index) => {
                    return <RegulatoryItem data={i} key={`regulatory_${index}`}/>
                })
            }
        </div>
    )
}

export default Regulatory;

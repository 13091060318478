import PropTypes from "prop-types";
import React from "react";
import popper from 'cytoscape-popper';
import cytoscape from 'cytoscape'
import {Button, Drawer, Snackbar} from '@mui/material';

import Utils from "../../utils";
import Node from "../../models/Node";
import Edges from "../../models/Edge";
import {API, doPost} from '../../api';
import {GraphBreakpoints,postActions} from "../../constants";
import Legend from "../report/components/graph/Legend.jsx";
import NodeDetails from "../report/components/graph/NodeDetails.jsx";
import SubnodesFilter from "../report/components/graph/SubnodesFilter.jsx";
import {showAlertDialog, showConfirmDialog} from "../../shared/dialog/actions";
import {graphUtils} from "../report/components/graph/graphHelpers";
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css';

cytoscape.use(popper);

export default class Relations extends React.Component {
    constructor( props ) {
        super( props );
         window.cy = null;
        this.cytoscape = null;
        this.nodeMap = {};
        this.edgeMap = {};
        let nodes = [];
        let edges = [];
        let categories = [];
        let filter = { info: false, pep: false, sanction: false, non_active: false, without_entity: false };

        if ( Utils.isNotNullOrUndefined( props.nodes ) && props.nodes.length > 0 ) {
            nodes = props.nodes.map( nodeData => {
                const node = new Node( nodeData );
                this.nodeMap[nodeData.id] = node;
                nodeData.category.forEach( ( cat ) => {
                    const c = cat.toLowerCase();
                    let item = c.startsWith("#") ? c.substr(1) : c;
                    if (Object.keys(filter).indexOf(item) === -1 && c !== "#other" && c !== "#generic_cross_entity" && c !== "#interpersonal") {
                        if(c.startsWith('#shareholding')) { item = 'shareholding'}
                        filter[item] = false;
                    }
                } )
                return node;
            } );

            edges = props.edges.map( edgeData => {
                const edge = new Edges( edgeData );
                edge.source = this.nodeMap[edge.sourceId];
                edge.target = this.nodeMap[edge.targetId];
                return edge;
            } );
        }
        this.state = {
            historyForBackNodes: [],
            nodes,
            edges,
            nodeSize: nodes.length < 6 ? 250 : 200,
            layout: 'cose',
            getImage: props.getImage,
            categories,
            nodeDetails: [],
            selectedCategory: props.selectedCategory,
            sourceNodes: props.sourceNodes || [],
            filter,
            updateGraph: props.updateGraph,
            open: true,
            openDialog: false,
            openSnackbar: false,
            openSnackbarSubnodes: false,
            realSubnodes: [],
            clickedNode: null,
            subnodesEdges: [],
            clickedNodeId: null,
            subnodesFilteredArray: null,
            checkedAll: false,
            hiddenNdes: null,
            snackbarText: "No more nodes to show.",
            doLayout: false,
            showFilter: false,
            openNodetoCartSnack: false,
            toCartNode: null,
            showFormerNodes: props.showFormerNodes,
            showOrHideTransitionNodes: props.showOrHideTransitionNodes,
            refreshGraph: props.refreshGraph,
            defaultGraph: props.defaultGraph,
            filteredItem: {},
            anotherFilter: Object.keys(filter),
            selectedFilter: '',
            checkedFilter: null,
            allChecked: false,
            changeHistoryNodes: props.changeHistoryNodes,
            nodeMore:false
        };
        this.clickedNode = React.createRef();
        this.clickedNode.current = [];
    }

    render() {
        const nodeInfo = [];
        let bnli = localStorage.getItem( "bnli" );
        let prId = sessionStorage.getItem( "pathSessionId" );
        this.state.nodeDetails.forEach( ( id ) => {
            const x = this.cytoscape.getElementById(id ).renderedPosition( 'x' );
            const y = this.cytoscape.getElementById(id ).renderedPosition( 'y' );
            nodeInfo.push( <NodeDetails
                key={ 'details-relations' + id + x + y }
                id={ id } x={ x } y={ y }
                projectId={ prId }
                bnli={ bnli }
                haveSubnodes={ ( id ) => this.detectValSubnodes( id ) }
                hideSubnodes={ ( id ) => this.showOrHideSubnodes( id , false) }
                onClose={ ( id ) => this.hideNodeDetails( id ) }
                addToCard={ ( id ) => this.addToCard( id ) }
                isPosibleToAddCard={ false }
                sessionExpired={ () => this.props.sessionExpired() }/>
            );
        } );

        let isPathsFounded = this.state.nodes.length > 0;
        if ( isPathsFounded ) {
            return (
                <div  onClick={() => this.handleFilterClick()}>{ nodeInfo }
                    <div className="graph" id="cytoscape_1" style={ { width: "100%", height: "92vh" } }/>
                    <div className="row component-graph maximize">
                        <button
                            onClick={ () => this.handleToggle() }
                            className={ this.state.open ? "toggle-btn-multi btn btn-floating btn-large waves-effect waves-light" : "toggle-btn-multi-1 btn btn-floating btn-large waves-effect waves-light" }
                        >
                            { this.state.open ? <i className="material-icons" style={ {
                                    color: '#ef4254',
                                    marginLeft: "-12px",
                                    marginTop: "7px"
                                } }>keyboard_arrow_right</i> :
                                <i className="material-icons" style={ {
                                    color: 'white',
                                    marginLeft: "-12px",
                                    marginTop: "7px"
                                } }>keyboard_arrow_left</i> }
                        </button>
                        <Drawer
                            open={ this.state.open }
                            style={ { fontFamily: "Roboto, sans-serif", marginTop: '44px !important' } }
                            className="drawer sidebar" variant="persistent" anchor="right">
                            <Legend
                                nodes={ this.state.nodes }
                                selectedNode={ this.state.selectedNode }
                                isMaximize={ true }
                                bgColor={ this.props.bgColor }
                                ref="legend"
                                toggleMaximize={ () => this.props.toggleMaximize() }
                                onNodeSelect={ ( node ) => this.selectNodeById( node.id ) }
                                checkboxClick={ ( node ) => this.addOrRemoveNode( node ) }
                                filterNodes={ ( filter ) => this.filterNodes( filter ) }
                                cachedFilter={ this.props.cachedState ? this.props.cachedState.filter : null }/>
                        </Drawer>
                    </div>

                    <Snackbar
                        key="snackbarText"
                        ContentProps={{style:{borderRadius: "2px", fontSize: "14px"}}}
                        style={{bottom: 0}}
                        open={this.state.openSnackbarSubnodes}
                        message={this.state.snackbarText}
                        onClose={this.handleRequestClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal : 'center'}}
                    />

                    {this.state.openDialog && <SubnodesFilter
                        nodes={this.state.subnodes}
                        mainNodeId={this.state.nodes[0].id}
                        extraNodes={this.state.nodeMore}
                        edges={this.state.subnodesEdges}
                        clickedNodeId={this.state.clickedNodeId}
                        addSubnodesToGraph={(nodesToAdd, nodesToRemove, subnodesEdges, clickedNodeFromDialog) => this.addSubnodesToGraph(nodesToAdd, nodesToRemove, subnodesEdges, clickedNodeFromDialog)}
                        handleClose={() => this.setState({openDialog: false})}
                        checkedAll={this.state.checkedAll}
                    />}
                </div>

            )
        } else {
            return (<div style={ { width: "100%", height: "500px", padding: "15% 35% ", textAlign: "center" } }>
                <div style={ { fontWeight: "bold" } }>There is no relation between the nodes.</div>
                <div>Please note that relation path search has limitations.</div>
                <div>We have searched the distance of 6 relations. The path among the searched nodes may still exist in
                    further radiuses.
                </div>
                <div>
                    <Button
                        className="fab-explore"
                        onClick={()=> this.generateEmptyReport()}
                        style={{
                            borderRadius:25,
                            position:'relative',
                            zIndex:100,
                            marginTop: 15,
                            backgroundColor: 'rgb(239, 66, 84)',
                            color:'#fff',
                            fontSize: 16,
                            padding: 10
                        }}
                    >
                        Generate No Result Report
                    </Button>
                </div>
            </div>)

        }

    }

    generateEmptyReport() {
        const pathData = {
            date: Utils.getReportFormattedDay(),
            nodes: this.state.sourceNodes
        }
        sessionStorage.setItem('resultFrom', 'path')
        sessionStorage.setItem('relationPathNodes', JSON.stringify(pathData))
        sessionStorage.removeItem("searchData")
        const newWin = window.open('/noresult')
        if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
            showAlertDialog({
                title: "Attention!",
                message: "Popups blocked in your browser. Please allow popups in browser settings and try again.",
            });
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( this.state.nodes.length > 0 && nextProps.layout !== this.state.layout ) {
            this.setState( { layout: nextProps.layout }, () => {
                if ( Utils.isNotNullOrUndefined( this.cytoscape ) ) {
                    const layout = this.cytoscape.layout( this.getLayoutOptions() );
                    layout.run();
                }
            } )
        }

        if (nextProps.changeHistoryNodes !== this.state.changeHistoryNodes) {
            this.setState({changeHistoryNodes: !this.state.changeHistoryNodes}, () => {
                this.historyNodes();
            })
        }

        if ( this.state.nodes.length > 0 && nextProps.status === "DONE" && nextProps.doFilter && this.state.updateGraph !== nextProps.updateGraph) {

            let refresh = Object.keys(this.state.filter).length === this.state.anotherFilter.length;
            const filters = !nextProps.filtered ?  [...this.state.anotherFilter, nextProps.selectedFilter] :
                this.state.anotherFilter.filter(cat => cat !== nextProps.selectedFilter);
            const allChecked = filters.length === Object.keys(this.state.filter).length;

            this.setState( {filter: nextProps.filter, anotherFilter: filters, updateGraph: nextProps.updateGraph, allChecked}, () => {
                this.filterNodes( nextProps.selectedFilter, nextProps.filtered, refresh );
           })
        }

        if(this.state.showFormerNodes !== nextProps.showFormerNodes) {
            this.setState({showFormerNodes: nextProps.showFormerNodes}, () =>  this.filterFormerNodes());
        }

        if(this.state.refreshGraph !== nextProps.refreshGraph) {
            this.setState({refreshGraph: nextProps.refreshGraph}, () => {
                const layout = this.cytoscape.layout(this.getLayoutOptions());
                layout.run();
            })
        }

        if(this.state.defaultGraph !== nextProps.defaultGraph) {
            if (this.state.anotherFilter.length > 0) {
                const filter = this.state.filter;
                Object.keys(filter).forEach(item => filter[item] = false);
                this.setState({filter, anotherFilter: Object.keys(this.state.filter), allChecked: true, defaultGraph: nextProps.defaultGraph}, () =>this.defaultNodes())
            } else {
                this.setState({defaultGraph: nextProps.defaultGraph}, () => this.defaultNodes())
            }
        }

        if(nextProps.showOrHideTransitionNodes !== this.state.showOrHideTransitionNodes) {
            this.setState({showOrHideTransitionNodes : nextProps.showOrHideTransitionNodes}, () => {
                this.showTransactions()

            })
        }
    }

    filterFormerNodes() {
        const nodes = [...this.state.nodes];

        nodes.forEach(node => {
            const elem = this.cytoscape.getElementById(node.id);
            this.showSingleFormer(elem);

            if (node.subNodes.length > 0) {
                node.subNodes.forEach(subNode => {
                    const subNodeGraph = this.cytoscape.getElementById(subNode.id);
                    this.showSingleFormer(subNodeGraph);

                })
            }
        })

        this.showOrHiddenEdges();
        this.setState({nodes});
    }

    showSingleFormer(elem) {
        const nodeData = elem._private.data;
        const nodeInfo = this.nodeMap[nodeData.id];
        if (Utils.isNullOrUndefined(nodeInfo)) return ;

        if (!nodeInfo.isMainNode) {
            if (elem && nodeInfo.isHistorical) {
                this.showOrHideNodes(this.state.showFormerNodes, nodeInfo, elem)
            }
        }
    }

    defaultNodes() {
        this.nodeMap = {};
        this.edgeMap = {};
        let historyForBackNodes = [];
        this.cytoscape.remove("node");
        this.cytoscape.remove("edge");

        let nodes = this.props.nodes.map((nodeData, index) => {
            nodeData.isMainNode = index === 0;
            const node = new Node(nodeData);
            node.checked = true;
            node.isVisible = true;
            node.parent = null;
            this.nodeMap[nodeData.id] = node;
            return node;
        });

        let edges = this.props.edges.map(edgeData => {
            const edge = new Edges(edgeData);
            edge.source = this.nodeMap[edge.sourceId];
            edge.target = this.nodeMap[edge.targetId];
            this.edgeMap[edge.id] = edge;
            return edge;
        });

        this.setState({nodes, edges, historyForBackNodes}, () => {
            new Promise((resolve) => {
                let nodesPromises = [];
                this.state.nodes.forEach(node => {
                    nodesPromises.push(this.addNode(node));
                });
                Promise.all(nodesPromises).then(() => {
                    resolve();
                });
            }).then(() => {
                this.createRelations();
                const layout = this.cytoscape.layout(this.getLayoutOptions());
                layout.run();
            });
        });
    }

    componentDidMount() {
        if ( this.state.nodes.length > 0 ) {
            this.initCytoscape();

            this.createNodes().then( () => {
                setTimeout( () => {
                    this.createRelations();
                    const layout = this.cytoscape.layout( this.getLayoutOptions() );
                    layout.run();
                    this.cytoscape.fit();
                    this.cytoscape.resize();
                }, 500 )
                this.nodeHandleClick();

            } );
            this.props.setFilter( this.state.filter );
        }
    }

    handleRequestClose = () => {
        this.setState( {
            openSnackbar: false,
            openSnackbarSubnodes: false
        } );
    };

    handleToggle() {
        this.setState( { open: !this.state.open } );
    }

    handleFilterClick() {
        this.props.onGraphClick()
    }

    selectNodeById( nodeId ) {
        const collection = this.cytoscape.getElementById(nodeId );
        if ( collection.length > 0 ) {
            this.unselectAllNodes();
            const node = collection[0];
            node.select();
            if ( !this.props.automation ) {
                this.setState( { selectedNode: this.nodeMap[nodeId] } );
            }

        }
    }

    unselectAllNodes() {
        this.cytoscape.nodes().unselect();
    }

    getSelectedFilterItems() {
        return Object.keys(this.state.filter).filter(item => this.state.filter[item] === true);
    }

    getLayoutOptions() {
        let options = {}
        const nodeQnt = this.state.nodes.length;
        const isXSmallCase = nodeQnt <= GraphBreakpoints.xSmall;
        const isSmallCase = nodeQnt <= GraphBreakpoints.small;
        const isMedCase = nodeQnt > GraphBreakpoints.small && nodeQnt <= GraphBreakpoints.medium;
        const isLargeCase = nodeQnt > GraphBreakpoints.medium;

        switch(this.state.layout) {
            case 'grid':
                options = {
                    name: this.state.layout,
                    padding:30,
                    avoidOverlapPadding: 20,
                    nodeDimensionsIncludeLabels: true,
                    spacingFactor: 1.5,
                    condense: true,
                    animate: true
                }
                break;
            case 'circle':
                options = {
                    name: this.state.layout,
                    levelWidth: function () {
                        return 2;
                    },
                    fit: true,
                    avoidOverlap: true,
                    avoidOverlapPadding: 30,
                    padding: 20,
                    animate: true,
                    animationThreshold: 0,
                    edgeElasticity: () => {
                        return 20;
                    },
                    useMultitasking: true,
                    idealEdgeLength: () => {
                        return 40;
                    },
                    nestingFactor: () => {
                        return 1.2;
                    },
                    refresh: 20,
                    spacingFactor: 1.2
                };
                break;
            case 'concentric':
                options = {
                    name: this.state.layout,
                    levelWidth: function () {
                        return 2;
                    },
                    minNodeSpacing: isXSmallCase ? 400 : null,
                    fit: true,
                    avoidOverlap: true,
                    avoidOverlapPadding: 30,
                    padding: 20,
                    animate: true,
                    animationThreshold: 0,
                    useMultitasking: true,
                    nestingFactor: () => {
                        return 1.2;
                    },
                    refresh: 20,
                    spacingFactor: 1.2
                }
                break;
            case 'breadthfirst':
                options = {
                    name: this.state.layout,
                    fit: true,
                    avoidOverlap: true,
                    padding: 20,
                    maximal: true,
                    animate: true,
                    animationThreshold: 0,
                    nodeDimensionsIncludeLabels: true,
                    refresh: 20,
                    spacingFactor: 2,

                }
                break;
            case 'cose':
                options = {
                    name: 'fcose',
                    quality: "default",
                    randomize: true,
                    fit: true,
                    padding: 30,
                    nodeDimensionsIncludeLabels: true,
                    uniformNodeDimensions: false,
                    step: "all",
                    nodeRepulsion: () => 1500,
                    idealEdgeLength: () => isSmallCase ? 750 : isMedCase ? 1250 : 1800,
                    numIter: 2500,
                    edgeElasticity: () => isMedCase ? 0.45 : 0.2,
                    gravity: 0,
                    gravityRangeCompound: 1.5,
                    gravityCompound: 1.0,
                    gravityRange: 3.8,
                    initialEnergyOnIncremental: 0.2,

                }
                break;
            case 'dagre':
                options = {
                    name: this.state.layout,
                    nodeSep: 150,
                    edgeSep: 90,
                    rankSep: 150,
                    rankDir: 'TB',
                    ranker: 'tight-tree',
                    minLen: () =>  isLargeCase ? 10 : 1,
                    randomize: true,
                    fit: true,
                    padding: 30,
                    nodeDimensionsIncludeLabels: true,
                    animate: true
                }
                break;
            default:
                break;
        }
        return options;
    }

    createNodes() {
        return new Promise( ( resolve ) => {
            let nodesPromises = [];
            this.state.nodes.forEach( node => {
                nodesPromises.push( this.addNode( node ) );
                node.isVisible = true;
            } );
            Promise.all( nodesPromises ).then( () => {
                resolve();
            } );

        } );
    }

    getCytoscapeStyles() {

        return [
            {
                selector: 'node',
                style : {
                    'height': 100,
                    'width': 100,
                    'background-fit': 'contain',
                    'background-image': 'data(nodeAvatar)',
                    'background-color': '#ffffff',
                    'background-opacity': 0,
                    'border-width': 2,
                    'border-opacity': 1,
                    'border-color': '#95989a',
                    'label': 'data(label)',
                    'text-wrap': 'wrap',
                    'text-max-width': this.state.nodeSize
                }
            },{
                selector: 'node:selected',
                style : {
                    'height': 180,
                    'width': 180,
                    'background-fit': 'contain',
                    'background-image': 'data(nodeAvatar)',
                    'background-color': '#ffffff',
                    'background-opacity': 1,
                    'border-opacity': 1,
                    'label': 'data(label)',
                    'text-wrap': 'wrap',
                    'border-width': 5,
                    'border-color': '#ef4254',
                    'text-max-width': this.state.nodeSize
                }
            },{
                selector: 'node.pie',
                style : {
                    'pie-size': '100%',
                    'height': 147,
                    'width': 147,
                }
            },{
                selector: 'node.pie:selected',
                style : {
                    'height': 190,
                    'width': 190,
                    'border-width': 5,
                    'border-color': '#ef4254'
                }
            },{
                selector: ':parent',
                style : {
                    'background-color': '#ffffff',
                    'border-color': '#ffffff',
                    'background-opacity': 0,
                    'border-opacity': 0
                }
            },{
                selector: ':parent:selected',
                style : {
                    'background-color': '#ffffff',
                    'border-color': '#ffffff',
                    'background-opacity': 0,
                    'border-opacity': 0,
                    'border-width': 5,
                }
            },{
                selector: 'edge',
                style : {
                    'width': 6,
                    'target-arrow-color': '#ef4254',
                    'curve-style': 'bezier',
                    'label': 'data(label)',
                    'font-size': 20,
                    'edge-text-rotation': 'autorotate',
                    'target-arrow-shape': 'triangle',
                    'line-color': 'data(color)',
                    'source-arrow-color': 'data(color)',
                    'line-style': 'data(style)',
                }
            },{
                selector: 'node.endpoint',
                style : {
                    'height': 300,
                    'width': 300,
                    'background-fit': 'contain',
                    'background-color': '#ffffff',
                    'background-opacity': 1,
                    'border-opacity': 1,
                    'label': 'data(label)',
                    'text-wrap': 'wrap',
                    'border-width': 10,
                    'border-color': '#ef4254'
                }
            },{
                selector:  'node.endpoint:selected',
                style : {
                    'height': 350,
                    'width': 350,
                    'background-fit': 'contain',
                    'background-color': '#ffffff',
                    'background-opacity': 1,
                    'border-opacity': 1,
                    'label': 'data(label)',
                    'text-wrap': 'wrap',
                    'border-width': 10,
                    'border-color': '#ef4254'
                }
            },{
                selector: '.extraParent',
                style: {
                    'background-image': 'unset',
                    'border-width': 0,
                }
            }
        ]
    }

    initCytoscape() {
        const container = document.getElementById( "cytoscape_1" );
        if (container) {
            window.cy = cytoscape(
                {
                    container: container,
                    levelWidth: () => 2,
                    edgeElasticity: () => 10,
                    idealEdgeLength: () => 10,
                    concentric: (node) => node.degree(),
                    maxZoom: 3,
                    minZoom: 0.01,
                    spacingFactor: 1.2,
                    layout: { name: this.state.layout },
                    wheelSensitivity: .2,
                    userZoomingEnabled: true,
                    autoungrabify: false,
                    boxSelectionEnabled: true,
                    style: this.getCytoscapeStyles(),
                }
            )
        }
        this.cytoscape = window.cy;
    }

    addNode(node, onlyVisibleSubnodes = false ) {
        if (Utils.isNullOrUndefined(node)) return;
        return new Promise((resolve, reject) => {
            if (Utils.isNotNullOrUndefined(node) && Utils.isNotNullOrUndefined(node.parent)) {
                let parentCyNode = this.cytoscape.getElementById(node.parent);
                if (parentCyNode.length === 0) {
                    this.cytoscape.add([{group: "nodes",  data: {
                            id: node.parent,
                            filterEles: node.category ? node.category : [],
                            pie: node.pie ? Object.keys(node.pie) : []
                        }}]);
                }
            }
            let cyNode = this.cytoscape.getElementById(node.id);
            if (cyNode.length > 0) {
                reject()
            } else {
                node.getCyElement().then( nodeOptions => {
                    this.setNodeOptions(nodeOptions, node, onlyVisibleSubnodes).then(() => {
                        resolve()
                    })
                        .catch(() => {
                        reject();
                    })
                } )
            }
        } )
    }

    setNodeOptionsCState(node) {
        const isVisible = node.isVisible;
        const cyNode = this.cytoscape.getElementById(node.id);
        let content;
        if (!node.isMore()) {
            if (!node.hasPie()) {
                if (!node.checkedFromList) {
                    cyNode.style('opacity' ,  isVisible ? '1 ' : this.state.showOrHideTransitionsNodes ? '0' : '.2')

                } else {
                    cyNode.style('opacity','0')
                }
                content = this.getNodeTippyContent(node);
            } else {
                if (!node.checkedFromList) {
                    cyNode.style('opacity' ,  isVisible ? '1 ' : this.state.showOrHideTransitionsNodes ? '0' : '.2')

                } else {
                    cyNode.style('opacity','0')
                }

                content = this.getNodePieTippyContent(node)
            }
        } else {
            if (!node.checkedFromList) {
                cyNode.style('opacity' ,  isVisible ? '1 ' : this.state.showOrHideTransitionsNodes ? '0' : '.2')

            } else {
                cyNode.style('opacity','0');
            }

            content = 'Click to load more nodes';
        }
        graphUtils.makePopup(cyNode, content);

        node.subNodes.forEach(subnode => {
            this.setNodeOptionsCState(subnode);
        });
    }

    getNodeTippyContent(node) {
        return `<div class = 'tooltipNode' style='max-height:230px!important; color:#95989A' >${node.title}</div>`
    }

    getNodePieTippyContent(node){
        let content = '';
        let value = '';
        Object.keys(node.pie).forEach((key) => {
            const values = node.pie[key];
            if (Utils.isNotNullOrUndefined(values.name)) {
                value = values.name + ": ";
            }
            let color;
            switch (key) {
                case 'pep':
                    color = '#6da6ce';
                    break;
                case 'sanction':
                    color = '#F05F7C';
                    break;
                case 'info':
                    color = '#8d6cab';
                    break;
                case 'non_active':
                    color = '#757575';
                    break;
                default:
                    color = '#000';
                    break;
            }

            content += "<div><b style='color:" + color + "; text-transform: capitalize'>&#9632; " + key.replace(/_/g, " ") + "</b> <div>" + value + values.value + "</div></div>";
        });

        return `<div class = 'tooltipNode' style='max-height:230px!important; max-width: 280px; color:#95989A;word-wrap: break-word;overflow-y: auto' ><p>${node.title}</p> ${content}</div>`;
    }

    hideOrShowTooltips(node, element) {
        let content;
        if (!node.isMore()) {
            if (!node.hasPie()) {
                content = this.getNodeTippyContent(node);
            } else {
                content = this.getNodePieTippyContent(node)
            }
        } else {
            content = 'Click to load more nodes'
        }
        graphUtils.makePopup(element, content);
    }

    setNodeOptions(nodeOptions, node, onlyVisibleSubnodes) {
        return new Promise((resolve) => {
            let cyNode1 = this.cytoscape.getElementById(node.id);
            if (cyNode1.length === 0) {
                this.cytoscape.add( nodeOptions );
            }
            const cyNode = this.cytoscape.getElementById(node.id);
            let content;
            if (!node.isMore()) {
                if (!node.hasPie()) {
                    content = this.getNodeTippyContent(node);
                } else {
                    content = this.getNodePieTippyContent(node);
                }
            } else {
                content = 'Click to load more nodes';
            }
            graphUtils.makePopup(cyNode, content);

            this.setSubnodes(node.subNodes, onlyVisibleSubnodes).then( () => {
                resolve();
            } );
        } )
    }

    setSubnodes(subnodes = [], onlyVisibleSubnodes) {
        return new Promise((resolve, reject) => {
            const subNodesPromises = [];
            subnodes.forEach(subnode => {
                const node =  this.cytoscape.getElementById(subnode.id);
                if (onlyVisibleSubnodes) {
                    if (node.length === 0) {
                        if (subnode.isVisible) {
                            subNodesPromises.push(this.addNode(subnode));
                        }
                    }
                } else {
                    if (node.length === 0 ) {
                        if (subnode.checked) {
                            subnode.isVisible = true;
                            subNodesPromises.push(this.addNode(subnode));
                        }
                    }
                }
            } );
            Promise.all(subNodesPromises).then( () => {
                resolve();
            }).catch((error) => {
                reject();
                console.log(error)
            });
        } )
    }

    createRelations() {
        const elements = [];
        let filtered = this.getSelectedFilterItems();

        let egdeFilter = filtered.filter( item => {
            if ( item !== "pep" && item !== "sanction" && item !== "info" && item !== "non_active" ) {
                return item;
            }
            return null;
        } )
        this.state.edges.forEach( edge => {
            let filterExist = egdeFilter.length === 0 || egdeFilter.indexOf(edge.category) !== -1 || edge.category.startsWith('shareholding')
                || edge.category.toLowerCase() === "other" || edge.category.toLowerCase() === "generic_cross_entity" || edge.category.toLowerCase() === "interpersonal"
            const sourceNode = Utils.isNotNullOrUndefined( edge.source ) ? this.cytoscape.getElementById(edge.source.id) : [];
            const targetNode = Utils.isNotNullOrUndefined( edge.target ) ? this.cytoscape.getElementById(edge.target.id) : [];
            if ( sourceNode.length === 1 && targetNode.length === 1 && filterExist && this.cytoscape.getElementById(edge.id).length === 0 ) {
                elements.push( edge.getCyEdge() );
                this.cytoscape.add( edge.getCyEdge() );
            }
        } );

        this.cytoscape.add( elements );
        this.state.edges.forEach( edge => {
            if ( Utils.isNotNullOrUndefined( edge.titleHover ) ) {
                const cyEdge = this.cytoscape.getElementById(edge.id);
                const content = `<div class='tooltipNode' style='max-height:300px!important; color:#95989A'>${edge.titleHover}</div>`;
                graphUtils.makePopup(cyEdge, content);
            }
        });
    }

    createSubnodesRelations(subnodeEdges) {
        const elements = [];
        subnodeEdges.forEach(edge => {
            const sourceNode = Utils.isNotNullOrUndefined(edge.source) ? this.cytoscape.getElementById(edge.source.id) : [];
            const targetNode = Utils.isNotNullOrUndefined(edge.target) ? this.cytoscape.getElementById(edge.target.id) : [];
            if (sourceNode.length === 1 && targetNode.length === 1) {
                elements.push(edge.getCyEdge());
            }
        });

        this.cytoscape.add(elements);
        elements.forEach(edge => {
            if (Utils.isNotNullOrUndefined(edge.titleHover)) {
                const cyEdge = this.cytoscape.getElementById(edge.id);
                const content = `<div class='tooltipNode' style='max-height:300px!important; color:#95989A'>${edge.titleHover}</div>`;
                graphUtils.makePopup(cyEdge, content);
            }
        });
        this.showOrHiddenEdges();
    }

    nodeHandleClick() {
        let clickCount = 0;
        let singleClickTimer;

        this.cytoscape.on( "click", "node", ( event ) => {
            const node = this.nodeMap[event.target.id()];
            clickCount++;
            if ( clickCount === 1 ) {
                singleClickTimer = setTimeout( () => {
                    clickCount = 0;
                }, 400 );
            } else if ( clickCount === 2 ) {
                let edgesOfNode = this.cytoscape.getElementById(event.target.id()).connectedEdges();
                clearTimeout( singleClickTimer );
                clickCount = 0;
                //smth for single click
                this.nodeHandleDblClick( node, edgesOfNode );
            }

        } );
        this.cytoscape.on( "cxttap", "node", ( event ) => {
            const node = this.nodeMap[event.target.id()];
            this.nodeHandleSingleClick( node );
        } );
    }

    hideNodeDetails( nodeId ) {
        const nodeDetails = this.state.nodeDetails;
        const index = nodeDetails.indexOf( nodeId );
        if ( index !==-1 ) {
            nodeDetails.splice( index, 1 );
            this.setState( { nodeDetails } );
        }
    }

    nodeHandleSingleClick( node ) {
        if ( this.state.nodeDetails.indexOf( node.id ) === -1 ) {
            const nodeDetails = this.state.nodeDetails;
            nodeDetails.push( node.id );
            this.setState( { nodeDetails } );
        }
    }

    componentWillUnmount() {
        this.setState({doLayout: false});
        if(Utils.isNotNullOrUndefined(this.cytoscape)) {
            this.cytoscape.removeAllListeners();
            this.cytoscape.unmount();
            this.cytoscape.destroy();
        }
        sessionStorage.removeItem('relationPathNodes');
    }

    nodeHandleDblClick( clickedNode ) {
        if ( Utils.isNullOrUndefined( clickedNode ) ) return;
        let mainNodeId = this.state.nodes[0].id;
        let nodeNeighbours = this.cytoscape.getElementById(clickedNode.id ).neighborhood();
        const doID = clickedNode.subNodes.length === 0;

        nodeNeighbours.forEach( neighbor => {
            if ( mainNodeId === neighbor._private.data.id ) {
                clickedNode.fromFirstLayer = true;
            }
        } )

        const prId = sessionStorage.getItem( 'pathSessionId' );
        const bnli = localStorage.getItem( 'bnli' );

        const requestData = {
            action: postActions.GRAPH,
            time: new Date().getTime(),
            nid: clickedNode.id,
            bnli: bnli,
            projectId: prId
        };
        const additionalClick = this.clickedNode.current.filter(item => item === clickedNode.id);
        const activeReq = additionalClick.size <= 1 || this.clickedNode.current.length === 0;

        this.setState( { openSnackbarSubnodes: true, snackbarText: "Loading related nodes." } );
        this.clickedNode.current.push(clickedNode.id);

        activeReq && doPost( API.PATH, { qry: JSON.stringify( requestData ) }, true ).then( response => {
            if ( !Utils.isNullOrUndefined( response.data.error ) ) {
                this.props.goToLogin();
            }
            if ( Utils.isNotNullOrUndefined( response.data.data ) ) {
                const filters = Object.keys(this.state.filter);
                let checkedFilters = filters.filter(key => this.state.filter[key]);
                if (checkedFilters.length !== filters.length && this.state.anotherFilter.length !== filters.length) {
                    showConfirmDialog({
                        title: "Filters applied.",
                        message: "Clear all filters to continue",
                        onContinue: () => {
                            this.clearAll();
                            this.setState({openSnackbarSubnodes: false, snackbarText: ''})
                        },
                        continueButtonLabel: "Clear all filters"
                    });
                    return;
                }
                this.setState( { subnodes: null, openSnackbarSubnodes: false } );
                let subnodes = response.data.data ? response.data.data.nodes : [];//all subnodes from response with already shown nodes
                let realSubnodes = [];// get only the nodes that are not in cytoscape
                let newEdges = response.data.data ? response.data.data.edges : []; // all edges from response
                let subnodesEdges = [];
                let clickedNodeId = clickedNode.id;
                this.parentPos = {
                    x: this.cytoscape.getElementById(clickedNode.id).position( 'x' ),
                    y: this.cytoscape.getElementById(clickedNode.id ).position( 'y' ),
                }
                if (subnodes === 0) return;

                newEdges.forEach((edge => {
                    const newEdge = new Edges(edge)
                    subnodesEdges.push(newEdge);
                }))

                subnodes.forEach(subnode => {
                    let nodeElement = this.cytoscape.getElementById(subnode.id);
                    const nodeElementIsHidden = nodeElement.length > 0 && nodeElement.hidden();

                    if (Object.keys(this.nodeMap).indexOf(subnode.id) === -1 || nodeElement.length === 0) {
                        realSubnodes.push(subnode);
                    }
                    if (nodeElementIsHidden) {
                        realSubnodes.push(subnode);
                    }

                    if (nodeElement.length !== 0 && !nodeElementIsHidden) {
                        subnode.isVisible = true;
                        subnode.checked = true;
                    } else {
                        subnode.checked = false;
                        subnode.isVisible = false;
                    }
                } );

                let isVisible = true;
                for ( let i = 0; i < subnodes.length; i++ ) {
                    const node = subnodes[i];
                    const visible = Utils.isNullOrUndefined( node.isVisible ) ? false : node.isVisible;
                    if ( !visible ) {
                        isVisible = false;
                        break;
                    }
                }
                let doGuiID = doID && realSubnodes.length > 0;
                this.setState( { checkedAll: isVisible, doGuiID } );

                if ( subnodes.length === 0 || realSubnodes.length === 0 ) {
                    this.clickedNode.current = [];
                    this.setState( { openSnackbarSubnodes: true, snackbarText: "No more nodes to show." }, () => {
                        setTimeout( () => this.setState( { openSnackbarSubnodes: false } ), 2000 )
                    } );
                }

                if (subnodes.length > 0 && subnodes.length <= 10) {
                    this.setState({subnodes, subnodesEdges, clickedNode, clickedNodeId}, () => {
                        if (realSubnodes.length === 0) return;
                        this.clickedNode.current = [];
                        this.addSubnodesToGraph(realSubnodes, []);
                    })
                } else if (Utils.isNotNullOrUndefined(subnodes) && subnodes.length>10){
                    const moreNodes = response.data.data.nodeMore;
                    this.setState({subnodes, subnodesEdges, clickedNode, clickedNodeId, nodeMore: moreNodes}, () => {
                        this.clickedNode.current = [];
                        this.setState({openDialog: true});
                    })
                }
            }
        } ).catch( error => {
            console.error( error );
        } );
    }

    clearAll() {
        const filters = this.state.filter;
        Object.keys(filters).forEach( item => filters[item] = false);
        this.clickedNode.current = [];
        this.setState({filter: filters, anotherFilter: Object.keys(filters), allChecked: true}, () => {
            this.filterNodes();
            this.props.onClearAll(filters)
        })
    }

    addSubnodesToGraph(nodesToAdd, nodesToRemove, subnodesEdgesFromMore = null) {
        this.setState({openBackDrop: true, openDialog: false}, () => {
            let historyForBackNodes = this.state.historyForBackNodes;
            let clickedNode = this.state.clickedNode;

            let clickedNodeSubnodesIds = clickedNode.subNodes.length > 0 ? clickedNode.subNodes.map(node => node.id) : [];
            const mainNode = this.state.nodes[0].id;
            const idsNodesForRemove = nodesToRemove.map(item => item.id);

            idsNodesForRemove.forEach(id => {
                let node = this.nodeMap[id];
                node.isVisible = false;
                node.checked = false;
            });

            const collectionForRemove = this.cytoscape.nodes().filter(ele => {
                const id = ele.data('id');
                return idsNodesForRemove.includes(id) && mainNode !== id;
            });

            if (collectionForRemove.length > 0) {
                collectionForRemove.style('visibility', 'hidden');
            }

            const newSubnodes = [];
            if (this.state.doGuiID) {
                clickedNode.parent = clickedNode.id + '_' + this.guid();//added container
            }

            let idsVisible = [];
            nodesToAdd.forEach((nodeData) => {
                if (nodeData.id === clickedNode.id) return;
                let node = this.nodeMap[nodeData.id];

                if (Utils.isNullOrUndefined(node)) {
                    nodeData.parentPos = this.parentPos;
                    node = new Node(nodeData);
                    node.isVisible = true;
                    node.checked = true;
                    if (!clickedNode.fromFirstLayer) {
                        node.parentId = clickedNode.id;
                    }
                    node.parent = clickedNode.parent;
                    node.parentId = clickedNode.id;
                    if (clickedNodeSubnodesIds.indexOf(node.id) === -1 && Object.keys(this.nodeMap).indexOf(node.id) === -1) {
                        this.nodeMap[nodeData.id] = node;
                        clickedNode.subNodes.push(node);
                        newSubnodes.push(node.getCyElement());
                    }
                } else {
                    node.isVisible = true;
                    node.checked = true;
                    if (!clickedNode.fromFirstLayer) {
                        node.parentId = clickedNode.id;
                    }
                    node.parent = clickedNode.parent;

                    idsVisible.push(node.id);
                }
            });

            if (idsVisible.length > 0) {
                const collectionForAdd = this.cytoscape.nodes().filter(ele => {
                    return idsVisible.includes(ele.data('id'));
                });

                if (collectionForAdd.length > 0) {
                    collectionForAdd.style('visibility', 'visible');
                    collectionForAdd.move({'parent': clickedNode.parent, "parentId": clickedNode.id});
                }
            }
            let parentCyNode = this.cytoscape.getElementById(clickedNode.parent);
            if (nodesToAdd.length > 0 && parentCyNode.length === 0 && nodesToAdd.length !== idsVisible.length) {
                this.cytoscape.add([{
                    group: "nodes",
                    classes: "extraParent",
                    data: {
                        id: clickedNode.parent,
                        filterEles: [],
                        pie: [],
                        label: ''
                    }
                }]);
            }

            Promise.all(newSubnodes).then((values) => {
                const hasAddNodes = values.length > 0;
                let cyCickedNode = this.cytoscape.getElementById(clickedNode.id);
                this.nodeMap[clickedNode.id] = clickedNode;
                cyCickedNode.move({'parent': clickedNode.parent});

                if (hasAddNodes) this.cytoscape.add(values);

                const edges = [];
                this.state.subnodesEdges.forEach(edge => {
                    edge.source = this.nodeMap[edge.sourceId];
                    edge.target = this.nodeMap[edge.targetId];
                    this.edgeMap[edge.id] = edge;
                });

                if (Utils.isNotNullOrUndefined(subnodesEdgesFromMore)) {
                    subnodesEdgesFromMore.forEach(edge => {
                        edge.source = this.nodeMap[edge.sourceId];
                        edge.target = this.nodeMap[edge.targetId];
                        this.edgeMap[edge.id] = edge;
                    });
                }
                Object.values(this.edgeMap).forEach(edge => {
                    edges.push(edge);
                });

                clickedNode.subNodes.forEach(node => {
                    this.setNodeOptionsCState(node);
                });
                this.createSubnodesRelations(edges);

                graphUtils.layoutSubNodes(clickedNode, this.cytoscape);

                if (nodesToAdd.length > 0) {
                    historyForBackNodes.push(clickedNode);
                }
                this.clickedNode.current = [];
                this.setState({historyForBackNodes, edges, openBackDrop: false});
            });
        })
    }

    guid() {
        let s4 = Math.floor( (1 + Math.random()) * 0x10000 ).toString( 16 ).substring( 1 )
        return s4 + s4 + '-' + s4;
    }
    detectValSubnodes( id ) {
        let have = false;
        const node = this.nodeMap[id];
        if ( node.subNodes.length > 0 ) {
            node.subNodes.forEach( subnode => {
                if ( subnode.isVisible ) {
                    have = true;
                }
            } )
        }
        return have;
    }

    historyNodes() {
        if (this.state.historyForBackNodes.length > 0) {
            let lastNodeId = this.state.historyForBackNodes.pop();
            this.showOrHideSubnodes(lastNodeId.id, false)
        }
    }

    filterSingleNode (nodeGraph, item = null, filtered = false,  refresh = false) {
        const nodeData = nodeGraph._private.data;
        const nodeInfo = this.nodeMap[nodeData.id];
        const categories = nodeData.pie.concat(nodeData.filterEles)

        if (!this.state.allChecked) {
            if (categories.includes(item)) {
                const moreCategories = categories.filter(cat => cat!== 'other' && cat!== 'interpersonal' && cat!== 'generic_cross_entity');
                if (moreCategories.length > 0) {
                    const otherCategory = this.crossCategories(moreCategories, this.state.anotherFilter,item);
                    if (otherCategory.length === 0 && nodeInfo) {
                        this.showOrHideNodes(filtered, nodeInfo, nodeGraph)
                    }
                }
            } else {
                if(refresh && nodeInfo){
                    this.showOrHideNodes(false, nodeInfo, nodeGraph);
                }
            }
        } else {
            nodeInfo && this.showOrHideNodes(true, nodeInfo, nodeGraph);
        }
    }

    filterNodes(item = null, filtered = false, refresh = false) {
        const nodes = [...this.state.nodes];

        nodes.forEach(node => {
            const nodeGraph = this.cytoscape.getElementById(node.id);
            this.filterSingleNode(nodeGraph,item, filtered,  refresh);
            if (node.subNodes.length > 0) {
                node.subNodes.forEach(subNode => {
                    const subNodeGraph = this.cytoscape.getElementById(subNode.id);
                    this.filterSingleNode(subNodeGraph,item, filtered,  refresh);
                })
            }
        })

        this.showOrHiddenEdges();
        this.setState({nodes});
    }

    showOrHideNodes(filtered, node, graphElement) {
        if (!filtered) {
            node.checked = false;
            node.isVisible = false;
            graphElement.addClass('classOpacity').style('visibility', 'visible');
            graphElement.style('opacity', !this.state.showOrHideTransitionNodes ? '.2' : '0');
            this.state.showOrHideTransitionNodes ? graphElement.removeAllListeners()
                : this.hideOrShowTooltips(node, graphElement)
        } else {
            node.checked = true;
            node.isVisible = true;
            graphElement.style('visibility', 'visible').removeClass('classOpacity');
            graphElement.style('opacity', '1').removeClass('addRemoveToggleLegend');
            this.hideOrShowTooltips(node, graphElement)
        }
    }

    crossCategories(arr1, arr2, item) {
        let newOne = [];
        for(let i=0; i < arr1.length; i++) {
            if (!arr2.includes(arr1[i]) && arr1[i] !== item) {
                newOne.push(arr1[i])
            }
        }
        return newOne;
    }

    showTransactions(){
        this.cytoscape.nodes().forEach(item => {
            const nodeData = item._private.data;
            const nodeInfo = this.nodeMap[nodeData.id];
            const graphElement = this.cytoscape.getElementById(nodeData.id);
            if (nodeInfo && !nodeInfo.isVisible) {
                    this.showOrHideNodes(false, nodeInfo, graphElement)
            }
        })
        this.showOrHiddenEdges();
    }

    addOrRemoveNode(node, alsoRemove = true) {
        if (node.isVisible) {
            node.isVisible = false;
            node.checked = false;
            if (alsoRemove) {
                if (node.subNodes.length > 0) {
                    this.showOrHideSubnodes(node.id, false);
                }

                const elem = this.cytoscape.getElementById(node.id);
                if (elem) {
                    elem.style('visibility', 'hidden');
                    elem.style('opacity', '.3')
                    elem.addClass('addRemoveToggleLegend');
                    elem.addClass('classOpacity')
                }
                if (Utils.isNotNullOrUndefined(this.nodeMap[node.parentId]) && this.nodeMap[node.parentId].subNodes.filter(_node => _node.checked && _node.id !== node.id).length === 0) {
                    let historyForBackNodes = this.state.historyForBackNodes.filter(history => history.id !== node.parentId);
                    this.setState({historyForBackNodes});
                }
                this.showOrHiddenEdges();
            }
        } else {
            node.checked = true;
            node.isVisible = true;
            const elem = this.cytoscape.getElementById(node.id);
            elem.removeClass('addRemoveToggleLegend');
            elem.removeClass('classOpacity')

            if (elem) {
                elem.style('visibility', 'visible');
                elem.style('opacity', '1')
            }
            if (node.subNodes.length > 0) {
                this.showOrHideSubnodes(node.id, true);
            }
            this.showOrHiddenEdges();
        }
    }

    showOrHideSubnodes(id, show) {
        const node = this.nodeMap[id];

        if (Utils.isNullOrUndefined(this.newHistoryForBackNodes)) {
            this.newHistoryForBackNodes = this.state.historyForBackNodes;
        }
        let newHistoryForBackNodes = this.newHistoryForBackNodes.filter(_node => _node.id !== id);
        this.newHistoryForBackNodes = newHistoryForBackNodes;

        this.setState({historyForBackNodes: newHistoryForBackNodes}, () => {
            if (node.subNodes.length > 0) {
                node.subNodes.forEach((subnode) => {
                    subnode.isVisible = show;
                    subnode.checked = show;
                    this.showOrHideSubnodes(subnode.id, show);
                });
                const subnodesIds = node.subNodes.map(item => item.id);
                const collectionForRemove = this.cytoscape.nodes().filter(ele => {
                    return subnodesIds.includes(ele.data('id'));
                });

                if (collectionForRemove.length > 0) {
                    collectionForRemove.style( 'visibility', show? 'visible' : 'hidden');
                    collectionForRemove.style('opacity', show ? '1' : '0')
                }
                this.showOrHiddenEdges();
            }
        });
    }

    showOrHiddenEdges() {
        this.cytoscape.edges().forEach(ele => {
            const edgeData = ele._private.data;
            const source = edgeData.source;
            const target = edgeData.target;
            const hasTitleHover = Utils.isNotNullOrUndefined(edgeData.titleHover);

            const sourceEl = this.cytoscape.getElementById(source);
            const targetEl = this.cytoscape.getElementById(target);

            const sourceHidden = sourceEl.hidden();
            const targetHidden = targetEl.hidden();
            if (sourceHidden || targetHidden) {
                const elem = this.cytoscape.getElementById(edgeData.id);
                if (elem) {
                    elem.style('visibility', 'hidden');
                    hasTitleHover && elem.removeAllListeners();
                }
            } else if (!sourceHidden && !targetHidden) {
                const elem = this.cytoscape.getElementById(edgeData.id);
                if (elem) {
                    elem.style('visibility', 'visible');
                    if(edgeData.isHistorical && !this.state.showFormerNodes) {
                        elem.style('visibility' , !this.state.showOrHideTransitionNodes ? 'visible' : 'hidden');
                    }
                    if (hasTitleHover) {
                        const content = `<div class='tooltipNode' style='max-height:300px!important; color:#95989A'>${edgeData.titleHover}</div>`;
                        graphUtils.makePopup(elem, content);
                    }
                }
            }
            const sourceHiddenOpacity = sourceEl.classes().includes('classOpacity');
            const targetHiddenOpacity = targetEl.classes().includes('classOpacity');

            if (sourceHiddenOpacity || targetHiddenOpacity) {
                const elem = this.cytoscape.getElementById(edgeData.id);
                if (elem) elem.style('opacity', '.3');
            } else if (!sourceHidden && !targetHidden) {
                const elem = this.cytoscape.getElementById(edgeData.id);
                if (edgeData.isHistorical) {
                    elem.style('opacity', !this.state.showFormerNodes ? '.3' : '1');
                } else {
                    elem.classes().filter(i => i !== 'classOpacity');
                    if (elem) elem.style('opacity', '1');
                }
            }
        });
    }
}

Relations.propTypes = {
    nodes: PropTypes.array,
    edges: PropTypes.array,
    layout: PropTypes.string,
    getImage: PropTypes.bool,
    setFilter: PropTypes.func,
    filter: PropTypes.object,
    status: PropTypes.string,
    doFilter: PropTypes.bool,
    sourceNodes: PropTypes.array,
    updateGraph: PropTypes.number,
};


import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ApplicationLayout from "./layouts/ApplicationLayout";
import Utils from "./utils";

const PublicRoute = ({component: Component,layout: Layout, restricted, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            Utils.isLogin() && restricted ?
                <ApplicationLayout>
                    <Redirect to="/search" />
                </ApplicationLayout>
                :<Layout>
                    <Component {...props} />
                </Layout>
        )} />
    );
};

export default PublicRoute;

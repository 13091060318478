import {createTheme} from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import {Badge} from "@mui/material";

export const cardNodesColor = createTheme({
    components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: '#ef4254',
                    },
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#ef4254',
                }
            }
        }
    }
});

export const ArdisBadge = withStyles({
    root: {
        marginTop: '1px',
        marginRight: '25px',
        marginLeft: '25px'
    },
    badge: {
        backgroundColor: '#ef4254 !important',
        color: '#fff',
        fontSize: '1.2rem',
        width: '25px',
        height: '25px',
        borderRadius: '25px',
        right: -5,
        top: 5
    },
})(Badge);

export const ReportArdisBadge = withStyles({
    root: {
        marginTop: '1px',
        marginRight: '25px',
        marginLeft: '25px'
    },
    badge: {
        backgroundColor: '#fff',
        color: '#ef4254',
        fontSize: '1.2rem',
        width: '25px',
        height: '25px',
        borderRadius: '25px',
        right: "-1px",
        top: "8px"
    }
})(Badge);

export const styleMenu = {
    paper: {
        color: 'rgb(125, 125, 125)',
        textAlign: 'left',
        minWidth: '700px',
        zIndex: '5000',
        position: 'absolute',
        top: '55px',
        right: '5px',
        padding: '15px'
    },

    paperMin: {
        color: 'rgb(125, 125, 125)',
        textAlign: 'left',
        minWidth: '700px',
        zIndex: '5000',
        position: 'absolute',
        top: '85px',
        right: '75px',
        padding: '15px'
    },
    paper1: {
        color: 'rgb(125, 125, 125)',
        textAlign: 'left',
        width: '550px',
        zIndex: '5000',
        position: 'absolute',
        top: '52px',
        right: '5px',
        padding: '0 0 15px 0'
    },
    rightIcon: {
        textAlign: 'center',
        lineHeight: '24px',
    },
};

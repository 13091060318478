import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Button from '@mui/material/Button';

import {doSearch, doPathSearch, generateReport, clearStore} from "../actions";
import {logout} from "../../authentication/actions";
import Utils from "../../utils";
import CardNodes from "./CardNodes.jsx";
import Results from "./Results.jsx";
import PolixisRoutes from "../../routes";
import {userTypes} from "../../constants";
import SearchBar from "./SearchBar";

const mapDispatchToProps = (dispatch) => {
    return {
        doSearch: query => dispatch(doSearch(query)),
        clearStore: () => dispatch(clearStore()),
        doPathSearch: query => dispatch(doPathSearch(query)),
        generateReport: (ids, isOnlyNews) => dispatch(generateReport(ids, true, true, isOnlyNews)),
        logout: () => dispatch(logout())
    };
};

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: null,
            showMenu: false,
            isPlxUser: (localStorage.getItem('type') === userTypes.superAdmin || localStorage.getItem('type') === userTypes.dataManager),
            isOnlyNewsSearch: JSON.parse(sessionStorage.getItem('isOnlyNewsSearch')),
            country: [],
            isChangedFilter: false,
            isRunSearch: false,
            filter: {
                sanction: false,
                pep: false,
                info: false,
                non_active: false,
                country: [],
                dateOfBirth: null,
            }
        }
    }

    handleLogout() {
        this.props.logout();
    }

    render() {
        return (
            <div className="search" key="search">
                <div className="header">
                    <div className="col-xs-6"/>
                    <div className="col-xs-6 text-right ">
                        <div className="inline">
                            <Button key="btn_4" onClick={() => this.generateNewsReport()}>News Search</Button>
                            {!this.state.isOnlyNewsSearch && <CardNodes isInReport={false} onReport={(path) => this.handleRouteChange(path)}/>}
                            <a href='/login' onClick={() => this.handleLogout()}><Button
                                key="btn_4">Logout</Button></a>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="search-header" style={this.state.isPlxUser ? {marginTop: "12px"} : {}}>
                    <SearchBar showExplore={true} query={this.state.query}
                               filter={this.state.filter}
                               onSearch={(data) => this.handleRouteChange(data)}
                               onResultFilter={(filters, isReset) => this.filterResultSearch(filters, isReset)}
                               isChangedFilter={this.state.isChangedFilter}
                               isRunSearch={this.state.isRunSearch}
                               onGenerateReport={(path) => this.handleRouteChange(path)}
                               onRoutChange={(path) => {
                                    this.handleRouteChange(path)
                                }}/>
                </div>
                <Results
                    key="result"
                    searchType={this.props.location.type}
                    doSearch={() => this.props.doSearch(this.state.query)}
                    filters={this.state.filter}
                />
            </div>
        );
    }

    handleRouteChange(data) {
        this.props.history.push(data);
    }

    componentDidMount() {
        this.setState({
            isPlxUser: (localStorage.getItem('type') === userTypes.superAdmin || localStorage.getItem('type') === userTypes.dataManager),
            isOnlyNewsSearch: JSON.parse(sessionStorage.getItem('isOnlyNewsSearch'))
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({query: nextProps.location.state})
        if (Utils.isNotNullOrUndefined(nextProps.location.state) && nextProps.location.fromAction) {
            this.props.doSearch(nextProps.location.state);
            this.setState({isRunSearch: false});
        }
        if (!nextProps.location.fromAction) {
            this.props.clearStore();
        }
    }

    filterResultSearch(filters, isReset = false) {
        const localFilters = {
            sanction: false,
            pep: false,
            info: false,
            non_active: false,
            country: [],
            dateOfBirth: null,
        };

        if (isReset) {
            this.setState({isChangedFilter: false, filter: localFilters})
        } else {
            const isChangedFilter = Object.keys(filters).filter(key => {
                let value = filters[key];
                if ((key === "country" || key === "dateOfBirth") && Boolean(filters[key])) value = filters[key].length > 0;
                if (Utils.isNullOrUndefined(value)) value = false
                return value;
            }).length > 0;
            this.setState({isChangedFilter, filter: filters});
        }
    }

    async generateNewsReport() {
        sessionStorage.removeItem('HITSPIN');
        sessionStorage.removeItem('HITSCHECK');
        sessionStorage.removeItem('pinnedJudicial');
        sessionStorage.removeItem('includedJudicial');
        sessionStorage.removeItem('judicialFromSelected')
        sessionStorage.removeItem('judicialAppendix');
        sessionStorage.removeItem('ckdata');
        sessionStorage.removeItem('PinnedItemsNer');
        sessionStorage.removeItem('PinnedItemsQuality');
        sessionStorage.removeItem('PinnedItemsSentiment');
        sessionStorage.removeItem('PinnedItemsNews');
        sessionStorage.removeItem('queryQ');
        sessionStorage.setItem("isOnlyNews", 'true');
        this.props.generateReport(null, true);
        this.props.history.push(PolixisRoutes.App.Report)
    }
}

Search.propTypes = {
    location: PropTypes.object,
    doSearch: PropTypes.func.isRequired,
    generateReport: PropTypes.func,
    goToLogin: PropTypes.func,
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(Search);

import React from "react";
import PropTypes from "prop-types";
import Utils from "../../../utils";
import {getNextChartColor, hBarCategory} from "../../../constants";

let StackBarInstanceIndex = 0;
export default class StackBar extends React.Component {
    constructor(props) {
        super(props);
        this.id = StackBarInstanceIndex++;
        this.height = 0;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return document.getElementById("stackbar-component-" + this.id).textContent === "" || !Utils.isEqual(nextProps.data, this.props.data);
    }

    render() {
        if(this.props.data.dataProvider.length>0){
            this.height = hBarCategory.width * this.props.data.dataProvider.length + hBarCategory.offset;
        }

        let graphs = [];
        if (this.props.data) {
            Object.keys(this.props.data.dataProvider[0]).forEach((data,i) => {
                if (i !== 0){
                    graphs.push({'valueField':data, 'title':data.toUpperCase(), 'fillColors':  getNextChartColor() ,'color': '#ffffff', "fillAlphas": 1,
                        "labelText": "[[value]]","lineAlpha": 0.3,"type": "column","balloonText": "<b>[[title]]</b><br><span style='font-size:14px'>[[category]]: <b>[[value]]</b></span>"})
                }
            });
            window.AmCharts.addInitHandler( function ( chart ) {
                const chartHeight = hBarCategory.width * chart.dataProvider.length + hBarCategory.offset;
                chart.div.style.height = chartHeight + 'px';
            }, ['serial'] );

            setTimeout(() => {
                const ctx = document.getElementById("stackbar-component-"+this.id);
                    window.AmCharts.makeChart( ctx, {
                    "type": "serial",
                    "legend": {
                        "divId": "legenddiv"+this.id,
                        "position": "bottom",
                        "horizontalGap": 10,
                        "useGraphSettings": true,
                        "markerSize": 10,
                        "fontFamily": 'Roboto',
                        "labelColorField": "#000000",
                    },
                    "addClassNames": true,
                    "dataProvider": this.props.data.dataProvider,
                    "valueAxes": [{
                        "stackType": "regular",
                        "gridAlpha": 0,
                        "labelRotation": 45
                    }],
                    "graphs": graphs,
                    "rotate": true,
                    "fontFamily": 'Roboto',
                    "categoryField": "Year",
                    "colorField": "color",
                    "categoryAxis": {
                        "gridPosition": "start",
                        "axisAlpha": 0,
                        "gridAlpha": 0,
                        "position": "left"
                    },
                    "export": {
                        "enabled": false
                    }
                });
            }, 0)
        }
        return (
            <div>
                { this.props.data.dataProvider.length>0 && <div className="horizontal-bar text-center" id={"stackbar-component-" + this.id} style={this.props.print === "" ?  {}:{ width:'900px', height: this.height +'px'}}/> }
                { this.props.data.dataProvider.length>0 && <div className="legendDiv" id={"legenddiv"+this.id} style={{height: '100%', width:'900px', margin: '5px 0'}}></div>}
                {this.props.print === "" && <div style={{width:'100%', textAlign:'center'}}><div style={{backgroundColor:'#f6f6f6', border:'1px solid #95989a', color:'#95989a', padding:'20px 80px', borderRadius:'6px', fontWeight:'400', display: 'inline-block',textAlign:'center'}}>
                    Click on name to show/hide.
                </div></div>}
            </div>
        );
    }
}

StackBar.defaultProps = {
    isMaximize: false,
};
StackBar.propTypes = {
    data: PropTypes.object,
    isMaximize: PropTypes.bool,
    print: PropTypes.string
};

import {useCallback, useEffect, useRef, useState} from "react";
import JudicialDetails from "./JudicialDetails";

import c from "./judicial.module.css"
import {useSelector} from "react-redux";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";

const Judicial = ({data, print, toggleInclude}) => {
    const [rootJudicial, setRootJudicial] = useState(data?.rootJudicialDetails);
    const [relatedJudicial, setRelatedJudicial] = useState(data?.relatedJudicialDetails);
    const description = data?.description;
    const root = data?.rootJudicialDetails;
    const related = data?.relatedJudicialDetails;
    const [selectedCountry, setSelectedCountry] = useState('');
    const isPrint = print === "?print=true";
    const judicialAppendixRemoveId = useSelector(state => state?.application.judicialAppendixRemoveId);
    const countriesRef = useRef(new Set());

    const handleRootPin = () => {
        const pinned = handleJudicialPin(rootJudicial);
        setRootJudicial({...pinned});
    }

    const handleRelatedPin = () => {
        const pinned = handleJudicialPin(relatedJudicial);
        setRelatedJudicial({...pinned});
    }

    const handleRootInclude = useCallback((item , toggle = true) => {
        const included = handleJudicialInclude(rootJudicial);
        setRootJudicial({...included});
        toggle && toggleInclude(item);
    },[rootJudicial, toggleInclude]);

    const handleRelatedInclude = useCallback((item, toggle = true) => {
        const included = handleJudicialInclude(relatedJudicial);
        setRelatedJudicial({...included});
        toggle && toggleInclude(item);
    },[relatedJudicial, toggleInclude]);

    const handleJudicialPin = (judicialArr) => {
        const pinnedJudicial = sessionStorage.getItem("pinnedJudicial") || [];
        let judicial = pinnedJudicial.length > 0 ? JSON.parse(pinnedJudicial) : pinnedJudicial;
        Object.keys(judicialArr).forEach(i => {
            judicialArr[`${i}`].forEach(j => {
                j.pinned = judicial.includes(j.id)
            })
        })
        return judicialArr;
    }

    const handleJudicialInclude = (judicialArr) => {
        const includedJudicial = sessionStorage.getItem("includedJudicial") || [];
        let judicial = includedJudicial.length > 0 ? JSON.parse(includedJudicial) : includedJudicial;
        Object.keys(judicialArr).forEach(i => {
            judicialArr[`${i}`].forEach(j => {
                j.checked = judicial.includes(j.id)
            })
        })
        return judicialArr;
    }

    const handleAllInclude = useCallback(() => {
        const fromRoot = Object.keys(rootJudicial).filter(k =>
            rootJudicial[`${k}`].filter(j => j.id === judicialAppendixRemoveId)
        ).length > 0;

        const fromRelated = Object.keys(relatedJudicial).filter(k =>
            relatedJudicial[`${k}`].filter(j => j.id === judicialAppendixRemoveId)
        ).length > 0;

        if (fromRoot) handleRootInclude(null, false);
         else if (fromRelated) handleRelatedInclude(null, false);
    },[judicialAppendixRemoveId, rootJudicial, relatedJudicial, handleRootInclude, handleRelatedInclude]);

    const handleCountryFilter = (e) => {
        setSelectedCountry(e.target.value)
    }

    useEffect(() => {
       if (judicialAppendixRemoveId) {
           handleAllInclude();
       }
    }, [judicialAppendixRemoveId, handleAllInclude])

    useEffect(() => {
        if (root && Object.keys(root).length > 0) {
            setRootJudicial(root);
            Object.keys(root).forEach(r => countriesRef.current.add(r));
        }

        if (related && Object.keys(related).length > 0) {
            setRelatedJudicial(related);
            Object.keys(related).forEach(r => countriesRef.current.add(r))
        }
    }, [root, related])

    const existRelated = relatedJudicial && Object.keys(relatedJudicial).length > 0;
    const existRoot = rootJudicial && Object.keys(rootJudicial).length > 0;
    const existJudicial = existRoot || existRelated;

    return (
        <div>
            <div className={c.judicialContainer}>
                <div className={c.judicialHeader} style={{marginTop: 20}}>
                    <p>{description}</p>
                </div>

                {
                    !isPrint && existJudicial && <div className={c.judicialCountryFilter}>
                        <FormControl>
                            <FormLabel  className={c.filterLabel}>Countries</FormLabel>
                            <RadioGroup defaultValue="All" className={c.filterGroup} onChange={handleCountryFilter}>
                                <FormControlLabel value={'All'} control={<Radio />} label={"All"} />
                                {
                                    countriesRef.current && countriesRef.current.size > 0 && Array.from(countriesRef.current).map((c,i) => (
                                        <FormControlLabel value={c} control={<Radio />} label={c} key={`${c}_${i}_filter_judicial`}/>
                                    ))
                                }
                            </RadioGroup>
                        </FormControl>
                    </div>
                }
                <div className={`${c.judicialBody}`}>
                    {
                        rootJudicial && Object.keys(rootJudicial).length > 0 &&
                        Object.keys(rootJudicial).map((j, i) => {
                            if (selectedCountry) {
                                if(selectedCountry === j || selectedCountry === 'All')  {
                                    return (
                                        <JudicialDetails details={rootJudicial[`${j}`]} key={`${j}_${i}_root`} onJudicialPin={handleRootPin} onJudicialInclude={handleRootInclude}
                                                         isPrint={isPrint} />
                                    )
                                } else return null;
                            } else {
                                return (
                                    <JudicialDetails details={rootJudicial[`${j}`]} key={`${j}_${i}_root`} onJudicialPin={handleRootPin} onJudicialInclude={handleRootInclude}
                                                     isPrint={isPrint} />
                                )
                            }
                        })
                    }

                    {
                        existRelated && <>
                            <div className={c.relatedHeader}>
                                <h5>{data?.relatedJudicialHeader}</h5>
                            </div>
                            {
                                Object.keys(relatedJudicial).map((j, i) => {
                                    if (selectedCountry) {
                                        if(selectedCountry === j || selectedCountry === 'All')  {
                                            return (
                                                <JudicialDetails details={relatedJudicial[`${j}`]} key={`${j}_${i}_related`} onJudicialPin={handleRelatedPin}
                                                                 onJudicialInclude={handleRelatedInclude} isPrint={isPrint}/>
                                            )
                                        } else return null;
                                    } else {
                                        return (
                                            <JudicialDetails details={relatedJudicial[`${j}`]} key={`${j}_${i}_related`} onJudicialPin={handleRelatedPin}
                                                             onJudicialInclude={handleRelatedInclude} isPrint={isPrint} />
                                        )
                                    }
                            })}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Judicial;

export const NewsAction = {
    SearchNews: "Application.Actions.News.Search",
    SetNews: "Application.Actions.News.Set",
    SearchNewsStop: "Application.Actions.News.Stop",
    EmptyNewsSearch: "Application.Actions.News.Empty"
}

export const searchNews = params => {
    return {
        type: NewsAction.SearchNews,
        payload: params
    }
}

export const setNews = params => {
    return {
        type: NewsAction.SetNews,
        payload: params
    }
}

export const cancelNewsSearch = (params) => {
    return {
        type: NewsAction.SearchNewsStop,
        payload: params
    }
}

export const emptyNewsState = () => {
    return {
        type: NewsAction.EmptyNewsSearch
    }
}

import React from "react";
import PropTypes from "prop-types";

import IconButton from '@mui/material/IconButton';

import Utils from "../../../utils";
import {API} from "../../../api";
import {nodeTypes} from "../../../constants";

export default class TreeAppendix extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            data: props.data
        };
    }

    render() {
        const content = Object.keys( this.state.data ).map( ( id, index ) => {
            return <div key={ index + id }>
                <div className="row">
                    { this.renderContent( id ) }
                </div>
            </div>
        } )

        return (
            <div>
                { content }
            </div>
        );
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        this.setState( { data: nextProps.data } );
    }

    removeTreeAppendixData( id ) {
        let appendixTreeData = {};
        let appendixTree = sessionStorage.getItem( "appendixTree" );

        if ( Utils.isNotNullOrUndefined( (appendixTree) ) ) {
            appendixTreeData = JSON.parse( appendixTree );
            delete appendixTreeData[id];
            sessionStorage.setItem( "appendixTree", JSON.stringify( appendixTreeData ) );

        }
        this.props.refreshTreeAppendixData();
    }

    renderContent( id ) {
        let content = [];
        let time = new Date().getTime();
        let nodeData = this.state.data[id];
        let avatar = Utils.isNotNullOrUndefined( nodeData.avatar ) ? nodeData.avatar.startsWith("../") ? nodeData.avatar.replace( "..", API.BASE_IMG_URL ): nodeData.avatar : null;

        let isPerson = Utils.isNotNullOrUndefined( avatar );

        if ( Utils.isNotNullOrUndefined( avatar ) ) {
            content.push(
                <div key={ "id_" + time } className={ isPerson ? "col-xs-4" : "hidden" }>
                    <div className={ isPerson ? 'avatar-gray' : 'avatar-color' }>
                        <img alt='avatarGray' src={ avatar }
                             style={ isPerson ? {
                                 width: '100%',
                                 maxWidth: "50px",
                                 maxHeight: "50px",
                                 marginTop: '13px',
                                 borderRadius: '50%',
                                 filter: 'grayscale(100%)'
                             } : {
                                 width: '100%',
                                 maxHeight: "50px",
                                 maxWidth: "50px",
                                 marginTop: '13px',
                                 borderRadius: '50%'
                             } }/>
                    </div>
                </div>
            )
        }

        if (nodeData) {
            if (nodeData.ardisId) {
                content.push(
                    <div key={ "ardeisID_" + id } className="col-sm-12">
                        <span className="main-color">ARDIS ID: </span>
                        <span className="word-break">{ nodeData.ardisId }</span>
                    </div>
                );

                if (nodeData.uni) {
                    content.push(
                        <div key={`${nodeData.ardisId}_unis`} className="col-xs-12">
                            {
                                (Object.keys(nodeData.uni).length >= 2 ? Object.keys(nodeData.uni).slice(0, 2) : Object.keys(nodeData.uni)).map((u) => {
                                    return (
                                        <div key={`${nodeData.ardisId}_${u}_detail`}>
                                            <span className="main-color">{u}: &nbsp;</span>
                                            <span className="word-break"
                                                  style={{wordBreak: "break-all"}}>{nodeData.uni[u]}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    );
                }

                if (nodeData.names) {
                    content.push(
                        <div key={`${nodeData.ardisId}_names`}  className="col-sm-12">
                            <span className="main-color">AKA:&nbsp;</span>
                            <span  className="word-break">{nodeData.names.join(", ")}</span>
                        </div>
                    );
                }
                if (nodeData.nodeType.toLowerCase() === nodeTypes.PERSON && nodeData.dates["Date of Birth"]) {
                    content.push(
                        <div key={`${nodeData.ardisId}_birth`} className="col-xs-12">
                            <span className="main-color">Date of Birth:&nbsp;</span>
                            <span  className="word-break">{nodeData.dates["Date of Birth"].join(", ")}</span>
                        </div>
                    );
                }

            }
        }

        return (
            <div key={ "appendix_" + id }
                 style={ { borderRadius: '7px', backgroundColor: "#fbfbfb", padding: "10px 0", margin: "20px" } }>
                { this.props.print === "" &&
                <div style={ { zIndex: 5000, position: "absolute", right: "10px" } }>
                    <IconButton style={ { fontSize: '10px', padding: "0px" } }
                                onClick={ () => this.removeTreeAppendixData( id ) }>
                        <i className="material-icons main-color" title="Remove">delete</i>
                    </IconButton>
                </div>
                }
                <div className="row">
                    <div className="col-xs-12">
                        <div className="node-content" key={ "content_" + id }>
                            { content }
                        </div>
                    </div>
                </div>
            </div>);
    }
}

TreeAppendix.propTypes = {
    print: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.object,
    refreshTreeAppendixData: PropTypes.func,
};

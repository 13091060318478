import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {
    Box,
    Dialog,
    Checkbox,
    TextField,
    DialogContent,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    DialogContentText
} from "@mui/material";
import {cyan} from "@mui/material/colors";
import withStyles from "@mui/styles/withStyles";

import Utils from "../../utils";
import {API, doPost} from '../../api';
import {closeMergeNodesDialog} from "../actions";

const mapDispatchToProps = dispatch => {
    return {
        closeMergeNodesDialog: () => dispatch(closeMergeNodesDialog()),

    };
};

const styles = {
    checkboxStyle: {
        "& svg": {
            fontSize: "24px!important",
            backgroundColor: "#fff0",
        }
    },
    underlineStyle: {
        "& input": {
            fontSize: "16px",
        },
        "& textarea": {
            fontSize: "16px",
        },
        "&>label": {
            color: 'rgba(0, 0, 0, 0.3)',
            fontSize: "16px"
        },
        "&:focus-within>label": {
            color: '#ef4254'
        },
        "& div::after": {
            borderColor: '#ef4254'
        },
        "& div::before": {
            borderBottom: "1px solid rgb(224, 224, 224)!important"
        },
    },
};


class MergeNodesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.subnodesIds = [];
        this.subnodesNames = [];
        let subnodesComments = '';
        const selectedNodes = Utils.getSelectedNodes(this.props.selectedNodes);
        let title = selectedNodes[0].nameEn;
        selectedNodes.forEach(selectedNode => {
            selectedNode
                .subnodes
                .forEach(subnode => {
                    if (subnode.checked) {
                        this.subnodesIds.push(subnode.id);
                        this.subnodesNames.push(
                            <div className="col-sm-12" key={subnode.id}>
                                <span style={{fontSize: "16px"}} className="text-lighter capitalize">
                                    {subnode.names[0]}
                                </span>
                            </div>
                        );

                    }
                });
            if (Utils.isNotNullOrUndefined(selectedNode.comment)) {
                subnodesComments += selectedNode.comment + '\n';
            }
        });

        this.state = {
            open: true,
            checkedAuto: true,
            subnodesComments,
            title
        };
    }

    render() {
        const actions = [
            <button key='merge-btn+1' className="fab-explore btn-float btn-action btn-cancel"
                    onClick={() => this.handleClose()}>
                <span>Cancel</span>
            </button>,
            <button key='merge-btn+2' className="fab-explore btn-float btn-action" onClick={() => this.mergeNodes()}>
                <span>Continue</span>
            </button>
        ];
        return (
            <Dialog
                open={this.state.open}
                maxWidth='lg'
                fullWidth
                PaperProps={{
                    style: {width: '95%', maxWidth: 'none'}
                }
                }>
                <DialogTitle className="explore-header">Map nodes</DialogTitle>
                <DialogContent className="explore-dialog-body">
                    <DialogContentText component="div">
                        <div className="aa ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-8">
                                        <div className="col-sm-12">
                                            <TextField
                                                margin="normal"
                                                placeholder="Enter Title"
                                                label="Title"
                                                value={this.state.title}
                                                className={this.props.classes.underlineStyle + " mapTitle"}
                                                onChange={(event) => this.setState({title: event.target.value})}
                                                fullWidth={true}/>
                                        </div>
                                        <div className="col-sm-12">
                                            <TextField
                                                label="Comment"
                                                margin="normal"
                                                inputProps={{style: {minHeight: "144px"}}}
                                                InputProps={{style: {lineHeight: 1.5}}}
                                                className={this.props.classes.underlineStyle + " mapComment"}
                                                fullWidth
                                                multiline
                                                value={this.state.subnodesComments}
                                                onChange={(event) => this.setState({subnodesComments: event.target.value})}
                                            />
                                        </div>
                                        <div className="col-sm-12" style={{marginTop: '30px'}}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    className={this.props.classes.checkboxStyle}
                                                    style={{color: this.state.checkedAuto ? cyan[500] : "rgba(0, 0, 0, 0.87)"}}
                                                    checked={this.state.checkedAuto}
                                                    onChange={() => this.updateCheck()}
                                                />}
                                                label={<Box style={{color: "rgba(0, 0, 0, 0.87)", fontSize: "16px"}}>
                                                    Apply changes (confirm automatically)
                                                </Box>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-4" style={{maxHeight: "50vh", overflowY: "auto"}}>
                                        <div className="col-sm-12 text-center">
                                            <br/>
                                            <label style={{fontSize: "16px"}}>Selected nodes: </label>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="row">
                                                {this.subnodesNames}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="line">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>{actions}</DialogActions>
            </Dialog>
        );
    }

    mergeNodes() {
        const subNodeIds = this.props.selectedNodes.map(n =>
            n.metadata.map(s =>
                s.id)
        )

        const requestData = {
            title: this.state.title,
            type: this.props.query.type,
            comment: this.state.subnodesComments,
            searchStrings: [
                this.props.query.q
            ],
            mappingNodeIds: subNodeIds,
            bnli: localStorage.getItem('bnli')
        }

        doPost(API.MAP_NODES, {qry: JSON.stringify(requestData)}, true).then(response => {
            if (Utils.isNotNullOrUndefined(response.data)) {
                this.handleClose();
                this.props.doSearch();
            }
        }).catch(error => {
            console.error(error);
        });
    }

    updateCheck() {
        this.setState({checkedAuto: !this.state.checkedAuto})
    }

    handleClose() {
        this.setState({
            open: false
        }, () => {
            setTimeout(() => {
                this
                    .props
                    .closeMergeNodesDialog();
            }, 500);
        });
    }
}

MergeNodesDialog.propTypes = {
    selectedNodes: PropTypes.array.isRequired,
    closeMergeNodesDialog: PropTypes.func.isRequired,
    generateReport: PropTypes.func,
    query: PropTypes.object,
    doSearch: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(null, mapDispatchToProps)(MergeNodesDialog));

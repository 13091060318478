import React from "react";
import PropTypes from "prop-types";

import IconButton from '@mui/material/IconButton';

import Utils from "../../../utils";
import { showGraphScreenshotEditDialog } from "../../../shared/dialog/actions";

export default class GraphAppendix extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            data: props.data
        };
    }

    render() {
        if ( Utils.isNotNullOrUndefined( this.state.data ) ) {
            const content = this.state.data.map( ( item, index ) => {
                return <div key={ index }>
                    <div className="row">
                        <div className="col-xs-8" style={ { color: "#ef4254" } }><h4>{ item.title }</h4></div>
                        <div className="col-xs-4 text-right">
                            { this.props.print === "" &&
                            <div>
                                <IconButton style={ { fontSize: '10px' } }
                                            onClick={ () => this.editGraphAppendix( index, item.title, item.description ) }>
                                    <i className="material-icons" title="Edit">edit</i>
                                </IconButton>
                                <IconButton style={ { fontSize: '10px' } }
                                            onClick={ () => this.removeGraphAppendix( index ) }>
                                    <i className="material-icons" title="Remove">delete</i>
                                </IconButton>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12"><h5>{ item.description }</h5></div>
                    </div>
                    <div><img alt='AnonymousImg' crossOrigin='Anonymous' style={ { maxWidth: "1000px" } } src={ item.base }/></div>

                </div>
            } )

            return (
                <div>{ content }</div>
            );
        } else {
            return null;
        }

    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        this.setState( { data: nextProps.data } );
    }

    editGraphAppendix( index, value, description ) {
        showGraphScreenshotEditDialog( {
            title: "Title for screenshot from relation explorer", index, value, description,
            onContinue: ( index, value, description ) => this.setScreenshot( index, value, description )
        } );
    }

    setScreenshot( index, value, description ) {
        let screenshotData = JSON.parse( sessionStorage.getItem( "Graph" ) );
        screenshotData[index].title = value;
        screenshotData[index].description = description;
        sessionStorage.setItem( "Graph", JSON.stringify( screenshotData ) );
        this.props.refreshShcreenshotData();
    }

    removeGraphAppendix( index ) {
        let screenshotData = JSON.parse( sessionStorage.getItem( "Graph" ) );
        screenshotData.splice( index, 1 );
        sessionStorage.setItem( "Graph", JSON.stringify( screenshotData ) );
        this.props.refreshShcreenshotData();
    }

}

GraphAppendix.defaultProps = {
    isMaximize: false,
};
GraphAppendix.propTypes = {
    isMaximize: PropTypes.bool,
    print: PropTypes.string,
    title: PropTypes.string,
    toggleMaximize: PropTypes.func,
    projectId: PropTypes.string,
    status: PropTypes.string,
    data: PropTypes.array,
    refreshShcreenshotData: PropTypes.func,
};

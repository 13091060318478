import React, {useCallback, useRef, useState} from 'react';
import PropTypes from "prop-types";
import {Chip, Autocomplete, TextField, Checkbox} from "@mui/material";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import {makeStyles} from "@mui/styles";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {newsSearchItems} from "../../../constants";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
const autoCompleteTheme = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                popupIndicator: {
                    fontSize: 16
                },
                option: {
                    fontSize: 14
                },
                tagSizeSmall: {
                    overflow: 'hidden'
                },
                input: {
                    minWidth: '20px !important',
                },
                endAdornment: {
                    position: "static !important",
                    display: 'flex !important',
                    marginRight: 10,
                    transform: "unset !important"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: 14
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: '#717171',
                },
                root: {
                    flexWrap: 'nowrap!important',
                    height: 42,
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: 24,
                    marginRight: -6
                },
                fontSizeSmall: {
                    fontSize: 16,
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    fontSize: 14
                },
                notchedOutline: {
                    borderColor: "transparent !important",
                },
                root: {
                    paddingRight: "0 !important"
                }
            }

        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    verticalAlign: 'text-top'
                }
            }
        }
    },
});

const useStylesDef = makeStyles(() => ({
    clearIndicator: {
        visibility: "visible !important"
    }
}));

export default function CheckboxesTags({data = [], label, setValue, colorMode, selectFirst, value}) {
    const [editableTag, setEditableTag] = useState(null);
    const autoRef = useRef();
    const classesDef = useStylesDef();
    const [current, setCurrent] = useState(null);

    const handleChange = useCallback((e, val) => {
        e.stopPropagation();
        const withoutEmpty = val.filter(item => item.length >0)
        setValue(withoutEmpty);
        setCurrent(null);
    }, [setValue])

    const handleDelete = useCallback((index, option) => {
        const removed = value.filter(item => item !== option);
        setValue(removed);
        setCurrent(null);
    }, [setValue, value])

    const handleDblClick = useCallback((index) => {
        setEditableTag({
            index,
            value: value[index]
        })
    }, [setEditableTag, value])

    const handleChipUpdate = useCallback(() => {
        const prevValues = [...value];
        if (editableTag) {
            if (editableTag.value.length > 0) {
                prevValues[editableTag.index] = editableTag.value;
            } else {
                prevValues.splice(editableTag.index, 1)
            }
            setValue(prevValues);
            setEditableTag(null);
        }
    }, [value, editableTag, setValue])

    const handleEdit = useCallback((e) => {
        setEditableTag({...editableTag, value: e.target.value})
    },[editableTag])

    const handleKeyDown = useCallback((e, forStop)=> {
        if (e.code === newsSearchItems.codes.ENTER || e.code === newsSearchItems.codes.NUM_ENTER) {
            handleChipUpdate();
        } else {
            if (forStop) e.stopPropagation();
            // for autoSelect error (stop handling, list down/up)
            else if (e.code === newsSearchItems.codes.LEFT ||e.code === newsSearchItems.codes.RIGHT ) {
                e.stopPropagation();
            }
        }
    }, [handleChipUpdate])

    const handleOutClick = useCallback((e) => {
        if (editableTag) {
            if (e.target.name === 'mainAuto') {
                handleChipUpdate()
            } else e.stopPropagation();
        }
    }, [editableTag, handleChipUpdate])

    const currentBlurChange = useCallback((e) => {
        setCurrent(e.target.value)
    }, []);

    const handleBlurChange = useCallback(() => {
        if (current) {
            let blurValue = current;
            setCurrent(null);
            setValue([...value, blurValue]);
        }
    },[current, value, setValue])

    return (
        <ThemeProvider theme={autoCompleteTheme}>
            <div className='autoComplete'>
                <Autocomplete
                    ref={autoRef}
                    className={colorMode === 'light' ? 'optionItemLight' : 'optionItem'}
                    multiple
                    clearOnBlur={true}
                    size="small"
                    onBlur={handleBlurChange}
                    style={{height:48}}
                    forcePopupIcon={data.length > 0}
                    openText={newsSearchItems.expandTitle}
                    closeText={newsSearchItems.expandTitle}
                    options={data.length > 0 ? data : []}
                    getOptionLabel={(option) => option}
                    classes={value.length > 0 ?  classesDef : null}
                    value={value|| (selectFirst && data.length > 0 && [data[0]])}
                    ListboxProps={{style: {backgroundColor: colorMode === 'light' ? '#f0efef' : '#cccaca'}}}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{marginRight: 8, fontSize: 18}}
                                checked={selected}
                            />
                            {option}
                        </li>
                    )}
                    renderTags={(value) => value.map((option, index) => {
                        if (index === editableTag?.index) {
                            return (
                                <TextField
                                    key={index}
                                    autoFocus
                                    size='small'
                                    style={{overflow: 'hidden', background: 'transparent'}}
                                    variant="outlined"
                                    onChange={handleEdit}
                                    onKeyDown={(e) => handleKeyDown(e, true)}
                                    onBlur={handleChipUpdate}
                                    inputProps={{
                                        style: {
                                            backgroundColor: colorMode === 'light' ? '#f0efef' : '#cccaca',
                                            height: '1px',
                                            fontSize: 12.9,
                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                            borderRadius: '30px',
                                            color:'#000'
                                        }
                                    }}
                                    value={editableTag.value}
                                />
                            )
                        } else {
                            return (
                                <Chip style={{overflow: 'hidden', background: 'transparent', border: '1px solid rgba(0, 0, 0, 0.23)'}}
                                      size='small'
                                      variant="outlined"
                                      label={option}
                                      key={`chip-${index}`}
                                      onDelete={() => handleDelete(index, option)}
                                      onDoubleClick={() => handleDblClick(index)}
                                />

                            )
                        }
                    })}
                    fullWidth
                    freeSolo
                    onChange={(e, newValue) => handleChange(e, newValue)}
                    renderInput={(params) =>  (
                        <TextField
                            onChange={currentBlurChange}
                            name="mainAuto"
                            placeholder={value.length > 0 ? " " : label}
                            {...params}
                            onKeyDown={(e) => handleKeyDown(e, false)}
                            onClick={handleOutClick}
                        />
                    )}
                />
            </div>
        </ThemeProvider>
    );
}

CheckboxesTags.propTypes = {
    setValue: PropTypes.func,
    label: PropTypes.string,
    data: PropTypes.any,
    colorMode: PropTypes.string,
    selectFirst: PropTypes.bool,
};

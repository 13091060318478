import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import Utils from "../../utils";
import Node from "../components/Node.jsx";
import {updateSearchBar} from "../actions";
import {INITIAL_STATE, ProgressStatus, RowType, searchResultsAddMore} from "../../constants";
import {Button} from "@mui/material";
import {nodePieTypes} from "./constants/search";

const mapStateToProps = (state = INITIAL_STATE) => {
    return {
        store: state.application.search,
        statusCancel: state.application.searchCancel,
        exploreOpened: state.application.explore
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSearchBar: () => dispatch(updateSearchBar()),
    };
};

class Results extends React.Component {
    constructor(props) {
        super(props);
        this.overlapChecked = false;
        this.paddingTop = 0;
        this.state = {
            checkedAll: false,
            nodes: props.store.results,
            readNodesCount: searchResultsAddMore,
            readNodes: [],
            status: props.store.status,
            statusCancel: ProgressStatus.RUNNING,
            resultCount: props.store.resultCount,
            open: false,
            filter: {
                sanction: false,
                pep: false,
                info: false,
                non_active: false,
                country: [],
                dateOfBirth: null,
            },
        };
    }

    handleGoToReadMore () {
        window.scrollTo(0, document.body.scrollHeight);
    }

    render() {
        const checkAllNodesImage = this.state.checkedAll ? "/images/node_all_checkbox_checked.png" : "/images/node_all_checkbox.png";
        let evenNodes = this.renderItems(RowType.Even);
        let oddNodes = this.renderItems(RowType.Odd);
        let totalNodesCount = this.state.resultCount;
        const shownNodes = totalNodesCount <= searchResultsAddMore ? totalNodesCount : this.state.readNodesCount;
        return (
            <div className="results">
                <div className="container-result">
                    {this.state.resultCount > 0 &&
                        <div className="row found-result">
                            <div className="col-xs-12 text-center" onClick={this.handleToggle}>
                                {
                                    totalNodesCount > searchResultsAddMore ? <>
                                        {shownNodes} results displayed out of <span className="count"> {totalNodesCount}</span>

                                    </> : <>
                                        Result found - <span className="count">{totalNodesCount}</span>
                                    </>
                                }
                            </div>
                            {
                                totalNodesCount !== this.state.readNodesCount && totalNodesCount > searchResultsAddMore &&
                                    <div className="col-xs-12 text-center">
                                        <Button className="load-more-top" onClick={() => this.handleGoToReadMore()}
                                                title='Not all results are displayed on this page, click here for more results.'
                                        > Load More</Button>
                                    </div>
                            }

                        </div>}
                    {this.state.resultCount > 0 &&
                        <div className="row">
                            <div className="col-xs-12 text-center">
                                <img alt='checkAllNodesImage' src={checkAllNodesImage}
                                     className={(this.state.status === ProgressStatus.DONE || this.state.statusCancel === ProgressStatus.DONE) ? "pointer " : "cursor-progress"}
                                     onClick={() => this.toggleCheckboxes()}/>
                            </div>
                        </div>}
                    {this.state.resultCount > 0 && <div className="row" id="nodeId">
                        <div className="col-xs-12 col-sm-6 left-side">
                            {evenNodes}
                        </div>
                        <div className="col-xs-12 col-sm-6 right-side">
                            <div className="loader-side">
                                {oddNodes}
                            </div>
                        </div>
                        {this.state.resultCount > 0 && <div className="line">&nbsp;</div>}
                    </div>}
                    {this.state.resultCount > 0 &&
                        <div className="row">
                            <div className="col-xs-12 text-center">
                                <img alt='checkAllNodes' src={checkAllNodesImage}
                                     className={(this.state.status === ProgressStatus.DONE || this.state.statusCancel === ProgressStatus.DONE) ? "pointer " : "cursor-progress"}
                                     onClick={() => this.toggleCheckboxes()}/>
                            </div>
                        </div>
                    }

                </div>
                {(this.state.resultCount !== 0 && this.state.readNodesCount < this.state.resultCount)
                    ?
                    <div className="text-center read-more" onClick={() => this.readMore()} >Load More<br/>
                        <i className="material-icons">expand_more</i>
                    </div> : null
                }
            </div>
        );
    }

    handleToggle = () => this.setState({open: !this.state.open});

    handleFilterResult(addMore = null) {
        const filteredNodes = [];
        this.state.nodes.forEach(n => {
            let isValid = true;

            if (this.state.filter.country.length > 0 ) {
                const filtered = Utils.getNodesByCountry(n.countries, this.state.filter.country );
                isValid = filtered.length > 0;
            }

            if (this.state.filter.sanction) {
               isValid = isValid && Utils.filterNodesByType(n, nodePieTypes.sanction)
            }

            if (this.state.filter.info) {
                isValid = isValid && Utils.filterNodesByType(n, nodePieTypes.info)
            }

            if (this.state.filter.pep) {
                isValid = isValid && Utils.filterNodesByType(n, nodePieTypes.pep)
            }

            if (this.state.filter.non_active) {
                isValid = isValid && Utils.filterNodesByType(n, nodePieTypes.non_active)
            }

            if (this.state.filter.dateOfBirth ) {
                isValid= isValid && Utils.checkNodesByDate(n.dates, this.state.filter.dateOfBirth);
            }

            if (isValid) filteredNodes.push(n);
        })
        this.setState({readNodes:filteredNodes.length > searchResultsAddMore ? filteredNodes.slice(0, addMore || searchResultsAddMore)  : filteredNodes, resultCount: filteredNodes.length})
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            nodes: nextProps.store.results,
            status: nextProps.store.status,
            readNodes: nextProps.store.results.length > searchResultsAddMore ? nextProps.store.results.slice(0,searchResultsAddMore) : nextProps.store.results,
            resultCount: nextProps.store.resultCount,
            readNodesCount: searchResultsAddMore,
            checkedAll: false
        });

        if (this.state.filter !== nextProps.filters && nextProps.store.resultCount > 0) {
            this.setState({filter: nextProps.filters} , () => this.handleFilterResult());
        }

        if (Utils.isNotNullOrUndefined(nextProps.statusCancel)) {
            this.setState({statusCancel: nextProps.statusCancel.status})
        }
        if (Utils.isNotNullOrUndefined(nextProps.exploreOpened) && nextProps.exploreOpened.closeFilter) {
            this.setState({open: false})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const isDone = this.state.status === ProgressStatus.DONE || this.state.statusCancel === ProgressStatus.DONE;
        if (this.state.status !== ProgressStatus.DONE) this.overlapChecked = false;
        if (isDone && !this.overlapChecked) {
            this.overlapChecked = true;
            this.checkOverlap();
        }
        if (isDone && (this.state.readNodesCount !== prevState.readNodesCount)) {
            setTimeout(() => {
                this.overlapChecked = true;
                this.checkOverlap();
            }, 1200);
        }

    }

    readMore() {
        const addMore = this.state.readNodesCount + searchResultsAddMore < this.state.resultCount ? this.state.readNodesCount + searchResultsAddMore : this.state.resultCount;
        this.setState({readNodesCount: addMore, checkedAll: false, readNodes:  this.state.nodes.slice(0, addMore)}, () => this.handleFilterResult(addMore));
    }

    checkOverlap(evenStart = 0, oddStart = 0) {
        const evenNodes = document.querySelectorAll(".node.even");
        const oddNodes = document.querySelectorAll(".node.odd");

        let stop = false;
        for (let k = evenStart; k < evenNodes.length; k++) {
            let evenNode = evenNodes[k].getElementsByClassName('timeline')[0].getElementsByClassName('sub-check');
            let evenBound = evenNode[0].getBoundingClientRect();
            for (let i = oddStart; i < oddNodes.length; i++) {
                let oddNode = oddNodes[i].getElementsByClassName('timeline')[0].getElementsByClassName('sub-check');
                let oddBound = oddNode[0].getBoundingClientRect();
                if (this.isOverlap(oddBound, evenBound) || this.isOverlap(evenBound, oddBound)) {
                    oddNodes[i].style.paddingTop = '105px';
                    setTimeout(() => {
                        this.checkOverlap(k - 1, i - 1);
                    }, 700);
                    stop = true;
                    break;
                }
            }
            if (stop) break;
        }
    }

    isOverlap( $div1, $div2 ) {
        const x1 = $div1.left + window.scrollX;
        const y1 = $div1.top + window.scrollY;
        const x2 = $div2.left + window.scrollX;
        const y2 = $div2.top + window.scrollY;

        const h = 24;//$div1.outerHeight(true);
        const w = 100; //$div1.outerWidth(true);
        const b1 = y1 + h;
        const r1 = x1 + w;

        const b2 = y2 + h;
        const r2 = x2 + w;

        return !(b1 < y2 || y1 > b2 || r1 < x2 || x1 > r2 || Math.abs(y1 - y2) > 30);
    }

    renderItems(type) {
        let start = 0;
        if (type === RowType.Odd) {
            start = 1;
        }

        let items = [];
        if (this.state.readNodes && this.state.readNodes.length > 0) {
            for (let index = start; index < this.state.readNodes.length; index += 2) {
                const node = this.state.readNodes[index];
                items.push(<Node
                    key={node.id}
                    type={type}
                    status={this.state.status}
                    doSearch={this.props.doSearch}
                    statusCancel={this.state.statusCancel}
                    index={index + 1}
                    onChange={() => this.onNodeChange()}
                    pie={this.pie}
                    node={node}
                />);
            }
        }

        return items;
    }

    toggleCheckboxes() {
        if (this.state.status === ProgressStatus.DONE || this.state.statusCancel === ProgressStatus.DONE) {
            const nodes = [];
            this.state.readNodes.forEach(node => {
                node.checked = !this.state.checkedAll;
                node.metadata.forEach((subnode => {
                    subnode.checked = !this.state.checkedAll;
                }));
                nodes.push(node);
            });

            this.setState({
                checkedAll: !this.state.checkedAll,
                nodes
            }, () => {
                this.props.updateSearchBar();
            });
        }

    }

    onNodeChange() {
        const result = this.state.readNodes.filter(node => node.checked);
        const isChecked = result.length === this.state.readNodes.length;
        if (isChecked)  this.setState({checkedAll: isChecked});
        else {
            if (this.state.checkedAll) this.setState({checkedAll: isChecked});
        }
    }
}

Results.propTypes = {
    store: PropTypes.any.isRequired,
    updateSearchBar: PropTypes.func.isRequired,
    searchType: PropTypes.string,
    exploreOpened: PropTypes.object,
    doSearch: PropTypes.func,
    removeAdvancedSearchData: PropTypes.func,
    statusCancel: PropTypes.object,
    reference: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Results);

import React from "react";
import {connect} from "react-redux";
import PropTypes from 'prop-types';

const mapStateToProps = (store) => {
    return {
        isLoggedIn: store.authentication.isLoggedIn
    };
};

class WebsiteLayout extends React.Component {
    render() {
        return (
            <div className="website container-full">
                { this.props.children }
            </div>
        );
    }
}

WebsiteLayout.propTypes = {
    children: PropTypes.element
};

export default connect(mapStateToProps)(WebsiteLayout);

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import WebsiteLayout from "./layouts/WebsiteLayout";
import Utils from "./utils";

const PrivateRoute = ({component: Component, layout: Layout,isLoggedIn, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            Utils.isLogin() ?
                <Layout>
                    <Component {...props} />
                </Layout>
                : <WebsiteLayout>
                    <Redirect to="/login" />
                </WebsiteLayout>
        )} />
    );
};

export default PrivateRoute;

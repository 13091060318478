export const riskScores = {
    highest : {
        color: "#454545",
        text: "Highest"
    },
    high: {
        color:"#FE0000",
        text: "High"
    },
    medium: {
        color:"#FFDA6B",
        text: "Medium"
    },
    low: {
        color:"#65B741",
        text: "Low"
    }
}

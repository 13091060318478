import React from "react";
import Button from '@mui/material/Button';
import PolixisRoutes from "../../routes";

export default class Home extends React.Component {
    render() {
        return (
            <div className="home">
                <div className="container-fluid text-center">
                    <div className="row home-row">
                        <div className="col-xs-8 home-left">
                        </div>
                        <div className="col-xs-4 home-right">
                        </div>
                    </div>
                </div>
                <div className="container home-container">
                    <div className="row">
                        <div className="col-xs-12"><img alt='polixisLogo' className="polixis-logo pull-left"
                                                        src="/images/polixis-logo.png"/></div>
                    </div>
                    <div className="col-xs-6">
                        <div className="row home-body">
                            <div className="col-xs-3">
                                <hr className="body-hr"/>
                            </div>
                            <div className="col-xs-9 home-body-title">
                                <h5>translating risk into business</h5>
                            </div>
                            <div className="col-xs-12">
                                <p className="body-big-title">ARDIS - Applied Risk & Data Intelligence Solution</p>
                                <div className="home-body-text">
                                    Welcome to next generation AML, Sanction and KYC automation software designed by
                                    Compliance Practitioners for Compliance Practitioners.<br/>
                                </div>
                                <Button
                                    style={ {
                                        borderRadius: 25,
                                        marginTop: "30px",
                                        color: "#fff",
                                        backgroundColor: "#ef4254"
                                    } }
                                    onClick={ ()=>this.goToLogin() }>Login</Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <img alt='polixisP' className="logo-p" src="/images/polixis-p.png"/>
                    </div>
                </div>
            </div>
        );
    }

    goToLogin() {
        this.props.history.push(PolixisRoutes.Website.Login);
    }

    componentDidMount() {
        if(localStorage.getItem('bnli')){
            this.props.history.push(PolixisRoutes.App.Login)
        } else {
            sessionStorage.clear();
            localStorage.clear();
        }
    }
}

import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Utils from "../../utils";

import {Dialog, DialogTitle, DialogActions, DialogContent} from '@mui/material';

import Node from "../components/Node.jsx";
import {RowType} from "../../constants";
import {showAlertDialog} from "../../shared/dialog/actions";
import {closeExploreDialog, generateReport} from "../actions";

const mapDispatchToProps = dispatch => {
    return {
        closeExploreDialog: () => dispatch(closeExploreDialog()),
        generateReport: (ids) => dispatch(generateReport(ids)),
    };
};

class ExploreDialog extends React.Component {
    constructor(props) {
        super(props);
        this.colorFooter = '#fff';
        this.text = '';
        this.state = {open: true, allowExplore: 'allow'};
    }

    render() {
        if (this.state.allowExplore === 'allow') {
            this.colorFooter = '#fff';
        } else {
            this.colorFooter = '#ef4254';
        }
        const actions = [
            <div key='explore-btn+text' style={{
                textAlign: 'center',
                color: this.colorFooter,
                float: 'left',
                padding: '10px 0 0 20px'
            }}>{this.text}</div>,
            <button key='explore-btn+1' className="fab-explore btn-float btn-action btn-cancel"
                    onClick={() => this.handleClose()}>
                <span>Cancel</span>
            </button>,
            <button key='explore-btn+2' className="fab-explore btn-float btn-action"
                    onClick={() => this.generateReport()}>
                <span>Continue</span>
            </button>
        ];
        return (
            <Dialog
                open={this.state.open}
                maxWidth='lg'
                fullWidth
                PaperProps={{style: {width: '95%', maxWidth: 'none'}}
                }>
                <DialogTitle className="explore-header">Explore</DialogTitle>
                <DialogContent className="explore-dialog-body">
                    <div className="results explore-dialog">
                        <div className="container-result">
                            <div className="row">
                                <div className="col-md-12 left-side">
                                    {this.renderItems(RowType.Even)}
                                </div>
                                {this.props.mainNodes.length > 0 ?
                                    <div className="col-md-12 right-side">
                                        <div style={{
                                            backgroundColor: '#f6f6f6',
                                            border: '1px solid #ef4254',
                                            color: '#ef4254',
                                            padding: '20px',
                                            borderRadius: '6px',
                                            fontWeight: '400'
                                        }}> Our phonetic research suggests persons (entities) with identical
                                            names have positive match with your selection. In case if you believe
                                            this is the same person as the Subject of your research please select
                                            to merge these two nodes for your final research.
                                        </div>
                                        <div style={{height: "100px"}}>&nbsp;</div>
                                        {this.renderMainItems()}
                                        {this.props.showDots &&
                                            <div style={{height: "50px", fontSize: '3em'}}>...</div>}
                                    </div> :
                                    <div className="col-md-12 right-side">
                                        <div style={{height: "100px"}}>&nbsp;</div>
                                        {this.renderItems(RowType.Odd)}
                                    </div>
                                }
                                <div className="line">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>{actions}</DialogActions>
            </Dialog>
        );
    }

    componentDidMount() {
        this.checkGenerateReportIsAllow();
    }

    renderItems(type) {
        let start = 0;
        if (type === RowType.Odd) {
            start = 1;
        }
        let step = 2;
        let items = [];
        const {selectedNodes} = this.props;
        const {mainNodes} = this.props;
        if (!Utils.isNullOrUndefined(selectedNodes)) {
            if (!Utils.isNullOrUndefined(mainNodes) && mainNodes.length > 0) {
                step = 1;
            }
            for (let i = start; i < selectedNodes.length; i += step) {
                const result = selectedNodes[i];

                items.push(<Node key={result.id} type={type} status={this.props.status} node={result}
                                 mouseEnter={() => this.nothing()}
                                 onChange={() => this.checkGenerateReportIsAllow()}/>);
            }
        }

        return items;
    }

    renderMainItems() {
        let items = [];
        const {mainNodes} = this.props;

        if (!Utils.isNullOrUndefined(mainNodes)) {
            for (let i = 0; i < mainNodes.length; i += 1) {
                const node = mainNodes[i];
                if (!node.checked || node.checkedInDialog) {
                    items.push(<Node key={node.id} type={RowType.Odd} status={this.props.status} node={node}
                                     mouseEnter={() => this.nothing()}
                                     onChange={() => this.checkGenerateReportIsAllow()}/>);
                }
            }
        }
        return items;
    }

    nothing() {
        return false;
    }

    checkGenerateReportIsAllow() {
        let isManuallyMapped = [];

        this.props.selectedNodes.forEach(item => {
            if (item.checked && item.metadata.length >= 0) {
                isManuallyMapped.push(item);
            }
        });
        this.props.mainNodes.forEach(item => {
            item.checkedInDialog = true;
            if (item.checked && item.metadata.length >= 0) {
                isManuallyMapped.push(item);
            }
        });
        if (isManuallyMapped.length > 1) {
            this.text = 'The selection contains nodes confirmed to belong to different entities. Are you sure you want to continue.';
            this.setState({allowExplore: 'warning'});
        } else {
            this.text = null;
            this.setState({allowExplore: 'allow'});
        }

    }

    async generateReport() {
        sessionStorage.removeItem('Graph');
        sessionStorage.removeItem('PAGES');
        sessionStorage.removeItem('PNG');
        sessionStorage.removeItem('HITSPIN');
        sessionStorage.removeItem('HITSCHECK');
        sessionStorage.removeItem('pinnedJudicial');
        sessionStorage.removeItem('includedJudicial');
        sessionStorage.removeItem('judicialAppendix');
        sessionStorage.removeItem('ckdata');
        sessionStorage.removeItem('PinnedItemsNews');
        sessionStorage.removeItem("judicialFromSelected")

        const selectedNodes = Utils.getSelectedNodes(this.props.selectedNodes);
        if (selectedNodes.length === 0) {
            showAlertDialog({
                title: "Error",
                message: "You must select at least one person/company to generate report."
            });
        } else {
            const subnodesIds = [];
            selectedNodes.forEach(selectedNode => {
                selectedNode.metadata.forEach(subnode => subnodesIds.push(subnode.id));
            });
            await this.props.generateReport(subnodesIds);
            this.props.handleGenerate();
        }
    }

    handleClose() {
        this.setState({open: false}, () => {
            setTimeout(() => {
                this.props.closeExploreDialog();
            }, 500);
        });
    }
}

ExploreDialog.propTypes = {
    selectedNodes: PropTypes.array.isRequired,
    mainNodes: PropTypes.array.isRequired,
    status: PropTypes.string,
    closeExploreDialog: PropTypes.func.isRequired,
    generateReport: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(ExploreDialog);

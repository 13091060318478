import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

import Utils from "../../../utils";
import {getNextColor, highlightAlerts} from "../../../constants";
import {showLegacyNewsDialog} from "../../../shared/dialog/actions";
import {API} from "../../../api";

let ITEMS_PER_PAGE = 200;
let ListInstanceIndex = 0;

export default class List extends React.Component {
    constructor( props ) {
        super( props );
        this.id = ListInstanceIndex++;
        this.state = { currentPage: 0 };
        this.col = null;
    }

    render() {
        return (
            <div className="list-component">
                { this.props.map && this.renderMap() }
                { (this.props.list && this.props.title.toLowerCase().trim() !== "judicial") && this.renderList() }
                { this.renderPagination() }
            </div>
        );
    }

    renderMap() {
        const data = [];

        Object.keys( this.props.map ).forEach( ( label, index ) => {
            let values = this.props.map[label];
            const isMainPrint = this.props.print === '?print=true' && (this.props.sectionId === 'main' || this.props.sectionId ==='bio' );
            let colSize = isMainPrint ? 'col-xs-6 key-map' : 'col-xs-4 key-map';
            const contentValue = values.map( ( value, index ) => {
                const isHighLight = label.toLowerCase() ==='alerts' && highlightAlerts.includes(value);
                let semic = (index < values.length - 1) ? "," : ""
                colSize = ((value.length > 200 || label.toLowerCase() === 'remarks') || values.length > 10) ? 'col-xs-12 key-map' : (Utils.isNotNullOrUndefined( this.props.avatar )
                    && this.props.title.toLowerCase() === 'key identifiers') ? 'col-xs-6 key-map' : isMainPrint ? 'col-xs-6 key-map' : 'col-xs-4 key-map'

                if (label.toLowerCase() ==='alerts' && this.props.title.toLowerCase() === 'key identifiers') {
                    return <span key={ "value-" + value + value.length }
                                 className={isHighLight ? "list-value highlightAlert" : "list-value"}
                                 style={{ marginRight: "10px", position: "relative", marginBlockStart: 0, marginBlockEnd: 0, lineHeight: 1.6}}
                                 dangerouslySetInnerHTML={ { __html: value + semic + "<br/>"} }/>;
                } else {
                    return <span key={ "value-" + value + value.length }
                                 className={isHighLight ? "list-value highlightAlert" : "list-value"}
                                 style={ label.toLowerCase() === 'aka' && values.length > 10 ? { marginRight: "10px", position: "relative"} : { marginRight: "10px", position: "relative", display: 'inline-flex'}}
                                 dangerouslySetInnerHTML={ { __html: value.trim() + semic } }/>;
                }
            } )

            if ( values.length > 0 ) {
                if ( index % 3 === 0 && colSize === 'col-xs-4 key-map' ) {
                    data.push( <div className="row" key={ "data-" + index + label }>
                        <div className="col-xs-12">
                        </div>
                    </div> )
                }
                data.push(
                    <div className={colSize} key={ "data-" + label + index } style={ {
                        borderLeftStyle: 'solid',
                        borderLeftWidth: '2px',
                        borderLeftColor: getNextColor(),
                        float: colSize === 'col-xs-6 key-map' && this.props.print === '?print=true' ? index % 3  === 0 ? 'left' : 'right' : ''
                    } }>
                        <p className="text-info" style={{fontSize: '15px', fontWeight: 'bold',marginBlockStart: '0.5em',marginBlockEnd: '0.5em'}}>{label}</p>
                        <div>{ contentValue }</div>
                    </div>
                );
            }
        } );
        const imgSrc = this.props.avatar && this.props.avatar.startsWith("../") ? this.props.avatar.replace( "..", API.BASE_IMG_URL ) : this.props.avatar;
        return (
            <div className="row component-list" style={{display: 'flex'}}>
                { Utils.isNotNullOrUndefined( this.props.avatar ) && this.props.title.toLowerCase() === 'key identifiers' &&
                    <div className="col-xs-3">
                        <div className={ this.props.print !== "" ? "col-xs-12" : "col-xs-12 rounded-grayscale-div" }>
                            <img alt='imageAvatar' src={ imgSrc}
                                 style={ (Utils.isNotNullOrUndefined( this.props.nodeType ) && this.props.nodeType.toLowerCase() === 'person') ? {
                                     width: '100%',
                                     marginTop: '13px',
                                     borderRadius: '50%'
                                 } : { width: '100%', marginTop: '13px' } }/>
                        </div>
                    </div>
                 }
                <div
                    className={ (Utils.isNotNullOrUndefined( this.props.avatar ) && this.props.title.toLowerCase() === 'key identifiers') ? "col-xs-9" : "col-xs-12" }>
                    <div className="component-list-map">
                        <div className="row">
                            { data }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderList() {
        let items = this.props.list;
        const totalCount = items.length;
        if ( this.props.print !== "" ) {
            ITEMS_PER_PAGE = totalCount;
        }
        if ( totalCount > ITEMS_PER_PAGE ) {
            items = items.slice( this.state.currentPage * ITEMS_PER_PAGE, this.state.currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE );
        }
        if (this.props.sectionId === "businessupdates") {
            const listNews = items.map((item, index) => {
                return <div key={`list-${index}`} className="legacy-component-item" >
                    <p  dangerouslySetInnerHTML={ { __html: `${item[0]} ${item[1]}`}}/>
                    <button
                        style={{border: 'none', backgroundColor: 'transparent', marginBottom: 10, marginLeft: '-5px', color: '#2679af'}}
                        onClick={() => showLegacyNewsDialog({item})}
                    >View article</button>
                </div>;
            });
            return <div className="component-list list legacy-list">{ listNews }</div>;
        } else {
            const list = items.map( ( item, index ) => {
                try {
                    return <p key={ "list-" + index } dangerouslySetInnerHTML={ { __html: item.join( '' ) } }/>;
                } catch (error) {
                    return <p key={ "list-" + index } dangerouslySetInnerHTML={ { __html: item } }/>;
                }
            } );
            return <div className="component-list list">{ list }</div>;
        }
    }

    renderPagination() {
        let items = this.props.list;
        const totalCount = items.length;
        if ( totalCount > ITEMS_PER_PAGE ) {
            return <div className="pagination-div">
                <ReactPaginate
                    containerClassName="pagination pull-right"
                    pageCount={ totalCount / ITEMS_PER_PAGE }
                    breakLabel={ <a href='/#' onClick={(e)=>e.preventDefault()}>...</a> }
                    breakClassName={ "break-me" }
                    subContainerClassName={ "pages pagination" }
                    activeClassName={ "active" }
                    pageRangeDisplayed={ ITEMS_PER_PAGE }
                    marginPagesDisplayed={ ITEMS_PER_PAGE }
                    onPageChange={ ( data ) => this.changePage( data ) }/>
            </div>
        } else {
            return null;
        }
    }

    changePage( data ) {
        this.setState( { currentPage: data.selected } );
    }
}

List.defaultProps = {
    isMaximize: false,
};

List.propTypes = {
    map: PropTypes.any,
    list: PropTypes.array,
    data: PropTypes.object,
    isMaximize: PropTypes.bool,
    print: PropTypes.string,
    title: PropTypes.string,
    toggleMaximize: PropTypes.func.isRequired,
    avatar: PropTypes.string,
    nodeType: PropTypes.string
};

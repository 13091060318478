import React from "react";
import PropTypes from "prop-types";
import popper from 'cytoscape-popper';
import cytoscape from 'cytoscape'
import fcose from 'cytoscape-fcose';
import dagre from "cytoscape-dagre";
import {Backdrop, Checkbox, CircularProgress, Drawer, Fab, FormControlLabel, Icon, InputAdornment, MenuItem, Snackbar, TextField} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Utils from "../../../utils";
import Node from "../../../models/Node";
import Legend from "./graph/Legend.jsx";
import Edges from "../../../models/Edge";
import {API, doPost} from '../../../api';
import {GraphBreakpoints, GraphCategoriesFilterTitles, postActions} from "../../../constants";
import NodeDetails from "./graph/NodeDetails.jsx";
import SubnodesFilter from "./graph/SubnodesFilter.jsx";
import {showAlertDialog, showConfirmDialog, showScreenshotTitleDialog} from "../../../shared/dialog/actions";
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css';
import {graphUtils} from "./graph/graphHelpers";

let GraphInstanceIndex = 0;
cytoscape.use(popper)
cytoscape.use(dagre);
cytoscape.use(fcose);
cytoscape.warnings(false);

export default class Graph extends React.Component {
    constructor(props) {
        super(props);
        this.id = GraphInstanceIndex++;
        this.nodeMap = {};
        this.edgeMap = {};
        this.cytoscape = null;
        this.zoom = null;
        let historyForBackNodes = [];
        let nodes = [];
        let edges = [];
        let layout = 'cose';
        let hideNodes;
        let anotherFilter = [];
        let showFormerNodes = true;
        let showOrHideTransitionsNodes = false;
        window.cy = null;


        let filter = {info: false, pep: false, sanction: false, non_active: false, without_entity: false};
        if (Utils.isNotNullOrUndefined(this.props.cachedState)) {
            this.zoom = this.props.cachedState.zoom;
            nodes = this.props.cachedState.nodes;
            edges = this.props.cachedState.edges;
            layout = this.props.cachedState.layout;
            filter = this.props.cachedState.filter;
            anotherFilter = Object.keys(this.props.cachedState.filter)?.filter(item => !filter[item]);
            hideNodes = this.props.cachedState.hideNodes;
            showFormerNodes = this.props.cachedState.formerNodes;
            showOrHideTransitionsNodes = this.props.cachedState.showOrHideTransitionsNodes;
            historyForBackNodes = this.props.cachedState.historyForBackNodes;
            this.nodeMap = this.props.cachedState.nodeMap;
            this.edgeMap = this.props.cachedState.edgeMap;
        } else {
            props.data.nodes.forEach((nodeData, index) => {
                nodeData.isMainNode = index === 0;  // if else simply
                if (Object.keys(this.nodeMap).indexOf(nodeData.id) === -1) {
                    const node = new Node(nodeData);

                    nodeData.category.forEach((cat) => {
                        const c = cat.toLowerCase();
                        let item = c.startsWith("#") ? c.substr(1) : c;
                        if (Object.keys(filter).indexOf(item) === -1 && c !== "#other" && c !== "#generic_cross_entity" && c !== "#interpersonal") {
                            if (c.startsWith('#shareholding')) {
                                item = 'shareholding'
                            }
                            filter[item] = false;
                        }
                    });
                    this.nodeMap[nodeData.id] = node;
                    nodes.push(node);
                }
            });
            props.data.edges.forEach((edgeData) => {
                if (Object.keys(this.edgeMap).indexOf(edgeData.id) === -1) {
                    const edge = new Edges(edgeData);
                    edge.source = this.nodeMap[edge.sourceId];
                    edge.target = this.nodeMap[edge.targetId];
                    this.edgeMap[edge.id] = edge;
                    edges.push(edge);
                }
            });
        }
        this.state = {
            nodes,
            edges,
            nodeDetails: [],
            historyForBackNodes,
            layout,
            nodeSize: nodes.length < 10 ? 250 : 200,
            openSnackbar: false,
            openSnackbarSubnodes: false,
            open: false,
            filter,
            openDialog: false,
            realSubnodes: [],
            clickedNode: null,
            subnodesEdges: [],
            clickedNodeId: null,
            subnodesFilteredArray: null,
            checkedAll: false,
            hideNodes,
            snackbarText: "No more nodes to show.",
            doLayout: false,
            showFilter: false,
            openNodeToCartSnack: false,
            toCartNode: null,
            openBackDrop: false,
            showOrHideTransitionsNodes,
            showFormerNodes,
            anotherFilter,
            allChecked: false,
            nodeMore: false
        };
        this.clickedNode = React.createRef();
        this.clickedNode.current = [];
    }

    render() {
        const nodeInfo = [];
        this.state.nodeDetails.forEach((id) => {
            const x = this.cytoscape.getElementById(id).renderedPosition('x');
            const y = this.cytoscape.getElementById(id).renderedPosition('y')
            const isPossibleToAddCard = (this.nodeMap[id].type === "UNDETERMINED");
            nodeInfo.push(<NodeDetails key={'details' + id + x + y}
                                       id={id} x={x} y={y}
                                       projectId={this.props.projectId}
                                       bnli={this.props.bnli}
                                       haveSubnodes={(id) => this.detectValSubnodes(id)}
                                       hideSubnodes={(id) => this.showOrHideSubnodes(id, false)}
                                       onClose={(id) => this.hideNodeDetails(id)}
                                       addToCard={(id) => this.addToCard(id)}
                                       isPossibleToAddCard={!isPossibleToAddCard}
                                       sessionExpired={() => this.props.sessionExpired()}/>);
        });

        return (
            <div>
                {this.props.isMaximize &&
                    <div className="cytoscape-options" style={{zIndex: 2008}}>
                        <TextField select
                                   SelectProps={{
                                       SelectDisplayProps: {
                                           style: {paddingLeft: "56px", marginLeft: "-56px"}
                                       },
                                       MenuProps: {
                                           style: {zIndex: 6000},
                                           PaperProps: {elevation: 2, className: "graphSelectPaper"},
                                           MenuListProps: {style: {padding: "16px 0"}}
                                       }
                                   }}
                                   variant={"standard"}
                                   margin='normal'
                                   value={this.state.layout}
                                   InputProps={{
                                       style: {fontSize: "15px", letterSpacing: "0px", lineHeight: "28px"},
                                       startAdornment: <InputAdornment position="start"><span
                                           className='adornment'>Layout:</span></InputAdornment>,
                                   }} style={{marginTop: "11px", marginLeft: "24px", marginRight: "24px"}}
                                   className='text-field-layout'
                                   onChange={(event) => this.changeLayout(event.target.value)}
                        >
                            <MenuItem className="graphSelectItem" value="grid" style={{
                                fontSize: '14px',
                                backgroundColor: "#fff",
                                color: this.state.layout === "grid" ? "#ef4254" : "rgba(0, 0, 0, 0.87)"
                            }}>Grid</MenuItem>
                            <MenuItem className="graphSelectItem" value="circle" style={{
                                fontSize: '14px',
                                backgroundColor: "#fff",
                                color: this.state.layout === "circle" ? "#ef4254" : "rgba(0, 0, 0, 0.87)"
                            }}>Circle</MenuItem>
                            <MenuItem className="graphSelectItem" value="concentric" style={{
                                fontSize: '14px',
                                backgroundColor: "#fff",
                                color: this.state.layout === "concentric" ? "#ef4254" : "rgba(0, 0, 0, 0.87)"
                            }}>Concentric</MenuItem>
                            <MenuItem className="graphSelectItem" value="breadthfirst" style={{
                                fontSize: '14px',
                                backgroundColor: "#fff",
                                color: this.state.layout === "breadthfirst" ? "#ef4254" : "rgba(0, 0, 0, 0.87)"
                            }}>Breadthfirst</MenuItem>
                            <MenuItem className="graphSelectItem" value="cose" style={{
                                fontSize: '14px',
                                backgroundColor: "#fff",
                                color: this.state.layout === "cose" ? "#ef4254" : "rgba(0, 0, 0, 0.87)"
                            }}>Smart</MenuItem>
                            <MenuItem className="graphSelectItem" value="dagre" style={{
                                fontSize: '14px',
                                backgroundColor: "#fff",
                                color: this.state.layout === "dagre" ? "#ef4254" : "rgba(0, 0, 0, 0.87)"
                            }}>Dagre (beta)</MenuItem>
                        </TextField>
                        <div style={{
                            cursor: "pointer",
                            position: "relative",
                            height: "43px",
                            borderBottom: "1px solid #e0e0e0",
                            width: "115px",
                            margin: "5px 15px 0 0",
                            fontSize: "15px"
                        }}>
                            {this.state.showFilter && this.renderFilter()}

                            <div style={{
                                color: " rgba(0, 0, 0, 0.87)",
                                height: "46px",
                                lineHeight: " 46px",
                                overflow: "hidden",
                                top: " 0px",
                                whiteSpace: "nowrap"
                            }}
                                 onClick={() => this.toggleFilter()}>
                                Filter Graph
                                <i className="material-icons" style={{
                                    color: "#e0e0e0",
                                    position: "absolute",
                                    right: "0",
                                    margin: "11px 8px"
                                }}> arrow_drop_down</i>
                            </div>

                        </div>
                        <div className="graph-buttons pull-right">
                            <img src="/images/graph_refresh.png" className="pointer" alt="Autoarrange" title="Autoarrange"
                                 onClick={() => this.getRefreshOptions()}/>
                            <img src="/images/graph_download.png" className="pointer" alt="DownloadImage"
                                 title="Download" onClick={() => this.savePng()}/>
                            <img src="/images/screenshot.png" className="pointer" alt="Do Screenshot"
                                 title="Take a screenshot of the graph and save it as an Appendix to your PDF report."
                                 onClick={() => this.getScreenshotGraph()}/>
                            <img src="/images/graph_download.png" style={{transform: 'rotate(90deg)'}}
                                 className="pointer"
                                 alt="Back" title="Back" onClick={() => this.historyNodes()}/>
                            <img
                                src={this.state.showOrHideTransitionsNodes ? "/images/showHideH.png" : '/images/showHideS.png'}
                                alt="show"
                                className="pointer"
                                style={{transform: 'rotate(180deg)', visibility: 'hide'}}
                                title={this.state.showOrHideTransitionsNodes ? 'Show hidden relations in transparent mode' : 'Hide transparent relations'}
                                onClick={() => {
                                    this.setState({showOrHideTransitionsNodes: !this.state.showOrHideTransitionsNodes}, () => {
                                        this.showTransactions();
                                    })
                                }}
                            />
                            <span
                                style={{
                                    color: "#c6c6c6",
                                    fontWeight: "bold",
                                    backgroundColor: "#ffffff",
                                    padding: "7px 7px 9px",
                                    marginTop: "30px"
                                }}
                                className="pointer"
                                title={this.state.showFormerNodes ? 'Click to hide the former relations' : 'Click to show the former relations'}

                                onClick={() => this.setState({showFormerNodes: !this.state.showFormerNodes}, () => {
                                    this.showOrHideFormers()
                                })}>
                        {this.state.showFormerNodes ? "Hide All Formers" : "Show All Formers"}
                    </span>
                        </div>
                        <Backdrop style={{zIndex: 2010}} open={this.state.openBackDrop}>
                            <CircularProgress style={{color: "#fff"}}/>
                        </Backdrop>
                    </div>}
                {nodeInfo}
                <div onClick={() => this.handleClick()}
                     className={"row component-graph " + (this.props.isMaximize && "maximize") + " component-graph-" + this.id}>
                    <div className={(this.props.isMaximize || this.props.print !== "") ? "col-md-12" : "col-md-9"}>
                        <div className="graph" id={"cytoscape-" + this.id} style={{pageBreakInside: 'avoid !important', pageBreakBefore:'always'}}></div>
                        {this.props.print === "" &&
                            <div style={{marginTop: "-130px"}} className="pull-left zoom">
                                <Fab title={'Click on + symbol to zoom in the graph.'} color="primary" size='small'
                                     style={{marginBottom: "10px", zIndex:998}}
                                     onClick={() => this.cytoscape.zoom(this.cytoscape.zoom() + .05)}>
                                    <AddIcon fontSize="large"/>
                                </Fab>
                                <br/>
                                <Fab title={'Click on - symbol to zoom out the graph'} color="primary" size='small'
                                     style={{zIndex:998}}
                                     onClick={() => this.cytoscape.zoom(this.cytoscape.zoom() - .05)}>
                                    <RemoveIcon fontSize="large"/>
                                </Fab>
                            </div>
                        }
                    </div>

                    {(this.props.print === "" && !this.props.isMaximize) &&
                    <div className={this.state.open ? "col-md-1" : "col-md-3"}>
                        <div style={{width: "96%"}}>
                            <Legend nodes={this.state.nodes}
                                    selectedNode={this.state.selectedNode}
                                    isMaximize={this.props.isMaximize}
                                    bgColor={this.props.bgColor}
                                    ref="legend"
                                    toggleMaximize={() => this.props.toggleMaximize()}
                                    onNodeSelect={(node) => this.selectNodeById(node.id)}
                                    checkboxClick={(node) => this.addOrRemoveNode(node)}
                                    filterNodes={(filter) => this.filterNodes(filter)}
                                    cachedFilter={this.props.cachedState ? this.props.cachedState.filter : null}/>
                        </div>
                    </div>
                    }

                    {this.props.isMaximize &&
                        <div>
                            <button style={this.state.open ? {marginLeft: '282px !important'} : {}}
                                    onClick={() => this.handleToggle()}
                                    className={this.state.open ? "toggle-btn btn btn-floating btn-large waves-effect waves-light" : "toggle-btn1 btn btn-floating btn-large waves-effect waves-light"}
                            >
                                {this.state.open ? <i className="material-icons" style={{
                                        color: '#ef4254',
                                        marginLeft: "-12px",
                                        marginTop: "7px"
                                    }}>keyboard_arrow_right</i> :
                                    <i className="material-icons" style={{
                                        color: 'white',
                                        marginLeft: "-12px",
                                        marginTop: "7px"
                                    }}>keyboard_arrow_left</i>}
                            </button>
                            <Drawer open={this.state.open} style={{fontFamily: "Roboto, sans-serif"}}
                                    className="drawer sidebar" variant="persistent" anchor="right">
                                <Legend nodes={this.state.nodes}
                                        selectedNode={this.state.selectedNode}
                                        isMaximize={this.props.isMaximize}
                                        bgColor={this.props.bgColor}
                                        ref="legend"
                                        toggleMaximize={() => this.props.toggleMaximize()}
                                        onNodeSelect={(node) => this.selectNodeById(node.id)}
                                        checkboxClick={(node) => this.addOrRemoveNode(node)}
                                        filterNodes={(filter) => this.filterNodes(filter)}
                                        cachedFilter={this.props.cachedState ? this.props.cachedState.filter : null}/>
                            </Drawer>
                        </div>
                    }
                </div>
                <Snackbar key="snackbar"
                          open={this.state.openSnackbar}
                          message="Screenshot attached to custom"
                          ContentProps={{style: {borderRadius: "2px", fontSize: "14px"}}}
                          style={{bottom: 0}}
                          autoHideDuration={3000}
                          anchorOrigin={{ vertical: 'bottom', horizontal : 'center'}}
                          onClose={this.handleRequestClose}
                />
                {this.state.openDialog && <SubnodesFilter
                    nodes={this.state.subnodes}
                    mainNodeId={this.state.nodes[0].id}
                    clickedNodeId={this.state.clickedNodeId}
                    extraNodes={this.state.nodeMore}
                    addSubnodesToGraph={(nodesToAdd, nodesToRemove, subnodesEdges, clickedNodeFromDialog) => this.addSubnodesToGraph(nodesToAdd, nodesToRemove, subnodesEdges, clickedNodeFromDialog)}
                    edges={this.state.subnodesEdges}
                    handleClose={() => this.handleClose()}
                    checkedAll={this.state.checkedAll}
                    sessionExpired={() => this.props.sessionExpired()}
                />}

                <Snackbar key="snackbarText"
                          open={this.state.openSnackbarSubnodes}
                          message={this.state.snackbarText}
                          ContentProps={{style: {borderRadius: "2px", fontSize: "14px"}}}
                          anchorOrigin={{ vertical: 'bottom', horizontal : 'center'}}
                          style={{bottom: 0}}
                />
                {this.state.openNodeToCartSnack &&
                    <div className="box-shadow"
                         style={{
                             position: "fixed",
                             backgroundColor: "#ffffff",
                             color: "#00bb32",
                             padding: "10px",
                             top: "50px",
                             right: "20px",
                             zIndex: 5000,
                             borderRadius: "2px"
                         }}
                    >
                        {this.state.toCartNode}
                    </div>}
            </div>
        );
    }

    handleClick() {
        if (this.state.showFilter) {
            this.setState({showFilter: !this.state.showFilter})
        }
    }

    renderFilter() {
        const time = new Date().getTime();

        return (
            <div key={"cat" + time}
                 id='filters'
                 style={{
                     color: "#ffffff",
                     backgroundColor: "#ffffff",
                     padding: "10px 10px 20px 10px",
                     width: '200px',
                     position: "absolute",
                     zIndex: "300",
                     border: "1px solid #ececec",
                     borderRadius: "2px",
                     boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px"
                 }}>
                <i className="material-icons"
                   style={{zIndex: "500", color: "#ef4254", position: "absolute", right: "5px", top: "5px"}}
                   onClick={() => this.toggleFilter()}>clear</i>
                {this.renderFilterItems()}
            </div>
        )
    }

    renderFilterItems() {
        return Object.keys(this.state.filter).map((item, index) => {
            const label = item.startsWith("#") ? item.substr(1) : item;
            let labelText = label.toLowerCase().startsWith("shareholding") ? "shareholding" : label.toLowerCase().replace(/_/g, ' ');
            let title;
            switch (labelText) {
                case 'info' :
                    title = GraphCategoriesFilterTitles.INFO;
                    break;
                case 'pep' :
                    title = GraphCategoriesFilterTitles.PEP;
                    break;
                case 'sanction' :
                    title = GraphCategoriesFilterTitles.SANCTION;
                    break;
               case 'non active' :
                    title = GraphCategoriesFilterTitles.NONACTIVE;
                    break;
               default:
                    title = GraphCategoriesFilterTitles.OTHER + labelText + '.';
            }

            const filterContent = <FormControlLabel key={labelText}
                                                    title={title}
                                                    style={{textTransform: "capitalize", color: "rgba(0, 0, 0, 0.87)"}}
                                                    label={<div style={{fontSize: "14px"}}>{labelText}</div>}
                                                    control={<Checkbox style={{marginLeft: "2px", marginRight: "7px"}}
                                                                       checkedIcon={<CheckBoxIcon
                                                                           className="material-icons"
                                                                           style={{
                                                                               fontSize: "24px",
                                                                               color: "#ef4254"
                                                                           }}/>}
                                                                       icon={<CheckBoxOutlineBlankIcon
                                                                           className="material-icons"
                                                                           style={{
                                                                               fontSize: "24px",
                                                                               color: "#6e7072"
                                                                           }}/>}
                                                                       checked={this.state.filter[item]}
                                                                       onChange={() => this.selectFilterItem(item)}
                                                    />}
            />;
            if (index === 0 || index === 5) {
                const icon = index === 0 ? 'panorama_fish_eye_icon' : 'linear_scale';
                const text = index === 0 ? 'Entity' : 'Relationships';

                return (
                    <div key={labelText + 'container' + index}>
                        <div className='filter-title-container' style={{marginBottom: "0px"}}>
                            <div className='filter-title'>
                                <Icon fontSize="large" className="material-icons filter-icon"
                                      style={{color: "#ef4254"}}>{icon}</Icon>
                            </div>
                            <div>{text}</div>
                        </div>
                        <div style={{
                            width: "170px",
                            minWidth: "170px",
                            marginBottom: "2px",
                            zIndex: "100px",
                            height: "30px"
                        }}>
                            {filterContent}
                        </div>
                    </div>)
            } else {
                return <div key={index}
                            style={{
                                width: "170px",
                                minWidth: "170px",
                                marginBottom: "2px",
                                zIndex: "100px",
                                height: "33px"
                            }}>
                    {filterContent}
                </div>;
            }
        });
    }

    detectValSubnodes(id) {
        let have = false;
        const node = this.nodeMap[id];
        if (node.subNodes.length > 0) {
            node.subNodes.forEach(subnode => {
                if (subnode.isVisible) {
                    have = true;
                }
            })
        }
        return have;
    }

    getCytoscapeStyles() {
        return [
            {
                selector: 'node',
                style: {
                    'height': 100,
                    'width': 100,
                    'background-fit': 'contain',
                    'background-image': 'data(nodeAvatar)',
                    'background-color': '#ffffff',
                    'background-opacity': 0,
                    'border-width': 2,
                    'border-opacity': 1,
                    'border-color': '#95989a',
                    'label': 'data(label)',
                    'text-wrap': 'wrap',
                    'font-size': 22,
                    'line-height':1.2,
                    'text-max-width': this.state.nodeSize+10,
                }
            }, {
                selector: '.mainNode',
                style: {
                    'height': 250,
                    'width': 250,
                    'background-fit': 'contain',
                    'background-color': '#ffffff',
                    'background-image': 'data(nodeAvatar)',
                    'background-opacity': 0,
                    'border-width': 5,
                    'border-opacity': 1,
                    'border-color': 'rgb(153, 186, 152)',
                    'label': 'data(label)',
                    'text-wrap': 'wrap',
                }
            },
            {
                selector: 'node:selected',
                style: {
                    'height': 180,
                    'width': 180,
                    'background-fit': 'contain',
                    'background-color': '#ffffff',
                    'background-image': 'data(nodeAvatar)',
                    'background-opacity': 1,
                    'border-opacity': 1,
                    'label': 'data(label)',
                    'text-wrap': 'wrap',
                    'border-width': 5,
                    'border-color': '#ef4254',
                    'text-max-width': this.state.nodeSize+10
                }
            }, {
                selector: 'node.pie',
                style: {
                    'pie-size': '100%',
                    'height': 147,
                    'width': 147,
                }
            },{
                selector: '.extraParent',
                style: {
                    'background-image': 'unset',
                    'border-width': 0,
                }
            },
            {
                selector: 'node.pie:selected',
                style: {
                    'height': 190,
                    'width': 190,
                    'border-width': 5,
                    'border-color': '#ef4254',
                }
            }, {
                selector: ':parent',
                style: {
                    'background-color': '#ffffff',
                    'border-color': '#ffffff',
                    'background-opacity': 0,
                    'border-opacity': 0,
                }
            },{
                selector: ':parent:selected',
                style: {
                    'background-color': '#ffffff',
                    'border-color': '#ffffff',
                    'background-opacity': 0,
                    'border-opacity': 0,
                    'border-width': 5,
                }
            },{
                selector: 'node.mainNode:selected',
                style: {
                    'border-width': 15,
                    'border-opacity': 1,
                    'background-fit': 'contain',
                    'background-color': '#ffffff',
                    'border-color': '#ef4254',
                }
            }, {
                selector: 'edge',
                style: {
                    'width': 6,
                    'target-arrow-color': '#ef4254',
                    'curve-style': 'bezier',
                    'label': 'data(label)',
                    'font-size': 21,
                    'edge-text-rotation': 'autorotate',
                    'target-arrow-shape': 'triangle',
                    'line-color': 'data(color)',
                    'source-arrow-color': 'data(color)',
                    'line-style': 'data(style)',
                    // 'loop-direction': '180deg',//loop position
                    'control-point-step-size': 200, //loop view
                    'text-background-opacity': 1,
                    'text-background-color': '#fafafd'
                }
            }

        ];
    }

    initCytoscape() {
        const graph = document.getElementsByClassName(`component-graph-${this.id}`)[0];
        const graphBody = graph.getElementsByClassName('graph')[0] ;
        graphBody.style.height = `${graph.clientHeight}px`;

        const container = document.getElementById("cytoscape-" + this.id);
        if (container) {
            window.cy = cytoscape(
                {
                    container: container,
                    levelWidth: () => 2,
                    edgeElasticity: () => 30,
                    idealEdgeLength: () => 30,
                    concentric: (node) => node.degree(),
                    style: this.getCytoscapeStyles(),
                    maxZoom: 3,
                    minZoom: 0.01,
                    spacingFactor: 1.2,
                    wheelSensitivity:  .2,
                    userZoomingEnabled: this.props.isMaximize,
                    autoungrabify: !this.props.isMaximize,
                    boxSelectionEnabled: this.props.isMaximize,
                    zoom: .5,
                    layout: this.getLayoutOptions()
                }
            )
        }
        this.cytoscape = window.cy;
    }

    changeCanvasToImage() {
        const graphWrapper = document.getElementsByClassName('graph')[0];

        let png = sessionStorage.getItem("PNG");
        const src = JSON.parse(png);
        graphWrapper.innerHTML = `<img style="max-height:555px" alt="imagePrint" src="${src}" />`
    }

    componentDidMount() {
        this.setState({doLayout: true});
        this.initCytoscape();

        if (Utils.isNullOrUndefined(this.props.cachedState)) {
            this.createNodes().then(() => {
                this.createRelations();
                this.cytoscape.on("mousedown", () => {
                    if (this.props.isMaximize) {
                        this.cytoscape.resize();
                    }
                });

                this.cytoscape.on("click", () => {
                    this.setState({selectedNode: null});
                    if (!this.props.isMaximize && this.props.print === "") {
                        this.props.toggleMaximize();
                    }
                    this.cytoscape.resize();
                });

                this.cytoscape.on("select", "node", (event) => {
                    this.setState({selectedNode: this.nodeMap[event.target.id()]});
                });

                this.nodeHandleClick();

                if (Utils.isNullOrUndefined(this.props.cachedState) && this.state.doLayout) {
                    try {
                        const layout = this.cytoscape.layout(this.getLayoutOptions());
                        layout.run();
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    this.cytoscape.fit();
                }

                if (this.props.print === "?print=true") {
                    this.changeCanvasToImage();
                }
            });
        } else {
            this.cytoscape.add(this.props.cachedState.cy);

            this.state.nodes.forEach(node => {
                this.setNodeOptionsCState(node);
            });
            this.showOrHiddenEdges()

            this.state.edges.forEach(edge => {
                if (Utils.isNotNullOrUndefined(edge.titleHover)) {
                    const cyEdge =  this.cytoscape.getElementById(edge.id);
                    const content = `<div class='tooltipNode' style='max-height:300px!important; color:#95989A'>${edge.titleHover}</div>`;
                    graphUtils.makePopup(cyEdge, content);
                }
            });

            this.cytoscape.on("mousedown", () => {
                if (this.props.isMaximize) {
                    this.cytoscape.resize();
                }
            });

            this.cytoscape.on("select", "node", (event) => {
                this.setState({selectedNode: this.nodeMap[event.target.id()]});
            });

            this.cytoscape.on("click", () => {
                this.setState({selectedNode: null});
                if (!this.props.isMaximize && this.props.print === "") {
                    this.props.toggleMaximize();
                }
                this.cytoscape.resize();
            });

            this.nodeHandleClick();
            this.cytoscape.fit();

            if (this.props.print === "?print=true") {
                this.changeCanvasToImage()
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.isScreenshot !== nextProps.isScreenshot) {
            this.handleTouchTap();
        }
        if (this.props.changeCanvasToImage !== nextProps.changeCanvasToImage) {
            try {
                const png = document.querySelector('canvas[data-id=layer2-node]').toDataURL("image/png");
                sessionStorage.setItem("PNG", JSON.stringify(png));
            } catch (error) {
                console.log(error);
            }
        }
    }

    componentWillUnmount() {
        this.setState({doLayout: false});
        if(Utils.isNotNullOrUndefined(this.cytoscape)) {
            this.cytoscape.removeAllListeners();
            this.cytoscape.unmount();
            this.cytoscape.destroy();
        }
    }

    handleToggle() {
        this.setState({open: !this.state.open});
    }

    handleTouchTap() {
        this.setState({
            openSnackbar: true,
        });
    };

    handleRequestClose = () => {
        this.setState({
            openSnackbar: false,
            openSnackbarSubnodes: false
        });
    };

    nodeHandleClick() {
        let clickCount = 0;
        let singleClickTimer;

        this.cytoscape.on("click", "node", (event) => {
            if (this.props.isMaximize) {
                const node = this.nodeMap[event.target.id()];
                clickCount++;
                if (clickCount === 1) {
                    singleClickTimer = setTimeout(() => {
                        clickCount = 0;
                    }, 400);
                } else if (clickCount === 2) {
                    clearTimeout(singleClickTimer);
                    clickCount = 0;
                    if (Utils.isNotNullOrUndefined(node)) this.nodeHandleDblClick(node);
                }
            }
        });
        this.cytoscape.on("cxttap", "node", (event) => {
            if (this.props.isMaximize) {
                const node = this.nodeMap[event.target.id()];
                this.nodeHandleSingleClick(node);
            }
        });
    }

    hideNodeDetails(nodeId) {
        const nodeDetails = this.state.nodeDetails;
        const index = nodeDetails.indexOf(nodeId);
        if (index !== -1) {
            nodeDetails.splice(index, 1);
            this.setState({nodeDetails});
        }
    }

    nodeHandleSingleClick(node) {
        if (this.state.nodeDetails.indexOf(node.id) === -1) {
            const nodeDetails = this.state.nodeDetails;
            nodeDetails.push(node.id);
            this.setState({nodeDetails});
        }
    }

    nodeHandleDblClick(clickedNode) {
        let mainNodeId = this.state.nodes[0].id;
        let nodeNeighbours = this.cytoscape.getElementById(clickedNode.id).neighborhood();
        const doID = clickedNode.subNodes.length === 0;

        nodeNeighbours.forEach(neighbor => {
            if (mainNodeId === neighbor._private.data.id) {
                clickedNode.fromFirstLayer = true;
            }
        })

        const prId = sessionStorage.getItem('prId');
        const requestData = {
            action: postActions.GRAPH,
            time: new Date().getTime(),
            nid: clickedNode.id,
            bnli: localStorage.getItem('bnli'),
            projectId: prId
        };
        const additionalClick = this.clickedNode.current.filter(item => item === clickedNode.id);
        const activeReq = additionalClick.size <= 1 || this.clickedNode.current.length === 0;

        this.setState({openSnackbarSubnodes: true, snackbarText: "Loading related nodes."});
        this.clickedNode.current.push(clickedNode.id);

        activeReq && doPost(API.GET_RELATED_NODES, {qry: JSON.stringify(requestData)}, true).then(response => {
            if (!Utils.isNullOrUndefined(response.data.error)) {
              return showAlertDialog({title: "Error", message: "Try again later."});
            }
            if (Utils.isNotNullOrUndefined(response.data)) {
                this.setState({subnodes: null, openSnackbarSubnodes: false});
                let subnodes = response.data.data ? response.data.data.nodes : [];
                let realSubnodes = [];
                let newEdges = response.data.data ? response.data.data.edges : [];
                let subnodesEdges = [];
                let clickedNodeId = clickedNode.id;
                this.parentPos = {
                    x: this.cytoscape.getElementById(clickedNode.id).position('x'),
                    y: this.cytoscape.getElementById(clickedNode.id).position('y'),
                }
                if (subnodes === 0) return;

                newEdges.forEach((edge => {
                    const newEdge = new Edges(edge)
                    subnodesEdges.push(newEdge);
                }))

                subnodes.forEach(subnode => {
                    let nodeElement = this.cytoscape.getElementById(subnode.id);
                    const nodeElementIsHidden = nodeElement.length > 0 && nodeElement.hidden();

                    if (Object.keys(this.nodeMap).indexOf(subnode.id) === -1 || nodeElement.length === 0) {
                        realSubnodes.push(subnode);
                    }
                    if (nodeElementIsHidden) {
                        realSubnodes.push(subnode);
                    }

                    if (nodeElement.length !== 0 && !nodeElementIsHidden) {
                        subnode.isVisible = true;
                        subnode.checked = true;
                    } else {
                        subnode.checked = false;
                        subnode.isVisible = false;
                    }
                });

                let isVisible = true;
                for (let i = 0; i < subnodes.length; i++) {
                    const node = subnodes[i];
                    const visible = Utils.isNullOrUndefined(node.isVisible) ? false : node.isVisible;
                    if (!visible) {
                        isVisible = false;
                        break;
                    }
                }
                let doGuiID = doID && realSubnodes.length > 0;
                this.setState({checkedAll: isVisible, doGuiID});

                if (subnodes.length === 0 || (realSubnodes.length === 0 && subnodes.length <= 10)) {
                    this.clickedNode.current = [];
                    this.setState({openSnackbarSubnodes: true, snackbarText: "No more nodes to show."}, () => {
                        setTimeout(() => this.setState({openSnackbarSubnodes: false}), 2000)
                    });
                }

                if (subnodes.length > 0 && subnodes.length <= 10) {
                    this.setState({subnodes, subnodesEdges, clickedNode, clickedNodeId}, () => {
                        if (realSubnodes.length === 0) return;
                        this.addSubnodesToGraph(realSubnodes, []);
                    })
                } else if (Utils.isNotNullOrUndefined(subnodes) && subnodes.length>10){
                    const moreNodes = response.data.data.nodeMore;
                    this.setState({subnodes, subnodesEdges, clickedNode, clickedNodeId, nodeMore: moreNodes}, () => {
                        this.clickedNode.current = [];
                        this.setState({openDialog: true});
                    })
                }
            }
        }).catch(error => {
            console.error(error);
        });
    }

    addSubnodesToGraph(nodesToAdd, nodesToRemove, subnodesEdgesFromMore = null) {
        this.setState({openBackDrop: true,  openDialog: false}, () => {
            let historyForBackNodes = this.state.historyForBackNodes;
            let clickedNode = this.state.clickedNode;
            let clickedNodeSubnodesIds = clickedNode.subNodes.length > 0 ? clickedNode.subNodes.map(node => node.id) : [];
            const mainNode = this.state.nodes[0].id;
            const idsNodesForRemove = nodesToRemove.map(item => item.id);

            idsNodesForRemove.forEach(id => {
                let node = this.nodeMap[id];
                node.isVisible = false;
                node.checked = false;
            });

            const collectionForRemove = this.cytoscape.nodes().filter(ele => {
                const id = ele.data('id');
                return idsNodesForRemove.includes(id) && mainNode !== id;
            });

            if (collectionForRemove.length > 0) {
                collectionForRemove.style('visibility', 'hidden');
            }

            const newSubnodes = [];
            if (this.state.doGuiID) {
                clickedNode.parent = clickedNode.id + '_' + this.guid();//added container
            }

            let idsVisible = [];
            nodesToAdd.forEach((nodeData) => {
                if (nodeData.id === clickedNode.id) return;
                let node = this.nodeMap[nodeData.id];

                if (Utils.isNullOrUndefined(node)) {
                    nodeData.parentPos = this.parentPos;
                    node = new Node(nodeData);
                    node.isVisible = true;
                    node.checked = true;
                    if (!clickedNode.fromFirstLayer) {
                        node.parentId = clickedNode.id;
                    }
                    node.parent = clickedNode.parent;
                    node.parentId = clickedNode.id;
                    if (clickedNodeSubnodesIds.indexOf(node.id) === -1 && Object.keys(this.nodeMap).indexOf(node.id) === -1) {
                        this.nodeMap[nodeData.id] = node;
                        clickedNode.subNodes.push(node);
                        newSubnodes.push(node.getCyElement());
                    }
                } else {
                    node.isVisible = true;
                    node.checked = true;
                    if (!clickedNode.fromFirstLayer) {
                        node.parentId = clickedNode.id;
                    }
                    node.parent = clickedNode.parent;

                    idsVisible.push(node.id);
                }
            });
            if (idsVisible.length > 0) {
                const collectionForAdd = this.cytoscape.nodes().filter(ele => {
                    return idsVisible.includes(ele.data('id'));
                });

                if (collectionForAdd.length > 0) {
                    collectionForAdd.style('visibility', 'visible');
                    collectionForAdd.move({'parent': clickedNode.parent, "parentId": clickedNode.id});
                }
            }

            let parentCyNode = this.cytoscape.getElementById(clickedNode.parent);
            if (nodesToAdd.length > 0 && parentCyNode.length === 0 && nodesToAdd.length !== idsVisible.length) {
                    this.cytoscape.add([{
                        group: "nodes",
                        classes: "extraParent",
                        data: {
                            id: clickedNode.parent,
                            filterEles: [],
                            pie: [],
                            label: '',
                        }
                    }]);
                    this.setNodeOptionsCState(clickedNode)
            }

            Promise.all(newSubnodes).then((values) => {

                const hasAddNodes = values.length > 0;
                let cyClickedNode = this.cytoscape.getElementById(clickedNode.id);
                this.nodeMap[clickedNode.id] = clickedNode;
                cyClickedNode.move({'parent': clickedNode.parent});

                if (hasAddNodes) this.cytoscape.add(values);
                const edges = [];
                this.state.subnodesEdges.forEach(edge => {
                    edge.source = this.nodeMap[edge.sourceId];
                    edge.target = this.nodeMap[edge.targetId];
                    this.edgeMap[edge.id] = edge;
                });
                if (Utils.isNotNullOrUndefined(subnodesEdgesFromMore)) {
                    subnodesEdgesFromMore.forEach(edge => {
                        edge.source = this.nodeMap[edge.sourceId];
                        edge.target = this.nodeMap[edge.targetId];
                        this.edgeMap[edge.id] = edge;
                    });
                }
                Object.values(this.edgeMap).forEach(edge => {
                    edges.push(edge);
                });

                clickedNode.subNodes.forEach(node => {
                    this.setNodeOptionsCState(node);
                });
                this.createSubnodesRelations(edges);

                graphUtils.layoutSubNodes(clickedNode, this.cytoscape);

                if (nodesToAdd.length > 0) {
                    historyForBackNodes.push(clickedNode);
                }
                this.clickedNode.current = [];
                this.setState({historyForBackNodes, edges, openBackDrop: false});
            });
        })
    }

    historyNodes() {
        if (this.state.historyForBackNodes.length > 0) {
            let lastNodeId = this.state.historyForBackNodes.pop();
            this.showOrHideSubnodes(lastNodeId.id, false)
        }
    }

    guid() {
        let s4 = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
        return s4 + s4 + '-' + s4;
    }

    getContentState() {
        this.cytoscape.nodes().forEach(cyNode => {
            const node = this.nodeMap[cyNode.id()];
            if (Utils.isNotNullOrUndefined(node)) {
                node.setPosition(cyNode.position());
            }
        });

        return {
            layout: this.state.layout,
            nodes: this.state.nodes,
            edges: this.state.edges,
            filter: this.state.filter,
            hideNodes: this.state.hideNodes,
            anotherFilter: this.state.anotherFilter,
            nodeMap: this.nodeMap,
            edgeMap: this.edgeMap,
            formerNodes: this.state.showFormerNodes,
            showOrHideTransitionsNodes: this.state.showOrHideTransitionsNodes,
            historyForBackNodes: this.state.historyForBackNodes,
            cy: this.cytoscape.elements().jsons()
        };
    }

    createNodes() {
        return new Promise((resolve) => {
            if (Utils.isNotNullOrUndefined(this.props.cachedState)) {
                let nodesPromise = [];
                this.props.cachedState.nodes.forEach(node => {
                    if (node.isVisible) {
                        nodesPromise.push(this.addNode(node, true));
                    }
                });
                Promise.all(nodesPromise).then(() => {
                    this.selectNodeById(this.state.nodes[0].id, false);
                    resolve();
                });
            } else {
                let nodesPromises = [];
                this.state.nodes.forEach(node => {
                    nodesPromises.push(this.addNode(node));
                    node.isVisible = true;
                });
                Promise.all(nodesPromises).then(() => {
                    this.selectNodeById(this.state.nodes[0].id, false);
                    resolve();
                });
            }
        });
    }

    addNode(node, onlyVisibleSubnodes = false) {

        if (Utils.isNullOrUndefined(node)) return;
        return new Promise((resolve, reject) => {

            if (Utils.isNotNullOrUndefined(node) && Utils.isNotNullOrUndefined(node.parent)) {
                let parentCyNode = this.cytoscape.getElementById(node.parent)
                if (parentCyNode.length === 0) {
                    this.cytoscape.add([{
                        group: "nodes",
                        data: {
                            id: node.parent,
                            filterEles: node.category ? node.category : [],
                            pie: node.pie ? Object.keys(node.pie) : []
                        }
                    }]);
                }
            }
            let cyNode = this.cytoscape.getElementById(node.id)
            if (cyNode.length > 0) {
                cyNode.style('visibility', 'visible');
                reject()
            } else {
                node.getCyElement().then(nodeOptions => {
                    nodeOptions.data.filterEles = node.category ? node.category : [];
                    nodeOptions.data.pie = node.pie ? Object.keys(node.pie) : [];
                    this.setNodeOptions(nodeOptions, node, onlyVisibleSubnodes).then(() => {
                        resolve()
                    }).catch(() => {
                        reject();
                    })
                })
            }
        })
    }

    getNodePieTippyContent(node) {
        let content = '';
        let value = '';
        Object.keys(node.pie).forEach((key) => {
            const values = node.pie[key];
            if (Utils.isNotNullOrUndefined(values.name)) {
                value = values.name + ": ";
            }
            let color;
            switch (key) {
                case 'pep':
                    color = '#6da6ce';
                    break;
                case 'sanction':
                    color = '#F05F7C';
                    break;
                case 'info':
                    color = '#8d6cab';
                    break;
                case 'non_active':
                    color = '#757575';
                    break;
                default:
                    color = '#000';
                    break;
            }

            content += "<div><b style='color:" + color + "; text-transform: capitalize'>&#9632; " + key.replace(/_/g, " ") + "</b> <div>" + value + values.value + "</div></div>";
        });

        return `<div class = 'tooltipNode' style='max-height:230px!important; max-width: 280px; color:#95989A;word-wrap: break-word;overflow-y: auto' ><p>${node.title}</p> ${content}</div>`;

    }

    getNodeTippyContent(node){
        return `<div class = 'tooltipNode' style='max-height:230px!important; color:#95989A' >${node.title}</div>`
    }

    setNodeOptionsCState(node) {
        const isVisible = node.isVisible;
        const cyNode = this.cytoscape.getElementById(node.id);
        let content;
        if (!node.isMore()) {
            if (!node.hasPie()) {
                if (!node.checkedFromList) {
                    cyNode.style('opacity' ,  isVisible ? '1 ' : this.state.showOrHideTransitionsNodes ? '0' : '.2')

                } else {
                    cyNode.style('opacity','0')
                }
                 content = this.getNodeTippyContent(node);
            } else {
                if (!node.checkedFromList) {
                    cyNode.style('opacity' ,  isVisible ? '1 ' : this.state.showOrHideTransitionsNodes ? '0' : '.2')

                } else {
                    cyNode.style('opacity','0')
                }

                content = this.getNodePieTippyContent(node)
            }
        } else {
            if (!node.checkedFromList) {
                cyNode.style('opacity' ,  isVisible ? '1 ' : this.state.showOrHideTransitionsNodes ? '0' : '.2')

            } else {
                cyNode.style('opacity','0')
            }

            content = 'Click to load more nodes';
        }
        graphUtils.makePopup(cyNode, content);

        node.subNodes.forEach(subnode => {
            this.setNodeOptionsCState(subnode);
        });
    }

    setNodeOptions(nodeOptions, node, onlyVisibleSubnodes, isNotClicked = true) {
        return new Promise((resolve) => {
            let cyNode1 = this.cytoscape.getElementById(node.id);
            if (cyNode1.length === 0) {
                this.cytoscape.add(nodeOptions);
            }

            const cyNode = this.cytoscape.getElementById(node.id);
            let content;
            if (!node.isMore()) {
                if (!node.hasPie()) {
                    content = this.getNodeTippyContent(node);
                } else {
                    content = this.getNodePieTippyContent(node)
                }
            } else {
                content = 'Click to load more nodes';
            }
            graphUtils.makePopup(cyNode, content);

            if (isNotClicked) {
                this.setSubnodes(node.subNodes, onlyVisibleSubnodes).then(() => {
                    resolve()
                });
            }
        })
    }

    setSubnodes(subnodes = [], onlyVisibleSubnodes) {
        return new Promise((resolve, reject) => {
            let subNodesPromises = [];
            subnodes.forEach(subnode => {
                const node = this.cytoscape.getElementById(subnode.id)
                if (onlyVisibleSubnodes) {
                    if (node.length === 0) {
                        if (subnode.isVisible) {
                            subNodesPromises.push(this.addNode(subnode));
                        }
                    }
                } else {
                    if (node.length === 0) {
                        if (subnode.checked) {
                            subnode.isVisible = true;
                            subNodesPromises.push(this.addNode(subnode));
                        }
                    }
                }
            });
            Promise.all(subNodesPromises).then(() => {
                resolve();
            }).catch((error) => {
                reject();
                console.log(error)
            });
        })
    }

    createRelations() {
        this.cytoscape.edges().remove();
        const elements = [];
        let filtered = this.getSelectedFilterItems();

        let egdeFilter = filtered.filter(item => {
            if (item !== "pep" && item !== "sanction" && item !== "info" && item !== "non_active") {
                return item;
            }
            return null;
        })

        this.state.edges.forEach(edge => {
            let filterExist = egdeFilter.length === 0 || egdeFilter.indexOf(edge.category) !== -1 || edge.category.startsWith('shareholding')
            || edge.category === "other" || edge.category === "generic_cross_entity" || edge.category === "interpersonal"

            const sourceNode = Utils.isNotNullOrUndefined(edge.source) ? this.cytoscape.getElementById(edge.source.id) : [];
            const targetNode = Utils.isNotNullOrUndefined(edge.target) ? this.cytoscape.getElementById(edge.target.id) : [];
            const edgeExist = Utils.isNotNullOrUndefined(edge.id) ? this.cytoscape.getElementById(edge.id).length : false;
            if (sourceNode.length === 1 && targetNode.length === 1 && filterExist && !edgeExist) {
                elements.push(edge.getCyEdge());
                this.cytoscape.add(edge.getCyEdge());
            }
        });

        this.cytoscape.add(elements);
        this.state.edges.forEach(edge => {
            if (Utils.isNotNullOrUndefined(edge.titleHover)) {
                const cyEdge =  this.cytoscape.getElementById(edge.id);
                const content = `<div class='tooltipNode' style='max-height:300px!important; color:#95989A'>${edge.titleHover}</div>`;
                graphUtils.makePopup(cyEdge, content);
            }
        });
        this.showOrHiddenEdges();
    }

    createSubnodesRelations(subnodeEdges) {
        const elements = [];

        subnodeEdges.forEach(edge => {
            const sourceNode = Utils.isNotNullOrUndefined(edge.source) ? this.cytoscape.getElementById(edge.source.id) : [];
            const targetNode = Utils.isNotNullOrUndefined(edge.target) ? this.cytoscape.getElementById(edge.target.id) : [];
            if (sourceNode.length === 1 && targetNode.length === 1) {
                elements.push(edge.getCyEdge());
            }
        });

        this.cytoscape.add(elements);
        elements.forEach(edge => {
            if (Utils.isNotNullOrUndefined(edge.titleHover)) {
                const cyEdge =  this.cytoscape.getElementById(edge.id);
                const content = `<div class='tooltipNode' style='max-height:300px!important; color:#95989A'>${edge.titleHover}</div>`;
                graphUtils.makePopup(cyEdge, content);
            }
        });
        this.showOrHiddenEdges();
    }

    selectNodeById(nodeId) {
        const collection = this.cytoscape.getElementById(nodeId);
        if (collection.length > 0) {
            this.unselectAllNodes();
            const node = collection[0];
            node.select();
            if (!this.props.automation) {
                this.setState({selectedNode: this.nodeMap[nodeId]});
            }
        }
    }

    unselectAllNodes() {
        this.cytoscape.nodes().unselect();
    }

    addOrRemoveNode(node, alsoRemove = true) {
        if (node.isVisible) {
            node.isVisible = false;
            node.checked = false;
            node.checkedFromList = true;
            if (alsoRemove) {
                if (node.subNodes.length > 0) {
                    this.showOrHideSubnodes(node.id, false);
                }

                const elem = this.cytoscape.getElementById(node.id);
                if (elem) elem.style('visibility', 'hidden');
                elem.addClass('addRemoveToggleLegend');
                if (Utils.isNotNullOrUndefined(this.nodeMap[node.parentId]) && this.nodeMap[node.parentId].subNodes.filter(_node => _node.checked && _node.id !== node.id).length === 0) {
                    let historyForBackNodes = this.state.historyForBackNodes.filter(history => history.id !== node.parentId);
                    this.setState({historyForBackNodes});
                }
                this.showOrHiddenEdges();
            }
        } else {
            node.checked = true;
            node.isVisible = true;
            node.checkedFromList = false;

            const elem = this.cytoscape.getElementById(node.id);
            elem.removeClass('addRemoveToggleLegend');
            elem.removeClass('classOpacity')
            if (elem){
                elem.style('visibility', 'visible');
                elem.style('opacity', '1')
            }

            if (node.subNodes.length > 0) {
                this.showOrHideSubnodes(node.id, true);
            }
            this.showOrHiddenEdges();
        }
    }

    showOrHideSubnodes (id, show) {
        const node = this.nodeMap[id];
        if (Utils.isNullOrUndefined(this.newHistoryForBackNodes)) {
            this.newHistoryForBackNodes = this.state.historyForBackNodes;
        }
        let newHistoryForBackNodes = this.newHistoryForBackNodes.filter(_node => _node.id !== id);
        this.newHistoryForBackNodes = newHistoryForBackNodes;
        this.setState({historyForBackNodes: newHistoryForBackNodes}, () => {
            if (node.subNodes.length > 0) {
                node.subNodes.forEach((subnode) => {
                    subnode.isVisible = show;
                    subnode.checked = show;
                    subnode.checkedFromList = node.checkedFromList
                    this.showOrHideSubnodes(subnode.id, show);
                });
                const subnodesIds = node.subNodes.map(item => item.id);
                const collectionForEdit = this.cytoscape.nodes().filter(ele => {
                    return subnodesIds.includes(ele.data('id'));
                });

                if (collectionForEdit.length > 0) {
                    collectionForEdit.style('visibility', show ? 'visible' : 'hidden');
                    collectionForEdit.style('opacity', show ? '1' : '0')

                }
                this.showOrHiddenEdges();
            }
        });
    }

    changeLayout(value) {
        this.setState({layout: value}, () => {
            const layout = this.cytoscape.layout(this.getLayoutOptions());
            layout.run();
        });
    }

    getRefreshOptions() {
        showConfirmDialog({
            title: "Warning",
            message: "Choose arrange position of elements",
            continueButtonLabel: 'Autoarrange',
            continueSecondButtonLabel: 'Cancel all changes',
            onContinue: () => {
                const layout = this.cytoscape.layout(this.getLayoutOptions());
                layout.run();
            },
            onSecondContinue: () => {
                const filter = this.state.filter;

                Object.keys(filter).forEach(item => filter[item] = false);
                this.setState({filter, anotherFilter: Object.keys(filter), allChecked: true, showFormerNodes: true}, () =>this.defaultNodes()
                )
            },
        });
    }

    defaultNodes() {
        this.nodeMap = {};
        this.edgeMap = {};
        let historyForBackNodes = [];
        this.cytoscape.remove("node");
        this.cytoscape.remove("edge");

        let nodes = this.props.data.nodes.map((nodeData, index) => {
            nodeData.isMainNode = index === 0;
            const node = new Node(nodeData);
            node.isVisible = true;
            node.checked = true;
            node.parent = null;
            this.nodeMap[nodeData.id] = node;
            return node;
        });

        let edges = this.props.data.edges.map(edgeData => {
            const edge = new Edges(edgeData);
            edge.source = this.nodeMap[edge.sourceId];
            edge.target = this.nodeMap[edge.targetId];
            this.edgeMap[edge.id] = edge;
            return edge;
        });

        this.setState({nodes, edges, historyForBackNodes}, () => {
            new Promise((resolve) => {
                let nodesPromises = [];
                this.state.nodes.forEach(node => {
                    nodesPromises.push(this.addNode(node));
                });
                Promise.all(nodesPromises).then(() => {
                    resolve();
                });
            }).then(() => {
                this.createRelations();
                const layout = this.cytoscape.layout(this.getLayoutOptions());
                layout.run();
            });
        });
    }

    getLayoutOptions() {

        let options = {}
        const nodeQnt = this.state.nodes.length;
        const isXSmallCase = nodeQnt <= GraphBreakpoints.xSmall;
        const isSmallCase = nodeQnt <= GraphBreakpoints.small;
        const isMedCase = nodeQnt > GraphBreakpoints.small && nodeQnt <= GraphBreakpoints.medium;
        const isLargeCase = nodeQnt > GraphBreakpoints.medium;

        switch(this.state.layout) {
            case 'grid':
                options = {
                    name: this.state.layout,
                    padding:30,
                    avoidOverlapPadding: 20,
                    nodeDimensionsIncludeLabels: true,
                    spacingFactor: 1.5,
                    condense: true,
                }
                break;
            case 'circle':
                options = {
                    name: this.state.layout,
                    levelWidth: function () {
                        return 2;
                    },
                    fit: true,
                    avoidOverlap: true,
                    avoidOverlapPadding: 30,
                    padding: 20,
                    animate: this.state.isMaximize || false,
                    animationThreshold: 0,
                    edgeElasticity: () => {
                        return 20;
                    },
                    useMultitasking: true,
                    idealEdgeLength: () => {
                        return 40;
                    },
                    nestingFactor: () => {
                        return 1.2;
                    },
                    refresh: 20,
                    spacingFactor: 1.2
                };
                break;
            case 'concentric':
                options = {
                        name: this.state.layout,
                        levelWidth: function () {
                            return 2;
                        },
                        minNodeSpacing: isXSmallCase ? 400 : null,
                        fit: true,
                        avoidOverlap: true,
                        avoidOverlapPadding: 30,
                        padding: 20,
                        animate: this.state.isMaximize || false,
                        animationThreshold: 0,
                        useMultitasking: true,
                        nestingFactor: () => {
                            return 1.2;
                        },
                        refresh: 20,
                        spacingFactor: 1.2
                }
                break;
            case 'breadthfirst':
                options = {
                    name: this.state.layout,
                    fit: true,
                    avoidOverlap: true,
                    padding: 20,
                    maximal: true,
                    animate: this.state.isMaximize || false,
                    animationThreshold: 0,
                    nodeDimensionsIncludeLabels: true,
                    refresh: 20,
                    spacingFactor: 2,

                }
                break;
            case 'cose':
                options = {
                    name: 'fcose',
                    quality: "default",
                    randomize: true,
                    fit: true,
                    padding: 30,
                    nodeDimensionsIncludeLabels: true,
                    uniformNodeDimensions: false,
                    step: "all",
                    nodeRepulsion: () => 1500,
                     idealEdgeLength: () => isSmallCase ? 750 : isMedCase ? 1250 : 1800,
                    numIter: 2500,
                    edgeElasticity: () => isMedCase ? 0.45 : 0.2,
                    gravity: 0,
                    gravityRangeCompound: 1.5,
                    gravityCompound: 1.0,
                    gravityRange: 3.8,
                    initialEnergyOnIncremental: 0.2,

                }
                break;
            case 'dagre':
                options = {
                    name: this.state.layout,
                    nodeSep: 150,
                    edgeSep: 90,
                    rankSep: 150,
                    rankDir: 'TB',
                    ranker: 'tight-tree',
                    minLen: () =>  isLargeCase ? 10 : 1,
                    randomize: true,
                    fit: true,
                    padding: 30,
                    nodeDimensionsIncludeLabels: true,
                }
                break;
            default:
                break;
        }
        return options;
    }

    handleClose() {
        this.setState({openDialog: false})
    }

    handleCloseSnack() {
        this.setState({openNodeToCartSnack: false})
    };

    addToCard(id) {
        let nodes = [];
        let node = this.nodeMap[id];
        node.titleEn = node.title;
        node.subnodeIdList = Utils.isNullOrUndefined(node.subnodeIdList) ? [node.id] : node.subnodeIdList;
        nodes.push(node);
        this.setState({openNodeToCartSnack: true, toCartNode: node.titleEn}, () => {
            setTimeout(() => {
                this.setState({openNodeToCartSnack: false, toCartNode: null})
            }, 4000)
        })
        this.props.addToCard(nodes);
    }

    toggleFilter() {
        this.setState({showFilter: !this.state.showFilter})
    }

    selectFilterItem(item) {
        let cat = {};
        let allChecked = false;
        let refresh = false;
        cat = this.state.filter;
        cat[item] = !cat[item];
        const arr = cat[item] ? this.state.anotherFilter.filter(cat => cat !== item) : [...this.state.anotherFilter, item]
        if (this.state.anotherFilter.length === Object.keys(cat).length  ) refresh = true;
        if (arr.length === Object.keys(cat).length) allChecked = true;
        this.setState({filter: cat, anotherFilter: arr , allChecked}, () => {
            this.filterNodes(item, cat[item], refresh);
        })
    }

    getSelectedFilterItems() {
        return Object.keys(this.state.filter).filter(item => this.state.filter[item] === true);
    }

    showOrHideFormers() {
        const nodes = [...this.state.nodes];

        nodes.forEach(node => {
            const elem = this.cytoscape.getElementById(node.id);
            this.showSingleFormer(elem);

            if (node.subNodes.length > 0) {
                node.subNodes.forEach(subNode => {
                    const subNodeGraph = this.cytoscape.getElementById(subNode.id);
                    this.showSingleFormer(subNodeGraph);

                })
            }
        })

        this.showOrHiddenEdges();
        this.setState({nodes});
    }

    showSingleFormer(elem) {
        const nodeData = elem._private.data;
        const nodeInfo = this.nodeMap[nodeData.id];
        if (Utils.isNullOrUndefined(nodeInfo)) return ;

        if (!nodeInfo.isMainNode) {
            if (elem && nodeInfo.isHistorical) {
                this.showOrHideNodes(this.state.showFormerNodes, nodeInfo, elem)
            }
        }
    }

    filterSingleNode (node, graphElement, item = null, filtered = false,  refresh = false) {
        const nodeData = graphElement._private.data;
        const isMainNode = this.state.nodes[0].id === nodeData.id;
        const nodeInfo = this.nodeMap[nodeData.id];
        const categories = nodeData.pie.concat(nodeData.filterEles)

        if(!isMainNode){
            if (!this.state.allChecked) {
                if (categories.includes(item)) {
                    const moreCategories = categories.filter(cat => cat!== 'other' && cat!== 'interpersonal' && cat!== 'generic_cross_entity');
                    if (moreCategories.length > 0) {
                        const otherCategory = this.crossCategories(moreCategories, this.state.anotherFilter,item);

                        if (otherCategory.length === 0 && nodeInfo) {
                            this.showOrHideNodes(filtered, node, graphElement)
                        }
                    }
                } else {
                    if(refresh && nodeInfo){
                        this.showOrHideNodes(false, node, graphElement);
                    }
                }
            } else {
                nodeInfo && this.showOrHideNodes(true, node, graphElement);
            }
        } else {
            nodeInfo && !nodeInfo.checked && this.showOrHideNodes(true, node, graphElement);
        }
    }

    filterNodes(item = null, filtered = false, refresh = false) {
        const nodes = [...this.state.nodes];

        nodes.forEach(node => {
            const graphElement = this.cytoscape.getElementById(node.id);
            this.filterSingleNode(node, graphElement,item, filtered,  refresh);
            if (node.subNodes.length > 0) {
                node.subNodes.forEach(subNode => {
                    const subNodeGraph = this.cytoscape.getElementById(subNode.id);
                    this.filterSingleNode(subNode, subNodeGraph,item, filtered,  refresh);
                })
            }
        })

        this.showOrHiddenEdges();
        this.setState({nodes});
    }

    showOrHideNodes(filtered, node, graphElement) {
        if (!filtered) {
            node.checked = false;
            node.isVisible = false;
            graphElement.addClass('classOpacity').style('visibility', 'visible');
            graphElement.style('opacity', !this.state.showOrHideTransitionsNodes ? '.2' : '0');
            this.state.showOrHideTransitionsNodes ? graphElement.removeAllListeners()
                : this.hideOrShowTooltips(node, graphElement)
        } else {
            node.checked = true;
            node.isVisible = true;
            graphElement.style('visibility', 'visible').removeClass('classOpacity');
            graphElement.style('opacity', '1').removeClass('addRemoveToggleLegend');

            this.hideOrShowTooltips(node, graphElement)
        }
    }

    hideOrShowTooltips(node, element) {
        let content ;
        if (!node.isMore()){
            if (!node.hasPie()) {
                content = this.getNodeTippyContent(node);
            } else {
                content = this.getNodePieTippyContent(node)
            }
        } else {
            content = 'Click to load more nodes'
        }
        graphUtils.makePopup(element, content);
    }

    showTransactions(){
        this.cytoscape.nodes().forEach(item => {
            const nodeData = item._private.data;
            const nodeInfo = this.nodeMap[nodeData.id];
            const isMainNode = this.state.nodes[0].id === nodeData.id
            const graphElement = this.cytoscape.getElementById(nodeData.id);
            if (!isMainNode && nodeInfo) {
                if(!nodeInfo.isVisible) {
                    this.showOrHideNodes(false, nodeInfo, graphElement)
                }
            }
        })
        this.showOrHiddenEdges();
    }

    crossCategories(arr1, arr2, item) {
        let newOne = [];
        for(let i=0; i < arr1.length; i++) {
            if (!arr2.includes(arr1[i]) && arr1[i] !== item) {
                newOne.push(arr1[i])
            }
        }
        return newOne;
    }

    clearAll(){
        const filters = this.state.filter;
        this.clickedNode.current = [];
        Object.keys(this.state.filter).forEach( item => filters[item] = false)
        this.setState({filter: filters, anotherFilter: Object.keys(filters), allChecked: true, showFormerNodes: true}, () => {
            this.filterNodes(null, true);
            this.showOrHideFormers();
        })
    }

    showOrHiddenEdges() {
        this.cytoscape.edges().forEach(ele => {
            const edgeData = ele._private.data;
            const source = edgeData.source;
            const target = edgeData.target;
            const sourceEl = this.cytoscape.getElementById(source);
            const targetEl = this.cytoscape.getElementById(target);
            const hasTitleHover = Utils.isNotNullOrUndefined(edgeData.titleHover);

            const sourceHidden = sourceEl.hidden();
            const targetHidden = targetEl.hidden();

            if (sourceHidden || targetHidden) {
                const elem = this.cytoscape.getElementById(edgeData.id);
                if (elem) {
                    elem.style('visibility', 'hidden');
                    hasTitleHover && elem.removeAllListeners();
                }
            } else if (!sourceHidden && !targetHidden) {
                const elem = this.cytoscape.getElementById(edgeData.id);
                if (elem) {
                    elem.style('visibility', 'visible');
                    if(edgeData.isHistorical && !this.state.showFormerNodes) {
                        elem.style('visibility' , !this.state.showOrHideTransitionsNodes ? 'visible' : 'hidden');
                    }
                    if (hasTitleHover) {
                        const content = `<div class='tooltipNode' style='max-height:300px!important; color:#95989A'>${edgeData.titleHover}</div>`;
                        graphUtils.makePopup(elem, content);
                    }
                }
            }

            const sourceHiddenOpacity = sourceEl.classes().includes('classOpacity');
            const targetHiddenOpacity = targetEl.classes().includes('classOpacity');

            if (sourceHiddenOpacity || targetHiddenOpacity) {
                const elem = this.cytoscape.getElementById(edgeData.id);
                if (elem) elem.style('opacity', '.2');
            } else if (!sourceHidden && !targetHidden) {
                const elem = this.cytoscape.getElementById(edgeData.id);
                if (edgeData.isHistorical) {
                    elem.style('opacity', !this.state.showFormerNodes ? '.2' : '1');
                } else {
                    elem.classes().filter(i => i !== 'classOpacity');
                    if (elem) elem.style('opacity', '1');
                }
            }
        });
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);
            let byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {type: contentType});
    }

    getScreenshotGraph() {
        let imageSrc = document.querySelector('canvas[data-id=layer2-node]')
        let imageSrcData = imageSrc.toDataURL("image/png");
        showScreenshotTitleDialog({
            title: "Title for screenshot from relation explorer",
            onContinue: (value, description) => this.props.getScreenshot(imageSrcData, value, description)
        });
    }

    savePng() {
        let pngContent = this.cytoscape.png();
        let b64data = pngContent.substr(pngContent.indexOf(",") + 1);
        window.saveAs(this.b64toBlob(b64data, "image/png"), "graph.png");
    }

}

Graph.defaultProps = {
    isMaximize: false,
};
Graph.propTypes = {
    data: PropTypes.object.isRequired,
    isMaximize: PropTypes.bool,
    toggleMaximize: PropTypes.func.isRequired,
    bgColor: PropTypes.string,
    cachedState: PropTypes.object,
    bnli: PropTypes.string,
    projectId: PropTypes.string,
    print: PropTypes.string,
    getScreenshot: PropTypes.func,
    isScreenshot: PropTypes.number,
    changeCanvasToImage: PropTypes.number,
    sessionExpired: PropTypes.func,
    addToCard: PropTypes.func,
    status: PropTypes.string,
    automation: PropTypes.bool,
};

import React, {useCallback, useEffect, useMemo, useState} from "react";
import c from "./styles/avatar.module.css";
import Utils from "../../utils";
import {API} from "../../api";
import {avatarSize, getNextAvatar, nodeTypes, pieColors} from "../../constants";

const Avatar = ({node, size}) => {
    const [nodeData, setNodeData] = useState(node);

    const getGenderSrc = useCallback(() => {
        let imgSrc;
        let imgAlt;
        let imgClassName;
        if (!nodeData.avatar) {
            const type = nodeData.type || nodeData.nodeType;
            if (type.toLowerCase() === nodeTypes.PERSON) {
                if (!nodeData.gender) {
                    imgAlt = 'node_avatar'
                    imgSrc = "/images/node_avatar.png";
                } else {
                    imgSrc = getNextAvatar(nodeData.gender)
                    imgAlt = 'avatarImage';
                }
            } else {
                imgAlt = 'nodeCompany';
                imgSrc = "/images/node_company.png";
            }
        } else {
            if (nodeData.avatar.startsWith("../")) {
                imgAlt = 'greyscaleImage';
                imgSrc = nodeData.avatar.replace("..", API.BASE_IMG_URL);
                imgClassName = c.grayScale;
            } else {
                imgAlt = 'greyscaleImage';
                imgSrc = nodeData.avatar;
                imgClassName = c.grayScale;
            }
        }
        return {
            src: imgSrc,
            alt: imgAlt,
            className: imgClassName
        }
    }, [nodeData]);

    useEffect(() => {
        setNodeData(node);
    }, [node]);

    const genderImg = useMemo(() => getGenderSrc(), [getGenderSrc]);


    const pies = [];
    const pieData = node.avatarPie || node.pieData || node.pie;
    const containPie = pieData?.length > 0;
    const sizeClassName = size === avatarSize.BIG ? c.bigAvatar : size === avatarSize.NORMAL ? c.normalAvatar : c.smallAvatar;

    if (pieData) {
        pieData.forEach(i => pies.push({text: i, color: pieColors[i.toUpperCase()], count: 1}));
    }

    return (
        <div className={containPie ? `${c.avatar} ${sizeClassName} ${c.pieWrapper}` : `${c.avatar} ${sizeClassName}`}
             style={(node.pie || pies.length > 0) ? {
                 backgroundImage: `url(${pies.length > 0 && Utils.makeCanvasImage(pies, new Image())})`
             } : {}}
        >
            <img src={genderImg.src} alt={genderImg.alt} className={genderImg.className}/>
        </div>
    )
}

export default Avatar;

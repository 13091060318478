import React from "react";
import PropTypes from "prop-types";
import {Paper, Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import Utils from "../../../utils";
import {showAlertDialog} from "../../../shared/dialog/actions";
import ShowHitContentDialog from "./ShowHitContentDialog";
import {API, doGet} from "../../../api";
import {newsDisabledContent} from "../../../constants";

export default class Article extends React.Component {
    constructor(props) {
        super(props);
        this.update = false;
        const orderedAnalytics = {
            'Persons': [],
            'Organizations': [],
            'Locations': [],
            'Sentiment': [],
            'Topic': [],
            'Source Quality': []
        }

        props.data.analytics.forEach(item => {
            orderedAnalytics[item.title] = item.values
        })
        this.state = {
            data: props.data,
            show: false,
            showTranslate: false,
            orderedAnalytics,
            anchorEl: null
        }
    }

    getAuthPdf = async (url) => {
        const pdfUrl = `${API.BASE_URL}/pdf?name=${url}`
        try {
            const response = await doGet(pdfUrl, null, true, true);
            const blob = new Blob([response.data], {type: 'application/pdf'});
            let urlPdf = window.URL.createObjectURL(blob);
            window.open(urlPdf)
        } catch {
            showAlertDialog({title: "Error", message: "Try again later."});
        }
    }

    handleClick(event) {
        this.setState({anchorEl: this.state.anchorEl ? null : event.currentTarget});
    };

    handleArticlePin(id, withContent) {
        this.props.togglePinned(id, withContent);
    }

    render() {
        const {data} = this.state;
        const isDisabledContent = data.content.includes(newsDisabledContent);
        const showViewContent = (Utils.isNotNullOrUndefined(this.props.data.content) && this.props.data.content !== '') || (Utils.isNotNullOrUndefined(this.props.data.contentLong) && this.props.data.contentLong !== '');
        const hasTranslation = Utils.isNotNullOrUndefined(data.translation);
        const showViewTranslation = Utils.isNotNullOrUndefined(data.translation) && Utils.isNotNullOrUndefined(data.translation.content) && data.translation.content !== '';
        const isPrint = this.props.print === '?print=true';

        const itemContent = <div style={this.props.isMaximize ? {textAlign: 'left', marginTop: '15px'} : {}}>
            <div
                className={(Utils.isNotNullOrUndefined(data.translation) && (Utils.isNotNullOrUndefined(data.translation.name) || Utils.isNotNullOrUndefined(data.translation.snippet))) ? "col-xs-6" : "col-xs-12"}>
                <b style={{marginRight: '5px', color: '#ef4254', textTransform: 'capitalize'}}>{data.name}</b>
                <br/>
                <i style={{fontWeight: "bold"}}>{data.datePublished}</i>
                <br/>

                <blockquote className="text-left"
                            style={{fontSize: isPrint ? '14px' : '12px', marginBottom: '0px', marginTop: '10px'}}>
                    {Utils.isNotNullOrUndefined(data.imageThumbnail) ?
                        <img alt='imageThumbnail' src={data.imageThumbnail} style={{
                            width: '100px',
                            float: 'left',
                            marginRight: '20px'
                        }}/> : null}
                    <div dangerouslySetInnerHTML={{__html: data.snippet}}/>
                    <div style={{
                        backgroundColor: 'transparent',
                        fontSize: '80%',
                        color: '#777',
                        display: 'flex',
                        alignItems: 'center',
                        margin: '6px 0'
                    }}>Original:&nbsp;
                        {
                            data.report ? <img src="/images/pdf_download.png" alt='pdfDownload' width={35} height={35}
                                               style={{cursor: 'pointer'}}
                                               onClick={() => this.getAuthPdf(data.url)}/> :
                                <a href={data.url} target='_blank' rel="noopener noreferrer"
                                   style={{marginRight: '5px', wordBreak: "break-all"}}>
                                    {data.url}
                                </a>
                        }

                    </div>
                    {showViewContent && <div style={{
                        backgroundColor: 'transparent',
                        fontSize: '80%',
                        color: '#ef4254',
                        fontWeight: 'bold'
                    }} className='pointer pdf-view-content' onClick={() => this.setState({show: true})}>
                        View content
                    </div>}
                </blockquote>
            </div>
            {(Utils.isNotNullOrUndefined(data.translation) && (Utils.isNotNullOrUndefined(data.translation.name) || Utils.isNotNullOrUndefined(data.translation.snippet))) &&
                <div className="col-xs-6" title={data.translation.content}>
                    <b style={{
                        marginRight: '5px',
                        color: '#ef4254',
                        textTransform: 'capitalize'
                    }}>{data.translation.name}</b>
                    <br/>
                    <i style={{fontWeight: "bold"}}>{data.translation.datePublished}</i>
                    <br/>
                    <blockquote className="text-left"
                                style={{fontSize: '14px', marginBottom: '0px', marginTop: '10px'}}>
                        <div dangerouslySetInnerHTML={{__html: data.translation.snippet}}/>
                        {
                            !data.report && <div
                                style={{backgroundColor: 'transparent', fontSize: '80%', color: '#777'}}>Translation: <a
                                style={{marginRight: '5px', wordBreak: "break-all"}}
                                href={"https://translate.google.com/translate?sl=auto&tl=en&u=" + data.url}
                                target='_blank' rel="noopener noreferrer">{data.url}</a></div>
                        }
                        {showViewTranslation && <div style={{
                            backgroundColor: 'transparent',
                            fontSize: '80%',
                            color: '#ef4254',
                            fontWeight: 'bold'
                        }} className='pointer pdf-view-content' onClick={() => this.setState({showTranslate: true})}>
                            View content
                        </div>}
                    </blockquote>
                </div>
            }

        </div>

        return (
            <div
                className={(this.state.data.pinnedWithoutContent || this.state.data.pinnedWithContent) ? "news-pinned-article" : "news-article"}
                style={{border: "none !important"}}>
                <div style={{margin: '0 1.5rem 0 1.5rem', fontFamily: "Roboto, sans-serif", border: "none !important"}}>
                    <Paper elevation={0}
                           className="col-xs-12 right-entity article-paper"
                           style={{
                               borderLeftColor: this.props.borderColor === 'grey' ? '#eee' : this.props.borderColor,
                               boxShadow: isPrint ? 'none' : '0 0 5px 1px #ececec',
                               width: isPrint && '900px'
                           }}>
                        <div
                            className={(this.state.data.analytics.length > 0) ? "col-xs-9 right-entity" : "col-xs-12 right-entity"}>
                            <div className="row">
                                {this.props.print === "" &&
                                    <div className="inline pin-section remove-inPdf">
                                        <div>
                                            <div className="pin-options">
                                                <FormGroup className="inner-options">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={() => this.handleArticlePin(this.state.data.id, true)}
                                                                checkedIcon={<img
                                                                    src={"/images/pressa/pinnedContent.png"}
                                                                    alt={"content"}/>}
                                                                checked={this.state.data.pinnedWithContent || false}/>}
                                                        label="Pin with content"/>
                                                    <FormControlLabel control={
                                                        <Checkbox
                                                            onChange={() => this.handleArticlePin(this.state.data.id, false)}
                                                            checkedIcon={<img src={"/images/pressa/pinned.png"} alt="without-content"/>}

                                                            checked={this.state.data.pinnedWithoutContent || false}/>}
                                                                      label="Pin without content"/>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {itemContent}
                        </div>
                        {
                            this.state.data.analytics.length > 0 &&
                            <div className="col-xs-3"
                                 style={{padding: '0 1%', borderLeft: "5px solid #eee", cursor: "default"}}>
                                <p style={{fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>ANALYSIS</p>
                                {Object.keys(this.state.orderedAnalytics).map((each, i) => {
                                        const item = this.state.orderedAnalytics[each];
                                        if (item.length > 0) {
                                            let allValues = []
                                            return (
                                                <div key={`${each}_${i}`}
                                                     style={{
                                                         paddingTop: '1rem',
                                                         margin: "0 3px",
                                                         fontSize: "13px",
                                                     }}
                                                >
                                                    <span style={{padding: "5px 5px 5px 0"}}>
                                                        {each}:
                                                    </span>
                                                    {item.map((val, j) => {
                                                        allValues.push(val.title)
                                                        if (j >= 2) {
                                                            return null;
                                                        } else {
                                                            return (
                                                                <span
                                                                    key={`value_${j}_${val.title}`}
                                                                    onClick={() => this.openDialog(each, allValues)}
                                                                    style={{
                                                                        color: val.color,
                                                                        cursor: item.length > 2 ? 'pointer' : 'default'
                                                                    }}>
                                                                        {val.title}{(item.length > 1 && j < 1) && ', '}{(j >= 1 && item.length > 2) ? ' ...' : ''}
                                                                </span>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            )
                                        } else return null;
                                    }
                                )
                                }
                            </div>
                        }

                        <div className="article-pdf-content col-xs-12 article-content-hide">
                            {
                                !isDisabledContent && data.pinnedWithContent &&
                                <div dangerouslySetInnerHTML={{__html: this.state.data.content}}/>
                            }
                        </div>
                        {Utils.isNotNullOrUndefined(data.searchEngineUsed) &&
                            <div style={{position: "absolute", right: "10px", bottom: "10px"}}>
                                <img alt='searchEngine'
                                     src={"/images/searchEngine/" + data.searchEngineUsed.toLowerCase() + ".png"}
                                     style={data.searchEngineUsed === 'polixis' ? {
                                         cursor: "pointer",
                                         height: "20px",
                                         maxWidth: "80px",
                                     } : {
                                         height: "20px",
                                         maxWidth: "80px",
                                     }} title={"Search Engine Used: " + data.searchEngineUsed}/>
                            </div>
                        }

                        {(data.matchRatio || data.matchDescription) &&
                            <div className="col-xs-12 " style={{paddingTop: "15px"}}>
                                {data.matchRatio &&
                                    <span className="text-info">Matching rate: {data.matchRatio} %  </span>}
                                {data.matchDescription && <span style={{
                                    color: "#777",
                                    marginLeft: "10px"
                                }}> {data.matchDescription}</span>}</div>}
                    </Paper>
                    {(this.props.isMaximize && this.props.classLine) ?
                        <div style={{display: 'flex', width: '100%'}} className='remove-inPdf'>
                            <div style={{
                                borderBottom: '2px dotted #777777',
                                width: '48%',
                                margin: '40px 0 60px',
                            }}/>
                            <div style={{
                                width: '4%',
                                marginTop: '31px',
                                textAlign: "center"
                            }}>{this.props.classLine}</div>
                            <div style={{
                                borderBottom: '2px dotted #777777',
                                width: '48%',
                                margin: '40px 0 60px',
                                textAlign: 'center',
                            }}
                            />
                        </div> : null}
                </div>
                {showViewContent && this.state.show && <ShowHitContentDialog
                    key={data.id + 'dialog'}
                    subTitleData={{
                        name: data.name,
                        datePublished: data.datePublished,
                        provider: data.provider
                    }}
                    content={{content: data.contentLong || data.content}}
                    handleClose={() => this.setState({show: false})}/>}

                {showViewContent && showViewTranslation && this.state.showTranslate && <ShowHitContentDialog
                    key={data.id + 'dialog-translation'}
                    subTitleData={{
                        name: hasTranslation && Utils.isNotNullOrUndefined(data.translation.name) ? data.translation.name : data.name,
                        datePublished: data.datePublished,
                        provider: data.provider
                    }}
                    content={{
                        content: hasTranslation && Utils.isNotNullOrUndefined(data.translation.content) ? data.translation.content : data.content
                    }}
                    handleClose={() => this.setState({showTranslate: false})}/>}
            </div>
        )
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            data: nextProps.data,
        })
    }

    openDialog(title, items) {
        if (items.length >= 3) {
            showAlertDialog({
                title: <div style={{color: 'gray', marginBottom: "10px", borderBottom: "1px solid #ccc"}}>{title}</div>,
                message: <ul>{items.map((i, k) => (<li key={`${k}_${i}`}>{i}</li>))}</ul>,
            })
        }
    }
}

Article.propTypes = {
    data: PropTypes.object,
    isMaximize: PropTypes.bool,
    print: PropTypes.string,
    togglePinned: PropTypes.func,
};

import React from "react";
import PropTypes from "prop-types";

export default class EntityItem extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            checked: this.props.checked,
            isSelected: this.props.isSelected,
            update: false
        };
    }

    render() {
        const elementIds = this.props.elementIds.slice( 0 );
        const legendItems = [];
        const checked = this.state.checked;
        const checkboxImage = checked ? "/images/center_checkbox_checked.png" : "/images/center_checkbox.png";
        this.props.node.subNodes.forEach( ( elementData, index ) => {

            if ( elementIds.length > 0 && elementIds.indexOf( elementData.id ) === -1 ) {
                const isSelected = this.state.selectedNode ? this.state.selectedNode.id === elementData.id : false;
                legendItems.push( <EntityItem
                    checked={ elementData.isVisible }
                    key={ "item-" + index }
                    isSelected={ isSelected }
                    checkboxClick={ ( node ) => this.props.checkboxClick( node ) }
                    onNodeSelect={ ( node ) => this.props.onNodeSelect( node ) }
                    toggleMaximize={ () => this.props.toggleMaximize() }
                    node={ elementData }
                    elementIds={ elementIds }
                /> );
                elementIds.push( elementData.id );
            }
        } );

        return (
            <div className={ "entity-list" + (this.state.isSelected ? " select" : "") }>
                <table className="entity-table">
                    <tbody>
                    <tr>
                        <td className="td-1">
                            <img alt='entityItem' src={ checkboxImage } checked={ this.state.checked }
                                 onClick={ () => this.entityCheckboxClicked() } className="entity-item pointer"/>
                        </td>
                        <td className="td-2">
                            <div className="entity-label pointer"
                                 onClick={ () => this.props.onNodeSelect( this.props.node ) }>
                                <span>{ this.props.node.title }</span>
                            </div>
                        </td>
                        <td className="text-center td-3">
                            <div className={ this.props.node.hasImage ? 'avatar-color' : 'avatar-color' }>
                                <img alt='avatarColor' src={ this.props.node.avatar }/></div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div style={ { paddingLeft: "6px" } }>
                    { legendItems }
                </div>
            </div>
        );
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        this.setState( { checked: nextProps.checked, isSelected: nextProps.isSelected } );
        if ( this.props.update !== nextProps.update ) {
            this.setState( { update: true } );
        }
    }

    entityCheckboxClicked() {
        this.props.checkboxClick( this.props.node );
        this.setState( { checked: this.props.node.checked } );
    }
}

EntityItem.propTypes = {
    node: PropTypes.object.isRequired,
    checked: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    checkboxClick: PropTypes.func.isRequired,
    onNodeSelect: PropTypes.func.isRequired,
    toggleMaximize: PropTypes.func.isRequired,
    elementIds: PropTypes.array
};

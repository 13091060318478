import {applyMiddleware, createStore, compose} from "redux";
import thunk from 'redux-thunk';
import {INITIAL_STATE} from "../constants";
import { composeWithDevTools } from '@redux-devtools/extension';
import {rootReducer} from "./rootReduces";

const composeEnhancer = composeWithDevTools || compose;

const appStore = createStore(rootReducer, INITIAL_STATE, composeEnhancer(applyMiddleware(thunk)));

export default appStore;

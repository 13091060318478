import './App.scss';
import {StyledEngineProvider} from '@mui/material/styles';
import {useCustomRoutes} from "./routes";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import PolixisDialog from "./shared/dialog/components/Dialog.jsx";

const muiTheme = createTheme({
    palette: {
        primary: {main: '#ef4254'}
    },
});


function App() {
    const routes = useCustomRoutes();
    return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={muiTheme}>
                    <div>
                        <BrowserRouter>
                            {routes}
                        </BrowserRouter>
                        <PolixisDialog />
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
    );
}

export default App;

import {openDB, deleteDB} from 'idb';

class ReportIndexed {
    static dbNameStatic = "explore";
    constructor() {
        this.db = null;
        this.request = null;
        this.version = 1;
    }

    async init() {
        await openDB(ReportIndexed.dbNameStatic, this.version, {
            upgrade(db) {
                db.createObjectStore('report', {
                    keyPath: 'entityId',
                    autoIncrement: true,
                });
            }
        })
    }

    async insert(data, id) {
        const db = await openDB(ReportIndexed.dbNameStatic, this.version);
        const storeExist = this.checkStore(db);
        return new Promise((resolve, reject) => {
            if (storeExist) {
                try {
                    let transaction = db.transaction("report", "readwrite");
                    let report = transaction.objectStore("report");
                    const exist = report.get(id);
                    if (exist) {
                        report.delete(id);
                    }
                    const entity = {
                        title: "node",
                        node: JSON.stringify(data.node),
                        nodeProps: JSON.stringify(data.nodeProps),
                        entityId: id
                    };

                    report.add(entity);
                    const query = transaction.done;
                    resolve(query);
                } catch (err) {
                    reject("Insertion error.");
                }
            } else {
                reject("Insertion error.");

            }
        })

    }

    async resetDbs() {
        await deleteDB(ReportIndexed.dbNameStatic);
        this.init();
    }

    async checkStore(db) {
        return db.objectStoreNames.contains('report');
    }

    async query(id) {
        const db = await openDB(ReportIndexed.dbNameStatic, this.version);
        return new Promise((resolve, reject) => {
            try {
                let transaction = db.transaction("report", "readonly");
                let report = transaction.objectStore("report");
                const query = report.get(id);
                resolve(query);
            } catch {
                reject('Querying error');
            }
        })
    }

    async delete(id) {
        const db = await openDB(ReportIndexed.dbNameStatic, this.version);
        try {
            let transaction = db.transaction("report", "readwrite");
            let report = transaction.objectStore("report");
            await report.delete(id);
            await transaction.done;
        } catch {
            console.log('Deleting error')
        }
    }

    async dropDbInstance() {
        await ReportIndexed.dropDb();
    }

    static async dropDb() {

        await deleteDB(ReportIndexed.dbNameStatic);
    }
}

export default ReportIndexed;
